import React from 'react';
function Chashama2024Tab({ TabValues, side, history }) {
  const onRadioChange = (type) => {
    history.push({
      pathname: `/chashama-2024/${type}`,
      state: { side: type },
    });
  };

  return (
    <div>
      <ul className='chashama2024Tab mb-0 '>
        {TabValues.map((TabValue) => (
          <li key={TabValue.value} className='mr-3 -mt-3'>
            <input
              type='radio'
              id={TabValue.value}
              checked={side === TabValue.value}
              name='filter'
              value={TabValue.value}
              onChange={() => onRadioChange(TabValue.value)}
            />
            <label className='pointer' htmlFor={TabValue.value}>
              {TabValue.label}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Chashama2024Tab;
