import React, { Suspense, useState } from 'react';

import { HashRouter, Route, Switch } from 'react-router-dom';

import NavbarComponent from './components/NavbarComponent';
import Cities from './pages/Cities';
import CreateEventScreen from './pages/CreateEventScreen';
import ErrorPage from './pages/ErrorPage';
import EventDetailsPage from './pages/EventDetailsPage';
import EventsMapAndList from './pages/MainPage';
import NewEventDetailsPage from './pages/NewEventDetailPage';

import ArtEvent from './pages/ArtEvent';

// import OrganizationEventDetails from "./pages/OrganizationEventDetails";
// import OrganizationProfileUpdate from "./pages/OrganizationProfileUpdate";
// import OrganizationProfile from "./pages/OrganizationProfile";
// import LoginPage from "./pages/LoginPage";
// TODO: noFilter and ShowBackButton props code restructure

import './color.css';
import { TransactionProvider } from './context/TransactionContext';
import { Loader } from './helpers/helpers';
import './typography.css';
import AiopDress from './pages/AiopDress';
import ChashamaGala2024ArtEvent from './components/ChashamaGala2024/ChashamaGala2024ArtEvent';

function App() {
  const [cityInLocalStorage, setCityInLocalStorage] = useState(false);
  const [isNav, setIsNav] = useState(true);
  // const [isUserOrganizer, setIsUserOrganizer] = useState(false);
  const [isCreateEventbtnVisible, setIsCreateEventbtnVisible] = useState(true);
  const [isSearchBarVisible, setIsSearchbarVisible] = useState(true);
  return (
    <TransactionProvider>
      <div className='App bg-grey'>
        {/* Private Route */}
        <HashRouter>
          <Suspense fallback={<Loader />}>
            {window == window.parent && (
              <NavbarComponent
                cityInLocalStorage={cityInLocalStorage}
                isCreateEventbtnVisible={isCreateEventbtnVisible}
                isNav={isNav}
                isSearchBarVisible={isSearchBarVisible}
              />
            )}
            <Switch>
              <Route exact path='/'>
                <Cities
                  setCityInLocalStorage={setCityInLocalStorage}
                  setIsNav={setIsNav}
                  setIsSearchbarVisible={setIsSearchbarVisible}
                  setIsCreateEventbtnVisible={setIsCreateEventbtnVisible}
                />
              </Route>
              <Route exact path='/createEvent'>
                <CreateEventScreen
                  setIsSearchbarVisible={setIsSearchbarVisible}
                  setIsNav={setIsNav}
                  setIsCreateEventbtnVisible={setIsCreateEventbtnVisible}
                />
              </Route>

              {/* TODO: after chashama-2024 event remove below routes */}

              <Route exact path='/chashama-gala-2024'>
                <ChashamaGala2024ArtEvent
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>
              <Route exact path='/chashama-2024'>
                <ChashamaGala2024ArtEvent
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>
              <Route exact path='/chashama-2024/:side'>
                <ChashamaGala2024ArtEvent
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>

              {/* Remove till here */}

              <Route exact path='/:path/:cityId'>
                <NewEventDetailsPage
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>

              <Route
                exact
                path='/:filterType/:cityName/:id/:eventname/:cityId/:Page_no'
              >
                <EventDetailsPage
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>
              <Route exact path='/:filterType/:cityName/:id/'>
                <EventsMapAndList
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>
              <Route exact path='/:filterType/:cityName/:id/:cityId/:Page_no'>
                <EventsMapAndList
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>
              {/* <Route exact path="/make-us-visible">
                <MakeUsVisible
                  setIsSearchbarVisible={setIsSearchbarVisible}
                  setIsNav={setIsNav}
                />
              </Route>
              <Route exact path="/make-us-visible-documentation">
                <MakeUsVisible
                  setIsSearchbarVisible={setIsSearchbarVisible}
                  setIsNav={setIsNav}
                />
              </Route> */}

              {/* This is used to add is_back=false && is_nav=false in url
              so that we can fetch them from url by default they both will be true
              example -> http://localhost:3000/#/link-by-stilllife?is_back=true&is_nav=false
              default url example -> http://localhost:3000/#/link-by-stilllife
              */}
              <Route exact path='/2023-AiOP-DRESS'>
                <AiopDress
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>

              <Route exact path='/:exhibition_link'>
                <ArtEvent
                  setIsNav={setIsNav}
                  setCityInLocalStorage={setCityInLocalStorage}
                />
              </Route>

              <Route exact path='/*'>
                <ErrorPage />
              </Route>

              {/* <Route exact path="/eventDetails">
            <OrganizationEventDetails />
          </Route> */}

              {/* <Route exact path="/organizationProfile">
            <OrganizationProfile
              // setIsUserOrganizer={setIsUserOrganizer}
              setIsCreateEventbtnVisible={setIsCreateEventbtnVisible}
              setIsSearchbarVisible={setIsSearchbarVisible}
              setIsNav={setIsNav}
            />
          </Route> */}

              {/* <Route exact path="/login">
            <LoginPage setIsNav={setIsNav} />
          </Route> */}
            </Switch>
          </Suspense>
        </HashRouter>
      </div>
    </TransactionProvider>
  );
}

export default App;
