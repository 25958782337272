import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_BACK_IMAGE_URL } from '../Constants/Constant';
import '../components/DiscoverLocation.css';
import '../components/EventComponent.css';
import { getArtistName } from '../helpers/util';
import EventDetailScreen from '../pages/EventDetailScreen';
import useQuery from '../hooks/useQuery';

function EventCardComponent(props) {
  const location = useLocation();
  const history = useHistory();
  let query = useQuery();
  const eventId = query.get('eventId');

  const hideDateIncard = location.pathname.includes('chashama-2024');

  const handleDateFormat = (timings, startDate, endDate) => {
    let date = timings.split(' ');
    date[0] = date[0].slice(0, 3);
    date[3] = date[3].slice(0, 3);
    if (startDate === endDate) {
      return date[3] + ' ' + date[4] + ' ' + date[5];
    }
    return date.join(' ');
  };

  const handleLearnMore = () => {
    if (!props.isLearnMore) {
      props.setCurrentlyViewingLocationID(props.data.id);
    } else {
      props.setCurrentlyViewingLocationID(null);
    }
    props.setLearnMore(!props.isLearnMore);
  };

  const handleRedirectNewTab = (redirectLink) => {
    redirectLink && window.open(redirectLink, '_blank').focus();
  };

  const redirectLink = (id) => {
    history.push(location.pathname + '/' + id);
  };

  if (props.currentlyViewingLocationID) {
    if (props.currentlyViewingLocationID != props.data.id) {
      return null;
    }
  }
  return (
    <div>
      {props.isLearnMore ? (
        <EventDetailScreen eventDetails={props.data} />
      ) : (
        <div
          style={
            props?.data?.id === props.currentEid ||
            props?.data?.id === parseInt(eventId)
              ? {
                  border: '2px solid #E0F752',
                  boxShadow: '4px 2px 7px 6px rgba(0, 0, 255)',
                }
              : { border: 'none' }
          }
          className=' d-flex event-cards-desktop bg-white p-2 mb-3  br--12'
          onClick={() => {
            location.pathname === '/2023-AiOP-DRESS'
              ? redirectLink(props?.data?.id)
              : handleLearnMore();
          }}
        >
          <div className='ta-start '>
            {props?.data?.images && (
              <ImageComponent
                url={
                  props?.data?.images?.length > 0
                    ? props?.data?.images[0]?.image_file
                    : DEFAULT_BACK_IMAGE_URL
                }
              />
            )}
          </div>
          <div className='ml-3 py-1 display-direction-column justify-content-between w-100'>
            <div>
              <p
                style={{ fontSize: '24px', lineHeight: '32px' }}
                className='dot-3 color-black exhibition-name  mb-2 ta-start'
              >
                {props.data?.name}
              </p>
              {!props.onlyArtistName ? (
                <>
                  {props?.data?.artist?.length > 0 && (
                    <>
                      <hr className='mt-2' />
                      <p>
                        By
                        <span className='event-card-artist'>
                          {' '}
                          {getArtistName(
                            props?.data?.artist[0]?.first_name,
                            props?.data?.artist[0]?.last_name
                          )}
                        </span>
                        {props.data?.artist?.[1] && ' & '}
                        <span className='event-card-artist'>
                          {props.data?.artist?.[1] &&
                            getArtistName(
                              props?.data?.artist[1]?.first_name,
                              props?.data?.artist[1]?.last_name
                            )}
                        </span>
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className='mb-1 event-desc-1-line ta-start '>
                    {props.data.type === 'artist'
                      ? props.data.artist_name
                      : props.data.address}
                  </p>

                  {props?.cid == 198 && props?.data?.artist.length > 0 && (
                    <>
                      <hr className='mt-2' />
                      <p className='mb-1 text-left'>Artist:</p>
                    </>
                  )}
                  {!hideDateIncard && (
                    <p className='mb-0 event-desc-1-line ta-start '>
                      {props.data.type === 'artist'
                        ? `Booth No : ${props.data.booth_number}`
                        : props?.cid == 198
                        ? getArtistName(
                            props?.data?.artist[0]?.first_name,
                            props?.data?.artist[0]?.last_name
                          )
                        : handleDateFormat(
                            props.data?.timings,
                            props.data.start_date,
                            props.data.end_date
                          )}
                    </p>
                  )}
                  <p className='mt-1 mb-0 event-desc-1-line ta-start '>
                    {props.data.type === 'artist'
                      ? `No of artworks : ${props.data.images.length}`
                      : null}
                  </p>
                </>
              )}
            </div>
            <div>
              <div className='d-flex justify-content-between pr-3'>
                {/* <div className="d-flex align-items-center">
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsLiked(!isLiked);
                    }}
                    src={isLiked ? RedHeart : BlackHeart}
                    height="18px"
                    width="20px"
                  />
                  <p className="mb-0 ml-2 view-website color-black">
                    Add to favourites
                  </p>
                </div> */}
                {/* <div className="d-flex align-items-center">
                  <img src={BlackShareBtn} />
                  <p className="mb-0 ml-2 view-website color-black">Share</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ImageComponent(props) {
  return (
    <div
      onClick={props.handleArtworkImage}
      className='flex-direction-row pointer'
    >
      <img
        loading='lazy'
        className='image-container-3 br--12 object-fit-image'
        src={props.url}
        alt='props'
      />
    </div>
  );
}

export default EventCardComponent;
