import React from 'react';
import useQuery from '../../hooks/useQuery';
import ChashamaGala2024Slide1 from './ChashamaGala2024Slide1';
import ChashamaGala2024Slide2 from './ChashamaGala2024Slide2';

const ChashamaGala2024Booth = ({ setEid, eid }) => {
  const query = useQuery();
  const currentSlide = query.get('tab');

  if (currentSlide === 'left-side') {
    return <ChashamaGala2024Slide1 eid={eid} setEid={setEid} />;
  }

  return <ChashamaGala2024Slide2 eid={eid} setEid={setEid} />;
};

export default ChashamaGala2024Booth;
