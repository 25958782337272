import React from 'react';
import './chashmaBooth2024.css';

const ChashamaGala2024Slide1 = ({ setEid, eid }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1548 2160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='1548' height='2160' fill='white' />
      <path
        d='M1110.13 1971.13H1071.46V1986.11H1110.13V1971.13Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1189.36 1956.16H1157.89V1986.11H1189.36V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1275.8 1971.13H1237.13V1986.11H1275.8V1971.13Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1369.63 1956.16H1323.57V1986.11H1369.63V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.99 1956.16V1923.11H977.62V1951.04V1986.11H1023.69V1956.16H989.99Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M275.48 315.57H293.07V308.05H275.48H245.24V1613.58H275.48H462.56V1825.69H495.92H977.62V1832.69H989.38V1664.3H972.03V1787.4H495.92V1575.28H462.56H275.48V315.57Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.37 1870.06H977.62V1885.75H989.37V1870.06Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M962.17 308.05V315.57H985.03V291.23H978.73V308.05H962.17Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1009.48 117.53H978.73V146.67H1009.48V117.53Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1218.32 486.32H1205.75V907.17H1218.32V486.32Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M718.41 1136.53H713.75V1183.19H718.41V1136.53Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M718.41 1040.85H713.75V1100.79H718.41V1040.85Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.261 720.91V830.28H890.601V834.9H989.261H995.281V653.33H989.261H810.811V720.91V834.27H815.441V720.91H989.261Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M934.79 1173V1115.04'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1173H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.381 1545.23H1123.48V1375.33H1205.75H1218.32V949.77H1205.75H1146.21V878H1009.9V943.8H977.791H972.031V1065.15H897.561V898.54H977.791V878H897.561H810.811H474.221V886.24H639.651V981.77V1263.22H643.851H718.411V1258.12V1218.04H713.751V1258.12H643.851V981.77H715.381V1005.77H720.041V981.77V886.24H810.811V1263.22H897.561V1072.78H972.031V1222.04H977.791V949.77H1009.9H1058.6V1054.39H1205.75V1355.12H977.791V1308.17V1222.04H937.311V1303.04H841.331V1308.17H937.311H972.031V1355.12V1375.33V1403.93H905.401H816.161V1303.04H811.041V1403.93V1415.82H905.401V1444.94H972.031V1635.83H989.381V1545.23Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1165.75H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1158.51H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1151.26H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1144.02H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1115.04H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1122.28H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1129.53H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1136.77H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M934.79 964.931V1022.89'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1321.16 117.88H1302.95V131.79H1321.16V117.88Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 964.931H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1221.06 131.79H1256.64V117.53H1221.06H1205.75H1175V146.68H1205.75V459.56H1221.06V131.79Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1001.16H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1008.4H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1015.65H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 1022.89H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 993.91H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 986.67H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 979.42H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M972.031 972.181H897.561'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1302.95 117.88H1256.64V131.79H1302.95V117.88Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1175 117.53H1009.48V123.83H1175V117.53Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M985.031 146.681H978.73V291.231H985.031V146.681Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M962.17 308.05H293.07V315.57H962.17V308.05Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.37 1832.69H977.62V1870.06H989.37V1832.69Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.37 1885.74H977.62V1923.11H989.37V1885.74Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1071.46 1974.35H1023.69V1986.1H1071.46V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1323.57 1974.35H1275.8V1986.1H1323.57V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1237.13 1974.35H1189.36V1986.1H1237.13V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1157.9 1974.35H1110.13V1986.1H1157.9V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M933.73 332.6H862.69V358.49H933.73V332.6Z'
        stroke='#007F00'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M639.33 332.6H568.29V358.49H639.33V332.6Z'
        stroke='#007F00'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M568.29 332.6H497.25V358.49H568.29V332.6Z'
        stroke='#007F00'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M862.69 332.6H791.65V358.49H862.69V332.6Z'
        stroke='#007F00'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1154.08 227.24H1132.41V301.89H1154.08V227.24Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1154.08 152.58H1132.41V227.23H1154.08V152.58Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1135.61 249.76H1137.28L1137.26 253.25C1137.26 254.2 1137.43 254.88 1137.8 255.28C1138.17 255.68 1138.64 255.88 1139.24 255.88H1139.27C1139.91 255.88 1140.42 255.72 1140.8 255.38C1141.18 255.04 1141.37 254.49 1141.38 253.71L1141.4 249.79H1143.03L1143.01 253.72C1143.01 254.39 1143.16 254.89 1143.48 255.23C1143.8 255.57 1144.25 255.74 1144.86 255.74C1145.5 255.74 1145.98 255.56 1146.3 255.18C1146.62 254.8 1146.78 254.24 1146.79 253.49L1146.81 249.81H1148.48L1148.46 253.82C1148.46 255.09 1148.13 256.03 1147.5 256.64C1146.87 257.25 1146.02 257.56 1144.96 257.56C1144.32 257.56 1143.73 257.37 1143.2 256.99C1142.67 256.61 1142.33 256.02 1142.19 255.21C1142.07 256 1141.71 256.62 1141.13 257.06C1140.55 257.5 1139.88 257.71 1139.12 257.71H1139.09C1138.04 257.71 1137.2 257.36 1136.56 256.68C1135.92 256 1135.6 255.08 1135.61 253.92L1135.63 249.77L1135.61 249.76ZM1135.61 248.7L1148.46 248.76V250.55L1135.6 250.49V248.7H1135.61Z'
        fill='black'
      />
      <path
        d='M1135.56 258.88L1148.38 263.68V265.22L1135.5 269.89V267.95L1145.89 264.44L1135.54 260.83V258.89L1135.56 258.88ZM1138.37 261.07H1140.08L1140.05 267.84H1138.34L1138.37 261.07Z'
        fill='black'
      />
      <path
        d='M1135.46 279.06L1141.31 276.15L1141.71 277.95L1135.46 281.21V279.07V279.06ZM1135.5 271.83L1148.36 271.89V273.69L1135.5 273.63V271.83ZM1140.93 272.64H1142.64L1142.62 277.03C1142.62 277.55 1142.8 277.98 1143.16 278.3C1143.52 278.62 1144.01 278.78 1144.61 278.78C1145.21 278.78 1145.69 278.63 1146.06 278.31C1146.43 277.99 1146.61 277.57 1146.61 277.05L1146.63 272.67H1148.34L1148.32 276.99C1148.32 277.74 1148.16 278.39 1147.85 278.95C1147.54 279.51 1147.1 279.94 1146.55 280.25C1145.99 280.56 1145.34 280.71 1144.6 280.7C1143.86 280.7 1143.21 280.54 1142.65 280.22C1142.1 279.91 1141.66 279.47 1141.36 278.91C1141.05 278.35 1140.9 277.7 1140.91 276.95L1140.93 272.64Z'
        fill='black'
      />
      <path
        d='M1135.61 179.75H1137.28L1137.26 183.24C1137.26 184.19 1137.43 184.87 1137.8 185.27C1138.17 185.67 1138.64 185.87 1139.24 185.87H1139.27C1139.91 185.87 1140.42 185.71 1140.8 185.37C1141.18 185.03 1141.37 184.48 1141.38 183.7L1141.4 179.78H1143.03L1143.01 183.71C1143.01 184.38 1143.16 184.88 1143.48 185.22C1143.8 185.56 1144.25 185.73 1144.86 185.73C1145.5 185.73 1145.98 185.55 1146.3 185.17C1146.62 184.79 1146.78 184.23 1146.79 183.48L1146.81 179.8H1148.48L1148.46 183.81C1148.46 185.08 1148.13 186.02 1147.5 186.63C1146.87 187.24 1146.02 187.55 1144.96 187.55C1144.32 187.55 1143.73 187.36 1143.2 186.98C1142.67 186.6 1142.33 186.01 1142.19 185.2C1142.07 185.99 1141.71 186.61 1141.13 187.05C1140.55 187.49 1139.88 187.7 1139.12 187.7H1139.09C1138.04 187.7 1137.2 187.35 1136.56 186.67C1135.92 185.99 1135.6 185.07 1135.61 183.91L1135.63 179.76L1135.61 179.75ZM1135.61 178.69L1148.46 178.75V180.54L1135.6 180.48V178.69H1135.61Z'
        fill='black'
      />
      <path
        d='M1135.56 188.87L1148.38 193.67V195.21L1135.5 199.88V197.94L1145.89 194.43L1135.54 190.82V188.88L1135.56 188.87ZM1138.37 191.07H1140.08L1140.05 197.84H1138.34L1138.37 191.07Z'
        fill='black'
      />
      <path
        d='M1135.46 209.05L1141.31 206.14L1141.71 207.94L1135.46 211.2V209.06V209.05ZM1135.5 201.82L1148.36 201.88V203.68L1135.5 203.62V201.82ZM1140.93 202.64H1142.64L1142.62 207.03C1142.62 207.55 1142.8 207.98 1143.16 208.3C1143.52 208.62 1144.01 208.78 1144.61 208.78C1145.21 208.78 1145.69 208.63 1146.06 208.31C1146.43 207.99 1146.61 207.57 1146.61 207.05L1146.63 202.67H1148.34L1148.32 206.99C1148.32 207.74 1148.16 208.39 1147.85 208.95C1147.54 209.51 1147.1 209.94 1146.55 210.25C1145.99 210.56 1145.34 210.71 1144.6 210.7C1143.86 210.7 1143.21 210.54 1142.65 210.22C1142.1 209.91 1141.66 209.47 1141.36 208.91C1141.05 208.35 1140.9 207.7 1140.91 206.95L1140.93 202.64Z'
        fill='black'
      />
      <path
        d='M512.94 343.54V335.73H514.03V343.54H512.94ZM513.58 343.54V342.52H515.7C516.28 342.52 516.69 342.41 516.93 342.19C517.17 341.97 517.29 341.68 517.29 341.31V341.29C517.29 340.9 517.19 340.59 516.98 340.36C516.77 340.13 516.44 340.01 515.96 340.01H513.58V339.02H515.96C516.36 339.02 516.67 338.92 516.88 338.73C517.09 338.54 517.19 338.26 517.19 337.89C517.19 337.5 517.08 337.21 516.85 337.01C516.62 336.81 516.28 336.72 515.82 336.72H513.59V335.7H516.02C516.79 335.7 517.36 335.89 517.74 336.27C518.12 336.65 518.3 337.17 518.3 337.81C518.3 338.2 518.19 338.56 517.96 338.88C517.73 339.2 517.37 339.41 516.88 339.5C517.36 339.57 517.74 339.78 518.01 340.14C518.28 340.49 518.41 340.9 518.41 341.36V341.38C518.41 342.02 518.2 342.53 517.79 342.92C517.38 343.31 516.82 343.51 516.11 343.51H513.59L513.58 343.54Z'
        fill='black'
      />
      <path
        d='M522.51 343.62C521.64 343.62 520.97 343.37 520.49 342.86C520.01 342.35 519.771 341.64 519.771 340.71V335.73H520.87V340.75C520.87 341.32 521.01 341.76 521.3 342.07C521.59 342.38 521.99 342.54 522.51 342.54C523.03 342.54 523.44 342.38 523.73 342.07C524.02 341.76 524.16 341.32 524.16 340.75V335.73H525.26V340.71C525.26 341.63 525.02 342.35 524.54 342.86C524.06 343.37 523.38 343.62 522.51 343.62Z'
        fill='black'
      />
      <path
        d='M526.98 343.54V335.73H528.081V343.54H526.98ZM527.51 336.77V335.73H531.891V336.77H527.51ZM527.51 340.25V339.21H531.3V340.25H527.51Z'
        fill='black'
      />
      <path
        d='M533.23 343.54V335.73H534.331V343.54H533.23ZM533.771 336.77V335.73H538.151V336.77H533.771ZM533.771 340.25V339.21H537.56V340.25H533.771Z'
        fill='black'
      />
      <path
        d='M539.49 343.54V335.73H540.59V343.54H539.49ZM540.02 336.77V335.73H544.5V336.77H540.02ZM540.02 340.18V339.14H543.91V340.18H540.02ZM540.02 343.54V342.5H544.5V343.54H540.02Z'
        fill='black'
      />
      <path
        d='M545.211 336.77V335.73H550.721V336.77H545.211ZM547.411 343.54V336.37H548.511V343.54H547.411Z'
        fill='black'
      />
      <path
        d='M516.601 349.97V348.93H522.111V349.97H516.601ZM518.801 356.74V349.57H519.901V356.74H518.801Z'
        fill='black'
      />
      <path
        d='M521.94 356.74L524.82 348.93H525.75L528.63 356.74H527.45L525.29 350.44L523.13 356.74H521.95H521.94ZM523.27 355.03V353.99H527.38V355.03H523.27Z'
        fill='black'
      />
      <path
        d='M529.82 356.74V348.93H530.91V356.74H529.82ZM530.46 356.74V355.72H532.58C533.16 355.72 533.57 355.61 533.81 355.39C534.05 355.17 534.17 354.88 534.17 354.51V354.49C534.17 354.1 534.07 353.79 533.86 353.56C533.65 353.33 533.31 353.21 532.84 353.21H530.46V352.22H532.84C533.24 352.22 533.55 352.12 533.76 351.93C533.97 351.74 534.07 351.46 534.07 351.09C534.07 350.7 533.96 350.41 533.73 350.21C533.5 350.01 533.16 349.92 532.7 349.92H530.47V348.9H532.9C533.67 348.9 534.24 349.09 534.62 349.47C535 349.85 535.18 350.37 535.18 351.01C535.18 351.4 535.07 351.76 534.84 352.08C534.61 352.4 534.25 352.61 533.76 352.7C534.24 352.77 534.62 352.98 534.88 353.34C535.15 353.69 535.28 354.1 535.28 354.56V354.58C535.28 355.22 535.07 355.73 534.66 356.12C534.25 356.51 533.69 356.71 532.98 356.71H530.46V356.74Z'
        fill='black'
      />
      <path
        d='M536.92 356.74V348.93H538.02V356.74H536.92ZM537.47 356.74V355.7H541.94V356.74H537.47Z'
        fill='black'
      />
      <path
        d='M543.23 356.74V348.93H544.331V356.74H543.23ZM543.76 349.97V348.93H548.24V349.97H543.76ZM543.76 353.38V352.34H547.651V353.38H543.76ZM543.76 356.74V355.7H548.24V356.74H543.76Z'
        fill='black'
      />
      <path
        d='M582.66 343.54V335.73H583.75V343.54H582.66ZM583.3 343.54V342.52H585.42C586 342.52 586.41 342.41 586.65 342.19C586.89 341.97 587.01 341.68 587.01 341.31V341.29C587.01 340.9 586.91 340.59 586.7 340.36C586.49 340.13 586.16 340.01 585.68 340.01H583.3V339.02H585.68C586.08 339.02 586.39 338.92 586.6 338.73C586.81 338.54 586.91 338.26 586.91 337.89C586.91 337.5 586.8 337.21 586.57 337.01C586.34 336.81 586 336.72 585.54 336.72H583.31V335.7H585.74C586.51 335.7 587.08 335.89 587.46 336.27C587.84 336.65 588.02 337.17 588.02 337.81C588.02 338.2 587.91 338.56 587.68 338.88C587.45 339.2 587.09 339.41 586.6 339.5C587.08 339.57 587.46 339.78 587.73 340.14C588 340.49 588.13 340.9 588.13 341.36V341.38C588.13 342.02 587.92 342.53 587.51 342.92C587.1 343.31 586.54 343.51 585.83 343.51H583.31L583.3 343.54Z'
        fill='black'
      />
      <path
        d='M592.23 343.62C591.36 343.62 590.69 343.37 590.21 342.86C589.73 342.35 589.49 341.64 589.49 340.71V335.73H590.59V340.75C590.59 341.32 590.73 341.76 591.02 342.07C591.31 342.38 591.71 342.54 592.23 342.54C592.75 342.54 593.16 342.38 593.45 342.07C593.74 341.76 593.88 341.32 593.88 340.75V335.73H594.98V340.71C594.98 341.63 594.74 342.35 594.26 342.86C593.78 343.37 593.1 343.62 592.23 343.62Z'
        fill='black'
      />
      <path
        d='M596.7 343.54V335.73H597.8V343.54H596.7ZM597.24 336.77V335.73H601.62V336.77H597.24ZM597.24 340.25V339.21H601.03V340.25H597.24Z'
        fill='black'
      />
      <path
        d='M602.96 343.54V335.73H604.06V343.54H602.96ZM603.49 336.77V335.73H607.87V336.77H603.49ZM603.49 340.25V339.21H607.28V340.25H603.49Z'
        fill='black'
      />
      <path
        d='M609.21 343.54V335.73H610.31V343.54H609.21ZM609.75 336.77V335.73H614.23V336.77H609.75ZM609.75 340.18V339.14H613.64V340.18H609.75ZM609.75 343.54V342.5H614.23V343.54H609.75Z'
        fill='black'
      />
      <path
        d='M614.93 336.77V335.73H620.44V336.77H614.93ZM617.14 343.54V336.37H618.24V343.54H617.14Z'
        fill='black'
      />
      <path
        d='M586.32 349.97V348.93H591.83V349.97H586.32ZM588.52 356.74V349.57H589.62V356.74H588.52Z'
        fill='black'
      />
      <path
        d='M591.67 356.74L594.55 348.93H595.48L598.36 356.74H597.18L595.02 350.44L592.86 356.74H591.68H591.67ZM592.99 355.03V353.99H597.1V355.03H592.99Z'
        fill='black'
      />
      <path
        d='M599.54 356.74V348.93H600.63V356.74H599.54ZM600.19 356.74V355.72H602.31C602.89 355.72 603.3 355.61 603.54 355.39C603.78 355.17 603.9 354.88 603.9 354.51V354.49C603.9 354.1 603.8 353.79 603.59 353.56C603.38 353.33 603.04 353.21 602.57 353.21H600.19V352.22H602.57C602.97 352.22 603.28 352.12 603.49 351.93C603.7 351.74 603.8 351.46 603.8 351.09C603.8 350.7 603.69 350.41 603.46 350.21C603.23 350.01 602.89 349.92 602.43 349.92H600.2V348.9H602.63C603.4 348.9 603.97 349.09 604.35 349.47C604.73 349.85 604.91 350.37 604.91 351.01C604.91 351.4 604.8 351.76 604.57 352.08C604.34 352.4 603.98 352.61 603.49 352.7C603.97 352.77 604.35 352.98 604.61 353.34C604.88 353.69 605.01 354.1 605.01 354.56V354.58C605.01 355.22 604.8 355.73 604.39 356.12C603.98 356.51 603.42 356.71 602.71 356.71H600.19V356.74Z'
        fill='black'
      />
      <path
        d='M606.641 356.74V348.93H607.741V356.74H606.641ZM607.191 356.74V355.7H611.661V356.74H607.191Z'
        fill='black'
      />
      <path
        d='M612.95 356.74V348.93H614.05V356.74H612.95ZM613.48 349.97V348.93H617.96V349.97H613.48ZM613.48 353.38V352.34H617.37V353.38H613.48ZM613.48 356.74V355.7H617.96V356.74H613.48Z'
        fill='black'
      />
      <path
        d='M809.271 343.54V335.73H810.36V343.54H809.271ZM809.92 343.54V342.52H812.04C812.62 342.52 813.031 342.41 813.271 342.19C813.511 341.97 813.63 341.68 813.63 341.31V341.29C813.63 340.9 813.53 340.59 813.32 340.36C813.11 340.13 812.77 340.01 812.3 340.01H809.92V339.02H812.3C812.7 339.02 813.01 338.92 813.22 338.73C813.43 338.54 813.531 338.26 813.531 337.89C813.531 337.5 813.42 337.21 813.19 337.01C812.96 336.81 812.62 336.72 812.16 336.72H809.93V335.7H812.36C813.13 335.7 813.701 335.89 814.081 336.27C814.461 336.65 814.641 337.17 814.641 337.81C814.641 338.2 814.53 338.56 814.3 338.88C814.07 339.2 813.71 339.41 813.22 339.5C813.7 339.57 814.081 339.78 814.341 340.14C814.611 340.49 814.74 340.9 814.74 341.36V341.38C814.74 342.02 814.53 342.53 814.12 342.92C813.71 343.31 813.15 343.51 812.44 343.51H809.92V343.54Z'
        fill='black'
      />
      <path
        d='M818.841 343.62C817.971 343.62 817.301 343.37 816.821 342.86C816.341 342.35 816.101 341.64 816.101 340.71V335.73H817.201V340.75C817.201 341.32 817.341 341.76 817.631 342.07C817.921 342.38 818.321 342.54 818.841 342.54C819.361 342.54 819.771 342.38 820.061 342.07C820.351 341.76 820.491 341.32 820.491 340.75V335.73H821.591V340.71C821.591 341.63 821.351 342.35 820.871 342.86C820.391 343.37 819.711 343.62 818.841 343.62Z'
        fill='black'
      />
      <path
        d='M823.311 343.54V335.73H824.411V343.54H823.311ZM823.851 336.77V335.73H828.231V336.77H823.851ZM823.851 340.25V339.21H827.641V340.25H823.851Z'
        fill='black'
      />
      <path
        d='M829.57 343.54V335.73H830.67V343.54H829.57ZM830.11 336.77V335.73H834.49V336.77H830.11ZM830.11 340.25V339.21H833.9V340.25H830.11Z'
        fill='black'
      />
      <path
        d='M835.83 343.54V335.73H836.93V343.54H835.83ZM836.36 336.77V335.73H840.84V336.77H836.36ZM836.36 340.18V339.14H840.25V340.18H836.36ZM836.36 343.54V342.5H840.84V343.54H836.36Z'
        fill='black'
      />
      <path
        d='M841.54 336.77V335.73H847.05V336.77H841.54ZM843.75 343.54V336.37H844.85V343.54H843.75Z'
        fill='black'
      />
      <path
        d='M812.931 349.97V348.93H818.441V349.97H812.931ZM815.141 356.74V349.57H816.241V356.74H815.141Z'
        fill='black'
      />
      <path
        d='M818.28 356.74L821.16 348.93H822.09L824.97 356.74H823.79L821.63 350.44L819.47 356.74H818.29H818.28ZM819.61 355.03V353.99H823.72V355.03H819.61Z'
        fill='black'
      />
      <path
        d='M826.15 356.74V348.93H827.24V356.74H826.15ZM826.8 356.74V355.72H828.92C829.5 355.72 829.91 355.61 830.15 355.39C830.39 355.17 830.51 354.88 830.51 354.51V354.49C830.51 354.1 830.41 353.79 830.2 353.56C829.99 353.33 829.66 353.21 829.18 353.21H826.8V352.22H829.18C829.58 352.22 829.89 352.12 830.1 351.93C830.31 351.74 830.41 351.46 830.41 351.09C830.41 350.7 830.3 350.41 830.07 350.21C829.84 350.01 829.5 349.92 829.04 349.92H826.81V348.9H829.24C830.01 348.9 830.58 349.09 830.96 349.47C831.34 349.85 831.52 350.37 831.52 351.01C831.52 351.4 831.41 351.76 831.18 352.08C830.95 352.4 830.59 352.61 830.1 352.7C830.58 352.77 830.96 352.98 831.23 353.34C831.5 353.69 831.63 354.1 831.63 354.56V354.58C831.63 355.22 831.42 355.73 831.01 356.12C830.6 356.51 830.04 356.71 829.33 356.71H826.81L826.8 356.74Z'
        fill='black'
      />
      <path
        d='M833.25 356.74V348.93H834.35V356.74H833.25ZM833.8 356.74V355.7H838.27V356.74H833.8Z'
        fill='black'
      />
      <path
        d='M839.561 356.74V348.93H840.661V356.74H839.561ZM840.091 349.97V348.93H844.571V349.97H840.091ZM840.091 353.38V352.34H843.981V353.38H840.091ZM840.091 356.74V355.7H844.571V356.74H840.091Z'
        fill='black'
      />
      <path
        d='M879 343.54V335.73H880.09V343.54H879ZM879.64 343.54V342.52H881.76C882.34 342.52 882.75 342.41 882.99 342.19C883.23 341.97 883.35 341.68 883.35 341.31V341.29C883.35 340.9 883.25 340.59 883.04 340.36C882.83 340.13 882.5 340.01 882.02 340.01H879.64V339.02H882.02C882.42 339.02 882.73 338.92 882.94 338.73C883.15 338.54 883.25 338.26 883.25 337.89C883.25 337.5 883.14 337.21 882.91 337.01C882.68 336.81 882.34 336.72 881.88 336.72H879.65V335.7H882.08C882.85 335.7 883.42 335.89 883.8 336.27C884.18 336.65 884.36 337.17 884.36 337.81C884.36 338.2 884.25 338.56 884.02 338.88C883.79 339.2 883.43 339.41 882.94 339.5C883.42 339.57 883.8 339.78 884.07 340.14C884.34 340.49 884.47 340.9 884.47 341.36V341.38C884.47 342.02 884.26 342.53 883.85 342.92C883.44 343.31 882.88 343.51 882.17 343.51H879.65L879.64 343.54Z'
        fill='black'
      />
      <path
        d='M888.57 343.62C887.7 343.62 887.03 343.37 886.55 342.86C886.07 342.35 885.83 341.64 885.83 340.71V335.73H886.93V340.75C886.93 341.32 887.07 341.76 887.36 342.07C887.65 342.38 888.05 342.54 888.57 342.54C889.09 342.54 889.5 342.38 889.79 342.07C890.08 341.76 890.22 341.32 890.22 340.75V335.73H891.32V340.71C891.32 341.63 891.08 342.35 890.6 342.86C890.12 343.37 889.44 343.62 888.57 343.62Z'
        fill='black'
      />
      <path
        d='M893.04 343.54V335.73H894.14V343.54H893.04ZM893.57 336.77V335.73H897.95V336.77H893.57ZM893.57 340.25V339.21H897.36V340.25H893.57Z'
        fill='black'
      />
      <path
        d='M899.29 343.54V335.73H900.39V343.54H899.29ZM899.83 336.77V335.73H904.21V336.77H899.83ZM899.83 340.25V339.21H903.62V340.25H899.83Z'
        fill='black'
      />
      <path
        d='M905.55 343.54V335.73H906.65V343.54H905.55ZM906.08 336.77V335.73H910.56V336.77H906.08ZM906.08 340.18V339.14H909.97V340.18H906.08ZM906.08 343.54V342.5H910.56V343.54H906.08Z'
        fill='black'
      />
      <path
        d='M911.26 336.77V335.73H916.77V336.77H911.26ZM913.47 343.54V336.37H914.57V343.54H913.47Z'
        fill='black'
      />
      <path
        d='M882.65 349.97V348.93H888.16V349.97H882.65ZM884.86 356.74V349.57H885.96V356.74H884.86Z'
        fill='black'
      />
      <path
        d='M888 356.74L890.88 348.93H891.81L894.69 356.74H893.51L891.35 350.44L889.19 356.74H888.01H888ZM889.33 355.03V353.99H893.44V355.03H889.33Z'
        fill='black'
      />
      <path
        d='M895.88 356.74V348.93H896.97V356.74H895.88ZM896.52 356.74V355.72H898.64C899.22 355.72 899.63 355.61 899.87 355.39C900.11 355.17 900.23 354.88 900.23 354.51V354.49C900.23 354.1 900.13 353.79 899.92 353.56C899.71 353.33 899.37 353.21 898.9 353.21H896.52V352.22H898.9C899.3 352.22 899.61 352.12 899.82 351.93C900.03 351.74 900.13 351.46 900.13 351.09C900.13 350.7 900.02 350.41 899.79 350.21C899.56 350.01 899.22 349.92 898.76 349.92H896.53V348.9H898.96C899.73 348.9 900.3 349.09 900.68 349.47C901.06 349.85 901.24 350.37 901.24 351.01C901.24 351.4 901.13 351.76 900.9 352.08C900.67 352.4 900.31 352.61 899.82 352.7C900.3 352.77 900.68 352.98 900.94 353.34C901.21 353.69 901.34 354.1 901.34 354.56V354.58C901.34 355.22 901.13 355.73 900.72 356.12C900.31 356.51 899.75 356.71 899.04 356.71H896.52V356.74Z'
        fill='black'
      />
      <path
        d='M902.98 356.74V348.93H904.081V356.74H902.98ZM903.521 356.74V355.7H907.99V356.74H903.521Z'
        fill='black'
      />
      <path
        d='M909.28 356.74V348.93H910.38V356.74H909.28ZM909.81 349.97V348.93H914.29V349.97H909.81ZM909.81 353.38V352.34H913.7V353.38H909.81ZM909.81 356.74V355.7H914.29V356.74H909.81Z'
        fill='black'
      />
      <path
        d='M404.036 518.045L390.845 522.331L395.131 535.522L408.322 531.236L404.036 518.045Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M410.454 534.105L402.302 545.326L413.523 553.479L421.675 542.258L410.454 534.105Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M438.96 543.34H425.09V557.21H438.96V543.34Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M453.568 534.054L442.347 542.207L450.499 553.428L461.72 545.276L453.568 534.054Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M459.928 517.937L455.642 531.128L468.833 535.414L473.119 522.223L459.928 517.937Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M468.807 496.886L455.616 501.172L459.902 514.363L473.093 510.077L468.807 496.886Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M450.418 478.909L442.266 490.13L453.487 498.282L461.639 487.061L450.418 478.909Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M787.76 543.341C802.749 543.341 814.9 531.19 814.9 516.201C814.9 501.212 802.749 489.061 787.76 489.061C772.771 489.061 760.62 501.212 760.62 516.201C760.62 531.19 772.771 543.341 787.76 543.341Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M794.641 475.2H780.771V489.07H794.641V475.2Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M769.214 478.977L757.993 487.13L766.146 498.351L777.367 490.198L769.214 478.977Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M750.892 496.992L746.605 510.184L759.797 514.47L764.083 501.278L750.892 496.992Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M759.833 518.05L746.642 522.336L750.928 535.527L764.119 531.241L759.833 518.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M766.242 534.105L758.09 545.326L769.311 553.479L777.463 542.258L766.242 534.105Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M794.75 543.34H780.88V557.21H794.75V543.34Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M809.366 534.041L798.145 542.193L806.297 553.414L817.518 545.262L809.366 534.041Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M395.097 496.989L390.811 510.181L404.002 514.467L408.288 501.276L395.097 496.989Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M824.593 496.891L811.402 501.177L815.688 514.368L828.88 510.082L824.593 496.891Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M806.206 478.909L798.054 490.13L809.275 498.282L817.427 487.061L806.206 478.909Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M891.1 543.341C906.089 543.341 918.24 531.19 918.24 516.201C918.24 501.212 906.089 489.061 891.1 489.061C876.111 489.061 863.96 501.212 863.96 516.201C863.96 531.19 876.111 543.341 891.1 543.341Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M897.99 475.2H884.12V489.07H897.99V475.2Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M872.567 478.98L861.346 487.133L869.498 498.354L880.719 490.201L872.567 478.98Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M854.228 496.981L849.942 510.172L863.134 514.458L867.42 501.267L854.228 496.981Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M863.165 518.046L849.974 522.332L854.26 535.523L867.451 531.237L863.165 518.046Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M869.59 534.103L861.438 545.324L872.659 553.477L880.811 542.256L869.59 534.103Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M898.09 543.34H884.22V557.21H898.09V543.34Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M912.709 534.049L901.488 542.201L909.641 553.422L920.862 545.27L912.709 534.049Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M919.07 517.939L914.784 531.13L927.975 535.416L932.261 522.225L919.07 517.939Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M927.945 496.884L914.754 501.17L919.04 514.361L932.231 510.075L927.945 496.884Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M909.548 478.915L901.396 490.136L912.617 498.288L920.769 487.067L909.548 478.915Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1066.54 181.47C1071.66 181.47 1075.82 177.315 1075.82 172.19C1075.82 167.065 1071.66 162.91 1066.54 162.91C1061.41 162.91 1057.26 167.065 1057.26 172.19C1057.26 177.315 1061.41 181.47 1066.54 181.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1029.42 181.47C1034.55 181.47 1038.7 177.315 1038.7 172.19C1038.7 167.065 1034.55 162.91 1029.42 162.91C1024.3 162.91 1020.14 167.065 1020.14 172.19C1020.14 177.315 1024.3 181.47 1029.42 181.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1030.87 220.006C1035.93 219.205 1039.39 214.452 1038.59 209.39C1037.78 204.328 1033.03 200.873 1027.97 201.674C1022.91 202.475 1019.45 207.228 1020.25 212.29C1021.05 217.352 1025.81 220.807 1030.87 220.006Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M413.424 478.976L402.203 487.129L410.356 498.35L421.577 490.197L413.424 478.976Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M438.85 475.2H424.98V489.07H438.85V475.2Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M815.726 517.931L811.44 531.122L824.632 535.408L828.918 522.217L815.726 517.931Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M431.97 543.341C446.959 543.341 459.11 531.19 459.11 516.201C459.11 501.212 446.959 489.061 431.97 489.061C416.981 489.061 404.83 501.212 404.83 516.201C404.83 531.19 416.981 543.341 431.97 543.341Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1073.1 217.463C1076.72 213.839 1076.72 207.964 1073.1 204.339C1069.47 200.715 1063.6 200.715 1059.98 204.339C1056.35 207.963 1056.35 213.839 1059.98 217.463C1063.6 221.087 1069.47 221.087 1073.1 217.463Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1029.42 258.9C1034.55 258.9 1038.7 254.745 1038.7 249.62C1038.7 244.495 1034.55 240.34 1029.42 240.34C1024.3 240.34 1020.14 244.495 1020.14 249.62C1020.14 254.745 1024.3 258.9 1029.42 258.9Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1066.54 258.9C1071.66 258.9 1075.82 254.745 1075.82 249.62C1075.82 244.495 1071.66 240.34 1066.54 240.34C1061.41 240.34 1057.26 244.495 1057.26 249.62C1057.26 254.745 1061.41 258.9 1066.54 258.9Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M570.719 478.914L562.566 490.135L573.787 498.287L581.94 487.066L570.719 478.914Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M589.109 496.892L575.918 501.178L580.204 514.369L593.395 510.083L589.109 496.892Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M580.236 517.935L575.95 531.126L589.141 535.412L593.427 522.221L580.236 517.935Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M573.88 534.049L562.659 542.201L570.812 553.422L582.033 545.27L573.88 534.049Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M630.245 518.042L617.054 522.328L621.34 535.519L634.531 531.233L630.245 518.042Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M621.3 496.981L617.014 510.172L630.205 514.458L634.491 501.267L621.3 496.981Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M639.642 478.983L628.421 487.136L636.573 498.357L647.795 490.204L639.642 478.983Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M665.06 475.2H651.19V489.07H665.06V475.2Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M658.18 543.341C673.169 543.341 685.32 531.19 685.32 516.201C685.32 501.212 673.169 489.061 658.18 489.061C643.191 489.061 631.04 501.212 631.04 516.201C631.04 531.19 643.191 543.341 658.18 543.341Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1000.7 392.852L992.55 404.073L1003.77 412.226L1011.92 401.005L1000.7 392.852Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1019.1 410.819L1005.91 415.105L1010.19 428.297L1023.38 424.011L1019.1 410.819Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1010.23 431.876L1005.94 445.067L1019.13 449.353L1023.42 436.162L1010.23 431.876Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1003.86 447.98L992.64 456.133L1000.79 467.354L1012.01 459.201L1003.86 447.98Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.25 457.271H975.38V471.141H989.25V457.271Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M960.742 448.037L952.59 459.258L963.811 467.41L971.964 456.189L960.742 448.037Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M954.316 431.982L941.125 436.268L945.411 449.459L958.602 445.173L954.316 431.982Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M945.378 410.915L941.092 424.106L954.283 428.393L958.569 415.201L945.378 410.915Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M963.724 392.92L952.503 401.072L960.656 412.293L971.877 404.141L963.724 392.92Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M989.141 389.13H975.271V403H989.141V389.13Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M559.261 543.34H545.391V557.21H559.261V543.34Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M530.754 534.11L522.602 545.331L533.823 553.484L541.975 542.263L530.754 534.11Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M988.552 456.556C1003.13 453.072 1012.12 438.429 1008.64 423.851C1005.16 409.272 990.514 400.278 975.936 403.762C961.357 407.246 952.363 421.889 955.847 436.467C959.331 451.046 973.973 460.04 988.552 456.556Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M859.288 392.853L851.136 404.074L862.357 412.227L870.509 401.006L859.288 392.853Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M877.678 410.82L864.487 415.106L868.773 428.298L881.965 424.012L877.678 410.82Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M868.808 431.874L864.521 445.065L877.713 449.351L881.999 436.16L868.808 431.874Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M862.44 447.985L851.219 456.138L859.371 467.359L870.592 459.206L862.44 447.985Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M847.82 457.271H833.95V471.141H847.82V457.271Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M819.327 448.038L811.175 459.259L822.396 467.411L830.548 456.19L819.327 448.038Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.898 431.973L799.707 436.259L803.993 449.45L817.184 445.164L812.898 431.973Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M803.957 410.913L799.671 424.104L812.862 428.391L817.148 415.199L803.957 410.913Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M822.302 392.924L811.081 401.076L819.234 412.297L830.455 404.145L822.302 392.924Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M847.72 389.13H833.85V403H847.72V389.13Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M847.135 456.546C861.713 453.062 870.707 438.42 867.224 423.841C863.74 409.263 849.097 400.269 834.519 403.752C819.94 407.236 810.946 421.879 814.43 436.457C817.914 451.036 832.556 460.03 847.135 456.546Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M402.385 392.845L394.232 404.066L405.453 412.219L413.606 400.998L402.385 392.845Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M420.781 410.811L407.59 415.097L411.876 428.288L425.067 424.002L420.781 410.811Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M411.908 431.882L407.622 445.073L420.813 449.359L425.099 436.168L411.908 431.882Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M405.54 447.984L394.319 456.137L402.472 467.358L413.693 459.205L405.54 447.984Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M390.931 457.271H377.061V471.141H390.931V457.271Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M362.429 448.038L354.276 459.259L365.497 467.411L373.65 456.19L362.429 448.038Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M356.01 431.98L342.818 436.266L347.104 449.457L360.296 445.171L356.01 431.98Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M347.067 410.921L342.781 424.112L355.972 428.398L360.258 415.207L347.067 410.921Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M365.403 392.924L354.182 401.076L362.334 412.297L373.555 404.145L365.403 392.924Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M390.82 389.13H376.95V403H390.82V389.13Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M390.237 456.539C404.816 453.055 413.81 438.413 410.326 423.834C406.842 409.256 392.2 400.262 377.621 403.746C363.043 407.229 354.049 421.872 357.533 436.45C361.017 451.029 375.659 460.023 390.237 456.539Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M506.372 392.854L498.22 404.075L509.441 412.228L517.593 401.007L506.372 392.854Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M524.339 518.04L511.147 522.326L515.434 535.517L528.625 531.231L524.339 518.04Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M515.404 496.988L511.118 510.179L524.309 514.465L528.595 501.274L515.404 496.988Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M524.771 410.815L511.58 415.102L515.866 428.293L529.057 424.007L524.771 410.815Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M515.893 431.871L511.606 445.062L524.798 449.349L529.084 436.157L515.893 431.871Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M533.738 478.979L522.517 487.132L530.669 498.353L541.89 490.2L533.738 478.979Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M559.15 475.2H545.28V489.07H559.15V475.2Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M552.27 543.341C567.259 543.341 579.41 531.19 579.41 516.201C579.41 501.212 567.259 489.061 552.27 489.061C537.281 489.061 525.13 501.212 525.13 516.201C525.13 531.19 537.281 543.341 552.27 543.341Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M676.631 478.911L668.479 490.132L679.7 498.284L687.852 487.063L676.631 478.911Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M509.53 447.98L498.309 456.133L506.461 467.354L517.682 459.201L509.53 447.98Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M686.141 517.938L681.854 531.129L695.046 535.415L699.332 522.224L686.141 517.938Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M695.015 496.894L681.824 501.18L686.11 514.371L699.301 510.085L695.015 496.894Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M679.776 534.052L668.555 542.204L676.707 553.425L687.928 545.273L679.776 534.052Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M665.17 543.34H651.3V557.21H665.17V543.34Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M636.656 534.107L628.504 545.328L639.725 553.481L647.878 542.26L636.656 534.107Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M629.371 449.327C639.97 438.728 639.97 421.544 629.371 410.945C618.773 400.346 601.589 400.346 590.99 410.945C580.391 421.544 580.391 438.728 590.99 449.327C601.589 459.925 618.773 459.925 629.371 449.327Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M617.07 389.13H603.2V403H617.07V389.13Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M591.653 392.917L580.432 401.069L588.584 412.29L599.805 404.138L591.653 392.917Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M573.312 410.915L569.026 424.106L582.218 428.393L586.504 415.201L573.312 410.915Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M582.247 431.968L569.056 436.254L573.342 449.445L586.533 445.159L582.247 431.968Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M588.675 448.038L580.522 459.259L591.744 467.411L599.896 456.19L588.675 448.038Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M617.17 457.271H603.3V471.141H617.17V457.271Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M631.79 447.977L620.569 456.13L628.722 467.351L639.943 459.198L631.79 447.977Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M638.153 431.865L633.867 445.057L647.058 449.343L651.344 436.152L638.153 431.865Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M647.027 410.815L633.836 415.102L638.122 428.293L651.313 424.007L647.027 410.815Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M628.635 392.853L620.482 404.074L631.703 412.227L639.856 401.006L628.635 392.853Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M507.111 449.328C517.71 438.729 517.71 421.545 507.111 410.946C496.512 400.347 479.328 400.347 468.729 410.946C458.13 421.545 458.13 438.729 468.729 449.328C479.328 459.926 496.512 459.926 507.111 449.328Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M494.81 389.13H480.94V403H494.81V389.13Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M469.392 392.92L458.171 401.072L466.323 412.293L477.545 404.141L469.392 392.92Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M451.052 410.911L446.766 424.103L459.957 428.389L464.243 415.197L451.052 410.911Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M459.991 431.979L446.8 436.265L451.086 449.456L464.277 445.17L459.991 431.979Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M466.412 448.038L458.26 459.259L469.481 467.411L477.633 456.19L466.412 448.038Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M494.91 457.271H481.04V471.141H494.91V457.271Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M821.24 1365.83V1347.58H823.79V1365.83H821.24ZM822.75 1365.83V1363.46H827.69C829.04 1363.46 830 1363.2 830.57 1362.68C831.13 1362.16 831.42 1361.48 831.42 1360.63V1360.59C831.42 1359.69 831.18 1358.96 830.7 1358.42C830.22 1357.88 829.43 1357.61 828.32 1357.61H822.75V1355.3H828.32C829.27 1355.3 829.98 1355.08 830.46 1354.63C830.94 1354.18 831.18 1353.53 831.18 1352.67C831.18 1351.76 830.91 1351.08 830.38 1350.63C829.84 1350.18 829.04 1349.95 827.97 1349.95H822.75V1347.58H828.43C830.23 1347.58 831.57 1348.03 832.45 1348.92C833.33 1349.81 833.77 1351.02 833.77 1352.52C833.77 1353.43 833.5 1354.27 832.97 1355.02C832.43 1355.78 831.59 1356.26 830.45 1356.47C831.58 1356.64 832.46 1357.14 833.08 1357.96C833.7 1358.78 834.02 1359.74 834.02 1360.81V1360.85C834.02 1362.34 833.54 1363.54 832.58 1364.45C831.62 1365.36 830.31 1365.82 828.66 1365.82H822.76L822.75 1365.83Z'
        fill='black'
      />
      <path
        d='M835.7 1365.83L842.43 1347.58H844.61L851.34 1365.83H848.58L843.52 1351.11L838.46 1365.83H835.7ZM838.8 1361.82V1359.39H848.4V1361.82H838.8Z'
        fill='black'
      />
      <path
        d='M851.08 1350.02V1347.59H863.96V1350.02H851.08ZM856.24 1365.83V1349.08H858.8V1365.83H856.24Z'
        fill='black'
      />
      <path
        d='M866.471 1365.83V1347.58H869.031V1365.83H866.471ZM868.061 1358.06V1355.63H878.191V1358.06H868.061ZM876.831 1365.83V1347.58H879.391V1365.83H876.831Z'
        fill='black'
      />
      <path
        d='M883.91 1365.83V1347.57H886.47V1365.83H883.91ZM885.03 1358.11V1355.68H891.25C891.99 1355.68 892.59 1355.42 893.04 1354.9C893.49 1354.38 893.72 1353.69 893.72 1352.84C893.72 1351.99 893.49 1351.3 893.04 1350.78C892.59 1350.26 891.99 1350 891.25 1350H885.03V1347.57H891.15C892.21 1347.57 893.14 1347.79 893.94 1348.23C894.74 1348.67 895.35 1349.28 895.79 1350.07C896.23 1350.86 896.45 1351.78 896.45 1352.84C896.45 1353.9 896.23 1354.82 895.78 1355.61C895.34 1356.4 894.72 1357.02 893.93 1357.45C893.14 1357.89 892.21 1358.11 891.15 1358.11H885.03ZM894.17 1365.83L889.99 1357.55L892.54 1356.97L897.21 1365.83H894.17Z'
        fill='black'
      />
      <path
        d='M906.2 1366.02C904.91 1366.02 903.79 1365.74 902.82 1365.19C901.86 1364.63 901.11 1363.85 900.58 1362.84C900.05 1361.83 899.78 1360.65 899.78 1359.3V1354.13C899.78 1352.77 900.05 1351.59 900.58 1350.59C901.11 1349.59 901.86 1348.8 902.82 1348.24C903.78 1347.68 904.91 1347.41 906.2 1347.41C907.49 1347.41 908.61 1347.69 909.58 1348.24C910.55 1348.79 911.29 1349.58 911.82 1350.59C912.35 1351.6 912.62 1352.78 912.62 1354.13V1359.3C912.62 1360.65 912.35 1361.83 911.82 1362.84C911.29 1363.85 910.54 1364.64 909.58 1365.19C908.62 1365.75 907.49 1366.02 906.2 1366.02ZM906.2 1363.49C907.35 1363.49 908.26 1363.11 908.95 1362.36C909.64 1361.61 909.99 1360.61 909.99 1359.37V1354.02C909.99 1352.77 909.65 1351.78 908.95 1351.03C908.26 1350.28 907.34 1349.91 906.2 1349.91C905.06 1349.91 904.15 1350.29 903.45 1351.03C902.76 1351.78 902.41 1352.78 902.41 1354.02V1359.37C902.41 1360.61 902.76 1361.6 903.45 1362.36C904.14 1363.11 905.06 1363.49 906.2 1363.49Z'
        fill='black'
      />
      <path
        d='M922.28 1366.02C920.99 1366.02 919.87 1365.74 918.9 1365.19C917.94 1364.63 917.19 1363.85 916.66 1362.84C916.13 1361.83 915.86 1360.65 915.86 1359.3V1354.13C915.86 1352.77 916.13 1351.59 916.66 1350.59C917.19 1349.58 917.94 1348.8 918.9 1348.24C919.86 1347.68 920.99 1347.41 922.28 1347.41C923.57 1347.41 924.69 1347.69 925.66 1348.24C926.62 1348.8 927.37 1349.58 927.9 1350.59C928.43 1351.6 928.7 1352.78 928.7 1354.13V1359.3C928.7 1360.65 928.43 1361.83 927.9 1362.84C927.37 1363.85 926.62 1364.64 925.66 1365.19C924.7 1365.75 923.57 1366.02 922.28 1366.02ZM922.28 1363.49C923.43 1363.49 924.34 1363.11 925.03 1362.36C925.72 1361.61 926.07 1360.61 926.07 1359.37V1354.02C926.07 1352.77 925.72 1351.78 925.03 1351.03C924.34 1350.28 923.42 1349.91 922.28 1349.91C921.14 1349.91 920.23 1350.29 919.53 1351.03C918.84 1351.78 918.49 1352.78 918.49 1354.02V1359.37C918.49 1360.61 918.84 1361.6 919.53 1362.36C920.22 1363.12 921.14 1363.49 922.28 1363.49Z'
        fill='black'
      />
      <path
        d='M940.341 1359.63L945.701 1347.58H948.101V1365.83H945.601V1352.16L945.731 1352.96L941.241 1363.29H939.461L934.971 1353.21L935.101 1352.16V1365.83H932.601V1347.58H935.001L940.361 1359.63H940.341Z'
        fill='black'
      />
      <path
        d='M958.1 1366.02C957.21 1366.02 956.38 1365.94 955.6 1365.77C954.82 1365.6 954.09 1365.35 953.4 1365.02C952.72 1364.69 952.08 1364.27 951.49 1363.77L953.08 1361.81C953.77 1362.41 954.54 1362.86 955.38 1363.17C956.22 1363.48 957.12 1363.62 958.1 1363.62C959.42 1363.62 960.45 1363.37 961.17 1362.88C961.9 1362.39 962.26 1361.7 962.26 1360.81C962.26 1360.1 962.08 1359.57 961.71 1359.2C961.34 1358.83 960.86 1358.56 960.26 1358.38C959.66 1358.2 959.01 1358.05 958.3 1357.93C957.61 1357.81 956.92 1357.67 956.22 1357.51C955.52 1357.35 954.88 1357.1 954.29 1356.76C953.7 1356.42 953.23 1355.93 952.88 1355.3C952.52 1354.66 952.35 1353.81 952.35 1352.75C952.35 1351.04 952.91 1349.72 954.04 1348.79C955.17 1347.86 956.76 1347.39 958.82 1347.39C959.8 1347.39 960.76 1347.55 961.69 1347.86C962.62 1348.17 963.54 1348.65 964.43 1349.3L962.97 1351.33C962.28 1350.81 961.58 1350.43 960.89 1350.18C960.2 1349.93 959.51 1349.8 958.81 1349.8C957.56 1349.8 956.6 1350.05 955.9 1350.55C955.21 1351.05 954.86 1351.75 954.86 1352.66C954.86 1353.36 955.06 1353.88 955.46 1354.24C955.86 1354.6 956.39 1354.87 957.03 1355.04C957.68 1355.21 958.37 1355.37 959.12 1355.52C959.79 1355.65 960.46 1355.81 961.13 1356C961.8 1356.19 962.41 1356.46 962.96 1356.82C963.51 1357.18 963.95 1357.66 964.28 1358.28C964.61 1358.9 964.78 1359.71 964.78 1360.7V1360.73C964.78 1362.41 964.19 1363.71 963.02 1364.63C961.85 1365.55 960.2 1366.01 958.08 1366.01L958.1 1366.02Z'
        fill='black'
      />
      <path
        d='M833.19 792.17V773.92H835.74V792.17H833.19ZM834.7 792.17V789.8H839.64C840.99 789.8 841.95 789.54 842.52 789.02C843.08 788.5 843.37 787.82 843.37 786.97V786.93C843.37 786.03 843.13 785.31 842.65 784.77C842.17 784.23 841.38 783.96 840.27 783.96H834.7V781.65H840.27C841.22 781.65 841.93 781.43 842.41 780.98C842.89 780.53 843.13 779.88 843.13 779.02C843.13 778.11 842.86 777.43 842.33 776.97C841.79 776.52 840.99 776.29 839.92 776.29H834.7V773.92H840.38C842.18 773.92 843.52 774.37 844.4 775.26C845.28 776.16 845.72 777.36 845.72 778.86C845.72 779.77 845.45 780.61 844.92 781.36C844.38 782.12 843.54 782.6 842.4 782.81C843.53 782.98 844.41 783.48 845.03 784.3C845.65 785.12 845.97 786.08 845.97 787.15V787.19C845.97 788.68 845.49 789.88 844.53 790.79C843.57 791.7 842.26 792.16 840.61 792.16H834.71L834.7 792.17Z'
        fill='black'
      />
      <path
        d='M847.65 792.17L854.38 773.92H856.56L863.29 792.17H860.53L855.47 777.45L850.41 792.17H847.65ZM850.75 788.17V785.74H860.35V788.17H850.75Z'
        fill='black'
      />
      <path
        d='M863.04 776.361V773.931H875.92V776.361H863.04ZM868.19 792.171V775.421H870.75V792.171H868.19Z'
        fill='black'
      />
      <path
        d='M878.42 792.17V773.92H880.98V792.17H878.42ZM880.01 784.41V781.98H890.14V784.41H880.01ZM888.79 792.17V773.92H891.35V792.17H888.79Z'
        fill='black'
      />
      <path
        d='M895.86 792.17V773.91H898.42V792.17H895.86ZM896.98 784.46V782.03H903.2C903.94 782.03 904.54 781.77 904.99 781.25C905.44 780.73 905.67 780.05 905.67 779.19C905.67 778.33 905.44 777.65 904.99 777.13C904.54 776.61 903.94 776.35 903.2 776.35H896.98V773.92H903.1C904.16 773.92 905.09 774.14 905.89 774.58C906.68 775.02 907.3 775.63 907.74 776.42C908.18 777.21 908.4 778.13 908.4 779.19C908.4 780.25 908.18 781.17 907.73 781.96C907.28 782.75 906.67 783.37 905.88 783.8C905.09 784.24 904.16 784.46 903.1 784.46H896.98ZM906.13 792.17L901.95 783.89L904.5 783.31L909.17 792.17H906.13Z'
        fill='black'
      />
      <path
        d='M918.151 792.36C916.861 792.36 915.741 792.08 914.771 791.53C913.811 790.97 913.061 790.19 912.531 789.18C912.001 788.17 911.73 786.99 911.73 785.64V780.47C911.73 779.11 912.001 777.93 912.531 776.93C913.061 775.92 913.811 775.14 914.771 774.58C915.731 774.02 916.861 773.75 918.151 773.75C919.441 773.75 920.561 774.03 921.531 774.58C922.501 775.13 923.241 775.92 923.771 776.93C924.301 777.94 924.57 779.12 924.57 780.47V785.64C924.57 786.99 924.301 788.17 923.771 789.18C923.241 790.19 922.491 790.98 921.531 791.53C920.571 792.09 919.441 792.36 918.151 792.36ZM918.151 789.84C919.301 789.84 920.211 789.46 920.901 788.71C921.591 787.96 921.94 786.96 921.94 785.72V780.37C921.94 779.12 921.601 778.13 920.901 777.38C920.211 776.63 919.291 776.26 918.151 776.26C917.011 776.26 916.101 776.63 915.401 777.38C914.711 778.13 914.36 779.13 914.36 780.37V785.72C914.36 786.96 914.711 787.95 915.401 788.71C916.091 789.46 917.011 789.84 918.151 789.84Z'
        fill='black'
      />
      <path
        d='M934.24 792.36C932.95 792.36 931.83 792.08 930.86 791.53C929.9 790.97 929.15 790.19 928.62 789.18C928.09 788.17 927.82 786.99 927.82 785.64V780.47C927.82 779.11 928.09 777.93 928.62 776.93C929.15 775.92 929.9 775.14 930.86 774.58C931.82 774.02 932.95 773.75 934.24 773.75C935.53 773.75 936.65 774.03 937.62 774.58C938.58 775.14 939.33 775.92 939.86 776.93C940.39 777.94 940.66 779.12 940.66 780.47V785.64C940.66 786.99 940.39 788.17 939.86 789.18C939.33 790.19 938.58 790.98 937.62 791.53C936.66 792.09 935.53 792.36 934.24 792.36ZM934.24 789.84C935.39 789.84 936.3 789.46 936.99 788.71C937.68 787.96 938.03 786.96 938.03 785.72V780.37C938.03 779.12 937.68 778.13 936.99 777.38C936.3 776.63 935.38 776.26 934.24 776.26C933.1 776.26 932.19 776.63 931.49 777.38C930.8 778.13 930.45 779.13 930.45 780.37V785.72C930.45 786.96 930.8 787.95 931.49 788.71C932.18 789.47 933.1 789.84 934.24 789.84Z'
        fill='black'
      />
      <path
        d='M952.301 785.97L957.661 773.92H960.061V792.17H957.561V778.5L957.691 779.3L953.201 789.63H951.421L946.931 779.55L947.061 778.5V792.17H944.561V773.92H946.961L952.321 785.97H952.301Z'
        fill='black'
      />
      <path
        d='M970.05 792.36C969.16 792.36 968.33 792.28 967.55 792.11C966.77 791.94 966.04 791.69 965.35 791.36C964.67 791.03 964.03 790.61 963.44 790.11L965.03 788.15C965.72 788.75 966.49 789.2 967.33 789.51C968.17 789.82 969.07 789.96 970.05 789.96C971.37 789.96 972.4 789.71 973.12 789.22C973.85 788.73 974.21 788.04 974.21 787.15C974.21 786.44 974.03 785.91 973.66 785.54C973.29 785.17 972.81 784.9 972.21 784.72C971.61 784.54 970.96 784.39 970.25 784.27C969.56 784.15 968.87 784.02 968.17 783.86C967.47 783.7 966.83 783.45 966.24 783.11C965.65 782.77 965.18 782.28 964.83 781.65C964.47 781.01 964.3 780.17 964.3 779.1C964.3 777.39 964.86 776.07 965.99 775.14C967.12 774.21 968.71 773.74 970.77 773.74C971.75 773.74 972.71 773.9 973.64 774.21C974.58 774.52 975.49 775 976.38 775.65L974.92 777.68C974.23 777.16 973.53 776.78 972.84 776.53C972.15 776.28 971.46 776.15 970.76 776.15C969.51 776.15 968.55 776.4 967.85 776.9C967.16 777.4 966.81 778.1 966.81 779.01C966.81 779.71 967.01 780.23 967.41 780.59C967.81 780.95 968.34 781.22 968.98 781.39C969.63 781.56 970.32 781.72 971.07 781.87C971.74 782 972.41 782.16 973.08 782.35C973.75 782.54 974.36 782.81 974.91 783.17C975.46 783.53 975.9 784.01 976.23 784.63C976.56 785.25 976.73 786.06 976.73 787.05V787.08C976.73 788.76 976.14 790.06 974.97 790.98C973.8 791.9 972.15 792.36 970.03 792.36H970.05Z'
        fill='black'
      />
      <path
        d='M1025.56 1206.67C1024.29 1206.67 1023.18 1206.39 1022.22 1205.82C1021.27 1205.26 1020.53 1204.46 1020 1203.44C1019.47 1202.42 1019.21 1201.23 1019.21 1199.86V1194.85C1019.21 1193.48 1019.47 1192.28 1020 1191.26C1020.53 1190.24 1021.27 1189.45 1022.22 1188.88C1023.17 1188.31 1024.29 1188.03 1025.56 1188.03C1026.61 1188.03 1027.58 1188.26 1028.45 1188.71C1029.32 1189.16 1030.06 1189.79 1030.65 1190.61C1031.24 1191.42 1031.64 1192.37 1031.85 1193.46H1029.23C1029.07 1192.87 1028.81 1192.36 1028.43 1191.92C1028.06 1191.48 1027.62 1191.14 1027.12 1190.9C1026.62 1190.66 1026.1 1190.54 1025.56 1190.54C1024.44 1190.54 1023.54 1190.94 1022.86 1191.73C1022.18 1192.52 1021.84 1193.56 1021.84 1194.86V1199.87C1021.84 1201.17 1022.18 1202.21 1022.86 1202.99C1023.54 1203.78 1024.44 1204.17 1025.56 1204.17C1026.35 1204.17 1027.1 1203.92 1027.81 1203.42C1028.51 1202.92 1028.98 1202.19 1029.23 1201.25H1031.85C1031.64 1202.34 1031.24 1203.29 1030.65 1204.1C1030.06 1204.91 1029.32 1205.54 1028.45 1206C1027.58 1206.45 1026.62 1206.68 1025.56 1206.68V1206.67Z'
        fill='black'
      />
      <path
        d='M1041.02 1206.67C1039.73 1206.67 1038.61 1206.39 1037.64 1205.84C1036.68 1205.28 1035.93 1204.5 1035.4 1203.49C1034.87 1202.48 1034.6 1201.3 1034.6 1199.95V1194.78C1034.6 1193.42 1034.87 1192.24 1035.4 1191.24C1035.93 1190.24 1036.68 1189.45 1037.64 1188.89C1038.6 1188.33 1039.73 1188.06 1041.02 1188.06C1042.31 1188.06 1043.43 1188.34 1044.4 1188.89C1045.36 1189.45 1046.11 1190.23 1046.64 1191.24C1047.17 1192.25 1047.44 1193.43 1047.44 1194.78V1199.95C1047.44 1201.3 1047.17 1202.48 1046.64 1203.49C1046.11 1204.5 1045.36 1205.29 1044.4 1205.84C1043.44 1206.4 1042.31 1206.67 1041.02 1206.67ZM1041.02 1204.14C1042.17 1204.14 1043.08 1203.76 1043.77 1203.01C1044.46 1202.26 1044.81 1201.26 1044.81 1200.02V1194.67C1044.81 1193.42 1044.46 1192.43 1043.77 1191.68C1043.08 1190.93 1042.16 1190.56 1041.02 1190.56C1039.88 1190.56 1038.97 1190.94 1038.27 1191.68C1037.58 1192.43 1037.23 1193.43 1037.23 1194.67V1200.02C1037.23 1201.26 1037.58 1202.25 1038.27 1203.01C1038.96 1203.77 1039.88 1204.14 1041.02 1204.14Z'
        fill='black'
      />
      <path
        d='M1057.22 1206.67C1055.19 1206.67 1053.61 1206.08 1052.5 1204.89C1051.38 1203.71 1050.82 1202.04 1050.82 1199.88V1188.23H1053.38V1199.96C1053.38 1201.29 1053.71 1202.32 1054.38 1203.05C1055.05 1203.78 1055.99 1204.14 1057.22 1204.14C1058.45 1204.14 1059.4 1203.78 1060.07 1203.05C1060.74 1202.32 1061.08 1201.29 1061.08 1199.96V1188.23H1063.64V1199.88C1063.64 1202.04 1063.08 1203.71 1061.96 1204.89C1060.84 1206.07 1059.26 1206.67 1057.22 1206.67Z'
        fill='black'
      />
      <path
        d='M1067.66 1206.48V1188.22H1070.22V1206.48H1067.66ZM1068.77 1198.76V1196.33H1074.99C1075.73 1196.33 1076.33 1196.07 1076.78 1195.55C1077.23 1195.03 1077.46 1194.34 1077.46 1193.49C1077.46 1192.64 1077.23 1191.95 1076.78 1191.43C1076.33 1190.91 1075.73 1190.65 1074.99 1190.65H1068.77V1188.22H1074.89C1075.95 1188.22 1076.88 1188.44 1077.68 1188.88C1078.48 1189.32 1079.09 1189.93 1079.53 1190.72C1079.97 1191.51 1080.19 1192.43 1080.19 1193.49C1080.19 1194.55 1079.97 1195.47 1079.52 1196.26C1079.08 1197.05 1078.46 1197.67 1077.67 1198.1C1076.88 1198.54 1075.95 1198.76 1074.89 1198.76H1068.77ZM1077.92 1206.48L1073.74 1198.2L1076.29 1197.62L1080.96 1206.48H1077.92Z'
        fill='black'
      />
      <path
        d='M1081.9 1190.67V1188.24H1094.78V1190.67H1081.9ZM1087.06 1206.48V1189.73H1089.62V1206.48H1087.06Z'
        fill='black'
      />
      <path
        d='M1109.33 1188.23L1103.77 1199.06V1206.48H1101.21V1199.06L1095.65 1188.23H1098.5L1102.49 1196.46L1106.48 1188.23H1109.33Z'
        fill='black'
      />
      <path
        d='M1108.33 1206.48L1115.06 1188.23H1117.24L1123.97 1206.48H1121.21L1116.15 1191.76L1111.09 1206.48H1108.33ZM1111.43 1202.48V1200.05H1121.03V1202.48H1111.43Z'
        fill='black'
      />
      <path
        d='M1126.72 1206.48V1188.22H1129.28V1206.48H1126.72ZM1127.84 1198.76V1196.33H1134.06C1134.8 1196.33 1135.4 1196.07 1135.85 1195.55C1136.3 1195.03 1136.53 1194.34 1136.53 1193.49C1136.53 1192.64 1136.3 1191.95 1135.85 1191.43C1135.4 1190.91 1134.8 1190.65 1134.06 1190.65H1127.84V1188.22H1133.96C1135.02 1188.22 1135.95 1188.44 1136.75 1188.88C1137.54 1189.32 1138.16 1189.93 1138.6 1190.72C1139.04 1191.51 1139.26 1192.43 1139.26 1193.49C1139.26 1194.55 1139.04 1195.47 1138.59 1196.26C1138.15 1197.05 1137.53 1197.67 1136.74 1198.1C1135.95 1198.54 1135.02 1198.76 1133.96 1198.76H1127.84ZM1136.99 1206.48L1132.81 1198.2L1135.36 1197.62L1140.03 1206.48H1136.99Z'
        fill='black'
      />
      <path
        d='M1143.48 1206.48V1188.23H1146.04V1206.48H1143.48ZM1145.21 1206.48V1204.05H1149.34C1150.69 1204.05 1151.73 1203.7 1152.47 1203C1153.21 1202.3 1153.58 1201.32 1153.58 1200.04V1194.67C1153.58 1193.4 1153.21 1192.41 1152.47 1191.71C1151.73 1191.01 1150.69 1190.66 1149.34 1190.66H1145.21V1188.23H1149.26C1151.47 1188.23 1153.18 1188.8 1154.39 1189.94C1155.6 1191.08 1156.2 1192.69 1156.2 1194.77V1199.94C1156.2 1201.32 1155.93 1202.5 1155.38 1203.47C1154.84 1204.45 1154.05 1205.19 1153.01 1205.7C1151.97 1206.21 1150.72 1206.47 1149.25 1206.47H1145.21V1206.48Z'
        fill='black'
      />
      <path
        d='M664.391 1203.28H660.861V1188.07H664.391V1203.28ZM687.331 1205.09H660.851V1201.37H687.331V1205.09ZM675.951 1203.28H672.421V1190.07H675.951V1203.28ZM687.331 1203.28H683.801V1188.07H687.331V1203.28Z'
        fill='black'
      />
      <path
        d='M687.331 1182.79H660.851V1179.08H687.331V1182.79ZM687.331 1180.94H683.801V1165.79H687.331V1180.94Z'
        fill='black'
      />
      <path
        d='M664.391 1159.61H660.861V1144.4H664.391V1159.61ZM687.331 1161.41H660.851V1157.7H687.331V1161.41ZM675.951 1159.61H672.421V1146.41H675.951V1159.61ZM687.331 1159.61H683.801V1144.4H687.331V1159.61Z'
        fill='black'
      />
      <path
        d='M687.331 1133.22L660.851 1141.85V1137.81L681.611 1131.45L660.851 1125.09V1121.05L687.331 1129.68V1133.21V1133.22Z'
        fill='black'
      />
      <path
        d='M687.331 1121.42L660.851 1111.66V1108.49L687.331 1098.73V1102.74L665.971 1110.08L687.331 1117.42V1121.43V1121.42ZM681.521 1116.92H677.991V1102.99H681.521V1116.92Z'
        fill='black'
      />
      <path
        d='M664.39 1099.09H660.86V1080.4H664.39V1099.09ZM687.33 1091.6H663.02V1087.88H687.33V1091.6Z'
        fill='black'
      />
      <path
        d='M687.61 1068.73C687.61 1070.6 687.21 1072.23 686.4 1073.63C685.59 1075.03 684.45 1076.11 682.98 1076.88C681.51 1077.65 679.8 1078.04 677.84 1078.04H670.34C668.37 1078.04 666.66 1077.65 665.19 1076.88C663.73 1076.11 662.59 1075.03 661.78 1073.63C660.97 1072.23 660.57 1070.6 660.57 1068.73C660.57 1066.86 660.97 1065.23 661.78 1063.83C662.59 1062.43 663.72 1061.35 665.19 1060.58C666.65 1059.81 668.37 1059.42 670.34 1059.42H677.84C679.8 1059.42 681.51 1059.81 682.98 1060.58C684.45 1061.35 685.59 1062.43 686.4 1063.83C687.21 1065.23 687.61 1066.86 687.61 1068.73ZM683.95 1068.73C683.95 1067.07 683.4 1065.73 682.31 1064.73C681.22 1063.73 679.77 1063.23 677.98 1063.23H670.22C668.41 1063.23 666.96 1063.73 665.88 1064.73C664.79 1065.73 664.25 1067.06 664.25 1068.73C664.25 1070.4 664.79 1071.71 665.88 1072.72C666.97 1073.73 668.41 1074.23 670.22 1074.23H677.98C679.78 1074.23 681.22 1073.73 682.31 1072.72C683.4 1071.71 683.95 1070.38 683.95 1068.73Z'
        fill='black'
      />
      <path
        d='M676.13 1052.15H672.6V1043.12C672.6 1042.04 672.22 1041.17 671.47 1040.52C670.72 1039.87 669.72 1039.54 668.48 1039.54C667.24 1039.54 666.25 1039.87 665.49 1040.52C664.74 1041.18 664.36 1042.04 664.36 1043.12V1052.15H660.83V1043.26C660.83 1041.72 661.15 1040.37 661.79 1039.22C662.43 1038.07 663.32 1037.17 664.46 1036.53C665.6 1035.89 666.94 1035.57 668.48 1035.57C670.02 1035.57 671.35 1035.89 672.5 1036.54C673.65 1037.19 674.54 1038.08 675.18 1039.23C675.82 1040.38 676.14 1041.72 676.14 1043.26V1052.15H676.13ZM687.33 1053.77H660.83V1050.05H687.33V1053.77ZM687.33 1038.88L675.31 1044.94L674.47 1041.24L687.33 1034.47V1038.88Z'
        fill='black'
      />
      <path
        d='M687.61 1022.23C687.61 1023.52 687.49 1024.73 687.25 1025.86C687.01 1027 686.64 1028.06 686.16 1029.05C685.67 1030.04 685.07 1030.97 684.34 1031.83L681.5 1029.52C682.37 1028.51 683.03 1027.4 683.47 1026.19C683.91 1024.98 684.13 1023.66 684.13 1022.24C684.13 1020.32 683.77 1018.83 683.06 1017.78C682.34 1016.72 681.34 1016.2 680.06 1016.2H680.04C679.03 1016.2 678.26 1016.47 677.73 1017C677.2 1017.53 676.8 1018.24 676.54 1019.1C676.28 1019.97 676.06 1020.92 675.89 1021.95C675.72 1022.95 675.52 1023.95 675.29 1024.96C675.06 1025.97 674.7 1026.91 674.21 1027.76C673.72 1028.61 673.01 1029.29 672.09 1029.81C671.17 1030.33 669.94 1030.58 668.39 1030.58H668.37C665.91 1030.58 664 1029.76 662.64 1028.12C661.29 1026.48 660.61 1024.17 660.61 1021.18C660.61 1019.76 660.84 1018.37 661.29 1017.01C661.75 1015.65 662.44 1014.33 663.38 1013.04L666.33 1015.15C665.58 1016.16 665.02 1017.16 664.65 1018.16C664.29 1019.16 664.1 1020.17 664.1 1021.17C664.1 1022.98 664.46 1024.38 665.18 1025.39C665.9 1026.39 666.93 1026.89 668.25 1026.89H668.27C669.27 1026.89 670.02 1026.6 670.55 1026.02C671.07 1025.44 671.46 1024.68 671.71 1023.73C671.96 1022.79 672.19 1021.78 672.41 1020.7C672.6 1019.73 672.84 1018.76 673.11 1017.79C673.38 1016.82 673.78 1015.94 674.29 1015.14C674.81 1014.34 675.51 1013.71 676.41 1013.23C677.31 1012.75 678.48 1012.51 679.93 1012.51H679.97C682.41 1012.51 684.3 1013.36 685.63 1015.06C686.97 1016.76 687.63 1019.15 687.63 1022.24L687.61 1022.23Z'
        fill='black'
      />
      <path
        d='M1242.51 433.5C1242.51 434.77 1242.23 435.88 1241.66 436.84C1241.1 437.79 1240.3 438.53 1239.28 439.06C1238.26 439.59 1237.07 439.85 1235.7 439.85H1230.69C1229.32 439.85 1228.12 439.59 1227.1 439.06C1226.08 438.53 1225.29 437.79 1224.72 436.84C1224.16 435.89 1223.87 434.77 1223.87 433.5C1223.87 432.45 1224.1 431.48 1224.55 430.61C1225 429.74 1225.63 429 1226.45 428.41C1227.26 427.82 1228.21 427.42 1229.3 427.21V429.83C1228.71 429.99 1228.2 430.25 1227.76 430.63C1227.32 431 1226.98 431.44 1226.74 431.94C1226.5 432.44 1226.38 432.96 1226.38 433.5C1226.38 434.62 1226.78 435.52 1227.57 436.2C1228.36 436.88 1229.4 437.22 1230.7 437.22H1235.71C1237.01 437.22 1238.05 436.88 1238.83 436.2C1239.62 435.52 1240.01 434.62 1240.01 433.5C1240.01 432.71 1239.76 431.96 1239.26 431.25C1238.76 430.55 1238.03 430.07 1237.09 429.83V427.21C1238.18 427.42 1239.13 427.82 1239.94 428.41C1240.75 429 1241.38 429.74 1241.84 430.61C1242.29 431.48 1242.52 432.44 1242.52 433.5H1242.51Z'
        fill='black'
      />
      <path
        d='M1242.51 418.04C1242.51 419.33 1242.23 420.45 1241.68 421.42C1241.12 422.38 1240.34 423.13 1239.33 423.66C1238.32 424.19 1237.14 424.46 1235.79 424.46H1230.62C1229.26 424.46 1228.08 424.19 1227.08 423.66C1226.07 423.13 1225.29 422.38 1224.73 421.42C1224.17 420.46 1223.9 419.33 1223.9 418.04C1223.9 416.75 1224.18 415.63 1224.73 414.66C1225.29 413.7 1226.07 412.95 1227.08 412.42C1228.09 411.89 1229.27 411.62 1230.62 411.62H1235.79C1237.14 411.62 1238.32 411.89 1239.33 412.42C1240.34 412.95 1241.13 413.7 1241.68 414.66C1242.24 415.62 1242.51 416.75 1242.51 418.04ZM1239.98 418.04C1239.98 416.89 1239.6 415.98 1238.85 415.29C1238.1 414.6 1237.1 414.25 1235.86 414.25H1230.51C1229.26 414.25 1228.27 414.6 1227.52 415.29C1226.77 415.98 1226.4 416.9 1226.4 418.04C1226.4 419.18 1226.77 420.09 1227.52 420.79C1228.27 421.48 1229.27 421.83 1230.51 421.83H1235.86C1237.1 421.83 1238.09 421.48 1238.85 420.79C1239.61 420.1 1239.98 419.18 1239.98 418.04Z'
        fill='black'
      />
      <path
        d='M1242.32 409.999L1224.07 403.269V401.089L1242.32 394.359V397.119L1227.6 402.179L1242.32 407.239V409.999ZM1238.31 406.899H1235.88V397.299H1238.31V406.899Z'
        fill='black'
      />
      <path
        d='M1226.51 394.609H1224.08V381.729H1226.51V394.609ZM1242.32 389.459H1225.57V386.899H1242.32V389.459Z'
        fill='black'
      />
      <path
        d='M1242.51 366.859C1242.51 368.129 1242.23 369.239 1241.66 370.199C1241.1 371.149 1240.3 371.889 1239.28 372.419C1238.26 372.949 1237.07 373.209 1235.7 373.209H1230.69C1229.32 373.209 1228.12 372.949 1227.1 372.419C1226.08 371.889 1225.29 371.149 1224.72 370.199C1224.16 369.249 1223.87 368.129 1223.87 366.859C1223.87 365.809 1224.1 364.839 1224.55 363.969C1225 363.099 1225.63 362.359 1226.45 361.769C1227.26 361.179 1228.21 360.779 1229.3 360.569V363.189C1228.71 363.349 1228.2 363.609 1227.76 363.989C1227.32 364.359 1226.98 364.799 1226.74 365.299C1226.5 365.799 1226.38 366.319 1226.38 366.859C1226.38 367.979 1226.78 368.879 1227.57 369.559C1228.36 370.239 1229.4 370.579 1230.7 370.579H1235.71C1237.01 370.579 1238.05 370.239 1238.83 369.559C1239.62 368.879 1240.01 367.979 1240.01 366.859C1240.01 366.069 1239.76 365.319 1239.26 364.609C1238.76 363.899 1238.03 363.429 1237.09 363.189V360.569C1238.18 360.779 1239.13 361.179 1239.94 361.779C1240.75 362.369 1241.38 363.109 1241.84 363.979C1242.29 364.849 1242.52 365.809 1242.52 366.869L1242.51 366.859Z'
        fill='black'
      />
      <path
        d='M1242.32 346.82H1224.07V344.26H1242.32V346.82ZM1242.32 357.18H1224.07V354.62H1242.32V357.18ZM1234.55 355.59H1232.12V345.46H1234.55V355.59Z'
        fill='black'
      />
      <path
        d='M1226.51 338.5H1224.08V328.02H1226.51V338.5ZM1242.32 339.74H1224.07V337.18H1242.32V339.74ZM1234.47 338.5H1232.04V329.4H1234.47V338.5ZM1242.32 338.5H1239.89V328.02H1242.32V338.5Z'
        fill='black'
      />
      <path
        d='M1242.51 318.399C1242.51 319.669 1242.23 320.779 1241.66 321.739C1241.1 322.689 1240.3 323.429 1239.28 323.959C1238.26 324.489 1237.07 324.749 1235.7 324.749H1230.69C1229.32 324.749 1228.12 324.489 1227.1 323.959C1226.08 323.429 1225.29 322.689 1224.72 321.739C1224.16 320.789 1223.87 319.669 1223.87 318.399C1223.87 317.349 1224.1 316.379 1224.55 315.509C1225 314.639 1225.63 313.899 1226.45 313.309C1227.26 312.719 1228.21 312.319 1229.3 312.109V314.729C1228.71 314.889 1228.2 315.149 1227.76 315.529C1227.32 315.899 1226.98 316.339 1226.74 316.839C1226.5 317.339 1226.38 317.859 1226.38 318.399C1226.38 319.519 1226.78 320.419 1227.57 321.099C1228.36 321.779 1229.4 322.119 1230.7 322.119H1235.71C1237.01 322.119 1238.05 321.779 1238.83 321.099C1239.62 320.419 1240.01 319.519 1240.01 318.399C1240.01 317.609 1239.76 316.859 1239.26 316.149C1238.76 315.439 1238.03 314.969 1237.09 314.729V312.109C1238.18 312.319 1239.13 312.719 1239.94 313.309C1240.75 313.899 1241.38 314.639 1241.84 315.509C1242.29 316.379 1242.52 317.339 1242.52 318.399H1242.51Z'
        fill='black'
      />
      <path
        d='M1238.15 307.1L1234.31 306.72L1224.07 298.66V295.51L1238.15 307.09V307.1ZM1242.32 308.73H1224.07V306.17H1242.32V308.73ZM1242.32 297.89L1232.58 303.69L1230.71 301.67L1242.32 294.76V297.9V297.89Z'
        fill='black'
      />
      <path
        d='M328.98 664.51V631.43H333.6V664.51H328.98ZM331.71 664.51V660.21H340.67C343.13 660.21 344.87 659.74 345.89 658.8C346.91 657.86 347.43 656.62 347.43 655.09V655.02C347.43 653.38 346.99 652.07 346.12 651.09C345.25 650.11 343.81 649.62 341.81 649.62H331.71V645.43H341.81C343.52 645.43 344.82 645.02 345.69 644.21C346.56 643.4 347 642.21 347 640.65C347 639 346.51 637.76 345.54 636.94C344.57 636.12 343.11 635.71 341.17 635.71H331.7V631.41H342.01C345.27 631.41 347.7 632.22 349.29 633.84C350.88 635.46 351.68 637.64 351.68 640.37C351.68 642.02 351.19 643.54 350.22 644.91C349.25 646.28 347.72 647.16 345.65 647.54C347.7 647.84 349.29 648.75 350.42 650.25C351.55 651.75 352.12 653.47 352.12 655.42V655.49C352.12 658.19 351.25 660.37 349.5 662.02C347.76 663.67 345.39 664.5 342.4 664.5H331.71V664.51Z'
        fill='black'
      />
      <path
        d='M366.371 631.42V664.5H361.731V636.47L357.021 639.36V634.56L361.731 631.42H366.371Z'
        fill='black'
      />
      {/* {Booth N0 B1} */}
      <path
        d='M427.63 596.93H275.48V704.03H427.63V596.93Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2129)}
        className={
          eid === 2129
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='320' y='660' className='svgText'>
        B1
      </text>

      <path
        d='M301.99 379.61L293.69 369.81L295.06 368.65L303.36 378.45L301.99 379.61ZM302.8 378.92L301.72 377.65L304.38 375.4C305.11 374.78 305.51 374.21 305.57 373.67C305.64 373.13 305.48 372.64 305.09 372.19L305.07 372.17C304.66 371.68 304.2 371.41 303.7 371.34C303.2 371.27 302.65 371.49 302.05 371.99L299.06 374.53L298.01 373.29L301 370.75C301.51 370.32 301.79 369.87 301.84 369.42C301.89 368.96 301.73 368.5 301.33 368.04C300.91 367.55 300.46 367.31 299.97 367.31C299.48 367.31 298.94 367.56 298.37 368.04L295.57 370.42L294.49 369.15L297.54 366.56C298.51 365.74 299.43 365.37 300.31 365.45C301.19 365.53 301.97 365.98 302.66 366.79C303.08 367.28 303.31 367.85 303.37 368.5C303.43 369.15 303.19 369.79 302.67 370.43C303.35 370.01 304.05 369.87 304.76 370.04C305.47 370.2 306.07 370.57 306.56 371.14L306.58 371.16C307.26 371.96 307.55 372.82 307.44 373.75C307.34 374.68 306.84 375.52 305.96 376.27L302.79 378.95L302.8 378.92Z'
        fill='black'
      />
      <path
        d='M309.76 373.031L305.07 360.171L306.24 359.181L318.16 365.921L316.68 367.181L307.26 361.571L311.24 371.781L309.76 373.041V373.031ZM309.6 369.471L308.49 368.161L313.65 363.791L314.76 365.101L309.6 369.471Z'
        fill='black'
      />
      <path
        d='M319.64 364.66L311.33 354.85L312.71 353.68L321.02 363.49L319.64 364.66ZM316.73 360L315.62 358.69L318.96 355.86C319.36 355.52 319.56 355.11 319.57 354.63C319.57 354.15 319.39 353.67 319 353.22C318.61 352.76 318.18 352.5 317.7 352.42C317.22 352.35 316.78 352.48 316.38 352.82L313.04 355.65L311.93 354.34L315.22 351.55C315.79 351.07 316.39 350.76 317.02 350.64C317.65 350.52 318.26 350.56 318.85 350.79C319.44 351.02 319.98 351.41 320.47 351.98C320.95 352.55 321.25 353.14 321.37 353.77C321.49 354.4 321.44 355.01 321.21 355.6C320.98 356.19 320.59 356.74 320.02 357.22L316.73 360.01V360ZM325.15 359.99L319.14 357.44L320.25 355.97L326.79 358.61L325.16 359.99H325.15Z'
        fill='black'
      />
      {/* {Booth N0 B2} */}
      <path
        d='M322.571 338.593L281.306 373.269L296.347 391.168L337.612 356.493L322.571 338.593Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2130)}
        className={
          eid === 2130
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M346.471 373.651V340.571H351.091V373.651H346.471ZM349.201 373.651V369.351H358.161C360.621 369.351 362.361 368.881 363.381 367.941C364.401 367.001 364.921 365.761 364.921 364.231V364.161C364.921 362.521 364.481 361.211 363.611 360.231C362.741 359.251 361.301 358.761 359.301 358.761H349.201V354.571H359.301C361.011 354.571 362.311 354.161 363.181 353.351C364.051 352.541 364.491 351.351 364.491 349.791C364.491 348.141 364.001 346.901 363.031 346.081C362.061 345.261 360.601 344.851 358.661 344.851H349.191V340.551H359.501C362.761 340.551 365.191 341.361 366.781 342.981C368.371 344.601 369.171 346.781 369.171 349.511C369.171 351.161 368.681 352.681 367.711 354.051C366.741 355.421 365.211 356.301 363.141 356.681C365.191 356.981 366.781 357.891 367.911 359.391C369.041 360.891 369.611 362.611 369.611 364.561V364.631C369.611 367.331 368.741 369.511 366.991 371.161C365.251 372.811 362.881 373.641 359.891 373.641H349.201V373.651Z'
        fill='black'
      />
      <path
        d='M375.371 373.65V369.62L386.911 354.24C387.581 353.36 388.101 352.45 388.471 351.51C388.841 350.57 389.031 349.68 389.031 348.85V348.8C389.031 347.48 388.641 346.46 387.871 345.73C387.101 345 386.001 344.64 384.571 344.64C383.241 344.64 382.151 345.04 381.301 345.83C380.461 346.63 379.951 347.75 379.761 349.21V349.23H374.961V349.21C375.371 346.36 376.411 344.15 378.081 342.58C379.751 341.01 381.891 340.23 384.521 340.23C387.491 340.23 389.791 340.98 391.411 342.48C393.031 343.98 393.841 346.11 393.841 348.85V348.87C393.841 350.04 393.601 351.26 393.111 352.54C392.621 353.82 391.951 355.05 391.091 356.21L381.511 369.23H394.021V373.64H375.361L375.371 373.65Z'
        fill='black'
      />
      <path
        d='M294.791 460.329C294.791 460.949 294.741 461.539 294.621 462.089C294.501 462.639 294.311 463.159 294.061 463.639C293.811 464.119 293.491 464.569 293.111 464.999L291.601 463.889C292.061 463.399 292.401 462.849 292.621 462.259C292.841 461.669 292.951 461.029 292.951 460.339C292.951 459.409 292.741 458.689 292.361 458.179C291.981 457.669 291.441 457.419 290.761 457.429C290.231 457.429 289.821 457.569 289.541 457.829C289.261 458.089 289.051 458.429 288.921 458.859C288.791 459.289 288.681 459.739 288.591 460.249C288.511 460.729 288.401 461.219 288.291 461.709C288.171 462.199 287.991 462.659 287.731 463.069C287.471 463.489 287.101 463.819 286.621 464.069C286.131 464.329 285.491 464.459 284.671 464.469C283.371 464.479 282.361 464.099 281.631 463.309C280.911 462.519 280.541 461.399 280.521 459.949C280.521 459.259 280.631 458.589 280.861 457.919C281.091 457.249 281.461 456.609 281.941 455.979L283.511 456.989C283.121 457.479 282.831 457.969 282.641 458.459C282.451 458.949 282.361 459.439 282.371 459.919C282.371 460.799 282.581 461.479 282.961 461.959C283.351 462.439 283.891 462.679 284.591 462.669C285.121 462.669 285.521 462.519 285.791 462.229C286.061 461.939 286.261 461.569 286.391 461.119C286.521 460.659 286.641 460.169 286.751 459.639C286.851 459.169 286.971 458.699 287.111 458.219C287.251 457.739 287.451 457.319 287.721 456.929C287.991 456.539 288.361 456.229 288.831 455.989C289.301 455.749 289.921 455.629 290.681 455.619H290.701C291.991 455.609 292.991 456.009 293.711 456.829C294.421 457.649 294.791 458.799 294.801 460.299L294.791 460.329Z'
        fill='black'
      />
      <path
        d='M294.68 449.08C294.69 450.51 294.25 451.62 293.35 452.42C292.45 453.21 291.17 453.62 289.52 453.64L280.59 453.73L280.57 451.93L289.57 451.84C290.59 451.83 291.38 451.59 291.93 451.11C292.48 450.63 292.76 449.96 292.75 449.11C292.75 448.24 292.46 447.58 291.89 447.11C291.33 446.64 290.54 446.41 289.52 446.42L280.52 446.51L280.5 444.71L289.43 444.62C291.08 444.6 292.37 444.99 293.29 445.77C294.21 446.55 294.67 447.66 294.68 449.1V449.08Z'
        fill='black'
      />
      <path
        d='M294.571 438.039C294.571 438.659 294.521 439.249 294.401 439.799C294.281 440.349 294.091 440.869 293.841 441.349C293.591 441.829 293.271 442.279 292.891 442.709L291.381 441.599C291.841 441.109 292.181 440.559 292.401 439.969C292.621 439.379 292.731 438.739 292.731 438.049C292.731 437.119 292.521 436.399 292.141 435.889C291.761 435.379 291.221 435.129 290.541 435.139C290.011 435.139 289.601 435.279 289.321 435.539C289.041 435.799 288.831 436.139 288.701 436.569C288.571 436.989 288.461 437.449 288.371 437.959C288.291 438.439 288.181 438.929 288.071 439.429C287.961 439.929 287.771 440.379 287.511 440.789C287.251 441.199 286.881 441.539 286.401 441.789C285.911 442.039 285.271 442.179 284.451 442.179C283.151 442.189 282.141 441.809 281.411 441.019C280.691 440.229 280.321 439.109 280.301 437.659C280.301 436.969 280.411 436.289 280.641 435.629C280.871 434.969 281.241 434.319 281.721 433.689L283.291 434.699C282.901 435.189 282.611 435.679 282.421 436.169C282.231 436.659 282.141 437.149 282.151 437.629C282.151 438.509 282.361 439.189 282.741 439.669C283.121 440.149 283.671 440.389 284.371 440.379C284.901 440.379 285.301 440.229 285.571 439.939C285.841 439.649 286.041 439.279 286.171 438.819C286.301 438.359 286.421 437.869 286.531 437.349C286.631 436.879 286.751 436.409 286.891 435.929C287.031 435.449 287.231 435.029 287.501 434.639C287.771 434.249 288.141 433.939 288.611 433.699C289.081 433.459 289.701 433.339 290.461 433.329H290.481C291.771 433.319 292.771 433.719 293.481 434.539C294.191 435.359 294.561 436.509 294.571 438.009V438.039Z'
        fill='black'
      />
      <path
        d='M294.29 423.64L280.3 423.78L280.28 421.98L294.27 421.84L294.29 423.64ZM294.36 430.94L280.37 431.08L280.35 429.28L294.34 429.14L294.36 430.94ZM288.39 429.88L286.52 429.9L286.45 422.77L288.32 422.75L288.39 429.88Z'
        fill='black'
      />
      <path
        d='M280.23 416.99L294.22 416.85L294.24 418.65L280.25 418.79L280.23 416.99Z'
        fill='black'
      />
      <path
        d='M294.791 555.209C294.791 555.829 294.741 556.419 294.621 556.969C294.501 557.519 294.311 558.039 294.061 558.519C293.811 558.999 293.491 559.449 293.111 559.879L291.601 558.769C292.061 558.279 292.401 557.729 292.621 557.139C292.841 556.549 292.951 555.909 292.951 555.219C292.951 554.289 292.741 553.569 292.361 553.059C291.981 552.549 291.441 552.299 290.761 552.309C290.231 552.309 289.821 552.449 289.541 552.709C289.261 552.969 289.051 553.309 288.921 553.739C288.791 554.169 288.681 554.619 288.591 555.129C288.511 555.609 288.401 556.099 288.291 556.589C288.171 557.079 287.991 557.539 287.731 557.949C287.471 558.369 287.101 558.699 286.621 558.949C286.131 559.209 285.491 559.339 284.671 559.349C283.371 559.359 282.361 558.979 281.631 558.189C280.911 557.399 280.541 556.279 280.521 554.829C280.521 554.139 280.631 553.469 280.861 552.799C281.091 552.129 281.461 551.489 281.941 550.859L283.511 551.869C283.121 552.359 282.831 552.849 282.641 553.339C282.451 553.829 282.361 554.319 282.371 554.799C282.371 555.679 282.581 556.359 282.961 556.839C283.351 557.319 283.891 557.559 284.591 557.549C285.121 557.549 285.521 557.399 285.791 557.109C286.061 556.819 286.261 556.449 286.391 555.999C286.521 555.539 286.641 555.049 286.751 554.519C286.851 554.049 286.971 553.579 287.111 553.099C287.251 552.619 287.451 552.199 287.721 551.809C287.991 551.419 288.361 551.109 288.831 550.869C289.301 550.629 289.921 550.509 290.681 550.499H290.701C291.991 550.489 292.991 550.889 293.711 551.709C294.421 552.529 294.791 553.679 294.801 555.179L294.791 555.209Z'
        fill='black'
      />
      <path
        d='M294.68 543.949C294.69 545.379 294.25 546.489 293.35 547.289C292.45 548.079 291.17 548.489 289.52 548.509L280.59 548.599L280.57 546.799L289.57 546.709C290.59 546.699 291.38 546.459 291.93 545.979C292.48 545.499 292.76 544.829 292.75 543.979C292.75 543.109 292.46 542.449 291.89 541.979C291.33 541.509 290.54 541.279 289.52 541.289L280.52 541.379L280.5 539.579L289.43 539.489C291.08 539.469 292.37 539.859 293.29 540.639C294.21 541.419 294.67 542.529 294.68 543.969V543.949Z'
        fill='black'
      />
      <path
        d='M294.571 532.919C294.571 533.539 294.521 534.129 294.401 534.679C294.281 535.229 294.091 535.749 293.841 536.229C293.591 536.709 293.271 537.159 292.891 537.589L291.381 536.479C291.841 535.989 292.181 535.439 292.401 534.849C292.621 534.259 292.731 533.619 292.731 532.929C292.731 531.999 292.521 531.279 292.141 530.769C291.761 530.259 291.221 530.009 290.541 530.019C290.011 530.019 289.601 530.159 289.321 530.419C289.041 530.679 288.831 531.019 288.701 531.449C288.571 531.869 288.461 532.329 288.371 532.839C288.291 533.319 288.181 533.809 288.071 534.309C287.961 534.809 287.771 535.259 287.511 535.669C287.251 536.079 286.881 536.419 286.401 536.669C285.911 536.919 285.271 537.059 284.451 537.059C283.151 537.069 282.141 536.689 281.411 535.899C280.691 535.109 280.321 533.989 280.301 532.539C280.301 531.849 280.411 531.169 280.641 530.509C280.871 529.849 281.241 529.199 281.721 528.569L283.291 529.579C282.901 530.069 282.611 530.559 282.421 531.049C282.231 531.539 282.141 532.029 282.151 532.509C282.151 533.389 282.361 534.069 282.741 534.549C283.121 535.029 283.671 535.269 284.371 535.259C284.901 535.259 285.301 535.109 285.571 534.819C285.841 534.529 286.041 534.159 286.171 533.699C286.301 533.239 286.421 532.749 286.531 532.229C286.631 531.759 286.751 531.289 286.891 530.809C287.031 530.329 287.231 529.909 287.501 529.519C287.771 529.129 288.141 528.819 288.611 528.579C289.081 528.339 289.701 528.219 290.461 528.209H290.481C291.771 528.199 292.771 528.599 293.481 529.419C294.191 530.239 294.561 531.389 294.571 532.889V532.919Z'
        fill='black'
      />
      <path
        d='M294.29 518.52L280.3 518.66L280.28 516.86L294.27 516.72L294.29 518.52ZM294.36 525.82L280.37 525.96L280.35 524.16L294.34 524.02L294.36 525.82ZM288.39 524.75L286.52 524.77L286.45 517.64L288.32 517.62L288.39 524.75Z'
        fill='black'
      />
      <path
        d='M280.23 511.869L294.22 511.729L294.24 513.53L280.25 513.669L280.23 511.869Z'
        fill='black'
      />
      {/* {Booth N0 B3} */}
      <path
        d='M300.13 401.68H276.9V476.02H300.13V401.68Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2131)}
        className={
          eid === 2131
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M310.021 511.599V478.519H314.641V511.599H310.021ZM312.751 511.599V507.299H321.711C324.171 507.299 325.911 506.829 326.931 505.889C327.951 504.949 328.471 503.709 328.471 502.179V502.109C328.471 500.469 328.031 499.159 327.161 498.179C326.291 497.199 324.851 496.709 322.851 496.709H312.751V492.519H322.851C324.561 492.519 325.861 492.109 326.731 491.299C327.601 490.489 328.041 489.299 328.041 487.739C328.041 486.089 327.551 484.849 326.581 484.029C325.611 483.209 324.151 482.799 322.211 482.799H312.741V478.499H323.051C326.311 478.499 328.741 479.309 330.331 480.929C331.921 482.549 332.721 484.729 332.721 487.459C332.721 489.109 332.231 490.629 331.261 491.999C330.291 493.369 328.761 494.249 326.691 494.629C328.741 494.929 330.331 495.839 331.461 497.339C332.591 498.839 333.151 500.559 333.151 502.509V502.579C333.151 505.279 332.281 507.459 330.531 509.109C328.791 510.759 326.421 511.589 323.431 511.589H312.741L312.751 511.599Z'
        fill='black'
      />
      <path
        d='M347.971 511.939C346.101 511.939 344.471 511.599 343.071 510.929C341.671 510.259 340.541 509.279 339.691 508.009C338.841 506.739 338.291 505.189 338.051 503.369H342.781C342.991 504.799 343.551 505.839 344.461 506.499C345.371 507.159 346.541 507.489 347.961 507.489C349.491 507.489 350.681 507.079 351.531 506.259C352.381 505.439 352.801 504.289 352.801 502.799V501.799C352.801 500.149 352.401 498.869 351.591 497.969C350.791 497.069 349.651 496.619 348.181 496.619H345.841V492.209H348.181C349.481 492.209 350.491 491.819 351.191 491.049C351.901 490.279 352.251 489.179 352.251 487.769V486.749C352.251 485.449 351.871 484.439 351.121 483.719C350.371 483.009 349.301 482.649 347.921 482.649C346.751 482.649 345.761 482.979 344.951 483.639C344.141 484.299 343.581 485.339 343.281 486.769H338.571C339.041 484.039 340.091 481.929 341.721 480.429C343.351 478.939 345.421 478.189 347.921 478.189C350.771 478.189 352.981 478.939 354.541 480.439C356.101 481.939 356.881 484.059 356.881 486.789V487.339C356.881 488.989 356.431 490.429 355.541 491.649C354.641 492.869 353.381 493.769 351.741 494.349C353.551 494.729 354.941 495.609 355.941 496.999C356.931 498.389 357.431 500.149 357.431 502.289V502.839C357.431 505.739 356.601 507.979 354.951 509.579C353.301 511.169 350.961 511.969 347.941 511.969L347.971 511.939Z'
        fill='black'
      />
      {/* {Booth N0 B3} */}
      <path
        d='M299.78 497.819H277.62V572.159H299.78V497.819Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2131)}
        className={
          eid === 2131
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      {/* {Booth N0 B4} */}
      <path
        d='M1205.76 355.5H1157.84V459.56H1205.76V355.5Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2132)}
        className={
          eid === 2132
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M1105.85 416.01V382.93H1110.47V416.01H1105.85ZM1108.58 416.01V411.71H1117.54C1120 411.71 1121.74 411.24 1122.76 410.3C1123.78 409.36 1124.3 408.12 1124.3 406.59V406.52C1124.3 404.88 1123.86 403.57 1122.99 402.59C1122.12 401.61 1120.68 401.12 1118.68 401.12H1108.58V396.93H1118.68C1120.39 396.93 1121.69 396.52 1122.56 395.71C1123.43 394.9 1123.87 393.71 1123.87 392.15C1123.87 390.5 1123.38 389.26 1122.41 388.44C1121.44 387.62 1119.98 387.21 1118.04 387.21H1108.57V382.91H1118.88C1122.14 382.91 1124.57 383.72 1126.16 385.34C1127.75 386.96 1128.55 389.14 1128.55 391.87C1128.55 393.52 1128.06 395.04 1127.09 396.41C1126.12 397.78 1124.59 398.66 1122.52 399.04C1124.57 399.34 1126.16 400.25 1127.29 401.75C1128.42 403.25 1128.99 404.97 1128.99 406.92V406.99C1128.99 409.69 1128.12 411.87 1126.37 413.52C1124.63 415.17 1122.26 416 1119.27 416H1108.58V416.01Z'
        fill='black'
      />
      <path
        d='M1134.8 410.93V406.9L1145.9 382.94H1150.68L1139.85 406.63H1156.07V410.93H1134.8ZM1149.06 416.03V396.55H1153.59V416.03H1149.06Z'
        fill='black'
      />
      <path
        d='M1107.73 633.29H1047.69V653.34H1107.73V633.29Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1205.75 530.37H1173.48V653.33H1205.75V530.37Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1120.26 604.711L1127.04 578.771H1130.23L1137.01 604.711L1142.86 578.771H1147.68L1139.6 611.85H1134.87L1128.77 588.73L1122.4 611.85H1117.67L1109.59 578.771H1114.41L1120.26 604.711Z'
        fill='black'
      />
      <path
        d='M1159.76 578.771V611.85H1155.12V583.82L1150.41 586.711V581.91L1155.12 578.771H1159.76Z'
        fill='black'
      />
      <path
        d='M1230.94 1005.42L1237.72 979.48H1240.91L1247.69 1005.42L1253.54 979.48H1258.36L1250.28 1012.56H1245.55L1239.45 989.44L1233.08 1012.56H1228.35L1220.27 979.48H1225.09L1230.94 1005.42Z'
        fill='black'
      />
      <path
        d='M1261.95 1012.56V1008.53L1273.49 993.151C1274.16 992.271 1274.68 991.361 1275.05 990.421C1275.42 989.481 1275.61 988.591 1275.61 987.761V987.711C1275.61 986.391 1275.22 985.371 1274.45 984.641C1273.68 983.911 1272.58 983.551 1271.15 983.551C1269.82 983.551 1268.73 983.951 1267.88 984.741C1267.04 985.541 1266.53 986.661 1266.34 988.121V988.141H1261.54V988.121C1261.95 985.271 1262.99 983.061 1264.66 981.491C1266.33 979.921 1268.47 979.141 1271.1 979.141C1274.07 979.141 1276.37 979.891 1277.99 981.391C1279.61 982.891 1280.42 985.021 1280.42 987.761V987.781C1280.42 988.951 1280.18 990.171 1279.69 991.451C1279.2 992.731 1278.53 993.961 1277.67 995.121L1268.09 1008.14H1280.6V1012.55H1261.94L1261.95 1012.56Z'
        fill='black'
      />
      <path
        d='M1232.01 949.771H1218.32V1049.6H1232.01V949.771Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1234.18 486.32H1218.32V851.33H1234.18V486.32Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1233.66 717.81L1240.44 691.87H1243.63L1250.41 717.81L1256.26 691.87H1261.08L1253 724.95H1248.27L1242.17 701.83L1235.8 724.95H1231.07L1222.99 691.87H1227.81L1233.66 717.81Z'
        fill='black'
      />
      <path
        d='M1264.68 724.96V720.93L1276.22 705.55C1276.89 704.67 1277.41 703.76 1277.78 702.82C1278.15 701.88 1278.34 700.99 1278.34 700.16V700.11C1278.34 698.79 1277.95 697.77 1277.18 697.04C1276.41 696.31 1275.31 695.95 1273.88 695.95C1272.55 695.95 1271.46 696.35 1270.61 697.14C1269.77 697.94 1269.26 699.06 1269.07 700.52V700.54H1264.27V700.52C1264.68 697.67 1265.72 695.46 1267.39 693.89C1269.06 692.32 1271.2 691.54 1273.83 691.54C1276.8 691.54 1279.1 692.29 1280.72 693.79C1282.34 695.29 1283.15 697.42 1283.15 700.16V700.18C1283.15 701.35 1282.91 702.57 1282.42 703.85C1281.93 705.13 1281.26 706.36 1280.4 707.52L1270.82 720.54H1283.33V724.95H1264.67L1264.68 724.96Z'
        fill='black'
      />
      <path
        d='M773.23 645.42H478.32V669.57H773.23V645.42Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M616.401 633.11L623.18 607.17H626.37L633.151 633.11L639 607.17H643.82L635.74 640.25H631.01L624.91 617.13L618.54 640.25H613.81L605.73 607.17H610.55L616.401 633.11Z'
        fill='black'
      />
      <path
        d='M656.471 640.59C654.601 640.59 652.971 640.25 651.571 639.58C650.171 638.91 649.041 637.93 648.191 636.66C647.341 635.39 646.791 633.84 646.551 632.02H651.281C651.491 633.45 652.051 634.49 652.961 635.15C653.871 635.81 655.041 636.14 656.461 636.14C657.991 636.14 659.181 635.73 660.031 634.91C660.881 634.09 661.301 632.94 661.301 631.45V630.45C661.301 628.8 660.901 627.52 660.091 626.62C659.291 625.72 658.151 625.27 656.681 625.27H654.341V620.86H656.681C657.981 620.86 658.991 620.47 659.701 619.7C660.411 618.93 660.761 617.83 660.761 616.42V615.4C660.761 614.1 660.381 613.09 659.631 612.37C658.881 611.66 657.811 611.3 656.431 611.3C655.261 611.3 654.271 611.63 653.461 612.29C652.651 612.95 652.091 613.99 651.791 615.42H647.081C647.551 612.69 648.601 610.58 650.231 609.08C651.861 607.59 653.931 606.84 656.431 606.84C659.281 606.84 661.491 607.59 663.051 609.09C664.611 610.59 665.391 612.71 665.391 615.44V615.99C665.391 617.64 664.941 619.08 664.051 620.3C663.151 621.52 661.891 622.42 660.251 623C662.061 623.38 663.451 624.26 664.451 625.65C665.441 627.04 665.941 628.8 665.941 630.94V631.49C665.941 634.39 665.111 636.63 663.461 638.23C661.811 639.82 659.471 640.62 656.451 640.62L656.471 640.59Z'
        fill='black'
      />
      <path
        d='M1096.74 765.87C1093.58 765.87 1091.22 765.02 1089.65 763.33C1088.08 761.64 1087.3 759.35 1087.3 756.47V741.43C1087.3 738.5 1088.1 736.2 1089.69 734.54C1091.28 732.87 1093.63 732.04 1096.74 732.04C1099.85 732.04 1102.22 732.87 1103.8 734.53C1105.38 736.19 1106.18 738.49 1106.18 741.44V756.48C1106.18 759.41 1105.39 761.71 1103.8 763.37C1102.22 765.04 1099.86 765.87 1096.74 765.87ZM1096.74 761.45C1098.44 761.45 1099.66 761.01 1100.41 760.12C1101.16 759.23 1101.54 758.02 1101.54 756.47V741.43C1101.54 739.87 1101.16 738.65 1100.41 737.77C1099.66 736.89 1098.43 736.45 1096.74 736.45C1095.05 736.45 1093.82 736.89 1093.07 737.77C1092.32 738.65 1091.94 739.87 1091.94 741.43V756.47C1091.94 758.02 1092.32 759.23 1093.07 760.12C1093.82 761.01 1095.05 761.45 1096.74 761.45Z'
        fill='black'
      />
      <path
        d='M1121 732.44V765.52H1116.36V737.49L1111.65 740.38V735.58L1116.36 732.44H1121Z'
        fill='black'
      />
      {/* {Booth N0 01} */}
      <path
        d='M1205.75 653.33H995.28V831.37H1205.75V653.33Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2127)}
        className={
          eid === 2127
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='1080' y='760' className='svgText'>
        01
      </text>

      {/* {Booth N0 02} */}
      <path
        d='M773.23 669.57H478.32V831.37H773.23V669.57Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2128)}
        className={
          eid === 2128
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M611.93 765.87C608.77 765.87 606.41 765.02 604.84 763.33C603.27 761.64 602.49 759.35 602.49 756.47V741.43C602.49 738.5 603.29 736.2 604.88 734.54C606.47 732.87 608.82 732.04 611.93 732.04C615.04 732.04 617.41 732.87 618.99 734.53C620.57 736.19 621.37 738.49 621.37 741.44V756.48C621.37 759.41 620.58 761.71 618.99 763.37C617.41 765.04 615.05 765.87 611.93 765.87ZM611.93 761.45C613.63 761.45 614.85 761.01 615.6 760.12C616.35 759.23 616.73 758.02 616.73 756.47V741.43C616.73 739.87 616.35 738.65 615.6 737.77C614.85 736.89 613.62 736.45 611.93 736.45C610.24 736.45 609.01 736.89 608.26 737.77C607.51 738.65 607.13 739.87 607.13 741.43V756.47C607.13 758.02 607.51 759.23 608.26 760.12C609.01 761.01 610.24 761.45 611.93 761.45Z'
        fill='black'
      />
      <path
        d='M627.7 765.53V761.5L639.24 746.12C639.91 745.24 640.43 744.33 640.8 743.39C641.17 742.45 641.36 741.56 641.36 740.73V740.68C641.36 739.36 640.97 738.34 640.2 737.61C639.43 736.88 638.33 736.52 636.9 736.52C635.57 736.52 634.48 736.92 633.63 737.71C632.79 738.51 632.28 739.63 632.09 741.09V741.11H627.29V741.09C627.7 738.24 628.74 736.03 630.41 734.46C632.08 732.89 634.22 732.11 636.85 732.11C639.82 732.11 642.12 732.86 643.74 734.36C645.36 735.86 646.17 737.99 646.17 740.73V740.75C646.17 741.92 645.93 743.14 645.44 744.42C644.95 745.7 644.28 746.93 643.41 748.09L633.83 761.11H646.34V765.52H627.68L627.7 765.53Z'
        fill='black'
      />
      <path
        d='M337.741 763.02C334.581 763.02 332.221 762.17 330.651 760.48C329.081 758.79 328.301 756.5 328.301 753.62V738.58C328.301 735.65 329.101 733.35 330.691 731.69C332.281 730.02 334.631 729.19 337.741 729.19C340.851 729.19 343.221 730.02 344.801 731.68C346.381 733.34 347.181 735.64 347.181 738.59V753.63C347.181 756.56 346.391 758.86 344.801 760.52C343.221 762.19 340.861 763.02 337.741 763.02ZM337.741 758.61C339.441 758.61 340.661 758.17 341.411 757.28C342.161 756.39 342.541 755.18 342.541 753.63V738.59C342.541 737.03 342.161 735.81 341.411 734.93C340.661 734.05 339.431 733.61 337.741 733.61C336.051 733.61 334.821 734.05 334.071 734.93C333.321 735.81 332.941 737.03 332.941 738.59V753.63C332.941 755.18 333.321 756.39 334.071 757.28C334.821 758.17 336.051 758.61 337.741 758.61Z'
        fill='black'
      />
      <path
        d='M362.341 763.021C360.471 763.021 358.841 762.68 357.441 762.01C356.041 761.34 354.911 760.361 354.061 759.091C353.211 757.821 352.661 756.271 352.421 754.451H357.151C357.361 755.881 357.921 756.921 358.831 757.581C359.741 758.241 360.911 758.57 362.331 758.57C363.861 758.57 365.051 758.161 365.901 757.341C366.751 756.521 367.171 755.37 367.171 753.88V752.88C367.171 751.23 366.771 749.95 365.961 749.05C365.161 748.15 364.021 747.701 362.551 747.701H360.211V743.29H362.551C363.851 743.29 364.861 742.9 365.561 742.13C366.271 741.36 366.621 740.26 366.621 738.85V737.831C366.621 736.531 366.241 735.52 365.491 734.8C364.741 734.09 363.671 733.73 362.291 733.73C361.121 733.73 360.131 734.06 359.321 734.72C358.511 735.38 357.951 736.42 357.651 737.85H352.941C353.411 735.12 354.461 733.01 356.091 731.51C357.721 730.02 359.791 729.271 362.291 729.271C365.141 729.271 367.351 730.021 368.911 731.521C370.471 733.021 371.251 735.14 371.251 737.87V738.42C371.251 740.07 370.801 741.51 369.911 742.73C369.011 743.95 367.751 744.85 366.111 745.43C367.911 745.81 369.311 746.691 370.311 748.081C371.301 749.471 371.801 751.23 371.801 753.37V753.92C371.801 756.82 370.971 759.06 369.321 760.66C367.671 762.26 365.331 763.05 362.311 763.05L362.341 763.021Z'
        fill='black'
      />
      {/* {Booth N0 03} */}
      <path
        d='M427.63 704.03H275.48V793.28H427.63V704.03Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2133)}
        className={
          eid === 2133
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='320' y='760' className='svgText'>
        03
      </text>

      <path
        d='M337.741 852.27C334.581 852.27 332.221 851.42 330.651 849.73C329.081 848.04 328.301 845.75 328.301 842.87V827.83C328.301 824.9 329.101 822.6 330.691 820.94C332.281 819.27 334.631 818.44 337.741 818.44C340.851 818.44 343.221 819.27 344.801 820.93C346.381 822.59 347.181 824.89 347.181 827.84V842.88C347.181 845.81 346.391 848.11 344.801 849.77C343.221 851.44 340.861 852.27 337.741 852.27ZM337.741 847.86C339.441 847.86 340.661 847.42 341.411 846.53C342.161 845.64 342.541 844.43 342.541 842.88V827.84C342.541 826.28 342.161 825.06 341.411 824.18C340.661 823.3 339.431 822.86 337.741 822.86C336.051 822.86 334.821 823.3 334.071 824.18C333.321 825.06 332.941 826.28 332.941 827.84V842.88C332.941 844.43 333.321 845.64 334.071 846.53C334.821 847.42 336.051 847.86 337.741 847.86Z'
        fill='black'
      />
      <path
        d='M353.561 846.85V842.82L364.661 818.86H369.441L358.611 842.55H374.831V846.85H353.561ZM367.821 851.95V832.47H372.351V851.95H367.821Z'
        fill='black'
      />
      {/* {Booth N0 04} */}
      <path
        d='M427.63 793.28H275.48V884.9H427.63V793.28Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2134)}
        className={
          eid === 2134
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='320' y='855' className='svgText'>
        04
      </text>

      <path
        d='M337.741 943.891C334.581 943.891 332.221 943.041 330.651 941.351C329.081 939.661 328.301 937.371 328.301 934.491V919.451C328.301 916.521 329.101 914.221 330.691 912.561C332.281 910.891 334.631 910.061 337.741 910.061C340.851 910.061 343.221 910.891 344.801 912.551C346.381 914.211 347.181 916.511 347.181 919.461V934.501C347.181 937.431 346.391 939.731 344.801 941.391C343.221 943.061 340.861 943.891 337.741 943.891ZM337.741 939.481C339.441 939.481 340.661 939.041 341.411 938.151C342.161 937.261 342.541 936.051 342.541 934.501V919.461C342.541 917.901 342.161 916.681 341.411 915.801C340.661 914.921 339.431 914.481 337.741 914.481C336.051 914.481 334.821 914.921 334.071 915.801C333.321 916.681 332.941 917.901 332.941 919.461V934.501C332.941 936.051 333.321 937.261 334.071 938.151C334.821 939.041 336.051 939.481 337.741 939.481Z'
        fill='black'
      />
      <path
        d='M363.251 943.89C360.731 943.89 358.671 943.17 357.051 941.72C355.431 940.27 354.421 938.23 354.011 935.59V935.57H358.651V935.59C358.821 936.8 359.301 937.75 360.111 938.42C360.911 939.09 361.961 939.43 363.251 939.43C364.741 939.43 365.891 938.93 366.701 937.94C367.511 936.95 367.921 935.55 367.921 933.74V930.96C367.921 929.16 367.511 927.76 366.701 926.77C365.891 925.78 364.741 925.29 363.251 925.29C362.451 925.29 361.671 925.52 360.931 925.97C360.191 926.42 359.531 927.06 358.971 927.88H354.741V910.45H371.601V914.86H359.381V922.3C359.961 921.83 360.601 921.47 361.311 921.21C362.021 920.95 362.751 920.82 363.491 920.82C366.371 920.82 368.601 921.7 370.181 923.47C371.761 925.24 372.551 927.73 372.551 930.94V933.72C372.551 936.95 371.741 939.45 370.121 941.22C368.501 942.99 366.211 943.87 363.251 943.87V943.89Z'
        fill='black'
      />
      {/* {Booth N0 05} */}
      <path
        d='M427.63 884.9H275.48V977.01H427.63V884.9Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2135)}
        className={
          eid === 2135
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='320' y='942' className='svgText'>
        05
      </text>

      {/* {Booth N0 06} */}
      <path
        d='M639.65 886.24H474.22V999.79H639.65V886.24Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2136)}
        className={
          eid === 2136
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M550.52 960.53C547.36 960.53 545 959.68 543.43 957.99C541.86 956.3 541.08 954.01 541.08 951.13V936.09C541.08 933.16 541.88 930.86 543.47 929.2C545.06 927.53 547.41 926.7 550.52 926.7C553.63 926.7 556 927.53 557.58 929.19C559.16 930.85 559.96 933.15 559.96 936.1V951.14C559.96 954.07 559.17 956.37 557.58 958.03C556 959.7 553.64 960.53 550.52 960.53ZM550.52 956.12C552.22 956.12 553.44 955.68 554.19 954.79C554.94 953.9 555.32 952.69 555.32 951.14V936.1C555.32 934.54 554.94 933.32 554.19 932.44C553.44 931.56 552.21 931.12 550.52 931.12C548.83 931.12 547.6 931.56 546.85 932.44C546.1 933.32 545.72 934.54 545.72 936.1V951.14C545.72 952.69 546.1 953.9 546.85 954.79C547.6 955.68 548.83 956.12 550.52 956.12Z'
        fill='black'
      />
      <path
        d='M575.32 960.53C572.39 960.53 570.12 959.66 568.52 957.91C566.91 956.17 566.11 953.71 566.11 950.54V950.52C566.11 949.09 566.31 947.58 566.7 945.98C567.09 944.38 567.66 942.84 568.41 941.35L575.6 927.11H580.61L572.17 943.61L572.19 942.79C572.98 941.29 574.38 940.54 576.4 940.54C578.99 940.54 581 941.4 582.43 943.12C583.86 944.84 584.57 947.27 584.57 950.41V950.43C584.57 953.63 583.77 956.11 582.16 957.88C580.55 959.65 578.28 960.53 575.33 960.53H575.32ZM575.32 956.07C576.81 956.07 577.94 955.61 578.73 954.7C579.52 953.79 579.91 952.49 579.91 950.81V950.79C579.91 948.94 579.49 947.51 578.66 946.5C577.83 945.49 576.64 944.99 575.09 944.99C573.71 944.99 572.64 945.5 571.88 946.51C571.12 947.53 570.74 948.96 570.74 950.81V950.83C570.74 952.51 571.14 953.81 571.93 954.71C572.72 955.61 573.85 956.06 575.31 956.06L575.32 956.07Z'
        fill='black'
      />
      {/* {Booth No 07} */}
      <path
        d='M427.63 977.01H275.48V1115.47H427.63V977.01Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2137)}
        className={
          eid === 2137
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M337.741 1059.18C334.581 1059.18 332.221 1058.33 330.651 1056.64C329.081 1054.95 328.301 1052.66 328.301 1049.78V1034.74C328.301 1031.81 329.101 1029.51 330.691 1027.85C332.281 1026.18 334.631 1025.35 337.741 1025.35C340.851 1025.35 343.221 1026.18 344.801 1027.84C346.381 1029.5 347.181 1031.8 347.181 1034.75V1049.79C347.181 1052.72 346.391 1055.02 344.801 1056.68C343.221 1058.35 340.861 1059.18 337.741 1059.18ZM337.741 1054.76C339.441 1054.76 340.661 1054.32 341.411 1053.43C342.161 1052.54 342.541 1051.33 342.541 1049.78V1034.74C342.541 1033.18 342.161 1031.96 341.411 1031.08C340.661 1030.2 339.431 1029.76 337.741 1029.76C336.051 1029.76 334.821 1030.2 334.071 1031.08C333.321 1031.96 332.941 1033.18 332.941 1034.74V1049.78C332.941 1051.33 333.321 1052.54 334.071 1053.43C334.821 1054.32 336.051 1054.76 337.741 1054.76Z'
        fill='black'
      />
      <path
        d='M371.101 1025.75V1029.89L362.541 1058.83H357.601L366.161 1030.16H357.741V1034.92H353.101V1025.75H371.101Z'
        fill='black'
      />

      {/* {Booth N0 08} */}
      <path
        d='M639.65 999.79H474.22V1140.64H639.65V999.79Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2138)}
        className={
          eid === 2138
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M550.52 1087.73C547.36 1087.73 545 1086.89 543.43 1085.19C541.86 1083.49 541.08 1081.21 541.08 1078.33V1063.29C541.08 1060.36 541.88 1058.06 543.47 1056.4C545.06 1054.73 547.41 1053.9 550.52 1053.9C553.63 1053.9 556 1054.73 557.58 1056.39C559.16 1058.05 559.96 1060.35 559.96 1063.3V1078.34C559.96 1081.27 559.17 1083.57 557.58 1085.24C556 1086.91 553.64 1087.74 550.52 1087.74V1087.73ZM550.52 1083.32C552.22 1083.32 553.44 1082.88 554.19 1081.99C554.94 1081.1 555.32 1079.89 555.32 1078.34V1063.3C555.32 1061.74 554.94 1060.52 554.19 1059.64C553.44 1058.76 552.21 1058.32 550.52 1058.32C548.83 1058.32 547.6 1058.76 546.85 1059.64C546.1 1060.52 545.72 1061.74 545.72 1063.3V1078.34C545.72 1079.89 546.1 1081.11 546.85 1081.99C547.6 1082.88 548.83 1083.32 550.52 1083.32Z'
        fill='black'
      />
      <path
        d='M576.48 1087.73C574.46 1087.73 572.7 1087.35 571.2 1086.59C569.7 1085.83 568.53 1084.76 567.7 1083.38C566.87 1082 566.45 1080.38 566.45 1078.53V1077.98C566.45 1076.33 566.86 1074.77 567.69 1073.32C568.52 1071.86 569.62 1070.76 571 1070.02C569.85 1069.38 568.91 1068.46 568.2 1067.26C567.49 1066.06 567.13 1064.78 567.13 1063.45V1062.65C567.13 1060.03 567.98 1057.92 569.69 1056.34C571.4 1054.76 573.66 1053.96 576.48 1053.96C579.3 1053.96 581.57 1054.75 583.27 1056.34C584.98 1057.93 585.83 1060.03 585.83 1062.65V1063.45C585.83 1064.82 585.47 1066.1 584.75 1067.3C584.03 1068.5 583.08 1069.41 581.89 1070.03C583.29 1070.77 584.4 1071.87 585.25 1073.33C586.09 1074.79 586.51 1076.34 586.51 1077.99V1078.54C586.51 1080.39 586.09 1082.01 585.26 1083.39C584.43 1084.77 583.26 1085.84 581.76 1086.6C580.26 1087.36 578.5 1087.74 576.48 1087.74V1087.73ZM576.48 1083.27C578.1 1083.27 579.41 1082.8 580.4 1081.87C581.39 1080.94 581.89 1079.71 581.89 1078.2V1077.88C581.89 1076.35 581.39 1075.12 580.4 1074.18C579.41 1073.25 578.1 1072.78 576.48 1072.78C574.86 1072.78 573.55 1073.25 572.55 1074.18C571.56 1075.11 571.06 1076.35 571.06 1077.9V1078.24C571.06 1079.76 571.56 1080.97 572.55 1081.89C573.54 1082.81 574.85 1083.27 576.48 1083.27ZM576.48 1068.32C577.89 1068.32 579.03 1067.87 579.89 1066.98C580.75 1066.08 581.19 1064.9 581.19 1063.43V1063.11C581.19 1061.7 580.76 1060.57 579.89 1059.71C579.03 1058.85 577.89 1058.42 576.48 1058.42C575.07 1058.42 573.93 1058.85 573.07 1059.71C572.21 1060.57 571.77 1061.71 571.77 1063.13V1063.47C571.77 1064.93 572.2 1066.1 573.07 1066.99C573.93 1067.88 575.07 1068.32 576.48 1068.32Z'
        fill='black'
      />
      <path
        d='M337.741 1193.52C334.581 1193.52 332.221 1192.67 330.651 1190.98C329.081 1189.29 328.301 1187 328.301 1184.12V1169.08C328.301 1166.15 329.101 1163.85 330.691 1162.19C332.281 1160.52 334.631 1159.69 337.741 1159.69C340.851 1159.69 343.221 1160.52 344.801 1162.18C346.381 1163.84 347.181 1166.14 347.181 1169.09V1184.13C347.181 1187.06 346.391 1189.36 344.801 1191.02C343.221 1192.69 340.861 1193.52 337.741 1193.52ZM337.741 1189.1C339.441 1189.1 340.661 1188.66 341.411 1187.77C342.161 1186.88 342.541 1185.67 342.541 1184.12V1169.08C342.541 1167.52 342.161 1166.3 341.411 1165.42C340.661 1164.54 339.431 1164.1 337.741 1164.1C336.051 1164.1 334.821 1164.54 334.071 1165.42C333.321 1166.3 332.941 1167.52 332.941 1169.08V1184.12C332.941 1185.67 333.321 1186.88 334.071 1187.77C334.821 1188.66 336.051 1189.1 337.741 1189.1Z'
        fill='black'
      />
      <path
        d='M357.401 1193.18L365.731 1176.75L365.711 1177.57C365.321 1178.28 364.731 1178.8 363.961 1179.12C363.191 1179.44 362.291 1179.6 361.271 1179.6C358.891 1179.6 356.971 1178.72 355.511 1176.95C354.051 1175.18 353.331 1172.84 353.331 1169.91V1169.89C353.331 1166.67 354.141 1164.18 355.751 1162.42C357.361 1160.66 359.641 1159.77 362.561 1159.77C365.481 1159.77 367.761 1160.66 369.361 1162.44C370.961 1164.22 371.771 1166.74 371.771 1170.01V1170.03C371.771 1171.38 371.571 1172.84 371.181 1174.41C370.791 1175.98 370.211 1177.52 369.451 1179.02L362.401 1193.2H357.391L357.401 1193.18ZM362.571 1175.36C364.031 1175.36 365.151 1174.87 365.951 1173.88C366.751 1172.89 367.141 1171.51 367.141 1169.72V1169.7C367.141 1167.96 366.741 1166.61 365.951 1165.65C365.161 1164.69 364.031 1164.22 362.571 1164.22C361.111 1164.22 359.961 1164.7 359.171 1165.65C358.381 1166.6 357.981 1167.96 357.981 1169.72V1169.74C357.981 1171.53 358.381 1172.91 359.171 1173.89C359.961 1174.87 361.101 1175.36 362.571 1175.36Z'
        fill='black'
      />
      {/* {Booth N0 09} */}
      <path
        d='M427.63 1115.47H275.48V1245.7H427.63V1115.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2139)}
        className={
          eid === 2139
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='320' y='1200' className='svgText'>
        09
      </text>

      <path
        d='M549.06 1186.02V1219.1H544.42V1191.07L539.71 1193.96V1189.16L544.42 1186.02H549.06Z'
        fill='black'
      />
      <path
        d='M566.01 1219.44C562.85 1219.44 560.49 1218.6 558.92 1216.9C557.35 1215.21 556.57 1212.92 556.57 1210.04V1195C556.57 1192.07 557.37 1189.77 558.96 1188.11C560.55 1186.44 562.9 1185.61 566.01 1185.61C569.12 1185.61 571.49 1186.44 573.07 1188.1C574.65 1189.76 575.45 1192.06 575.45 1195.01V1210.05C575.45 1212.98 574.66 1215.28 573.07 1216.94C571.48 1218.61 569.13 1219.44 566.01 1219.44ZM566.01 1215.03C567.71 1215.03 568.93 1214.59 569.68 1213.7C570.43 1212.81 570.81 1211.6 570.81 1210.05V1195.01C570.81 1193.45 570.43 1192.23 569.68 1191.35C568.93 1190.47 567.7 1190.03 566.01 1190.03C564.32 1190.03 563.09 1190.47 562.34 1191.35C561.59 1192.23 561.21 1193.45 561.21 1195.01V1210.05C561.21 1211.6 561.59 1212.81 562.34 1213.7C563.09 1214.59 564.32 1215.03 566.01 1215.03Z'
        fill='black'
      />

      {/* {Booth N0 10} */}
      <path
        d='M639.65 1140.65H474.22V1263.22H639.65V1140.65Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2140)}
        className={
          eid === 2140
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='540' y='1220' className='svgText'>
        10
      </text>

      <path
        d='M642.82 1315.12V1348.2H638.18V1320.17L633.47 1323.06V1318.26L638.18 1315.12H642.82Z'
        fill='black'
      />
      <path
        d='M658.31 1315.12V1348.2H653.67V1320.17L648.96 1323.06V1318.26L653.67 1315.12H658.31Z'
        fill='black'
      />

      {/* {Booth N0 11} */}
      <path
        d='M811.04 1263.22H474.22V1415.82H811.04V1263.22Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2141)}
        className={
          eid === 2141
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='600' y='1350' className='svgText'>
        11
      </text>

      <path
        d='M336.29 1269.86V1302.94H331.65V1274.91L326.94 1277.8V1273L331.65 1269.86H336.29Z'
        fill='black'
      />
      <path
        d='M343.301 1302.95V1298.92L354.841 1283.54C355.511 1282.66 356.031 1281.75 356.401 1280.81C356.771 1279.87 356.961 1278.98 356.961 1278.15V1278.1C356.961 1276.78 356.571 1275.76 355.801 1275.03C355.031 1274.3 353.931 1273.94 352.501 1273.94C351.171 1273.94 350.081 1274.34 349.231 1275.14C348.391 1275.94 347.881 1277.06 347.691 1278.52V1278.54H342.891V1278.52C343.301 1275.67 344.341 1273.46 346.011 1271.89C347.681 1270.32 349.821 1269.53 352.451 1269.53C355.421 1269.53 357.721 1270.28 359.341 1271.78C360.961 1273.28 361.781 1275.41 361.781 1278.15V1278.17C361.781 1279.34 361.541 1280.56 361.051 1281.84C360.561 1283.12 359.891 1284.35 359.031 1285.51L349.451 1298.53H361.961V1302.94H343.301V1302.95Z'
        fill='black'
      />
      {/* {Booth N0 12} */}
      <path
        d='M427.63 1245.69H275.48V1335H427.63V1245.69Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2142)}
        className={
          eid === 2142
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='320' y='1310' className='svgText'>
        12
      </text>

      {/* {Booth No 13} */}
      <path
        d='M427.63 1335H275.48V1429.43H427.63V1335Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2143)}
        className={
          eid === 2143
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M336.29 1361.73V1394.81H331.65V1366.78L326.94 1369.67V1364.87L331.65 1361.73H336.29Z'
        fill='black'
      />
      <path
        d='M352.351 1395.16C350.481 1395.16 348.851 1394.82 347.451 1394.15C346.051 1393.48 344.921 1392.5 344.071 1391.23C343.221 1389.96 342.671 1388.41 342.431 1386.59H347.161C347.371 1388.02 347.931 1389.06 348.841 1389.72C349.751 1390.38 350.921 1390.71 352.341 1390.71C353.871 1390.71 355.061 1390.3 355.911 1389.48C356.761 1388.66 357.181 1387.51 357.181 1386.02V1385.02C357.181 1383.37 356.781 1382.09 355.971 1381.19C355.171 1380.29 354.031 1379.84 352.561 1379.84H350.221V1375.43H352.561C353.861 1375.43 354.871 1375.04 355.581 1374.27C356.291 1373.5 356.641 1372.4 356.641 1370.99V1369.97C356.641 1368.67 356.261 1367.66 355.511 1366.94C354.761 1366.23 353.691 1365.87 352.311 1365.87C351.141 1365.87 350.151 1366.2 349.341 1366.86C348.531 1367.52 347.971 1368.56 347.671 1369.99H342.961C343.431 1367.26 344.481 1365.15 346.111 1363.65C347.741 1362.16 349.811 1361.41 352.311 1361.41C355.161 1361.41 357.371 1362.16 358.931 1363.66C360.491 1365.16 361.271 1367.28 361.271 1370.01V1370.56C361.271 1372.21 360.821 1373.65 359.931 1374.87C359.031 1376.09 357.771 1376.99 356.131 1377.57C357.941 1377.95 359.331 1378.83 360.331 1380.22C361.321 1381.61 361.821 1383.37 361.821 1385.51V1386.06C361.821 1388.96 360.991 1391.2 359.341 1392.8C357.691 1394.39 355.351 1395.19 352.331 1395.19L352.351 1395.16Z'
        fill='black'
      />
      {/* {Booth No 14} */}
      <path
        d='M427.63 1429.43H275.48V1575.28H427.63V1429.43Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2144)}
        className={
          eid === 2144
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M336.29 1481.87V1514.95H331.65V1486.92L326.94 1489.81V1485.01L331.65 1481.87H336.29Z'
        fill='black'
      />
      <path
        d='M343.341 1509.88V1505.85L354.441 1481.89H359.221L348.391 1505.58H364.611V1509.88H343.341ZM357.611 1514.98V1495.5H362.141V1514.98H357.611Z'
        fill='black'
      />
      <path
        d='M534.85 1434.2V1467.28H530.21V1439.25L525.5 1442.14V1437.34L530.21 1434.2H534.85Z'
        fill='black'
      />
      <path
        d='M551.59 1467.63C549.07 1467.63 547.01 1466.91 545.39 1465.46C543.77 1464.01 542.76 1461.97 542.35 1459.33V1459.31H546.99V1459.33C547.16 1460.54 547.64 1461.49 548.45 1462.16C549.26 1462.83 550.3 1463.17 551.59 1463.17C553.08 1463.17 554.23 1462.67 555.04 1461.68C555.85 1460.69 556.26 1459.29 556.26 1457.48V1454.7C556.26 1452.9 555.85 1451.5 555.04 1450.51C554.23 1449.52 553.08 1449.03 551.59 1449.03C550.79 1449.03 550.01 1449.26 549.27 1449.71C548.53 1450.17 547.87 1450.8 547.31 1451.62H543.08V1434.19H559.94V1438.6H547.72V1446.04C548.3 1445.57 548.94 1445.21 549.65 1444.95C550.36 1444.69 551.09 1444.56 551.83 1444.56C554.71 1444.56 556.94 1445.44 558.52 1447.21C560.1 1448.98 560.89 1451.47 560.89 1454.68V1457.46C560.89 1460.69 560.08 1463.19 558.45 1464.96C556.83 1466.73 554.54 1467.61 551.58 1467.61L551.59 1467.63Z'
        fill='black'
      />
      {/* {Booth No 15} */}
      <path
        d='M612.36 1415.82H474.22V1493.56H612.36V1415.82Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        // onClick={() => setEid(2145)}
        // className={eid === 2145 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      {/* <text x='520' y='1467' className='svgText'>
        15
      </text> */}

      {/* {Booth N0 16} */}
      <path
        d='M720.96 1415.82H612.37V1493.56H720.96V1415.82Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2145)}
        className={
          eid === 2145
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M658.21 1434.2V1467.28H653.57V1439.25L648.86 1442.14V1437.34L653.57 1434.2H658.21Z'
        fill='black'
      />
      <path
        d='M674.261 1467.63C671.331 1467.63 669.061 1466.76 667.461 1465.01C665.861 1463.26 665.051 1460.81 665.051 1457.64V1457.62C665.051 1456.19 665.251 1454.68 665.641 1453.08C666.031 1451.48 666.601 1449.94 667.351 1448.45L674.541 1434.21H679.551L671.111 1450.71L671.131 1449.89C671.921 1448.39 673.321 1447.64 675.341 1447.64C677.931 1447.64 679.941 1448.5 681.371 1450.22C682.801 1451.94 683.511 1454.37 683.511 1457.51V1457.53C683.511 1460.73 682.711 1463.22 681.101 1464.98C679.491 1466.75 677.221 1467.63 674.271 1467.63H674.261ZM674.261 1463.17C675.751 1463.17 676.881 1462.71 677.671 1461.8C678.461 1460.89 678.851 1459.59 678.851 1457.91V1457.89C678.851 1456.04 678.431 1454.61 677.601 1453.6C676.771 1452.59 675.571 1452.09 674.031 1452.09C672.651 1452.09 671.581 1452.6 670.821 1453.61C670.061 1454.63 669.681 1456.06 669.681 1457.91V1457.93C669.681 1459.61 670.081 1460.91 670.871 1461.81C671.671 1462.71 672.791 1463.16 674.251 1463.16L674.261 1463.17Z'
        fill='black'
      />
      <path
        d='M757.55 1434.2V1467.28H752.91V1439.25L748.2 1442.14V1437.34L752.91 1434.2H757.55Z'
        fill='black'
      />
      <path
        d='M782.15 1434.2V1438.34L773.59 1467.28H768.65L777.21 1438.61H768.79V1443.37H764.15V1434.2H782.15Z'
        fill='black'
      />
      {/* {Booth N0 17} */}
      <path
        d='M811.03 1415.82H720.95V1493.56H811.03V1415.82Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2145)}
        className={
          eid === 2145
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='750' y='1467' className='svgText'>
        17
      </text>

      {/* {Booth N0 18} */}
      <path
        d='M905.41 1415.82H811.04V1493.56H905.41V1415.82Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        // onClick={() => setEid(2145)}
        // className={eid === 2145 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M849.77 1434.2V1467.28H845.13V1439.25L840.42 1442.14V1437.34L845.13 1434.2H849.77Z'
        fill='black'
      />
      <path
        d='M866.98 1467.63C864.96 1467.63 863.2 1467.25 861.7 1466.49C860.2 1465.73 859.03 1464.66 858.2 1463.28C857.37 1461.9 856.95 1460.28 856.95 1458.43V1457.88C856.95 1456.23 857.36 1454.67 858.19 1453.22C859.02 1451.76 860.12 1450.66 861.5 1449.92C860.35 1449.28 859.41 1448.36 858.7 1447.16C857.99 1445.95 857.63 1444.68 857.63 1443.35V1442.55C857.63 1439.93 858.48 1437.82 860.19 1436.24C861.9 1434.66 864.16 1433.86 866.98 1433.86C869.8 1433.86 872.07 1434.65 873.77 1436.24C875.48 1437.83 876.33 1439.93 876.33 1442.55V1443.35C876.33 1444.72 875.97 1446 875.25 1447.2C874.53 1448.4 873.58 1449.31 872.39 1449.93C873.79 1450.67 874.9 1451.77 875.75 1453.23C876.59 1454.69 877.01 1456.24 877.01 1457.89V1458.44C877.01 1460.29 876.59 1461.91 875.76 1463.29C874.93 1464.67 873.76 1465.74 872.26 1466.5C870.76 1467.26 869 1467.64 866.98 1467.64V1467.63ZM866.98 1463.17C868.6 1463.17 869.91 1462.7 870.91 1461.77C871.9 1460.84 872.4 1459.61 872.4 1458.1V1457.78C872.4 1456.25 871.9 1455.02 870.91 1454.08C869.92 1453.15 868.61 1452.68 866.98 1452.68C865.35 1452.68 864.05 1453.15 863.06 1454.08C862.07 1455.01 861.57 1456.25 861.57 1457.8V1458.14C861.57 1459.66 862.07 1460.87 863.06 1461.79C864.05 1462.71 865.36 1463.17 866.98 1463.17ZM866.98 1448.22C868.39 1448.22 869.53 1447.77 870.39 1446.88C871.25 1445.99 871.69 1444.8 871.69 1443.33V1443.01C871.69 1441.6 871.26 1440.47 870.39 1439.61C869.53 1438.75 868.39 1438.32 866.98 1438.32C865.57 1438.32 864.43 1438.75 863.57 1439.61C862.71 1440.47 862.27 1441.61 862.27 1443.03V1443.37C862.27 1444.83 862.7 1446 863.57 1446.89C864.43 1447.78 865.57 1448.22 866.98 1448.22Z'
        fill='black'
      />
      {/* {Booth N0 19} */}
      <path
        d='M625.74 1665.89H495.92V1787.4H625.74V1665.89Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2146)}
        className={
          eid === 2146
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M552.39 1712.79V1745.87H547.75V1717.84L543.04 1720.73V1715.93L547.75 1712.79H552.39Z'
        fill='black'
      />
      <path
        d='M563.29 1745.88L571.62 1729.45L571.6 1730.27C571.21 1730.98 570.62 1731.5 569.85 1731.82C569.08 1732.14 568.18 1732.3 567.16 1732.3C564.78 1732.3 562.86 1731.42 561.4 1729.65C559.94 1727.88 559.22 1725.54 559.22 1722.61V1722.59C559.22 1719.37 560.03 1716.88 561.64 1715.12C563.26 1713.35 565.53 1712.47 568.45 1712.47C571.37 1712.47 573.65 1713.36 575.25 1715.14C576.86 1716.92 577.66 1719.44 577.66 1722.71V1722.73C577.66 1724.08 577.46 1725.54 577.07 1727.11C576.67 1728.68 576.1 1730.22 575.34 1731.72L568.29 1745.9H563.28L563.29 1745.88ZM568.45 1728.06C569.91 1728.06 571.03 1727.57 571.83 1726.58C572.63 1725.59 573.02 1724.21 573.02 1722.42V1722.4C573.02 1720.66 572.62 1719.31 571.83 1718.35C571.04 1717.39 569.91 1716.92 568.45 1716.92C566.99 1716.92 565.84 1717.4 565.05 1718.35C564.26 1719.3 563.86 1720.66 563.86 1722.42V1722.44C563.86 1724.23 564.26 1725.61 565.05 1726.59C565.84 1727.57 566.98 1728.06 568.45 1728.06Z'
        fill='black'
      />
      <path
        d='M688.381 1745.88V1741.85L699.921 1726.47C700.591 1725.59 701.111 1724.68 701.481 1723.74C701.851 1722.8 702.041 1721.91 702.041 1721.08V1721.03C702.041 1719.71 701.651 1718.69 700.881 1717.96C700.111 1717.23 699.011 1716.87 697.581 1716.87C696.251 1716.87 695.161 1717.27 694.311 1718.07C693.471 1718.87 692.961 1719.99 692.771 1721.45V1721.47H687.971V1721.45C688.381 1718.6 689.421 1716.39 691.091 1714.82C692.761 1713.25 694.901 1712.46 697.531 1712.46C700.501 1712.46 702.801 1713.21 704.421 1714.71C706.041 1716.21 706.851 1718.34 706.851 1721.08V1721.1C706.851 1722.27 706.611 1723.49 706.121 1724.77C705.631 1726.05 704.961 1727.28 704.091 1728.44L694.511 1741.46H707.021V1745.87H688.361L688.381 1745.88Z'
        fill='black'
      />
      <path
        d='M722.391 1746.22C719.231 1746.22 716.871 1745.37 715.301 1743.68C713.731 1741.99 712.951 1739.7 712.951 1736.82V1721.78C712.951 1718.85 713.751 1716.55 715.341 1714.89C716.931 1713.22 719.281 1712.39 722.391 1712.39C725.501 1712.39 727.871 1713.22 729.451 1714.88C731.031 1716.54 731.831 1718.84 731.831 1721.79V1736.83C731.831 1739.76 731.041 1742.06 729.451 1743.72C727.861 1745.39 725.511 1746.22 722.391 1746.22ZM722.391 1741.8C724.091 1741.8 725.311 1741.36 726.061 1740.47C726.811 1739.58 727.191 1738.37 727.191 1736.82V1721.78C727.191 1720.22 726.811 1719 726.061 1718.12C725.311 1717.24 724.081 1716.8 722.391 1716.8C720.701 1716.8 719.471 1717.24 718.721 1718.12C717.971 1719 717.591 1720.22 717.591 1721.78V1736.82C717.591 1738.37 717.971 1739.58 718.721 1740.47C719.471 1741.36 720.701 1741.8 722.391 1741.8Z'
        fill='black'
      />
      {/* {Booth N0 20} */}
      <svg xmlns='http://www.w3.org/2000/svg' width='2000' height='2000'>
        <path
          d='M768.17 1665.89H625.74V1787.4H768.17V1665.89Z'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
          onClick={() => setEid(2147)}
          className={
            eid === 2147
              ? 'chashma-gala-2024-room-clicked'
              : 'chashma-gala-2024-room'
          }
        />
        <text x='675' y='1742' className='svgText'>
          20
        </text>
      </svg>

      {/* {Booth No 21} */}
      <path
        d='M862.6 1665.89H768.17V1787.4H862.6V1665.89Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2148)}
        className={
          eid === 2148
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />

      <path
        d='M806.72 1745.88V1741.85L818.26 1726.47C818.93 1725.59 819.45 1724.68 819.82 1723.74C820.19 1722.8 820.38 1721.91 820.38 1721.08V1721.03C820.38 1719.71 819.99 1718.69 819.22 1717.96C818.45 1717.23 817.35 1716.87 815.92 1716.87C814.59 1716.87 813.5 1717.27 812.65 1718.07C811.81 1718.87 811.3 1719.99 811.11 1721.45V1721.47H806.31V1721.45C806.72 1718.6 807.76 1716.39 809.43 1714.82C811.1 1713.25 813.24 1712.46 815.87 1712.46C818.84 1712.46 821.14 1713.21 822.76 1714.71C824.38 1716.21 825.19 1718.34 825.19 1721.08V1721.1C825.19 1722.27 824.95 1723.49 824.46 1724.77C823.97 1726.05 823.3 1727.28 822.43 1728.44L812.85 1741.46H825.36V1745.87H806.7L806.72 1745.88Z'
        fill='black'
      />
      <path
        d='M839.28 1712.79V1745.87H834.64V1717.84L829.93 1720.73V1715.93L834.64 1712.79H839.28Z'
        fill='black'
      />
      <path
        d='M905.86 1745.88V1741.85L917.4 1726.47C918.07 1725.59 918.59 1724.68 918.96 1723.74C919.33 1722.8 919.52 1721.91 919.52 1721.08V1721.03C919.52 1719.71 919.13 1718.69 918.36 1717.96C917.59 1717.23 916.49 1716.87 915.06 1716.87C913.73 1716.87 912.64 1717.27 911.79 1718.07C910.95 1718.87 910.44 1719.99 910.25 1721.45V1721.47H905.45V1721.45C905.86 1718.6 906.9 1716.39 908.57 1714.82C910.24 1713.25 912.38 1712.46 915.01 1712.46C917.98 1712.46 920.28 1713.21 921.9 1714.71C923.52 1716.21 924.33 1718.34 924.33 1721.08V1721.1C924.33 1722.27 924.09 1723.49 923.6 1724.77C923.11 1726.05 922.44 1727.28 921.57 1728.44L911.99 1741.46H924.5V1745.87H905.84L905.86 1745.88Z'
        fill='black'
      />
      <path
        d='M929.93 1745.88V1741.85L941.47 1726.47C942.14 1725.59 942.661 1724.68 943.031 1723.74C943.401 1722.8 943.591 1721.91 943.591 1721.08V1721.03C943.591 1719.71 943.2 1718.69 942.43 1717.96C941.66 1717.23 940.56 1716.87 939.13 1716.87C937.8 1716.87 936.71 1717.27 935.86 1718.07C935.02 1718.87 934.51 1719.99 934.32 1721.45V1721.47H929.521V1721.45C929.931 1718.6 930.971 1716.39 932.641 1714.82C934.311 1713.25 936.451 1712.46 939.081 1712.46C942.051 1712.46 944.35 1713.21 945.97 1714.71C947.59 1716.21 948.401 1718.34 948.401 1721.08V1721.1C948.401 1722.27 948.16 1723.49 947.67 1724.77C947.18 1726.05 946.511 1727.28 945.641 1728.44L936.06 1741.46H948.57V1745.87H929.91L929.93 1745.88Z'
        fill='black'
      />

      {/* {Booth No 22} */}
      <path
        d='M972.03 1665.89H862.59V1787.4H972.03V1665.89Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2149)}
        className={
          eid === 2149
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='890' y='1742' className='svgText'>
        22
      </text>

      {/* {Booth No 23} */}
      <path
        d='M1086.69 1820.09H989.99V1956.16H1086.69V1820.09Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2150)}
        className={
          eid === 2150
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M1018.37 1910.01V1905.98L1029.91 1890.6C1030.58 1889.72 1031.1 1888.81 1031.47 1887.87C1031.84 1886.93 1032.03 1886.04 1032.03 1885.21V1885.16C1032.03 1883.84 1031.64 1882.82 1030.87 1882.09C1030.1 1881.36 1029 1881 1027.57 1881C1026.24 1881 1025.15 1881.4 1024.3 1882.19C1023.46 1882.99 1022.95 1884.11 1022.76 1885.57V1885.59H1017.96V1885.57C1018.37 1882.72 1019.41 1880.51 1021.08 1878.94C1022.75 1877.37 1024.89 1876.58 1027.52 1876.58C1030.49 1876.58 1032.79 1877.33 1034.41 1878.83C1036.03 1880.33 1036.84 1882.46 1036.84 1885.2V1885.22C1036.84 1886.39 1036.6 1887.61 1036.11 1888.89C1035.62 1890.17 1034.95 1891.4 1034.08 1892.56L1024.5 1905.58H1037.01V1909.99H1018.35L1018.37 1910.01Z'
        fill='black'
      />
      <path
        d='M1051.5 1910.35C1049.63 1910.35 1048 1910.01 1046.6 1909.34C1045.2 1908.67 1044.07 1907.69 1043.22 1906.42C1042.37 1905.15 1041.82 1903.6 1041.58 1901.78H1046.31C1046.52 1903.21 1047.08 1904.25 1047.99 1904.91C1048.9 1905.57 1050.07 1905.9 1051.49 1905.9C1053.02 1905.9 1054.21 1905.49 1055.06 1904.67C1055.91 1903.85 1056.33 1902.7 1056.33 1901.21V1900.21C1056.33 1898.56 1055.93 1897.28 1055.12 1896.38C1054.31 1895.48 1053.18 1895.03 1051.71 1895.03H1049.37V1890.62H1051.71C1053.01 1890.62 1054.02 1890.23 1054.73 1889.46C1055.44 1888.69 1055.79 1887.59 1055.79 1886.18V1885.16C1055.79 1883.86 1055.41 1882.85 1054.66 1882.13C1053.91 1881.42 1052.84 1881.06 1051.46 1881.06C1050.29 1881.06 1049.3 1881.39 1048.49 1882.05C1047.68 1882.71 1047.12 1883.75 1046.82 1885.18H1042.11C1042.58 1882.45 1043.63 1880.34 1045.26 1878.84C1046.89 1877.35 1048.96 1876.6 1051.46 1876.6C1054.31 1876.6 1056.52 1877.35 1058.08 1878.85C1059.64 1880.35 1060.42 1882.47 1060.42 1885.2V1885.75C1060.42 1887.4 1059.97 1888.84 1059.08 1890.06C1058.18 1891.28 1056.92 1892.18 1055.28 1892.76C1057.08 1893.14 1058.48 1894.02 1059.48 1895.41C1060.47 1896.8 1060.97 1898.56 1060.97 1900.7V1901.25C1060.97 1904.15 1060.14 1906.39 1058.49 1907.99C1056.84 1909.59 1054.5 1910.38 1051.48 1910.38L1051.5 1910.35Z'
        fill='black'
      />
      {/* {Booth No 24} */}
      <path
        d='M1181.98 1820.09H1086.7V1956.16H1181.98V1820.09Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2151)}
        className={
          eid === 2151
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <path
        d='M1114.36 1910.01V1905.98L1125.9 1890.6C1126.57 1889.72 1127.09 1888.81 1127.46 1887.87C1127.83 1886.93 1128.02 1886.04 1128.02 1885.21V1885.16C1128.02 1883.84 1127.63 1882.82 1126.86 1882.09C1126.09 1881.36 1124.99 1881 1123.56 1881C1122.23 1881 1121.14 1881.4 1120.29 1882.19C1119.45 1882.99 1118.94 1884.11 1118.75 1885.57V1885.59H1113.95V1885.57C1114.36 1882.72 1115.4 1880.51 1117.07 1878.94C1118.74 1877.37 1120.88 1876.58 1123.51 1876.58C1126.48 1876.58 1128.78 1877.33 1130.4 1878.83C1132.02 1880.33 1132.83 1882.46 1132.83 1885.2V1885.22C1132.83 1886.39 1132.59 1887.61 1132.1 1888.89C1131.61 1890.17 1130.94 1891.4 1130.07 1892.56L1120.49 1905.58H1133V1909.99H1114.34L1114.36 1910.01Z'
        fill='black'
      />
      <path
        d='M1137.11 1904.93V1900.9L1148.21 1876.94H1152.99L1142.16 1900.63H1158.38V1904.93H1137.11ZM1151.38 1910.03V1890.55H1155.91V1910.03H1151.38Z'
        fill='black'
      />
      <path
        d='M1205.79 1910.01V1905.98L1217.33 1890.6C1218 1889.72 1218.52 1888.81 1218.89 1887.87C1219.26 1886.93 1219.45 1886.04 1219.45 1885.21V1885.16C1219.45 1883.84 1219.06 1882.82 1218.29 1882.09C1217.52 1881.36 1216.42 1881 1214.99 1881C1213.66 1881 1212.57 1881.4 1211.72 1882.19C1210.88 1882.99 1210.37 1884.11 1210.18 1885.57V1885.59H1205.38V1885.57C1205.79 1882.72 1206.83 1880.51 1208.5 1878.94C1210.17 1877.37 1212.31 1876.58 1214.94 1876.58C1217.91 1876.58 1220.21 1877.33 1221.83 1878.83C1223.45 1880.33 1224.26 1882.46 1224.26 1885.2V1885.22C1224.26 1886.39 1224.02 1887.61 1223.53 1888.89C1223.04 1890.17 1222.37 1891.4 1221.5 1892.56L1211.92 1905.58H1224.43V1909.99H1205.77L1205.79 1910.01Z'
        fill='black'
      />
      <path
        d='M1239.61 1910.35C1237.09 1910.35 1235.03 1909.63 1233.41 1908.18C1231.79 1906.73 1230.78 1904.69 1230.37 1902.05V1902.03H1235.01V1902.05C1235.18 1903.26 1235.66 1904.21 1236.47 1904.88C1237.27 1905.55 1238.32 1905.89 1239.61 1905.89C1241.1 1905.89 1242.25 1905.39 1243.06 1904.4C1243.87 1903.41 1244.28 1902.01 1244.28 1900.2V1897.42C1244.28 1895.62 1243.87 1894.22 1243.06 1893.23C1242.25 1892.24 1241.1 1891.75 1239.61 1891.75C1238.81 1891.75 1238.03 1891.98 1237.29 1892.43C1236.55 1892.88 1235.89 1893.52 1235.33 1894.34H1231.1V1876.91H1247.96V1881.32H1235.74V1888.76C1236.32 1888.29 1236.96 1887.93 1237.67 1887.67C1238.38 1887.41 1239.11 1887.28 1239.85 1887.28C1242.73 1887.28 1244.96 1888.16 1246.54 1889.93C1248.12 1891.7 1248.91 1894.19 1248.91 1897.4V1900.18C1248.91 1903.41 1248.1 1905.91 1246.48 1907.68C1244.86 1909.45 1242.57 1910.33 1239.61 1910.33V1910.35Z'
        fill='black'
      />
      {/* {Booth N0 25} */}
      <svg xmlns='http://www.w3.org/2000/svg' width='2000' height='2000'>
        <path
          d='M1273.41 1820.09H1181.97V1956.16H1273.41V1820.09Z'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
          onClick={() => setEid(2151)}
          className={
            eid === 2151
              ? 'chashma-gala-2024-room-clicked'
              : 'chashma-gala-2024-room'
          }
        />
        <text x='1200' y='1910' className='svgText'>
          25
        </text>
      </svg>

      {/* {Booth No 26} */}
      <path
        d='M1350.52 1820.09H1273.41V1956.16H1350.52V1820.09Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        // onClick={() => setEid(2151)}
        // className={
        //   eid === 2151
        //     ? 'chashma-gala-2024-room-clicked'
        //     : 'chashma-gala-2024-room'
        // }
      />
      <path
        d='M1290.12 1910.01V1905.98L1301.66 1890.6C1302.33 1889.72 1302.85 1888.81 1303.22 1887.87C1303.59 1886.93 1303.78 1886.04 1303.78 1885.21V1885.16C1303.78 1883.84 1303.39 1882.82 1302.62 1882.09C1301.85 1881.36 1300.75 1881 1299.32 1881C1297.99 1881 1296.9 1881.4 1296.05 1882.19C1295.21 1882.99 1294.7 1884.11 1294.51 1885.57V1885.59H1289.71V1885.57C1290.12 1882.72 1291.16 1880.51 1292.83 1878.94C1294.5 1877.37 1296.64 1876.58 1299.27 1876.58C1302.24 1876.58 1304.54 1877.33 1306.16 1878.83C1307.78 1880.33 1308.59 1882.46 1308.59 1885.2V1885.22C1308.59 1886.39 1308.35 1887.61 1307.86 1888.89C1307.37 1890.17 1306.7 1891.4 1305.83 1892.56L1296.25 1905.58H1308.76V1909.99H1290.1L1290.12 1910.01Z'
        fill='black'
      />
      <path
        d='M1321.86 1910.35C1318.93 1910.35 1316.66 1909.48 1315.06 1907.73C1313.46 1905.98 1312.65 1903.53 1312.65 1900.36V1900.34C1312.65 1898.92 1312.85 1897.4 1313.24 1895.8C1313.63 1894.2 1314.2 1892.66 1314.95 1891.17L1322.14 1876.93H1327.15L1318.71 1893.43L1318.73 1892.61C1319.52 1891.11 1320.92 1890.36 1322.94 1890.36C1325.53 1890.36 1327.54 1891.22 1328.97 1892.94C1330.4 1894.66 1331.11 1897.09 1331.11 1900.23V1900.25C1331.11 1903.45 1330.31 1905.94 1328.7 1907.7C1327.09 1909.47 1324.82 1910.35 1321.87 1910.35H1321.86ZM1321.86 1905.89C1323.35 1905.89 1324.48 1905.43 1325.27 1904.52C1326.06 1903.61 1326.45 1902.31 1326.45 1900.63V1900.61C1326.45 1898.76 1326.03 1897.33 1325.2 1896.32C1324.37 1895.31 1323.18 1894.81 1321.63 1894.81C1320.25 1894.81 1319.18 1895.32 1318.42 1896.33C1317.66 1897.35 1317.28 1898.78 1317.28 1900.63V1900.65C1317.28 1902.33 1317.68 1903.63 1318.47 1904.53C1319.27 1905.43 1320.39 1905.88 1321.85 1905.88L1321.86 1905.89Z'
        fill='black'
      />
      <path
        d='M1155.03 1433.02C1153.01 1433.02 1151.25 1432.64 1149.75 1431.88C1148.25 1431.12 1147.08 1430.05 1146.25 1428.67C1145.42 1427.29 1145 1425.67 1145 1423.82V1423.27C1145 1421.62 1145.41 1420.06 1146.24 1418.61C1147.07 1417.15 1148.17 1416.05 1149.55 1415.31C1148.4 1414.67 1147.46 1413.75 1146.75 1412.55C1146.04 1411.35 1145.68 1410.07 1145.68 1408.74V1407.94C1145.68 1405.32 1146.53 1403.21 1148.24 1401.63C1149.95 1400.05 1152.21 1399.25 1155.03 1399.25C1157.85 1399.25 1160.12 1400.04 1161.82 1401.63C1163.53 1403.22 1164.38 1405.32 1164.38 1407.94V1408.74C1164.38 1410.11 1164.02 1411.39 1163.3 1412.59C1162.58 1413.79 1161.63 1414.7 1160.44 1415.32C1161.84 1416.06 1162.95 1417.16 1163.8 1418.62C1164.64 1420.08 1165.06 1421.63 1165.06 1423.28V1423.83C1165.06 1425.68 1164.64 1427.3 1163.81 1428.68C1162.98 1430.06 1161.81 1431.13 1160.31 1431.89C1158.81 1432.65 1157.05 1433.03 1155.03 1433.03V1433.02ZM1155.03 1428.56C1156.65 1428.56 1157.96 1428.09 1158.95 1427.16C1159.94 1426.23 1160.44 1425 1160.44 1423.49V1423.17C1160.44 1421.64 1159.94 1420.41 1158.95 1419.47C1157.96 1418.54 1156.65 1418.07 1155.03 1418.07C1153.41 1418.07 1152.1 1418.54 1151.1 1419.47C1150.11 1420.4 1149.61 1421.64 1149.61 1423.19V1423.53C1149.61 1425.05 1150.11 1426.26 1151.1 1427.18C1152.09 1428.1 1153.4 1428.56 1155.03 1428.56ZM1155.03 1413.61C1156.44 1413.61 1157.58 1413.16 1158.44 1412.27C1159.3 1411.38 1159.74 1410.19 1159.74 1408.72V1408.4C1159.74 1406.99 1159.31 1405.86 1158.44 1405C1157.58 1404.14 1156.44 1403.71 1155.03 1403.71C1153.62 1403.71 1152.48 1404.14 1151.62 1405C1150.76 1405.86 1150.32 1407 1150.32 1408.42V1408.76C1150.32 1410.22 1150.75 1411.39 1151.62 1412.28C1152.48 1413.17 1153.62 1413.61 1155.03 1413.61Z'
        fill='black'
      />
      <path
        d='M1171.09 1427.61V1423.58L1182.19 1399.62H1186.97L1176.14 1423.31H1192.36V1427.61H1171.09ZM1185.36 1432.7V1413.22H1189.89V1432.7H1185.36Z'
        fill='black'
      />
      {/* {Booth No 84} */}
      <path
        d='M1218.31 1375.33H1123.48V1460.28H1218.31V1375.33Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2196)}
        className={
          eid === 2196
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='1150' y='1435' className='svgText'>
        84
      </text>

      <path
        d='M1155.03 1517.97C1153.01 1517.97 1151.25 1517.59 1149.75 1516.83C1148.25 1516.07 1147.08 1515 1146.25 1513.62C1145.42 1512.24 1145 1510.62 1145 1508.77V1508.22C1145 1506.57 1145.41 1505.01 1146.24 1503.56C1147.07 1502.1 1148.17 1501 1149.55 1500.26C1148.4 1499.62 1147.46 1498.7 1146.75 1497.5C1146.04 1496.3 1145.68 1495.02 1145.68 1493.69V1492.89C1145.68 1490.27 1146.53 1488.16 1148.24 1486.58C1149.95 1485 1152.21 1484.2 1155.03 1484.2C1157.85 1484.2 1160.12 1484.99 1161.82 1486.58C1163.53 1488.17 1164.38 1490.27 1164.38 1492.89V1493.69C1164.38 1495.06 1164.02 1496.34 1163.3 1497.54C1162.58 1498.74 1161.63 1499.65 1160.44 1500.27C1161.84 1501.01 1162.95 1502.11 1163.8 1503.57C1164.64 1505.03 1165.06 1506.58 1165.06 1508.23V1508.78C1165.06 1510.63 1164.64 1512.25 1163.81 1513.63C1162.98 1515.01 1161.81 1516.08 1160.31 1516.84C1158.81 1517.6 1157.05 1517.98 1155.03 1517.98V1517.97ZM1155.03 1513.51C1156.65 1513.51 1157.96 1513.04 1158.95 1512.11C1159.94 1511.18 1160.44 1509.95 1160.44 1508.44V1508.12C1160.44 1506.59 1159.94 1505.36 1158.95 1504.42C1157.96 1503.49 1156.65 1503.02 1155.03 1503.02C1153.41 1503.02 1152.1 1503.49 1151.1 1504.42C1150.11 1505.35 1149.61 1506.59 1149.61 1508.14V1508.48C1149.61 1510 1150.11 1511.21 1151.1 1512.13C1152.09 1513.05 1153.4 1513.51 1155.03 1513.51ZM1155.03 1498.56C1156.44 1498.56 1157.58 1498.11 1158.44 1497.22C1159.3 1496.33 1159.74 1495.14 1159.74 1493.67V1493.35C1159.74 1491.94 1159.31 1490.81 1158.44 1489.95C1157.58 1489.09 1156.44 1488.66 1155.03 1488.66C1153.62 1488.66 1152.48 1489.09 1151.62 1489.95C1150.76 1490.81 1150.32 1491.95 1150.32 1493.37V1493.71C1150.32 1495.17 1150.75 1496.34 1151.62 1497.23C1152.48 1498.12 1153.62 1498.56 1155.03 1498.56Z'
        fill='black'
      />
      <path
        d='M1188.63 1484.54V1488.68L1180.07 1517.62H1175.13L1183.69 1488.95H1175.27V1493.71H1170.63V1484.54H1188.63Z'
        fill='black'
      />
      {/* {Booth N0 87} */}
      <path
        d='M1218.31 1460.28H1123.48V1545.23H1218.31V1460.28Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2198)}
        className={
          eid === 2198
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='1150' y='1520' className='svgText'>
        87
      </text>

      <path
        d='M1025.65 1612.68C1023.63 1612.68 1021.87 1612.3 1020.37 1611.54C1018.87 1610.78 1017.7 1609.71 1016.87 1608.33C1016.04 1606.95 1015.62 1605.33 1015.62 1603.48V1602.93C1015.62 1601.28 1016.03 1599.72 1016.86 1598.27C1017.69 1596.81 1018.79 1595.71 1020.17 1594.97C1019.02 1594.33 1018.08 1593.41 1017.37 1592.21C1016.66 1591.01 1016.3 1589.73 1016.3 1588.4V1587.6C1016.3 1584.98 1017.15 1582.87 1018.86 1581.29C1020.57 1579.71 1022.83 1578.91 1025.65 1578.91C1028.47 1578.91 1030.74 1579.7 1032.44 1581.29C1034.15 1582.88 1035 1584.98 1035 1587.6V1588.4C1035 1589.77 1034.64 1591.05 1033.92 1592.25C1033.2 1593.45 1032.25 1594.36 1031.06 1594.98C1032.46 1595.72 1033.57 1596.82 1034.42 1598.28C1035.26 1599.74 1035.68 1601.29 1035.68 1602.94V1603.49C1035.68 1605.34 1035.26 1606.96 1034.43 1608.34C1033.6 1609.72 1032.43 1610.79 1030.93 1611.55C1029.43 1612.31 1027.67 1612.69 1025.65 1612.69V1612.68ZM1025.65 1608.22C1027.27 1608.22 1028.58 1607.75 1029.57 1606.82C1030.56 1605.89 1031.06 1604.66 1031.06 1603.15V1602.83C1031.06 1601.3 1030.56 1600.07 1029.57 1599.13C1028.58 1598.2 1027.27 1597.73 1025.65 1597.73C1024.03 1597.73 1022.72 1598.2 1021.72 1599.13C1020.73 1600.06 1020.23 1601.3 1020.23 1602.85V1603.19C1020.23 1604.71 1020.73 1605.92 1021.72 1606.84C1022.71 1607.76 1024.02 1608.22 1025.65 1608.22ZM1025.65 1593.27C1027.06 1593.27 1028.2 1592.82 1029.06 1591.93C1029.92 1591.04 1030.36 1589.85 1030.36 1588.38V1588.06C1030.36 1586.65 1029.93 1585.52 1029.06 1584.66C1028.2 1583.8 1027.06 1583.37 1025.65 1583.37C1024.24 1583.37 1023.1 1583.8 1022.24 1584.66C1021.38 1585.52 1020.94 1586.66 1020.94 1588.08V1588.42C1020.94 1589.88 1021.37 1591.05 1022.24 1591.94C1023.1 1592.83 1024.24 1593.27 1025.65 1593.27Z'
        fill='black'
      />
      <path
        d='M1051.87 1612.68C1049.85 1612.68 1048.09 1612.3 1046.59 1611.54C1045.09 1610.78 1043.92 1609.71 1043.09 1608.33C1042.26 1606.95 1041.84 1605.33 1041.84 1603.48V1602.93C1041.84 1601.28 1042.25 1599.72 1043.08 1598.27C1043.91 1596.81 1045.01 1595.71 1046.39 1594.97C1045.24 1594.33 1044.3 1593.41 1043.59 1592.21C1042.88 1591.01 1042.52 1589.73 1042.52 1588.4V1587.6C1042.52 1584.98 1043.37 1582.87 1045.08 1581.29C1046.79 1579.71 1049.05 1578.91 1051.87 1578.91C1054.69 1578.91 1056.96 1579.7 1058.66 1581.29C1060.37 1582.88 1061.22 1584.98 1061.22 1587.6V1588.4C1061.22 1589.77 1060.86 1591.05 1060.14 1592.25C1059.42 1593.45 1058.47 1594.36 1057.28 1594.98C1058.68 1595.72 1059.79 1596.82 1060.64 1598.28C1061.48 1599.74 1061.9 1601.29 1061.9 1602.94V1603.49C1061.9 1605.34 1061.48 1606.96 1060.65 1608.34C1059.82 1609.72 1058.65 1610.79 1057.15 1611.55C1055.65 1612.31 1053.89 1612.69 1051.87 1612.69V1612.68ZM1051.87 1608.22C1053.49 1608.22 1054.8 1607.75 1055.79 1606.82C1056.78 1605.89 1057.28 1604.66 1057.28 1603.15V1602.83C1057.28 1601.3 1056.78 1600.07 1055.79 1599.13C1054.8 1598.2 1053.49 1597.73 1051.87 1597.73C1050.25 1597.73 1048.94 1598.2 1047.94 1599.13C1046.95 1600.06 1046.45 1601.3 1046.45 1602.85V1603.19C1046.45 1604.71 1046.95 1605.92 1047.94 1606.84C1048.93 1607.76 1050.24 1608.22 1051.87 1608.22ZM1051.87 1593.27C1053.28 1593.27 1054.42 1592.82 1055.28 1591.93C1056.14 1591.04 1056.58 1589.85 1056.58 1588.38V1588.06C1056.58 1586.65 1056.15 1585.52 1055.28 1584.66C1054.42 1583.8 1053.28 1583.37 1051.87 1583.37C1050.46 1583.37 1049.32 1583.8 1048.46 1584.66C1047.6 1585.52 1047.16 1586.66 1047.16 1588.08V1588.42C1047.16 1589.88 1047.59 1591.05 1048.46 1591.94C1049.32 1592.83 1050.46 1593.27 1051.87 1593.27Z'
        fill='black'
      />
      {/* {Booth No 88} */}
      <path
        d='M1170.91 1545.23H989.38V1635.83H1170.91V1545.23Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2199)}
        className={
          eid === 2199
            ? 'chashma-gala-2024-room-clicked'
            : 'chashma-gala-2024-room'
        }
      />
      <text x='1050' y='1610' className='svgText'>
        88
      </text>

      <path
        d='M1031.97 1783.73L1012.9 1797.56L1026.75 1816.66L1045.82 1802.83L1031.97 1783.73Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1004.7 1750.75L998.162 1755.48L1012.01 1774.59L1018.55 1769.85L1004.7 1750.75Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1017.25 1741.64L998.172 1755.47L1012.02 1774.58L1031.1 1760.75L1017.25 1741.64Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1017.6 1679.35H992.23V1726.88H1017.6V1679.35Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M998.66 1679.35H992.23V1726.88H998.66V1679.35Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1056.92 1683.64H1034.4V1719.02H1056.92V1683.64Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1019.43 1792.82L1012.9 1797.56L1026.75 1816.66L1033.28 1811.93L1019.43 1792.82Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default ChashamaGala2024Slide1;
