import React from 'react';
function Tabs({ TabValues, state, setState, side, history }) {
  const onRadioChange = (type) => {
    console.log({ type });
    // when both floor data set in local storage then tab will switch to other floor otherwise not
    const floor1Data = JSON.parse(localStorage.getItem('floor-5'));
    const floor2Data = JSON.parse(localStorage.getItem('floor-6'));
    side
      ? history.push({
          pathname: `/chashama-2024/${type}`,
          state: { side: type },
        })
      : setState((prevState) => ({
          ...prevState,
          filter: type,
        }));
  };
  return (
    <div>
      <ul className='tabs mb-0 '>
        {TabValues.map((TabValue) => (
          <li key={TabValue.value} className='mr-3 -mt-3'>
            <input
              type='radio'
              id={TabValue.value}
              checked={(side ? side : state.filter) === TabValue.value}
              name='filter'
              value={TabValue.value}
              onChange={() => onRadioChange(TabValue.value)}
            />
            <label className='pointer' htmlFor={TabValue.value}>
              {TabValue.label}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Tabs;
