/** @format */

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, withRouter,useHistory } from 'react-router-dom';
import '../../App.css';
import '../../components/DiscoverLocation.css';
import { LATITUDE, LONGITUDE } from '../../Constants/Constant';
import ChashamaGala2024Slide1 from './ChashamaGala2024Slide1';
import ChashamaGala2024Slide2 from './ChashamaGala2024Slide2';
import EventList from './EventList';

function ChashamaGala2024ArtEvent(props) {
  let location = useLocation();
  // let query = useQuery();
  let { side } = useParams();
  const history = useHistory

  // let is_nav = query.get("is_nav") === "false";
  // let is_back = query.get("is_back") === "false";

  let is_nav = true;
  let is_back = true;
  const ART_EVENT_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_COLLECTIVE_SLUG}&collective_id=`;
  let todayDate = new Date();
  let id,
    component,
    className,
    filter,
    sideTabs = false;

  const [eid, setEid] = useState(0);
  const [isLearnMore, setLearnMore] = useState(false);
  const [currentlyViewingLocationID, setCurrentlyViewingLocationID] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (!side) {
     window?.location?.replace('https://chashama.pollinate.co/#/chashama-2024/left');
     return
    }
    // TODO: Remove the location storage for the storing of data &
    // also allow user to click on the Right Side even when data is not loaded
    if (localStorage.getItem('left-side')) {
      localStorage.removeItem('left-side');
    }
    if (localStorage.getItem('right-side')) {
      localStorage.removeItem('right-side');
    }
  }, []);

  switch (location.pathname) {
    case '/chashama-2024/left':
      id = '217';
      component = <ChashamaGala2024Slide1 eid={eid} setEid={setEid} />;
      className = 'chashama-2024 bg-white h-100vh';
      filter = true;
      sideTabs = true;
      break;
    case '/chashama-2024/right':
      id = '218';
      component = <ChashamaGala2024Slide2 eid={eid} setEid={setEid} />;
      className = 'chashama-2024 bg-white h-100vh';
      filter = true;
      sideTabs = true;
      break;

    default:
      id = '217';
      component = <ChashamaGala2024Slide1 eid={eid} setEid={setEid} />;
      className = 'chashama-2024 bg-white h-100vh ';
      filter = true;
      sideTabs = true;
  }
  const [cid, setCid] = useState(0);
  const [cname, setCname] = useState('');
  const [isDiscoverLocation, setDiscoverLocation] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState(11);
  const [state, setState] = useState({
    isCollapsible: false,
    ArtworkImage: '',
    ArtworkTitle: '',
    ArtworkDesc: '',
    ArtworkAudio: '',
    currentLocation: '',
    latitude: LATITUDE,
    longitude: LONGITUDE,
    data: [
      {
        type: 'exhibition',
        lat: undefined, //When Not Allowed for location , no Marker is visible
        lon: undefined,
      },
    ],
    collective_slug_url: ART_EVENT_URL + id,
  });

  const handleIsCollapsible = () => {
    setState((prevState) => ({
      ...prevState,
      isCollapsible: !state.isCollapsible,
    }));
  };

  const handleArtworkImage = (image, title, desc, audio) => {
    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };

  const handleDiscoverLocation = (type) => {
    setDiscoverLocation(!type);
  };

  useEffect(() => {
    localStorage.setItem('EventName', 'Artists');
    !is_nav ? props.setIsNav(true) : props.setIsNav(false);
  }, [is_nav, props]);

  const fetchGet = useCallback(async (url, firstTab = true) => {
    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (res.status >= 400) {
        console.log('Error:', res.status);
      } else {
        const json = await res.json();
        firstTab && setEventsData(json);

        if (url.slice(-3) === '217') {
          localStorage.setItem('left-side', JSON.stringify(json));
          setLoading(false);
        } else if (url.slice(-3) === '218') {
          localStorage.setItem('right-side', JSON.stringify(json));
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }, []);

  const callAPIOneAfterOther = useCallback(async () => {
    try {
      await fetchGet(ART_EVENT_URL + id);
      await fetchGet(ART_EVENT_URL + (side !== 'left' ? '217' : '218'), false);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  }, [fetchGet, side, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (id === '217' || id === '218') {
        try {
          const leftSideData = JSON.parse(localStorage.getItem('left-side'));
          const rightSideData = JSON.parse(localStorage.getItem('right-side'));
          if (leftSideData === null || rightSideData === null) {
            await callAPIOneAfterOther();
          } else {
            setTimeout(() => {
              if (id === '217') {
                setEventsData(leftSideData);
              } else if (id === '218') {
                setEventsData(rightSideData);
              }
              setLoading(false);
            }, 100);
          }
        } catch (error) {
          console.error('Error retrieving data from local storage:', error);
        }
      } else {
        try {
          await fetchGet(ART_EVENT_URL + id);
        } catch (error) {
          console.error('Error fetching data from API:', error);
        }
      }
    };

    fetchData();
  }, [callAPIOneAfterOther, fetchGet, id]);

  return (
    <div className='art-event-map-and-list  display-direction-row_main art-event-chanshama-2024'>
      <div
        id='list-container'
        className='xs-100 w-37 chashama-event card-list-color-and-height overflow-auto'
      >
        <EventList
          showBackButton={!is_back}
          filter={filter}
          sideTabs={sideTabs}
          eid={eid}
          eventsData={eventsData}
          eventsDataLoading={loading}
          setIsNav={props.setIsNav}
          isLearnMore={isLearnMore}
          setLearnMore={setLearnMore}
          history={props.history}
          collective_slug_url={state.collective_slug_url}
          ArtworkImage={state.ArtworkImage}
          handleArtworkImage={handleArtworkImage}
          isDiscoverLocation={isDiscoverLocation}
          handleDiscoverLocation={handleDiscoverLocation}
          cid={cid}
          setCityInLocalStorage={props.setCityInLocalStorage}
          setEid={setEid}
          todayDate={todayDate}
          onClick={(id) => setEid(id)}
          resetCid={() => setCid(0)}
          name={cname}
          handleZoomIn={(_) => setMapZoomLevel(19)}
          handleZoomOut={(_) => setMapZoomLevel(12)}
          currentlyViewingLocationID={currentlyViewingLocationID}
          setCurrentlyViewingLocationID={(id) =>
            setCurrentlyViewingLocationID(id)
          }
          url={location.pathname}
          // collective_slug_url={state.collective_slug_url}
          // ArtworkImage={state.ArtworkImage}
          // isDiscoverLocation={isDiscoverLocation}
          // handleDiscoverLocation={handleDiscoverLocation}
          // resetCid={() => setCid(0)}
          // handleZoomIn={(_) => setMapZoomLevel(19)}
          // handleZoomOut={(_) => setMapZoomLevel(12)}
          // isCollapsible={state.isCollapsible}
          // handleIsCollapsible={handleIsCollapsible}
        />
      </div>
      <div className={className}>
        {loading && (
          <div
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          ></div>
        )}
        <div
          className={`event-map-container mt-5 mt-md-0 ${!is_nav && 'mt-5'}`}
        >
          {component}
        </div>
      </div>
    </div>
  );
}
// ChashamaGala2024ArtEvent.defaultProps = {
//   showBackButton: true,
//   filter: true,
// };

export default withRouter(ChashamaGala2024ArtEvent);
