import React, { useEffect, useState } from 'react';
import '../App.css';
import EventListComponent from '../components/EventListComponent';
import '../components/DiscoverLocation.css';
import { withRouter, useHistory } from 'react-router-dom';
import { useGetFetch } from '../hooks/useGetFetch';
import { LATITUDE, LONGITUDE } from '../Constants/Constant';
import MiamiMapDesktop from '../components/MiamiMapDesktop';
import ChashamaBooth from '../components/ChashamaBooth';
import AkumalMap from '../components/AkumalMap';
import LinkByStillLifeMap from '../components/LinkByStillLifeMap';
import NRVLDBrandsXWeb3 from '../components/NRVLDBrandsXWeb3';
import { useLocation } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import ArtEventMap from '../components/ArtEventMap';
import { getQueryParamAsBoolean } from '../helpers/helpers';
import ChashamaGala2024Booth from '../components/ChashamaGala2024';

function ArtEvent(props) {
  let location = useLocation();
  const history = useHistory()
  let query = useQuery();
  let is_nav = getQueryParamAsBoolean(query, 'is_nav');
  let is_back = getQueryParamAsBoolean(query, 'is_back');

  let todayDate = new Date();
  let id, component, className, filter;
  const [eid, setEid] = useState(0);
  const [isLearnMore, setLearnMore] = useState(false);
  const [currentlyViewingLocationID, setCurrentlyViewingLocationID] =
    useState(null);

  useEffect(() => {
    if (location.pathname === '/chashama-2024') {
      history.push({
        pathname: 'https://chashama.pollinate.co/#/chashama-2024/left',
      });
    }
  }, []);

  switch (location.pathname) {
    case '/miami-art-week-2021':
      id = '187';
      component = <MiamiMapDesktop eid={eid} />;
      className = 'miami-art-week bg-white pt-5';
      filter = true;
      break;
    case '/chashama-gala':
      id = '195';
      component = <ChashamaBooth eid={eid} setEid={setEid} />;
      className = 'chashama-gala bg-white';
      filter = true;
      break;
    case '/chashama-2024':
      id = '217';
      component = <ChashamaGala2024Booth eid={eid} setEid={setEid} />;
      className = 'chashama-2024 bg-white';
      filter = true;
      break;
    case '/akumal-art-festival':
      id = '197';
      component = <AkumalMap eid={eid} setEid={setEid} />;
      className = 'akumal-art-festival bg-white';
      filter = true;
      break;
    case '/link-by-stilllife':
      id = '205';
      component = (
        <LinkByStillLifeMap
          eid={eid}
          setEid={setEid}
          isDetailOpen={isLearnMore}
          setCurrentlyViewingLocationID={setCurrentlyViewingLocationID}
          setDetailOpen={setLearnMore}
        />
      );
      className = 'link-by-stilllife bg-white';
      filter = false;
      break;
    case '/link-by-stilllife/exhibition-link':
      id = '205';
      component = (
        <LinkByStillLifeMap
          eid={eid}
          setEid={setEid}
          isLearn={isLearnMore}
          setCurrentlyViewingLocationID={setCurrentlyViewingLocationID}
          setLearnMore={setLearnMore}
        />
      );
      className = 'link-by-stilllife bg-white pt-5';
      filter = false;
      break;
    case '/NRVLD-Brands-x-Web3':
      id = '206';
      component = (
        <NRVLDBrandsXWeb3
          eid={eid}
          setEid={setEid}
          isLearn={isLearnMore}
          setCurrentlyViewingLocationID={setCurrentlyViewingLocationID}
          setLearnMore={setLearnMore}
        />
      );
      className = 'link-by-NRVLD';
      filter = false;
      break;
    case '/2023-AiOP-DRESS':
      id = '216';
      // updating its component before return
      className = 'xs-100 w-100 z-3';
      break;
    default:
      id = null;
      component = <EventListComponent />;
  }
  const [cid, setCid] = useState(0);
  const [cname, setCname] = useState('');
  const [isDiscoverLocation, setDiscoverLocation] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState(11);

  const [selectedTreeId, setSelectedTreeId] = useState(0);
  const [state, setState] = useState({
    isCollapsible: false,
    ArtworkImage: '',
    ArtworkTitle: '',
    ArtworkDesc: '',
    ArtworkAudio: '',
    currentLocation: '',
    latitude: LATITUDE,
    longitude: LONGITUDE,
    data: [
      {
        type: 'exhibition',
        lat: undefined, //When Not Allowed for location , no Marker is visible
        lon: undefined,
      },
    ],
    collective_slug_url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_COLLECTIVE_SLUG}&collective_id=${id}`,
  });

  const handleIsCollapsible = () => {
    setState((prevState) => ({
      ...prevState,
      isCollapsible: !state.isCollapsible,
    }));
  };

  // const handleOnClick = (id, name) => {
  //   console.log('IMAGE', id, name);
  //   setCid(id);
  //   setCname(name);
  //   setState((prevState) => ({
  //     ...prevState,
  //     collective_slug_url: `${process.env.REACT_APP_API_URL}${
  //       process.env.REACT_APP_COLLECTIVE_SLUG
  //     }${id > 0 ? '&collective_id=' : ''}${id > 0 ? id : ''}`,
  //   }));
  // };

  const handleArtworkImage = (image, title, desc, audio) => {
    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };

  const handleDiscoverLocation = (type) => {
    setDiscoverLocation(!type);
  };

  useEffect(() => {
    localStorage.setItem('EventName', 'Artists');
    !is_nav ? props.setIsNav(true) : props.setIsNav(false);
  }, [is_nav, props]);
  let [eventsData, eventsDataloading] = useGetFetch(state.collective_slug_url);

  // NEW
  // let url = state.collective_slug_url;
  // const [eventsListDataResult, setEventsListDataResult] = useState([]);
  // const [allEventsListData, setAllEventsListData] = useState("");

  // async function getData(url) {
  //   const res = await fetch(url);
  //   const json = await res.json();
  //   setAllEventsListData(json);
  //   setEventsListDataResult((eventsListDataResult) => [
  //     ...eventsListDataResult,
  //     ...json.results,
  //   ]);
  //   console.log(allEventsListData);
  // }
  // useEffect(() => {
  //   getData(url);
  // }, []);

  // For /2023-AiOP-DRESS url
  if (id === '216') {
    component = (
      <ArtEventMap
        showBackButton={!is_back}
        filter={filter}
        eid={eid}
        eventsData={eventsData}
        eventsDataloading={eventsDataloading}
        setIsNav={props.setIsNav}
        isLearnMore={isLearnMore}
        setLearnMore={setLearnMore}
        setSelectedTreeId={setSelectedTreeId}
        history={props.history}
        collective_slug_url={state.collective_slug_url}
        ArtworkImage={state.ArtworkImage}
        handleArtworkImage={handleArtworkImage}
        isDiscoverLocation={isDiscoverLocation}
        handleDiscoverLocation={handleDiscoverLocation}
        cid={cid}
        setCityInLocalStorage={props.setCityInLocalStorage}
        setEid={setEid}
        todayDate={todayDate}
        onClick={(id) => setEid(id)}
        resetCid={() => setCid(0)}
        name={cname}
        handleZoomIn={(_) => setMapZoomLevel(19)}
        handleZoomOut={(_) => setMapZoomLevel(12)}
        currentlyViewingLocationID={currentlyViewingLocationID}
        setCurrentlyViewingLocationID={(id) =>
          setCurrentlyViewingLocationID(id)
        }
        isCollapsible={state.isCollapsible}
        handleIsCollapsible={handleIsCollapsible}
        url={location.pathname}
      />
    );
  }

  return (
    <div className='art-event-map-and-list  display-direction-row_main '>
      <div
        id='list-container'
        className='xs-100 w-37 chashama-event card-list-color-and-height overflow-auto'
      >
        <div className=''>
          {/* <EventsListSidebar
            eventsListDataResult={eventsData}
            allEventsListData={[]}
            getData={getData}
            history={props.history}
          /> */}

          <EventListComponent
            showBackButton={!is_back}
            filter={filter}
            eid={eid}
            eventsData={eventsData}
            eventsDataloading={eventsDataloading}
            setIsNav={props.setIsNav}
            isLearnMore={isLearnMore}
            setLearnMore={setLearnMore}
            setSelectedTreeId={setSelectedTreeId}
            history={props.history}
            collective_slug_url={state.collective_slug_url}
            ArtworkImage={state.ArtworkImage}
            handleArtworkImage={handleArtworkImage}
            isDiscoverLocation={isDiscoverLocation}
            handleDiscoverLocation={handleDiscoverLocation}
            cid={cid}
            setCityInLocalStorage={props.setCityInLocalStorage}
            setEid={setEid}
            todayDate={todayDate}
            onClick={(id) => setEid(id)}
            resetCid={() => setCid(0)}
            name={cname}
            handleZoomIn={(_) => setMapZoomLevel(19)}
            handleZoomOut={(_) => setMapZoomLevel(12)}
            currentlyViewingLocationID={currentlyViewingLocationID}
            setCurrentlyViewingLocationID={(id) =>
              setCurrentlyViewingLocationID(id)
            }
            isCollapsible={state.isCollapsible}
            handleIsCollapsible={handleIsCollapsible}
            url={location.pathname}
          />
        </div>
      </div>
      <div
        style={
          // id === "195"
          //   ? { maxHeight: "100vh", marginTop: "-25px" }
          //   :
          { maxHeight: '100vh' }
        }
        className={className}
      >
        {eventsDataloading && (
          <div
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          ></div>
        )}
        <div className={`event-map-container ${!is_nav && 'mt-5'}`}>
          {component}
        </div>
      </div>
    </div>
  );
}
// ArtEvent.defaultProps = {
//   showBackButton: true,
//   filter: true,
// };

export default withRouter(ArtEvent);
