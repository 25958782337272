import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-scrolling-panel';
import { EventCurrentId, screenWidth } from '../Constants/Constant';
import BackBtn from '../assets/icons/BackBtn.svg';
import '../components/DiscoverLocation.css';
import { Loader, getQueryParamAsBoolean } from '../helpers/helpers';
import { isNotEmpty } from '../helpers/util';
import useQuery from '../hooks/useQuery';
import EventCardComponent from './EventCardComponent';
import MobileEventCard from './MobileEventCard';
function EventListComponent(props) {
  const location = useLocation();
  let query = useQuery();
  let is_nav = getQueryParamAsBoolean(query, 'is_nav');

  let { filterType } = useParams();
  const [count, setCount] = useState(0);
  const currentId = localStorage.getItem(EventCurrentId);
  let eventCount = 0;
  let currentTab;
  switch (filterType) {
    case 'past-events':
      currentTab = 'past-events';
      break;
    default:
      currentTab = 'featured';
  }

  if (location?.state?.cityName) {
    props.setCityInLocalStorage(true);
    localStorage.setItem('EventName', location?.state?.cityName);
  } else {
    if (localStorage.getItem('EventName') && filterType === 'City') {
      props.setCityInLocalStorage(true);
    }
  }
  const refs_mobile = props.eventsData?.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});
  const refs_web = props.eventsData?.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  // const handleSlideClick = (slide) => {
  //   queryParams.set("tab", slide);
  //   history.push({
  //     pathname: location.pathname,
  //     search: queryParams.toString(),
  //   });
  // };

  // useEffect(() => {
  //   if (
  //     location.pathname === "/chashama-2024" &&
  //     !queryParams.has("tab")
  //   ) {
  //     queryParams.set("tab", "left-side");
  //     history.replace({
  //       pathname: location.pathname,
  //       search: queryParams.toString(),
  //     });
  //   }
  // }, [location, history]);

  useEffect(() => {
    if (props.eid > 0 && props.currentlyViewingLocationID === null) {
      refs_mobile[props.eid].current.scrollIntoView({
        behavior: 'auto',

        block: 'center',
        inline: 'start',
      });
      refs_web[props.eid].current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      });
    } else {
      if (currentId > 0 && currentId != (undefined || null)) {
        refs_mobile[currentId]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [props.eid, count, []]);

  const handleEventDetails = (item) => {
    props.setSelectedTreeId(0);
    props.onClick(item.id);
  };
  const redirectToHomePage = () => {
    if (
      isNotEmpty(localStorage.getItem('EventName'))
      // && isNavbarShown !== "false"
    )
      props.history.push({
        pathname: '/',
        state: { filterType: currentTab },
      });
  };
  const backBtnHandler = () => {
    props.setCurrentlyViewingLocationID(null);
    setCount(count + 1);
    props.handleZoomOut();
    props.setLearnMore(false);
  };

  return (
    <div>
      {props.eventsDataloading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <Container direction='vertical'>
          <div
            style={{
              marginTop: !is_nav
                ? '2em'
                : screenWidth >= 768 && props.pathname !== '/make-us-visible'
                ? '-2.5em'
                : '',
            }}
            className=' display-direction-column art-event-sidebar-fixed-top '
          >
            {!props.currentlyViewingLocationID && (
              <div
                className={
                  props.showBackButton
                    ? 'px-3 px-md-4 d-flex align-items-center pointer '
                    : 'px-4 d-flex'
                }
                onClick={() => {
                  props.showBackButton && redirectToHomePage();
                }}
              >
                <>
                  {props.showBackButton && (
                    <img
                      className='mobile-back-btn-for-art-event'
                      src={BackBtn}
                      height='20px'
                      alt='backbtn'
                    />
                  )}
                  <p
                    className={
                      props.showBackButton
                        ? 'event-series-text  mb-0 ml-4 lg-show py-2'
                        : 'event-series-text  mb-0 lg-show '
                    }
                  >
                    {props.url
                      .replace('/', '')
                      .replaceAll('-', ' ')
                      .toUpperCase() ?? 'Artists'}
                  </p>
                </>
              </div>
            )}

            {/* Only for chashama gala 2024 */}
            {/* {location.pathname === "/chashama-2024" &&
              !props.currentlyViewingLocationID && (
                <div
                  className="d-flex mt-3 ml-4 justify-content-start"
                  style={{
                    marginBottom: "-8px",
                  }}
                >
                  <p
                    className="event-series-text mr-4"
                    style={{
                      padding: "4px 10px",
                      cursor: "pointer",
                      borderRadius: "20px",
                      color:
                        currentSlideForChasma2024 === "left-side"
                          ? "black"
                          : "#e0f752",
                      background:
                        currentSlideForChasma2024 === "left-side"
                          ? " #e0f752"
                          : "none",
                    }}
                    onClick={() => handleSlideClick("left-side")}
                  >
                    LEFT SIDE
                  </p>
                  <p
                    className="event-series-text "
                    style={{
                      padding: "4px 10px",
                      cursor: "pointer",
                      borderRadius: "20px",
                      color:
                        currentSlideForChasma2024 === "right-side"
                          ? "black"
                          : "#e0f752",
                      background:
                        currentSlideForChasma2024 === "right-side"
                          ? " #e0f752"
                          : "none",
                    }}
                    onClick={() => handleSlideClick("right-side")}
                  >
                    RIGHT SIDE
                  </p>
                </div>
              )} */}

            {props.currentlyViewingLocationID && (
              <div
                onClick={() => backBtnHandler()}
                className='px-4 d-flex pointer lg-show xs-100 w-60 py-2'
              >
                <img src={BackBtn} alt='backbtn' />
                <p className='event-series-text mb-0 ml-4 lg-show '>Back</p>
              </div>
            )}

            {/* {!mediaQuery.matches && (
                  <p
                    className={
                      props.isCollapsible == true
                        ? "event-name-text d-flex justify-content-center  xs-show w-80 mt-2"
                        : "event-name-text  d-flex justify-content-center xs-show w-80 mt-2"
                    }
                  >
                    Featured Events
                  </p>
                )} */}
          </div>

          <div
            // onScroll={(e)=>onScroll(e)}
            className={`w-100  show-scroll overflow-x-hidden xs-show display-box ${
              !is_nav ? 'mt-9rem' : 'mt-4rem'
            }`}
          >
            {props.eventsData.map((item) => {
              if (
                item.type === 'exhibition'
                // &&
                // (filterType !== "exhibition" && filterType !== "city"
                //   ? props.filter
                //     ? new Date(item.end_date) < props.todayDate
                //     : "true"
                //   : compareDatesForRender(
                //       new Date(item.end_date),
                //       props.todayDate
                //     ))
              ) {
                return (
                  <div
                    key={item.id}
                    ref={refs_mobile[item.id]}
                    onClick={() => props.onClick(item.id)}
                    className='w-80 mr-4  button '
                  >
                    <MobileEventCard
                      onlyArtistName={props.filter}
                      url={props.url}
                      handleArtworkImage={props.handleArtworkImage}
                      history={props.history}
                      data={item}
                      cid={props.cid}
                      currentlyViewingLocationID={
                        props.currentlyViewingLocationID
                      }
                      setCurrentlyViewingLocationID={
                        props.setCurrentlyViewingLocationID
                      }
                    />
                  </div>
                );
              }
            })}
          </div>

          <div
            className={`w-100 display-direction-column lg-show p-4 ${
              location.pathname === '/chashama-2024'
                ? 'mt-11rem'
                : !is_nav
                ? 'mt-9rem '
                : 'mt-4rem '
            }`}
          >
            {props.eventsData.map((item) => {
              if (
                item.type === 'exhibition'
                //  &&
                // filterType !== "exhibition" &&
                // filterType !== "city"
                //   ? props.filter
                //     ? new Date(item.end_date) < props.todayDate
                //     : "true"
                //   : compareDatesForRender(
                //       new Date(item.end_date),
                //       props.todayDate
                //     )
              ) {
                eventCount = eventCount + 1;
                return (
                  <div
                    key={item.id}
                    ref={refs_web[item.id]}
                    onClick={() => handleEventDetails(item)}
                    className='button'
                  >
                    <EventCardComponent
                      onlyArtistName={props.filter}
                      url={props.url}
                      isLearnMore={props.isLearnMore}
                      currentEid={props.eid}
                      setLearnMore={props.setLearnMore}
                      ArtworkImage={props.ArtworkImage}
                      handleArtworkImage={props.handleArtworkImage}
                      data={item}
                      count={count}
                      setCount={setCount}
                      key={item.id}
                      image={item.primary_image}
                      address={item.short_address}
                      name={item.name}
                      cid={props.cid}
                      zoomIn={props.handleZoomIn}
                      zoomOut={props.handleZoomOut}
                      currentlyViewingLocationID={
                        props.currentlyViewingLocationID
                      }
                      setCurrentlyViewingLocationID={
                        props.setCurrentlyViewingLocationID
                      }
                    />
                  </div>
                );
              }
            })}
          </div>
          {/* <div className="w-100 display-direction-column">
            {props.eventsData.map((item) => {
              if (item.type === null) {
                return (
                  <div
                    onClick={() => props.onClick(item.id)}
                    className="button"
                  >
                    <EventCardComponent
                      isLearnMore={props.isLearnMore}
                      ArtworkImage={props.ArtworkImage}
                      handleArtworkImage={props.handleArtworkImage}
                      key={item.id}
                      image={item.primary_image}
                      address={item.short_address}
                      name={item.name}
                      zoomIn={props.handleZoomIn}
                      zoomOut={props.handleZoomOut}
                      currentlyViewingLocationID={
                        props.currentlyViewingLocationID
                      }
                      setCurrentlyViewingLocationID={
                        props.setCurrentlyViewingLocationID
                      }
                    />
                  </div>
                );
              }
            })}
          </div> */}
          {eventCount < 1 && (
            <div className='no-events-show'>
              <p style={{ color: 'white' }}>
                Currently, There are no events for this{' '}
                {filterType === 'city' ? 'city' : 'collective'}
              </p>
            </div>
          )}
        </Container>
      )}
    </div>
  );
}

export default EventListComponent;
