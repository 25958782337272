import React from 'react';
import './chashmaBooth2024.css'

const ChashamaGala2024Slide2 = ({ setEid, eid }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1548 2160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='1548' height='2160' fill='white' />
      <path
        d='M330.069 1492.1V1586.61'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1268.57 1956.16H1237.14V1986.11H1268.57V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1101.21 1956.16H1056.7V1986.11H1101.21V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M931.2 1956.16H881.34V1986.11H931.2V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M755.85 1956.16H711.34V1986.11H755.85V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M318.63 1962.6H288.67V1977.58H318.63V1962.6Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M585.839 1956.16H541.329V1986.11H585.839V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M415.819 1956.16H366.399V1986.11H415.819V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M493.55 1962.6H463.59V1977.58H493.55V1962.6Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M240.9 1956.16H194.84V1986.11H240.9V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M663.56 1962.6H633.6V1977.58H663.56V1962.6Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M346.589 1539.36H305.279'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M833.569 1962.6H803.609V1977.58H833.569V1962.6Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M305.279 1492.1V1586.61'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M313.55 1492.1V1586.61'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1008.93 1962.6H978.969V1977.58H1008.93V1962.6Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M321.81 1492.1V1586.61'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M338.329 1492.1V1586.61'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1189.37 1971.13H1148.98V1986.11H1189.37V1971.13Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M346.59 1492.1V1586.61'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M923.11 1385.75H792.84V1553.79H923.11V1385.75Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1356.72 1971.13H1316.33V1986.11H1356.72V1971.13Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M624.1 571.12H517.92V578.59V654.72H522.01V578.59H729.55V657.32V690.68H522.01V680.1H517.92V690.68V698.14H729.55V771.33H522.01V721.98H517.92V826.46H522.01V790.11H729.55V866.67H522.97V851.98H518.64V866.67V875.05H729.55H792.84V896.5H812.62V875.05H992.19V657.32V116.6H976.88H827.14H788.1V124.5H596.75V147.31H726.35V252.58H827.14V299.61H976.88V505.96H729.55H624.1V571.12Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M672.499 634.63H600.369'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M600.369 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1350.34 1356.9H1333.92V1368.65H1350.34V1356.9Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M608.38 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M616.39 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M624.409 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M632.42 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M640.439 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M89.5895 486.319H77.0195V907.169H89.5895V486.319Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1266.51 1368.65H1298.08V1356.9H1266.51H1228.03V1385.75H1266.51V1368.65Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M362.43 518.02V900.35H438.02V872.06H432.17V895.47H369.26V523.6H432.17V545.03H438.02V520.4H524.11V501.52H425.89V518.02H362.43Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M438.02 574.149H432.17V614.779H438.02V574.149Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M438.02 792.359H432.17V843.229H438.02V792.359Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M438.02 721.97H432.17V764.84H438.02V721.97Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M438.02 643.26H432.17V694.45H438.02V643.26Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M648.449 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1180.26 1362.77H1150.3V1377.75H1180.26V1362.77Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M656.47 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1102.53 1362.77H1054.96V1385.75H1102.53V1362.77Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M664.479 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1007.19 1362.77H977.23V1377.75H1007.19V1362.77Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M672.5 578.59V690.68'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M929.459 1362.77H881.889V1385.75H929.459V1362.77Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M834.12 1343.87H792.84V1385.75H834.12V1343.87Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.619 1286.18H800.869V1297.4H812.619V1286.18Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M77.0195 1054.39V1355.12'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M77.0195 1375.33H89.5895V949.77H77.0195'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.62 1197.42H792.84V1239.71H812.62V1197.42Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.619 1118.57H800.869V1150.96H812.619V1118.57Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.62 1021.82H792.84V1072.11H812.62V1021.82Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1437.46 1956.16H1404.49V1986.11H1450.33V1951.04V1356.9H1386.18V1368.65H1437.46V1956.16Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.619 942.97H800.869V975.36H812.619V942.97Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M281.609 124.5V117.88H238.739V147.31H281.609V138.41H294.409V124.5H281.609Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M372.399 124.5H340.729V138.41H372.399V124.5Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M472.45 124.5H418.72V147.31H472.45V124.5Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M550.43 124.5H518.76V138.41H550.43V124.5Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M992.189 875.049V837.619'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M192.43 117.88H174.22V131.79H192.43V117.88Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M92.3295 131.789H127.91V117.529H92.3295H77.0195H46.2695V146.679H77.0195V459.559H92.3295V131.789Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M435.94 1395.47H430V1460.59H435.94V1395.47Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M363.24 1395.47H227V1484.96H363.24V1395.47Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M227 1586.61V1492.1H363.24H370.26V1395.47H436.5V938.55H363.24V1383.02H220.76V1395.47V1592.85H227H435.95V1586.61V1575.91H430V1586.61H227Z'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M77.0195 1054.39V949.77'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M77.0195 1355.12V1375.33'
        stroke='#C80000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M174.22 117.88H127.91V131.79H174.22V117.88Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M238.74 117.88H192.43V131.79H238.74V117.88Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M596.75 124.5H550.44V138.41H596.75V124.5Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M518.76 124.5H472.45V138.41H518.76V124.5Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M418.71 124.5H372.4V138.41H418.71V124.5Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M340.72 124.5H294.41V138.41H340.72V124.5Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.621 896.5H800.871V942.97H812.621V896.5Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.621 1072.1H800.871V1118.57H812.621V1072.1Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.621 1150.96H800.871V1197.43H812.621V1150.96Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.621 1239.72H800.871V1286.19H812.621V1239.72Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.621 1297.4H800.871V1343.87H812.621V1297.4Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M881.891 1362.77H834.121V1374.52H881.891V1362.77Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M977.231 1362.77H929.461V1374.52H977.231V1362.77Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1054.96 1362.77H1007.19V1374.52H1054.96V1362.77Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1150.3 1362.77H1102.53V1374.52H1150.3V1362.77Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1228.04 1362.77H1180.27V1374.52H1228.04V1362.77Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1333.91 1356.9H1298.08V1368.65H1333.91V1356.9Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1386.17 1356.9H1350.34V1368.65H1386.17V1356.9Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1404.49 1974.35H1356.72V1986.1H1404.49V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1316.34 1974.35H1268.57V1986.1H1316.34V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1237.14 1974.35H1189.37V1986.1H1237.14V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1148.98 1974.35H1101.21V1986.1H1148.98V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1056.7 1965.82H1008.93V1977.57H1056.7V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M978.969 1965.82H931.199V1977.57H978.969V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M881.34 1965.82H833.57V1977.57H881.34V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M803.61 1965.82H755.84V1977.57H803.61V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M711.34 1965.82H663.57V1977.57H711.34V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M633.6 1965.82H585.83V1977.57H633.6V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M541.331 1965.82H493.561V1977.57H541.331V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M463.59 1965.82H415.82V1977.57H463.59V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M366.4 1965.82H318.63V1977.57H366.4V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M288.67 1965.82H240.9V1977.57H288.67V1965.82Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M194.84 1974.35H147.07V1986.1H194.84V1974.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M812.621 975.35H800.871V1021.82H812.621V975.35Z'
        stroke='#7D26CD'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M360.29 1635.05H286.72V1658.66H360.29V1635.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M923.109 1553.79H761.439V1567.49H923.109V1553.79Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M934.9 1603.19H861.6V1626.14H934.9V1603.19Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M861.599 1603.19H788.299V1626.14H861.599V1603.19Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M791.13 285.601H714.5V308.511H791.13V285.601Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M714.5 285.601H644.58V308.511H714.5V285.601Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M667.89 210.95H644.58V285.61H667.89V210.95Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M809.08 1620.54L809.14 1607.69H810.93L810.87 1620.55H809.08V1620.54ZM810.15 1620.55V1618.88L813.64 1618.9C814.59 1618.9 815.27 1618.73 815.67 1618.36C816.07 1617.99 816.27 1617.52 816.27 1616.92V1616.89C816.27 1616.25 816.11 1615.74 815.77 1615.36C815.43 1614.98 814.88 1614.79 814.1 1614.78L810.18 1614.76V1613.14L814.11 1613.16C814.78 1613.16 815.28 1613.01 815.62 1612.69C815.96 1612.38 816.13 1611.92 816.13 1611.31C816.13 1610.67 815.95 1610.19 815.57 1609.87C815.19 1609.55 814.63 1609.39 813.88 1609.38L810.2 1609.36V1607.69L814.21 1607.71C815.48 1607.71 816.42 1608.04 817.03 1608.67C817.65 1609.3 817.95 1610.15 817.95 1611.21C817.95 1611.85 817.76 1612.44 817.38 1612.97C817 1613.5 816.41 1613.84 815.6 1613.98C816.39 1614.1 817.01 1614.45 817.45 1615.04C817.89 1615.62 818.1 1616.29 818.1 1617.05V1617.08C818.1 1618.13 817.75 1618.97 817.07 1619.61C816.39 1620.25 815.47 1620.57 814.31 1620.56L810.16 1620.54L810.15 1620.55Z'
        fill='black'
      />
      <path
        d='M819.26 1620.59L824.06 1607.77H825.6L830.27 1620.65H828.33L824.82 1610.26L821.21 1620.61H819.27L819.26 1620.59ZM821.46 1617.78V1616.07L828.23 1616.1V1617.81L821.46 1617.78Z'
        fill='black'
      />
      <path
        d='M832.221 1620.65L832.281 1607.79H834.081L834.021 1620.65H832.221ZM833.031 1615.22V1613.51L837.421 1613.53C837.941 1613.53 838.371 1613.35 838.691 1612.99C839.011 1612.63 839.171 1612.14 839.171 1611.54C839.171 1610.94 839.021 1610.46 838.701 1610.09C838.381 1609.72 837.961 1609.54 837.441 1609.54L833.061 1609.52V1607.81L837.381 1607.83C838.131 1607.83 838.781 1607.99 839.341 1608.3C839.901 1608.61 840.331 1609.05 840.641 1609.6C840.951 1610.16 841.101 1610.81 841.091 1611.56C841.091 1612.3 840.931 1612.95 840.611 1613.51C840.291 1614.06 839.861 1614.5 839.301 1614.8C838.741 1615.11 838.091 1615.26 837.341 1615.26L833.031 1615.24V1615.22ZM839.441 1620.69L836.531 1614.84L838.331 1614.44L841.591 1620.69H839.451H839.441Z'
        fill='black'
      />
      <path
        d='M663.67 301.82L663.73 288.97H665.52L665.46 301.83H663.67V301.82ZM664.73 301.83V300.16L668.22 300.18C669.17 300.18 669.85 300.01 670.25 299.64C670.65 299.27 670.85 298.8 670.85 298.2V298.17C670.85 297.53 670.69 297.02 670.35 296.64C670.01 296.26 669.46 296.07 668.68 296.06L664.76 296.04V294.41L668.69 294.43C669.36 294.43 669.86 294.28 670.2 293.96C670.54 293.65 670.71 293.19 670.71 292.58C670.71 291.94 670.53 291.46 670.15 291.14C669.77 290.82 669.21 290.66 668.46 290.65L664.78 290.63V288.96L668.79 288.98C670.06 288.98 671 289.31 671.61 289.94C672.22 290.57 672.53 291.42 672.53 292.48C672.53 293.12 672.34 293.71 671.96 294.24C671.58 294.77 670.99 295.11 670.18 295.25C670.97 295.37 671.59 295.73 672.03 296.31C672.47 296.89 672.68 297.56 672.68 298.32V298.35C672.68 299.4 672.33 300.24 671.65 300.88C670.97 301.52 670.05 301.84 668.89 301.83L664.74 301.81L664.73 301.83Z'
        fill='black'
      />
      <path
        d='M673.85 301.87L678.65 289.05H680.19L684.86 301.93H682.92L679.41 291.54L675.8 301.89H673.86L673.85 301.87ZM676.04 299.06V297.35L682.81 297.38V299.09L676.04 299.06Z'
        fill='black'
      />
      <path
        d='M686.8 301.94L686.86 289.08H688.66L688.6 301.94H686.8ZM687.61 296.51V294.8L692 294.82C692.52 294.82 692.95 294.64 693.27 294.28C693.59 293.92 693.75 293.43 693.75 292.83C693.75 292.23 693.6 291.75 693.28 291.38C692.96 291.01 692.54 290.83 692.02 290.83L687.64 290.81V289.1L691.96 289.12C692.71 289.12 693.36 289.28 693.92 289.59C694.48 289.9 694.91 290.34 695.22 290.89C695.53 291.44 695.68 292.1 695.67 292.84C695.67 293.58 695.51 294.23 695.19 294.79C694.87 295.34 694.44 295.78 693.88 296.08C693.32 296.39 692.67 296.54 691.92 296.53L687.61 296.51ZM694.03 301.97L691.12 296.12L692.92 295.72L696.18 301.97H694.04H694.03Z'
        fill='black'
      />
      <path
        d='M733.189 301.82L733.249 288.97H735.039L734.979 301.83H733.189V301.82ZM734.249 301.83V300.16L737.739 300.18C738.689 300.18 739.369 300.01 739.769 299.64C740.169 299.27 740.369 298.8 740.369 298.2V298.17C740.369 297.53 740.209 297.02 739.869 296.64C739.529 296.26 738.979 296.07 738.199 296.06L734.279 296.04V294.41L738.209 294.43C738.879 294.43 739.379 294.28 739.719 293.96C740.059 293.64 740.229 293.19 740.229 292.58C740.229 291.94 740.049 291.46 739.669 291.14C739.289 290.82 738.729 290.66 737.979 290.65L734.299 290.63V288.96L738.309 288.98C739.579 288.98 740.519 289.31 741.129 289.94C741.739 290.57 742.049 291.42 742.049 292.48C742.049 293.12 741.859 293.71 741.479 294.24C741.099 294.77 740.509 295.11 739.699 295.25C740.489 295.37 741.109 295.73 741.549 296.31C741.989 296.89 742.199 297.56 742.199 298.32V298.35C742.199 299.4 741.849 300.24 741.169 300.88C740.489 301.52 739.569 301.84 738.409 301.83L734.259 301.81L734.249 301.83Z'
        fill='black'
      />
      <path
        d='M743.359 301.87L748.159 289.05H749.699L754.369 301.93H752.429L748.919 291.54L745.309 301.89H743.369L743.359 301.87ZM745.559 299.06V297.35L752.329 297.38V299.09L745.559 299.06Z'
        fill='black'
      />
      <path
        d='M756.319 301.94L756.379 289.08H758.179L758.119 301.94H756.319ZM757.129 296.51V294.8L761.519 294.82C762.039 294.82 762.469 294.64 762.789 294.28C763.109 293.92 763.269 293.43 763.269 292.83C763.269 292.23 763.119 291.75 762.799 291.38C762.479 291.01 762.059 290.83 761.539 290.83L757.159 290.81V289.1L761.479 289.12C762.229 289.12 762.879 289.28 763.439 289.59C763.999 289.9 764.429 290.34 764.739 290.89C765.049 291.44 765.199 292.1 765.189 292.84C765.189 293.58 765.029 294.23 764.709 294.79C764.389 295.34 763.959 295.78 763.399 296.08C762.839 296.39 762.189 296.54 761.439 296.53L757.129 296.51ZM763.539 301.97L760.629 296.12L762.429 295.72L765.689 301.97H763.549H763.539Z'
        fill='black'
      />
      <path
        d='M649.519 233.46H651.189L651.169 236.95C651.169 237.9 651.349 238.58 651.709 238.98C652.069 239.38 652.549 239.58 653.149 239.58H653.179C653.819 239.58 654.329 239.42 654.709 239.08C655.089 238.74 655.279 238.19 655.289 237.41L655.309 233.49H656.939L656.919 237.42C656.919 238.09 657.069 238.59 657.379 238.93C657.689 239.27 658.149 239.44 658.759 239.44C659.399 239.44 659.879 239.26 660.199 238.88C660.519 238.5 660.679 237.94 660.679 237.19L660.699 233.51H662.369L662.349 237.52C662.349 238.79 662.019 239.73 661.389 240.34C660.759 240.96 659.909 241.26 658.849 241.26C658.209 241.26 657.619 241.07 657.089 240.69C656.559 240.31 656.219 239.72 656.079 238.91C655.959 239.7 655.599 240.32 655.019 240.76C654.439 241.2 653.769 241.41 653.009 241.41H652.979C651.929 241.41 651.089 241.06 650.449 240.38C649.809 239.7 649.489 238.78 649.499 237.62L649.519 233.47V233.46ZM649.519 232.4L662.369 232.46V234.25L649.509 234.19V232.4H649.519Z'
        fill='black'
      />
      <path
        d='M649.469 242.58L662.289 247.38V248.92L649.409 253.59V251.65L659.799 248.14L649.449 244.53V242.59L649.469 242.58ZM652.279 244.78H653.989L653.959 251.55H652.249L652.279 244.78Z'
        fill='black'
      />
      <path
        d='M649.369 262.76L655.209 259.85L655.609 261.65L649.359 264.91V262.77L649.369 262.76ZM649.409 255.53L662.269 255.59V257.39L649.409 257.33V255.53ZM654.839 256.35H656.549L656.529 260.74C656.529 261.26 656.709 261.69 657.069 262.01C657.429 262.33 657.919 262.49 658.519 262.49C659.119 262.49 659.599 262.34 659.969 262.02C660.339 261.7 660.519 261.28 660.519 260.76L660.539 256.38H662.249L662.229 260.7C662.229 261.45 662.069 262.1 661.759 262.66C661.449 263.22 661.009 263.65 660.459 263.96C659.909 264.27 659.249 264.42 658.509 264.41C657.769 264.41 657.119 264.25 656.559 263.93C655.999 263.61 655.569 263.18 655.269 262.62C654.959 262.06 654.809 261.41 654.819 260.66L654.839 256.35Z'
        fill='black'
      />
      <path
        d='M882.379 1620.54L882.439 1607.69H884.229L884.169 1620.55H882.379V1620.54ZM883.439 1620.55V1618.88L886.929 1618.9C887.879 1618.9 888.559 1618.73 888.959 1618.36C889.359 1617.99 889.559 1617.52 889.559 1616.92V1616.89C889.559 1616.25 889.399 1615.74 889.059 1615.36C888.719 1614.98 888.169 1614.79 887.389 1614.78L883.469 1614.76V1613.14L887.399 1613.16C888.069 1613.16 888.569 1613.01 888.909 1612.69C889.249 1612.38 889.419 1611.92 889.419 1611.31C889.419 1610.67 889.239 1610.19 888.859 1609.87C888.479 1609.55 887.919 1609.39 887.169 1609.38L883.489 1609.36V1607.69L887.499 1607.71C888.769 1607.71 889.709 1608.04 890.319 1608.67C890.939 1609.3 891.239 1610.15 891.239 1611.21C891.239 1611.85 891.049 1612.44 890.669 1612.97C890.289 1613.5 889.699 1613.84 888.889 1613.98C889.679 1614.1 890.299 1614.45 890.739 1615.04C891.179 1615.62 891.389 1616.29 891.389 1617.05V1617.08C891.389 1618.13 891.039 1618.97 890.359 1619.61C889.679 1620.25 888.759 1620.57 887.599 1620.56L883.449 1620.54L883.439 1620.55Z'
        fill='black'
      />
      <path
        d='M892.559 1620.59L897.359 1607.77H898.899L903.569 1620.65H901.629L898.119 1610.26L894.509 1620.61H892.569L892.559 1620.59ZM894.759 1617.78V1616.07L901.529 1616.1V1617.81L894.759 1617.78Z'
        fill='black'
      />
      <path
        d='M905.51 1620.65L905.57 1607.79H907.37L907.31 1620.65H905.51ZM906.33 1615.22V1613.51L910.72 1613.53C911.24 1613.53 911.67 1613.35 911.99 1612.99C912.31 1612.63 912.47 1612.14 912.47 1611.54C912.47 1610.94 912.32 1610.46 912 1610.09C911.68 1609.72 911.26 1609.54 910.74 1609.54L906.36 1609.52V1607.81L910.68 1607.83C911.43 1607.83 912.08 1607.99 912.64 1608.3C913.2 1608.61 913.63 1609.05 913.94 1609.6C914.25 1610.16 914.4 1610.81 914.39 1611.56C914.39 1612.3 914.23 1612.95 913.91 1613.51C913.59 1614.06 913.16 1614.5 912.6 1614.8C912.04 1615.11 911.39 1615.26 910.64 1615.26L906.33 1615.24V1615.22ZM912.74 1620.69L909.83 1614.84L911.63 1614.44L914.89 1620.69H912.75H912.74Z'
        fill='black'
      />
      <path
        d='M307.64 1653.62L307.7 1640.77H309.49L309.43 1653.63H307.64V1653.62ZM308.7 1653.63V1651.96L312.19 1651.98C313.14 1651.98 313.82 1651.81 314.22 1651.44C314.62 1651.07 314.82 1650.6 314.82 1650V1649.97C314.82 1649.33 314.66 1648.82 314.32 1648.44C313.98 1648.06 313.43 1647.87 312.65 1647.86L308.73 1647.84V1646.22L312.66 1646.24C313.33 1646.24 313.83 1646.09 314.17 1645.77C314.51 1645.46 314.68 1645 314.68 1644.39C314.68 1643.75 314.5 1643.27 314.12 1642.95C313.74 1642.63 313.18 1642.47 312.43 1642.46L308.75 1642.44V1640.77L312.76 1640.79C314.03 1640.79 314.97 1641.12 315.58 1641.75C316.19 1642.38 316.5 1643.23 316.5 1644.29C316.5 1644.93 316.31 1645.52 315.93 1646.05C315.55 1646.58 314.96 1646.92 314.15 1647.06C314.94 1647.18 315.56 1647.54 316 1648.12C316.44 1648.71 316.65 1649.37 316.65 1650.13V1650.16C316.65 1651.21 316.3 1652.05 315.62 1652.69C314.94 1653.33 314.02 1653.65 312.86 1653.64L308.71 1653.62L308.7 1653.63Z'
        fill='black'
      />
      <path
        d='M317.819 1653.67L322.619 1640.85H324.159L328.829 1653.73H326.889L323.379 1643.34L319.769 1653.69H317.829L317.819 1653.67ZM320.019 1650.87V1649.16L326.789 1649.19V1650.9L320.019 1650.87Z'
        fill='black'
      />
      <path
        d='M330.77 1653.74L330.83 1640.88H332.63L332.57 1653.75H330.77V1653.74ZM331.59 1648.31V1646.6L335.98 1646.62C336.5 1646.62 336.93 1646.44 337.25 1646.08C337.57 1645.72 337.73 1645.23 337.73 1644.63C337.73 1644.03 337.58 1643.55 337.26 1643.18C336.94 1642.81 336.52 1642.63 336 1642.63L331.62 1642.61V1640.9L335.94 1640.92C336.69 1640.92 337.34 1641.08 337.9 1641.39C338.46 1641.7 338.89 1642.14 339.2 1642.69C339.51 1643.24 339.66 1643.9 339.65 1644.64C339.65 1645.38 339.49 1646.03 339.17 1646.59C338.85 1647.14 338.42 1647.58 337.86 1647.88C337.3 1648.19 336.65 1648.34 335.9 1648.34L331.59 1648.32V1648.31ZM338 1653.77L335.09 1647.93L336.89 1647.53L340.15 1653.78H338.01L338 1653.77Z'
        fill='black'
      />
      <path
        d='M401.6 181.47C406.726 181.47 410.88 177.315 410.88 172.19C410.88 167.065 406.726 162.91 401.6 162.91C396.475 162.91 392.32 167.065 392.32 172.19C392.32 177.315 396.475 181.47 401.6 181.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M364.48 181.47C369.605 181.47 373.76 177.315 373.76 172.19C373.76 167.065 369.605 162.91 364.48 162.91C359.355 162.91 355.2 167.065 355.2 172.19C355.2 177.315 359.355 181.47 364.48 181.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M528.841 181.47C533.966 181.47 538.121 177.315 538.121 172.19C538.121 167.065 533.966 162.91 528.841 162.91C523.715 162.91 519.561 167.065 519.561 172.19C519.561 177.315 523.715 181.47 528.841 181.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M850.52 1738.1C855.645 1738.1 859.8 1733.95 859.8 1728.82C859.8 1723.69 855.645 1719.54 850.52 1719.54C845.395 1719.54 841.24 1723.69 841.24 1728.82C841.24 1733.95 845.395 1738.1 850.52 1738.1Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M801.96 1738.1C807.085 1738.1 811.24 1733.95 811.24 1728.82C811.24 1723.69 807.085 1719.54 801.96 1719.54C796.835 1719.54 792.68 1723.69 792.68 1728.82C792.68 1733.95 796.835 1738.1 801.96 1738.1Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M871.167 1697.53C873.129 1692.79 870.88 1687.37 866.145 1685.41C861.41 1683.44 855.981 1685.69 854.02 1690.43C852.059 1695.16 854.307 1700.59 859.042 1702.55C863.777 1704.51 869.206 1702.27 871.167 1697.53Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M832.618 1697.53C834.58 1692.79 832.331 1687.36 827.596 1685.4C822.861 1683.44 817.433 1685.69 815.471 1690.42C813.51 1695.16 815.758 1700.59 820.494 1702.55C825.229 1704.51 830.657 1702.26 832.618 1697.53Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M791.618 1701.33C795.648 1698.16 796.347 1692.33 793.181 1688.3C790.014 1684.27 784.18 1683.57 780.15 1686.74C776.12 1689.9 775.421 1695.74 778.587 1699.77C781.754 1703.8 787.588 1704.5 791.618 1701.33Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M316.197 1751.87C321.257 1751.05 324.695 1746.28 323.876 1741.22C323.058 1736.16 318.293 1732.73 313.233 1733.55C308.174 1734.36 304.736 1739.13 305.554 1744.19C306.373 1749.25 311.138 1752.69 316.197 1751.87Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M351.098 1773.1C353.049 1768.36 350.788 1762.94 346.049 1760.99C341.309 1759.04 335.886 1761.3 333.935 1766.04C331.985 1770.78 334.245 1776.2 338.985 1778.15C343.724 1780.1 349.148 1777.84 351.098 1773.1Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M292.229 1776.02C295.853 1772.4 295.853 1766.52 292.229 1762.9C288.605 1759.27 282.729 1759.27 279.105 1762.9C275.481 1766.52 275.481 1772.4 279.105 1776.02C282.729 1779.64 288.605 1779.64 292.229 1776.02Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M342.541 1725.63C347.666 1725.63 351.821 1721.48 351.821 1716.35C351.821 1711.23 347.666 1707.07 342.541 1707.07C337.416 1707.07 333.261 1711.23 333.261 1716.35C333.261 1721.48 337.416 1725.63 342.541 1725.63Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M285.671 1725.63C290.796 1725.63 294.951 1721.48 294.951 1716.35C294.951 1711.23 290.796 1707.07 285.671 1707.07C280.545 1707.07 276.391 1711.23 276.391 1716.35C276.391 1721.48 280.545 1725.63 285.671 1725.63Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M565.961 181.47C571.086 181.47 575.241 177.315 575.241 172.19C575.241 167.065 571.086 162.91 565.961 162.91C560.835 162.91 556.681 167.065 556.681 172.19C556.681 177.315 560.835 181.47 565.961 181.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M562.21 839.13V820.88H564.76V839.13H562.21ZM563.71 839.13V836.76H568.65C570.01 836.76 570.97 836.5 571.53 835.98C572.09 835.46 572.38 834.78 572.38 833.93V833.89C572.38 832.99 572.14 832.27 571.66 831.73C571.18 831.19 570.39 830.92 569.28 830.92H563.71V828.61H569.28C570.23 828.61 570.94 828.39 571.42 827.94C571.9 827.49 572.14 826.84 572.14 825.98C572.14 825.07 571.87 824.39 571.34 823.93C570.8 823.48 570 823.25 568.93 823.25H563.71V820.88H569.39C571.19 820.88 572.53 821.33 573.41 822.22C574.29 823.12 574.73 824.32 574.73 825.82C574.73 826.73 574.46 827.57 573.93 828.32C573.39 829.08 572.55 829.56 571.41 829.77C572.54 829.94 573.42 830.44 574.04 831.26C574.66 832.08 574.97 833.04 574.97 834.11V834.15C574.97 835.64 574.49 836.84 573.53 837.75C572.57 838.66 571.26 839.12 569.61 839.12H563.71V839.13Z'
        fill='black'
      />
      <path
        d='M576.66 839.13L583.39 820.88H585.57L592.3 839.13H589.54L584.48 824.41L579.42 839.13H576.66ZM579.76 835.13V832.7H589.36V835.13H579.76Z'
        fill='black'
      />
      <path
        d='M592.05 823.321V820.891H604.93V823.321H592.05ZM597.2 839.131V822.381H599.76V839.131H597.2Z'
        fill='black'
      />
      <path
        d='M607.43 839.13V820.88H609.99V839.13H607.43ZM609.03 831.36V828.93H619.16V831.36H609.03ZM617.8 839.13V820.88H620.36V839.13H617.8Z'
        fill='black'
      />
      <path
        d='M624.87 839.13V820.87H627.43V839.13H624.87ZM625.99 831.41V828.98H632.21C632.95 828.98 633.55 828.72 634 828.2C634.45 827.68 634.68 827 634.68 826.14C634.68 825.28 634.45 824.6 634 824.08C633.55 823.56 632.95 823.3 632.21 823.3H625.99V820.87H632.11C633.17 820.87 634.1 821.09 634.9 821.53C635.69 821.97 636.31 822.58 636.75 823.37C637.19 824.16 637.41 825.08 637.41 826.14C637.41 827.2 637.19 828.12 636.74 828.91C636.29 829.7 635.68 830.32 634.89 830.75C634.1 831.19 633.17 831.41 632.11 831.41H625.99ZM635.14 839.13L630.96 830.85L633.51 830.27L638.18 839.13H635.14Z'
        fill='black'
      />
      <path
        d='M647.16 839.32C645.87 839.32 644.75 839.04 643.78 838.49C642.82 837.93 642.07 837.15 641.54 836.14C641.01 835.13 640.74 833.95 640.74 832.6V827.43C640.74 826.07 641.01 824.89 641.54 823.89C642.07 822.88 642.82 822.1 643.78 821.54C644.74 820.98 645.87 820.71 647.16 820.71C648.45 820.71 649.57 820.99 650.54 821.54C651.51 822.09 652.25 822.88 652.78 823.89C653.31 824.9 653.58 826.08 653.58 827.43V832.6C653.58 833.95 653.31 835.13 652.78 836.14C652.25 837.15 651.5 837.94 650.54 838.49C649.58 839.05 648.45 839.32 647.16 839.32ZM647.16 836.8C648.31 836.8 649.22 836.42 649.91 835.67C650.6 834.92 650.95 833.92 650.95 832.68V827.33C650.95 826.08 650.61 825.09 649.91 824.34C649.22 823.59 648.3 823.22 647.16 823.22C646.02 823.22 645.11 823.59 644.41 824.34C643.72 825.09 643.37 826.09 643.37 827.33V832.68C643.37 833.92 643.72 834.91 644.41 835.67C645.1 836.43 646.02 836.8 647.16 836.8Z'
        fill='black'
      />
      <path
        d='M663.25 839.32C661.96 839.32 660.84 839.04 659.87 838.49C658.91 837.93 658.16 837.15 657.63 836.14C657.1 835.13 656.83 833.95 656.83 832.6V827.43C656.83 826.07 657.1 824.89 657.63 823.89C658.16 822.88 658.91 822.1 659.87 821.54C660.83 820.98 661.96 820.71 663.25 820.71C664.54 820.71 665.66 820.99 666.63 821.54C667.6 822.09 668.34 822.88 668.87 823.89C669.4 824.9 669.67 826.08 669.67 827.43V832.6C669.67 833.95 669.4 835.13 668.87 836.14C668.34 837.15 667.59 837.94 666.63 838.49C665.67 839.05 664.54 839.32 663.25 839.32ZM663.25 836.8C664.4 836.8 665.31 836.42 666 835.67C666.69 834.92 667.04 833.92 667.04 832.68V827.33C667.04 826.08 666.69 825.09 666 824.34C665.31 823.59 664.39 823.22 663.25 823.22C662.11 823.22 661.2 823.59 660.5 824.34C659.81 825.09 659.46 826.09 659.46 827.33V832.68C659.46 833.92 659.81 834.91 660.5 835.67C661.19 836.43 662.11 836.8 663.25 836.8Z'
        fill='black'
      />
      <path
        d='M681.31 832.93L686.67 820.88H689.07V839.13H686.57V825.46L686.7 826.26L682.21 836.59H680.43L675.94 826.51L676.06 825.46V839.13H673.56V820.88H675.96L681.32 832.93H681.31Z'
        fill='black'
      />
      <path
        d='M699.06 839.32C698.17 839.32 697.34 839.24 696.56 839.07C695.78 838.9 695.05 838.65 694.36 838.32C693.68 837.99 693.04 837.57 692.45 837.07L694.04 835.11C694.73 835.71 695.5 836.16 696.34 836.47C697.18 836.77 698.08 836.92 699.06 836.92C700.38 836.92 701.41 836.67 702.13 836.18C702.86 835.69 703.22 835 703.22 834.11C703.22 833.4 703.04 832.87 702.67 832.5C702.3 832.13 701.82 831.86 701.22 831.68C700.62 831.5 699.97 831.35 699.26 831.23C698.57 831.11 697.88 830.98 697.18 830.82C696.48 830.66 695.84 830.41 695.25 830.07C694.66 829.73 694.19 829.24 693.84 828.61C693.49 827.98 693.31 827.13 693.31 826.06C693.31 824.35 693.87 823.03 695 822.1C696.13 821.17 697.72 820.7 699.78 820.7C700.76 820.7 701.72 820.86 702.65 821.17C703.59 821.48 704.5 821.96 705.39 822.61L703.93 824.64C703.24 824.12 702.54 823.74 701.85 823.49C701.16 823.24 700.47 823.11 699.77 823.11C698.52 823.11 697.56 823.36 696.87 823.86C696.18 824.36 695.83 825.06 695.83 825.97C695.83 826.67 696.03 827.19 696.43 827.55C696.83 827.91 697.36 828.18 698.01 828.35C698.66 828.52 699.35 828.68 700.1 828.83C700.77 828.96 701.44 829.12 702.11 829.31C702.78 829.5 703.39 829.77 703.94 830.13C704.49 830.49 704.93 830.97 705.26 831.59C705.59 832.21 705.76 833.02 705.76 834.01V834.04C705.76 835.72 705.17 837.02 704 837.94C702.83 838.86 701.18 839.32 699.06 839.32Z'
        fill='black'
      />
      <path
        d='M243.59 1446.93C242.54 1446.93 241.62 1446.7 240.83 1446.23C240.04 1445.76 239.43 1445.11 238.99 1444.27C238.55 1443.43 238.34 1442.44 238.34 1441.31V1437.17C238.34 1436.04 238.56 1435.05 238.99 1434.2C239.42 1433.35 240.04 1432.7 240.83 1432.24C241.62 1431.77 242.54 1431.54 243.59 1431.54C244.46 1431.54 245.26 1431.73 245.98 1432.1C246.7 1432.47 247.31 1432.99 247.79 1433.67C248.28 1434.34 248.61 1435.13 248.78 1436.02H246.61C246.48 1435.54 246.26 1435.11 245.95 1434.75C245.64 1434.39 245.28 1434.11 244.87 1433.91C244.46 1433.71 244.03 1433.61 243.58 1433.61C242.65 1433.61 241.91 1433.94 241.35 1434.59C240.79 1435.24 240.5 1436.11 240.5 1437.18V1441.32C240.5 1442.39 240.78 1443.25 241.35 1443.9C241.91 1444.55 242.66 1444.87 243.58 1444.87C244.24 1444.87 244.86 1444.66 245.44 1444.25C246.02 1443.83 246.41 1443.24 246.61 1442.46H248.78C248.61 1443.36 248.27 1444.14 247.78 1444.81C247.29 1445.48 246.68 1446 245.97 1446.38C245.26 1446.76 244.46 1446.94 243.59 1446.94V1446.93Z'
        fill='black'
      />
      <path
        d='M256.36 1446.93C255.3 1446.93 254.36 1446.7 253.57 1446.24C252.77 1445.78 252.16 1445.13 251.72 1444.3C251.28 1443.46 251.06 1442.49 251.06 1441.38V1437.11C251.06 1435.99 251.28 1435.01 251.72 1434.18C252.16 1433.35 252.78 1432.7 253.57 1432.24C254.36 1431.78 255.29 1431.55 256.36 1431.55C257.43 1431.55 258.35 1431.78 259.15 1432.24C259.94 1432.7 260.56 1433.35 261 1434.18C261.44 1435.01 261.66 1435.99 261.66 1437.11V1441.38C261.66 1442.49 261.44 1443.47 261 1444.3C260.56 1445.14 259.94 1445.78 259.15 1446.24C258.36 1446.7 257.43 1446.93 256.36 1446.93ZM256.36 1444.84C257.31 1444.84 258.07 1444.53 258.64 1443.91C259.21 1443.29 259.5 1442.46 259.5 1441.44V1437.02C259.5 1435.99 259.21 1435.17 258.64 1434.55C258.07 1433.93 257.31 1433.62 256.36 1433.62C255.41 1433.62 254.66 1433.93 254.09 1434.55C253.52 1435.17 253.23 1435.99 253.23 1437.02V1441.44C253.23 1442.46 253.52 1443.29 254.09 1443.91C254.66 1444.53 255.42 1444.84 256.36 1444.84Z'
        fill='black'
      />
      <path
        d='M269.75 1446.93C268.07 1446.93 266.77 1446.44 265.85 1445.46C264.93 1444.48 264.47 1443.1 264.47 1441.32V1431.7H266.59V1441.4C266.59 1442.5 266.87 1443.35 267.42 1443.95C267.97 1444.55 268.75 1444.85 269.76 1444.85C270.77 1444.85 271.56 1444.55 272.12 1443.95C272.68 1443.35 272.96 1442.5 272.96 1441.4V1431.7H275.08V1441.32C275.08 1443.1 274.62 1444.48 273.69 1445.46C272.76 1446.44 271.46 1446.93 269.77 1446.93H269.75Z'
        fill='black'
      />
      <path
        d='M278.37 1446.77V1431.68H280.49V1446.77H278.37ZM279.3 1440.39V1438.38H284.44C285.05 1438.38 285.55 1438.17 285.92 1437.74C286.29 1437.31 286.48 1436.74 286.48 1436.04C286.48 1435.34 286.29 1434.77 285.92 1434.34C285.55 1433.91 285.05 1433.7 284.44 1433.7H279.3V1431.69H284.36C285.24 1431.69 286 1431.87 286.66 1432.23C287.32 1432.59 287.83 1433.1 288.19 1433.75C288.55 1434.4 288.73 1435.16 288.73 1436.04C288.73 1436.92 288.55 1437.67 288.18 1438.33C287.81 1438.98 287.3 1439.49 286.65 1439.85C286 1440.21 285.23 1440.39 284.35 1440.39H279.29H279.3ZM286.86 1446.77L283.41 1439.93L285.51 1439.45L289.37 1446.77H286.86Z'
        fill='black'
      />
      <path
        d='M290.14 1433.71V1431.7H300.78V1433.71H290.14ZM294.4 1446.77V1432.93H296.52V1446.77H294.4Z'
        fill='black'
      />
      <path
        d='M312.81 1431.7L308.22 1440.65V1446.78H306.1V1440.65L301.51 1431.7H303.86L307.16 1438.5L310.46 1431.7H312.81Z'
        fill='black'
      />
      <path
        d='M311.979 1446.77L317.539 1431.69H319.339L324.899 1446.77H322.619L318.439 1434.61L314.259 1446.77H311.979ZM314.539 1443.46V1441.45H322.469V1443.46H314.539Z'
        fill='black'
      />
      <path
        d='M327.18 1446.77V1431.68H329.3V1446.77H327.18ZM328.1 1440.39V1438.38H333.24C333.86 1438.38 334.35 1438.17 334.72 1437.74C335.09 1437.31 335.28 1436.74 335.28 1436.04C335.28 1435.34 335.09 1434.77 334.72 1434.34C334.35 1433.91 333.85 1433.7 333.24 1433.7H328.1V1431.69H333.16C334.04 1431.69 334.81 1431.87 335.46 1432.23C336.11 1432.59 336.63 1433.1 336.99 1433.75C337.35 1434.4 337.53 1435.16 337.53 1436.04C337.53 1436.92 337.35 1437.67 336.98 1438.33C336.61 1438.98 336.1 1439.49 335.45 1439.85C334.8 1440.21 334.03 1440.39 333.15 1440.39H328.09H328.1ZM335.66 1446.77L332.21 1439.93L334.32 1439.45L338.18 1446.77H335.67H335.66Z'
        fill='black'
      />
      <path
        d='M341.02 1446.77V1431.69H343.14V1446.77H341.02ZM342.45 1446.77V1444.76H345.86C346.97 1444.76 347.83 1444.47 348.45 1443.89C349.06 1443.31 349.37 1442.5 349.37 1441.45V1437.01C349.37 1435.96 349.07 1435.15 348.45 1434.57C347.83 1433.99 346.98 1433.7 345.86 1433.7H342.45V1431.69H345.8C347.63 1431.69 349.04 1432.16 350.04 1433.11C351.04 1434.05 351.54 1435.38 351.54 1437.1V1441.37C351.54 1442.51 351.32 1443.48 350.87 1444.29C350.42 1445.1 349.77 1445.71 348.91 1446.14C348.05 1446.56 347.02 1446.78 345.8 1446.78H342.46L342.45 1446.77Z'
        fill='black'
      />
      <path
        d='M113.77 433.5C113.77 434.77 113.49 435.88 112.92 436.84C112.36 437.79 111.56 438.53 110.54 439.06C109.52 439.59 108.33 439.85 106.96 439.85H101.95C100.58 439.85 99.3799 439.59 98.3599 439.06C97.3399 438.53 96.5499 437.79 95.9799 436.84C95.4099 435.89 95.1299 434.77 95.1299 433.5C95.1299 432.45 95.3599 431.48 95.8099 430.61C96.2599 429.74 96.8899 429 97.7099 428.41C98.5199 427.82 99.4699 427.42 100.56 427.21V429.83C99.9699 429.99 99.4599 430.25 99.0199 430.63C98.5799 431 98.2399 431.44 97.9999 431.94C97.7599 432.44 97.6399 432.96 97.6399 433.5C97.6399 434.62 98.0399 435.52 98.8299 436.2C99.6199 436.88 100.66 437.22 101.96 437.22H106.97C108.27 437.22 109.31 436.88 110.09 436.2C110.88 435.52 111.27 434.62 111.27 433.5C111.27 432.71 111.02 431.96 110.52 431.25C110.02 430.55 109.29 430.07 108.35 429.83V427.21C109.44 427.42 110.39 427.82 111.2 428.41C112.01 429 112.64 429.74 113.1 430.61C113.55 431.48 113.78 432.44 113.78 433.5H113.77Z'
        fill='black'
      />
      <path
        d='M113.77 418.04C113.77 419.33 113.49 420.45 112.94 421.42C112.38 422.38 111.6 423.13 110.59 423.66C109.58 424.19 108.4 424.46 107.05 424.46H101.88C100.52 424.46 99.3401 424.19 98.3401 423.66C97.3401 423.13 96.5502 422.38 95.9902 421.42C95.4302 420.46 95.1602 419.33 95.1602 418.04C95.1602 416.75 95.4402 415.63 95.9902 414.66C96.5502 413.7 97.3301 412.95 98.3401 412.42C99.3501 411.89 100.53 411.62 101.88 411.62H107.05C108.4 411.62 109.58 411.89 110.59 412.42C111.6 412.95 112.39 413.7 112.94 414.66C113.5 415.62 113.77 416.75 113.77 418.04ZM111.25 418.04C111.25 416.89 110.87 415.98 110.12 415.29C109.37 414.6 108.37 414.25 107.13 414.25H101.78C100.53 414.25 99.5402 414.6 98.7902 415.29C98.0402 415.98 97.6702 416.9 97.6702 418.04C97.6702 419.18 98.0502 420.09 98.7902 420.79C99.5402 421.48 100.54 421.83 101.78 421.83H107.13C108.37 421.83 109.36 421.48 110.12 420.79C110.88 420.1 111.25 419.18 111.25 418.04Z'
        fill='black'
      />
      <path
        d='M113.58 409.999L95.3301 403.269V401.089L113.58 394.359V397.119L98.8601 402.179L113.58 407.239V409.999ZM109.58 406.899H107.15V397.299H109.58V406.899Z'
        fill='black'
      />
      <path
        d='M97.7698 394.609H95.3398V381.729H97.7698V394.609ZM113.58 389.459H96.8298V386.899H113.58V389.459Z'
        fill='black'
      />
      <path
        d='M113.77 366.859C113.77 368.129 113.49 369.239 112.92 370.199C112.36 371.149 111.56 371.889 110.54 372.419C109.52 372.949 108.33 373.209 106.96 373.209H101.95C100.58 373.209 99.3799 372.949 98.3599 372.419C97.3399 371.889 96.5499 371.149 95.9799 370.199C95.4099 369.249 95.1299 368.129 95.1299 366.859C95.1299 365.809 95.3599 364.839 95.8099 363.969C96.2599 363.099 96.8899 362.359 97.7099 361.769C98.5299 361.179 99.4699 360.779 100.56 360.569V363.189C99.9699 363.349 99.4599 363.609 99.0199 363.989C98.5799 364.359 98.2399 364.799 97.9999 365.299C97.7599 365.799 97.6399 366.319 97.6399 366.859C97.6399 367.979 98.0399 368.879 98.8299 369.559C99.6199 370.239 100.66 370.579 101.96 370.579H106.97C108.27 370.579 109.31 370.239 110.09 369.559C110.88 368.879 111.27 367.979 111.27 366.859C111.27 366.069 111.02 365.319 110.52 364.609C110.02 363.899 109.29 363.429 108.35 363.189V360.569C109.44 360.779 110.39 361.179 111.2 361.779C112.01 362.369 112.64 363.109 113.1 363.979C113.55 364.849 113.78 365.809 113.78 366.869L113.77 366.859Z'
        fill='black'
      />
      <path
        d='M113.58 346.82H95.3301V344.26H113.58V346.82ZM113.58 357.18H95.3301V354.62H113.58V357.18ZM105.82 355.59H103.39V345.46H105.82V355.59Z'
        fill='black'
      />
      <path
        d='M97.7701 338.5H95.3401V328.02H97.7701V338.5ZM113.58 339.74H95.3301V337.18H113.58V339.74ZM105.74 338.5H103.31V329.4H105.74V338.5ZM113.58 338.5H111.15V328.02H113.58V338.5Z'
        fill='black'
      />
      <path
        d='M113.77 318.399C113.77 319.669 113.49 320.779 112.92 321.739C112.36 322.689 111.56 323.429 110.54 323.959C109.52 324.489 108.33 324.749 106.96 324.749H101.95C100.58 324.749 99.3799 324.489 98.3599 323.959C97.3399 323.429 96.5499 322.689 95.9799 321.739C95.4099 320.789 95.1299 319.669 95.1299 318.399C95.1299 317.349 95.3599 316.379 95.8099 315.509C96.2599 314.639 96.8899 313.899 97.7099 313.309C98.5299 312.719 99.4699 312.319 100.56 312.109V314.729C99.9699 314.889 99.4599 315.149 99.0199 315.529C98.5799 315.899 98.2399 316.339 97.9999 316.839C97.7599 317.339 97.6399 317.859 97.6399 318.399C97.6399 319.519 98.0399 320.419 98.8299 321.099C99.6199 321.779 100.66 322.119 101.96 322.119H106.97C108.27 322.119 109.31 321.779 110.09 321.099C110.87 320.419 111.27 319.519 111.27 318.399C111.27 317.609 111.02 316.859 110.52 316.149C110.02 315.439 109.29 314.969 108.35 314.729V312.109C109.44 312.319 110.39 312.719 111.2 313.309C112.01 313.899 112.64 314.639 113.1 315.509C113.55 316.379 113.78 317.339 113.78 318.399H113.77Z'
        fill='black'
      />
      <path
        d='M109.42 307.1L105.58 306.72L95.3401 298.66V295.51L109.42 307.09V307.1ZM113.58 308.73H95.3301V306.17H113.58V308.73ZM113.58 297.89L103.84 303.69L101.97 301.67L113.58 294.76V297.9V297.89Z'
        fill='black'
      />
      <path
        d='M562.21 743.789V725.539H564.76V743.789H562.21ZM563.71 743.789V741.419H568.65C570.01 741.419 570.97 741.159 571.53 740.639C572.09 740.119 572.38 739.439 572.38 738.589V738.549C572.38 737.649 572.14 736.929 571.66 736.389C571.18 735.849 570.39 735.579 569.28 735.579H563.71V733.269H569.28C570.23 733.269 570.94 733.049 571.42 732.599C571.9 732.149 572.14 731.499 572.14 730.639C572.14 729.729 571.87 729.049 571.34 728.589C570.8 728.139 570 727.909 568.93 727.909H563.71V725.539H569.39C571.19 725.539 572.53 725.989 573.41 726.879C574.29 727.779 574.73 728.979 574.73 730.479C574.73 731.389 574.46 732.229 573.93 732.979C573.39 733.739 572.55 734.219 571.41 734.429C572.54 734.599 573.42 735.099 574.04 735.919C574.66 736.739 574.97 737.699 574.97 738.769V738.809C574.97 740.299 574.49 741.499 573.53 742.409C572.57 743.319 571.26 743.779 569.61 743.779H563.71V743.789Z'
        fill='black'
      />
      <path
        d='M576.66 743.789L583.39 725.539H585.57L592.3 743.789H589.54L584.48 729.069L579.42 743.789H576.66ZM579.76 739.789V737.359H589.36V739.789H579.76Z'
        fill='black'
      />
      <path
        d='M592.05 727.98V725.55H604.93V727.98H592.05ZM597.2 743.79V727.04H599.76V743.79H597.2Z'
        fill='black'
      />
      <path
        d='M607.43 743.789V725.539H609.99V743.789H607.43ZM609.03 736.029V733.599H619.16V736.029H609.03ZM617.8 743.789V725.539H620.36V743.789H617.8Z'
        fill='black'
      />
      <path
        d='M624.87 743.789V725.529H627.43V743.789H624.87ZM625.99 736.079V733.649H632.21C632.95 733.649 633.55 733.389 634 732.869C634.45 732.349 634.68 731.669 634.68 730.809C634.68 729.949 634.45 729.269 634 728.749C633.55 728.229 632.95 727.969 632.21 727.969H625.99V725.539H632.11C633.17 725.539 634.1 725.759 634.9 726.199C635.69 726.639 636.31 727.249 636.75 728.039C637.19 728.829 637.41 729.749 637.41 730.809C637.41 731.869 637.19 732.789 636.74 733.579C636.29 734.369 635.68 734.989 634.89 735.419C634.1 735.859 633.17 736.079 632.11 736.079H625.99ZM635.14 743.789L630.96 735.509L633.51 734.929L638.18 743.789H635.14Z'
        fill='black'
      />
      <path
        d='M647.16 743.979C645.87 743.979 644.75 743.699 643.78 743.149C642.82 742.589 642.07 741.809 641.54 740.799C641.01 739.789 640.74 738.609 640.74 737.259V732.089C640.74 730.729 641.01 729.549 641.54 728.549C642.07 727.539 642.82 726.759 643.78 726.199C644.74 725.639 645.87 725.369 647.16 725.369C648.45 725.369 649.57 725.649 650.54 726.199C651.51 726.749 652.25 727.539 652.78 728.549C653.31 729.559 653.58 730.739 653.58 732.089V737.259C653.58 738.609 653.31 739.789 652.78 740.799C652.25 741.809 651.5 742.599 650.54 743.149C649.58 743.709 648.45 743.979 647.16 743.979ZM647.16 741.459C648.31 741.459 649.22 741.079 649.91 740.329C650.6 739.579 650.95 738.579 650.95 737.339V731.989C650.95 730.739 650.61 729.749 649.91 728.999C649.22 728.249 648.3 727.879 647.16 727.879C646.02 727.879 645.11 728.249 644.41 728.999C643.72 729.749 643.37 730.749 643.37 731.989V737.339C643.37 738.579 643.72 739.569 644.41 740.329C645.1 741.089 646.02 741.459 647.16 741.459Z'
        fill='black'
      />
      <path
        d='M663.25 743.979C661.96 743.979 660.84 743.699 659.87 743.149C658.91 742.589 658.16 741.809 657.63 740.799C657.1 739.789 656.83 738.609 656.83 737.259V732.089C656.83 730.729 657.1 729.549 657.63 728.549C658.16 727.539 658.91 726.759 659.87 726.199C660.83 725.639 661.96 725.369 663.25 725.369C664.54 725.369 665.66 725.649 666.63 726.199C667.6 726.749 668.34 727.539 668.87 728.549C669.4 729.559 669.67 730.739 669.67 732.089V737.259C669.67 738.609 669.4 739.789 668.87 740.799C668.34 741.809 667.59 742.599 666.63 743.149C665.67 743.709 664.54 743.979 663.25 743.979ZM663.25 741.459C664.4 741.459 665.31 741.079 666 740.329C666.69 739.579 667.04 738.579 667.04 737.339V731.989C667.04 730.739 666.69 729.749 666 728.999C665.31 728.249 664.39 727.879 663.25 727.879C662.11 727.879 661.2 728.249 660.5 728.999C659.81 729.749 659.46 730.749 659.46 731.989V737.339C659.46 738.579 659.81 739.569 660.5 740.329C661.19 741.089 662.11 741.459 663.25 741.459Z'
        fill='black'
      />
      <path
        d='M681.31 737.589L686.67 725.539H689.07V743.789H686.57V730.119L686.7 730.919L682.21 741.249H680.43L675.94 731.169L676.06 730.119V743.789H673.56V725.539H675.96L681.32 737.589H681.31Z'
        fill='black'
      />
      <path
        d='M699.06 743.979C698.17 743.979 697.34 743.899 696.56 743.729C695.78 743.559 695.05 743.309 694.36 742.979C693.68 742.649 693.04 742.229 692.45 741.729L694.04 739.769C694.73 740.369 695.5 740.819 696.34 741.129C697.18 741.429 698.08 741.579 699.06 741.579C700.38 741.579 701.41 741.329 702.13 740.839C702.86 740.349 703.22 739.659 703.22 738.769C703.22 738.059 703.04 737.529 702.67 737.159C702.3 736.789 701.82 736.519 701.22 736.339C700.62 736.159 699.97 736.009 699.26 735.889C698.57 735.769 697.88 735.639 697.18 735.479C696.48 735.319 695.84 735.069 695.25 734.729C694.66 734.389 694.19 733.899 693.84 733.269C693.49 732.639 693.31 731.789 693.31 730.719C693.31 729.009 693.87 727.689 695 726.759C696.13 725.829 697.72 725.359 699.78 725.359C700.76 725.359 701.72 725.519 702.65 725.829C703.59 726.139 704.5 726.619 705.39 727.269L703.93 729.299C703.24 728.779 702.54 728.399 701.85 728.149C701.16 727.899 700.47 727.769 699.77 727.769C698.52 727.769 697.56 728.019 696.87 728.519C696.18 729.019 695.83 729.719 695.83 730.629C695.83 731.329 696.03 731.849 696.43 732.209C696.83 732.569 697.36 732.839 698.01 733.009C698.66 733.179 699.35 733.339 700.1 733.489C700.77 733.619 701.44 733.779 702.11 733.969C702.78 734.159 703.39 734.429 703.94 734.789C704.49 735.149 704.93 735.629 705.26 736.249C705.59 736.869 705.76 737.679 705.76 738.669V738.699C705.76 740.379 705.17 741.679 704 742.599C702.83 743.519 701.18 743.979 699.06 743.979Z'
        fill='black'
      />
      <path
        d='M388.83 797.819H385.3V782.609H388.83V797.819ZM411.78 799.619H385.3V795.899H411.78V799.619ZM400.4 797.819H396.87V784.619H400.4V797.819ZM411.78 797.819H408.25V782.609H411.78V797.819Z'
        fill='black'
      />
      <path
        d='M411.78 777.329H385.3V773.609H411.78V777.329ZM411.78 775.469H408.25V760.319H411.78V775.469Z'
        fill='black'
      />
      <path
        d='M388.83 754.139H385.3V738.929H388.83V754.139ZM411.78 755.949H385.3V752.229H411.78V755.949ZM400.4 754.139H396.87V740.939H400.4V754.139ZM411.78 754.139H408.25V738.929H411.78V754.139Z'
        fill='black'
      />
      <path
        d='M411.78 727.749L385.3 736.379V732.339L406.06 725.979L385.3 719.619V715.579L411.78 724.209V727.739V727.749Z'
        fill='black'
      />
      <path
        d='M411.78 715.949L385.3 706.189V703.019L411.78 693.259V697.269L390.42 704.609L411.78 711.949V715.959V715.949ZM405.97 711.449H402.44V697.519H405.97V711.449Z'
        fill='black'
      />
      <path
        d='M388.83 693.619H385.3V674.929H388.83V693.619ZM411.78 686.139H387.47V682.419H411.78V686.139Z'
        fill='black'
      />
      <path
        d='M412.06 663.259C412.06 665.129 411.66 666.759 410.85 668.159C410.04 669.559 408.9 670.639 407.43 671.409C405.96 672.179 404.25 672.569 402.29 672.569H394.79C392.82 672.569 391.11 672.179 389.64 671.409C388.17 670.639 387.04 669.559 386.23 668.159C385.42 666.759 385.02 665.129 385.02 663.259C385.02 661.389 385.42 659.759 386.23 658.359C387.04 656.959 388.17 655.879 389.64 655.109C391.1 654.339 392.82 653.949 394.79 653.949H402.29C404.25 653.949 405.96 654.339 407.43 655.109C408.9 655.879 410.04 656.959 410.85 658.359C411.66 659.759 412.06 661.389 412.06 663.259ZM408.4 663.259C408.4 661.599 407.85 660.259 406.76 659.259C405.67 658.259 404.22 657.759 402.43 657.759H394.67C392.86 657.759 391.41 658.259 390.33 659.259C389.24 660.259 388.7 661.589 388.7 663.259C388.7 664.929 389.24 666.239 390.33 667.249C391.42 668.259 392.87 668.759 394.67 668.759H402.43C404.23 668.759 405.67 668.259 406.76 667.249C407.85 666.239 408.4 664.909 408.4 663.259Z'
        fill='black'
      />
      <path
        d='M400.579 646.689H397.049V637.659C397.049 636.579 396.669 635.709 395.919 635.059C395.169 634.409 394.169 634.079 392.929 634.079C391.689 634.079 390.699 634.409 389.939 635.059C389.179 635.709 388.809 636.579 388.809 637.659V646.689H385.279V637.799C385.279 636.259 385.599 634.909 386.239 633.759C386.879 632.609 387.769 631.709 388.909 631.069C390.049 630.429 391.389 630.109 392.929 630.109C394.469 630.109 395.799 630.429 396.949 631.079C398.099 631.729 398.989 632.619 399.629 633.769C400.269 634.919 400.589 636.259 400.589 637.799V646.689H400.579ZM411.779 648.309H385.279V644.589H411.779V648.309ZM411.779 633.409L399.759 639.469L398.919 635.769L411.779 628.999V633.409Z'
        fill='black'
      />
      <path
        d='M412.06 617.289C412.06 618.579 411.94 619.789 411.7 620.919C411.46 622.059 411.09 623.119 410.61 624.109C410.13 625.099 409.52 626.029 408.79 626.889L405.95 624.579C406.83 623.569 407.48 622.459 407.92 621.249C408.36 620.039 408.58 618.719 408.58 617.299C408.58 615.379 408.22 613.889 407.51 612.839C406.79 611.779 405.79 611.259 404.5 611.259H404.48C403.47 611.259 402.7 611.529 402.17 612.059C401.64 612.589 401.24 613.299 400.98 614.159C400.72 615.029 400.5 615.979 400.33 617.009C400.16 618.009 399.96 619.009 399.73 620.019C399.5 621.029 399.14 621.969 398.65 622.819C398.16 623.669 397.45 624.349 396.53 624.869C395.61 625.389 394.37 625.639 392.83 625.639H392.81C390.35 625.639 388.44 624.819 387.08 623.179C385.73 621.539 385.05 619.229 385.05 616.239C385.05 614.819 385.28 613.429 385.73 612.069C386.19 610.709 386.88 609.389 387.81 608.099L390.76 610.209C390.01 611.219 389.45 612.219 389.08 613.219C388.72 614.219 388.53 615.229 388.53 616.229C388.53 618.039 388.89 619.439 389.61 620.449C390.33 621.449 391.36 621.949 392.68 621.949H392.7C393.7 621.949 394.45 621.659 394.98 621.079C395.5 620.499 395.89 619.739 396.14 618.789C396.39 617.849 396.62 616.839 396.84 615.759C397.03 614.789 397.27 613.819 397.54 612.849C397.81 611.879 398.21 610.999 398.72 610.199C399.24 609.399 399.94 608.769 400.84 608.289C401.74 607.809 402.91 607.569 404.36 607.569H404.4C406.84 607.569 408.73 608.419 410.06 610.119C411.4 611.819 412.06 614.209 412.06 617.299V617.289Z'
        fill='black'
      />
      {/* {Booth N0 A1} */}
      <path
        d='M221.9 178.52H128.09V270.67H221.9V178.52Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2189)} 
        className={eid === 2189 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
        
      />
      <path
        d='M152.43 244.35L164.63 211.27H168.59L180.79 244.35H175.78L166.61 217.66L157.44 244.35H152.43ZM158.05 237.1V232.69H175.46V237.1H158.05Z'
        fill='black'
      />
      <path
        d='M193.089 211.27V244.35H188.449V216.32L183.739 219.21V214.41L188.449 211.27H193.089Z'
        fill='black'
      />
      {/* {Booth N0 A2} */}
      <path
        d='M420.95 241.86H284.44V381.06H420.95V241.86Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2188)} 
        className={eid === 2188 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M332.271 328.351L344.471 295.271H348.431L360.631 328.351H355.621L346.451 301.661L337.281 328.351H332.271ZM337.891 321.091V316.681H355.301V321.091H337.891Z'
        fill='black'
      />
      <path
        d='M364.441 328.351V324.321L375.981 308.941C376.651 308.061 377.171 307.151 377.541 306.211C377.911 305.271 378.101 304.381 378.101 303.551V303.501C378.101 302.181 377.711 301.161 376.941 300.431C376.171 299.701 375.071 299.341 373.641 299.341C372.311 299.341 371.221 299.741 370.381 300.531C369.541 301.331 369.031 302.451 368.841 303.911V303.931H364.041V303.911C364.451 301.061 365.491 298.851 367.161 297.281C368.831 295.711 370.971 294.931 373.601 294.931C376.571 294.931 378.871 295.681 380.491 297.181C382.111 298.681 382.921 300.811 382.921 303.551V303.571C382.921 304.741 382.681 305.961 382.191 307.241C381.701 308.521 381.031 309.751 380.161 310.911L370.581 323.931H383.091V328.341H364.431L364.441 328.351Z'
        fill='black'
      />
      {/* {Booth N0 B3} */}
      <path
        d='M455.14 719.391L467.34 686.311H471.3L483.5 719.391H478.49L469.32 692.701L460.15 719.391H455.14ZM460.76 712.131V707.721H478.17V712.131H460.76Z'
        fill='black'
      />
      <path
        d='M496.369 719.73C494.499 719.73 492.869 719.39 491.469 718.72C490.069 718.05 488.939 717.07 488.089 715.8C487.239 714.53 486.689 712.98 486.449 711.16H491.179C491.389 712.59 491.949 713.63 492.859 714.29C493.769 714.95 494.939 715.281 496.359 715.281C497.889 715.281 499.079 714.87 499.929 714.05C500.779 713.23 501.199 712.081 501.199 710.591V709.591C501.199 707.941 500.799 706.66 499.989 705.76C499.189 704.86 498.049 704.41 496.579 704.41H494.239V700H496.579C497.879 700 498.889 699.611 499.589 698.841C500.299 698.071 500.649 696.97 500.649 695.56V694.54C500.649 693.24 500.269 692.23 499.519 691.51C498.769 690.8 497.699 690.44 496.319 690.44C495.149 690.44 494.159 690.77 493.349 691.43C492.539 692.09 491.979 693.13 491.679 694.56H486.969C487.439 691.83 488.489 689.72 490.119 688.22C491.749 686.72 493.819 685.98 496.319 685.98C499.169 685.98 501.379 686.73 502.939 688.23C504.499 689.73 505.279 691.851 505.279 694.581V695.13C505.279 696.78 504.829 698.22 503.939 699.44C503.039 700.66 501.779 701.561 500.139 702.141C501.939 702.521 503.339 703.4 504.339 704.79C505.329 706.18 505.829 707.941 505.829 710.081V710.63C505.829 713.53 504.999 715.77 503.349 717.37C501.699 718.97 499.359 719.76 496.339 719.76L496.369 719.73Z'
        fill='black'
      />
      <path
        d='M717.09 1480.23L723.87 1454.29H727.06L733.84 1480.23L739.69 1454.29H744.51L736.43 1487.37H731.7L725.6 1464.25L719.23 1487.37H714.5L706.42 1454.29H711.24L717.09 1480.23Z'
        fill='black'
      />
      <path
        d='M756.59 1454.29V1487.37H751.95V1459.34L747.24 1462.23V1457.43L751.95 1454.29H756.59Z'
        fill='black'
      />
      <path
        d='M917.181 1740.09H901.301V1774.95H917.181V1740.09Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M741.14 1759.06H725.26V1774.94H741.14V1759.06Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M917.181 1581.25H901.301V1597.13H917.181V1581.25Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M753.96 1771.05L757.65 1756.93H759.38L763.07 1771.05L766.25 1756.93H768.87L764.47 1774.94H761.89L758.57 1762.36L755.1 1774.94H752.52L748.12 1756.93H750.75L753.93 1771.05H753.96Z'
        fill='black'
      />
      <path
        d='M775.46 1756.93V1774.94H772.93V1759.68L770.37 1761.25V1758.64L772.93 1756.93H775.46Z'
        fill='black'
      />
      <path
        d='M929.289 1771.05L932.979 1756.93H934.709L938.399 1771.05L941.579 1756.93H944.199L939.799 1774.94H937.219L933.899 1762.36L930.429 1774.94H927.849L923.449 1756.93H926.079L929.259 1771.05H929.289Z'
        fill='black'
      />
      <path
        d='M950.789 1756.93V1774.94H948.259V1759.68L945.699 1761.25V1758.64L948.259 1756.93H950.789Z'
        fill='black'
      />
      <path
        d='M929.289 1593.24L932.979 1579.12H934.709L938.399 1593.24L941.579 1579.12H944.199L939.799 1597.13H937.219L933.899 1584.55L930.429 1597.13H927.849L923.449 1579.12H926.079L929.259 1593.24H929.289Z'
        fill='black'
      />
      <path
        d='M950.789 1579.12V1597.13H948.259V1581.87L945.699 1583.44V1580.83L948.259 1579.12H950.789Z'
        fill='black'
      />
      <path
        d='M754.33 1593.24L758.02 1579.12H759.75L763.44 1593.24L766.62 1579.12H769.25L764.85 1597.13H762.27L758.95 1584.55L755.48 1597.13H752.9L748.5 1579.12H751.13L754.31 1593.24H754.33Z'
        fill='black'
      />
      <path
        d='M775.83 1579.12V1597.13H773.3V1581.87L770.74 1583.44V1580.83L773.3 1579.12H775.83Z'
        fill='black'
      />
      {/* {Booth No W1} */}
      <path
        d='M792.849 1385.75H761.439V1553.79H792.849V1385.75Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2179)} 
        className={eid === 2179 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M741.14 1581.25H725.26V1597.13H741.14V1581.25Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M102.21 1005.42L108.99 979.48H112.18L118.96 1005.42L124.81 979.48H129.63L121.55 1012.56H116.82L110.72 989.44L104.35 1012.56H99.62L91.54 979.48H96.36L102.21 1005.42Z'
        fill='black'
      />
      <path
        d='M133.22 1012.56V1008.53L144.76 993.151C145.43 992.271 145.95 991.361 146.32 990.421C146.69 989.481 146.88 988.591 146.88 987.761V987.711C146.88 986.391 146.49 985.371 145.72 984.641C144.95 983.911 143.85 983.551 142.42 983.551C141.09 983.551 140 983.951 139.15 984.741C138.31 985.541 137.8 986.661 137.61 988.121V988.141H132.81V988.121C133.22 985.271 134.26 983.061 135.93 981.491C137.6 979.921 139.75 979.141 142.37 979.141C145.34 979.141 147.64 979.891 149.26 981.391C150.88 982.891 151.69 985.021 151.69 987.761V987.781C151.69 988.951 151.45 990.171 150.96 991.451C150.47 992.731 149.8 993.961 148.93 995.121L139.35 1008.14H151.86V1012.55H133.2L133.22 1012.56Z'
        fill='black'
      />
      <path
        d='M103.28 949.771H89.5898V1049.6H103.28V949.771Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M105.45 486.32H89.5898V851.33H105.45V486.32Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M104.93 717.81L111.71 691.87H114.9L121.68 717.81L127.53 691.87H132.35L124.27 724.95H119.54L113.44 701.83L107.07 724.95H102.34L94.2598 691.87H99.0798L104.93 717.81Z'
        fill='black'
      />
      <path
        d='M135.939 724.96V720.93L147.479 705.55C148.149 704.67 148.669 703.76 149.039 702.82C149.409 701.88 149.599 700.99 149.599 700.16V700.11C149.599 698.79 149.209 697.77 148.439 697.04C147.669 696.31 146.569 695.95 145.139 695.95C143.809 695.95 142.719 696.35 141.869 697.14C141.029 697.94 140.519 699.06 140.329 700.52V700.54H135.529V700.52C135.939 697.67 136.979 695.46 138.649 693.89C140.319 692.32 142.469 691.54 145.089 691.54C148.059 691.54 150.359 692.29 151.979 693.79C153.599 695.29 154.409 697.42 154.409 700.16V700.18C154.409 701.35 154.169 702.57 153.679 703.85C153.189 705.13 152.519 706.36 151.649 707.52L142.069 720.54H154.579V724.95H135.919L135.939 724.96Z'
        fill='black'
      />
      <path
        d='M244.77 1910.01V1905.98L256.31 1890.6C256.98 1889.72 257.5 1888.81 257.87 1887.87C258.24 1886.93 258.43 1886.04 258.43 1885.21V1885.16C258.43 1883.84 258.04 1882.82 257.27 1882.09C256.5 1881.36 255.4 1881 253.97 1881C252.64 1881 251.55 1881.4 250.7 1882.19C249.86 1882.99 249.35 1884.11 249.16 1885.57V1885.59H244.36V1885.57C244.77 1882.72 245.81 1880.51 247.48 1878.94C249.15 1877.37 251.29 1876.58 253.92 1876.58C256.89 1876.58 259.19 1877.33 260.81 1878.83C262.43 1880.33 263.24 1882.46 263.24 1885.2V1885.22C263.24 1886.39 263 1887.61 262.51 1888.89C262.02 1890.17 261.35 1891.4 260.49 1892.56L250.91 1905.58H263.42V1909.99H244.76L244.77 1910.01Z'
        fill='black'
      />
      <path
        d='M286.431 1876.92V1881.06L277.871 1910H272.931L281.491 1881.33H273.071V1886.09H268.431V1876.92H286.431Z'
        fill='black'
      />
      {/* {Booth N0 27} */}
      <path
        d='M307.26 1857.38H221.79V1956.15H307.26V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2152)} 
        className={eid === 2152 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="234" y="1910" className='svgText'>27</text>
   
      <path
        d='M328.62 1910.01V1905.98L340.16 1890.6C340.83 1889.72 341.35 1888.81 341.72 1887.87C342.09 1886.93 342.28 1886.04 342.28 1885.21V1885.16C342.28 1883.84 341.89 1882.82 341.12 1882.09C340.35 1881.36 339.25 1881 337.82 1881C336.49 1881 335.4 1881.4 334.55 1882.19C333.71 1882.99 333.2 1884.11 333.01 1885.57V1885.59H328.21V1885.57C328.62 1882.72 329.66 1880.51 331.33 1878.94C333 1877.37 335.14 1876.58 337.77 1876.58C340.74 1876.58 343.04 1877.33 344.66 1878.83C346.28 1880.33 347.09 1882.46 347.09 1885.2V1885.22C347.09 1886.39 346.85 1887.61 346.36 1888.89C345.87 1890.17 345.2 1891.4 344.34 1892.56L334.76 1905.58H347.27V1909.99H328.61L328.62 1910.01Z'
        fill='black'
      />
      <path
        d='M362.89 1910.35C360.87 1910.35 359.11 1909.97 357.61 1909.21C356.11 1908.45 354.94 1907.38 354.11 1906C353.28 1904.62 352.86 1903 352.86 1901.15V1900.6C352.86 1898.95 353.27 1897.39 354.1 1895.94C354.93 1894.48 356.03 1893.38 357.41 1892.64C356.26 1892 355.32 1891.08 354.61 1889.88C353.9 1888.68 353.54 1887.4 353.54 1886.07V1885.27C353.54 1882.65 354.39 1880.54 356.1 1878.96C357.81 1877.38 360.07 1876.58 362.89 1876.58C365.71 1876.58 367.98 1877.37 369.68 1878.96C371.39 1880.55 372.24 1882.65 372.24 1885.27V1886.07C372.24 1887.44 371.88 1888.72 371.16 1889.92C370.44 1891.12 369.49 1892.03 368.3 1892.65C369.7 1893.39 370.81 1894.49 371.66 1895.95C372.5 1897.41 372.92 1898.96 372.92 1900.61V1901.16C372.92 1903.01 372.5 1904.63 371.67 1906.01C370.84 1907.39 369.67 1908.46 368.17 1909.22C366.67 1909.98 364.91 1910.36 362.89 1910.36V1910.35ZM362.89 1905.89C364.51 1905.89 365.82 1905.42 366.81 1904.49C367.8 1903.56 368.3 1902.33 368.3 1900.82V1900.5C368.3 1898.97 367.8 1897.74 366.81 1896.8C365.82 1895.87 364.51 1895.4 362.89 1895.4C361.27 1895.4 359.96 1895.87 358.97 1896.8C357.98 1897.73 357.48 1898.97 357.48 1900.52V1900.86C357.48 1902.38 357.98 1903.59 358.97 1904.51C359.96 1905.43 361.27 1905.89 362.89 1905.89ZM362.89 1890.94C364.3 1890.94 365.44 1890.49 366.3 1889.6C367.17 1888.71 367.6 1887.52 367.6 1886.05V1885.73C367.6 1884.32 367.17 1883.19 366.3 1882.33C365.44 1881.47 364.3 1881.04 362.89 1881.04C361.48 1881.04 360.34 1881.47 359.48 1882.33C358.62 1883.19 358.18 1884.33 358.18 1885.75V1886.09C358.18 1887.55 358.61 1888.72 359.48 1889.61C360.35 1890.5 361.48 1890.94 362.89 1890.94Z'
        fill='black'
      />
      {/* {Booth N0 28} */}
      <path
        d='M391.11 1857.38H307.25V1956.15H391.11V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2153)} 
        className={eid === 2153 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
         <text x="328" y="1910" className='svgText'>28</text>
   
      <path
        d='M413.52 1910.01V1905.98L425.06 1890.6C425.73 1889.72 426.25 1888.81 426.62 1887.87C426.99 1886.93 427.18 1886.04 427.18 1885.21V1885.16C427.18 1883.84 426.79 1882.82 426.02 1882.09C425.25 1881.36 424.15 1881 422.72 1881C421.39 1881 420.3 1881.4 419.45 1882.19C418.61 1882.99 418.1 1884.11 417.91 1885.57V1885.59H413.11V1885.57C413.52 1882.72 414.56 1880.51 416.23 1878.94C417.9 1877.37 420.04 1876.58 422.67 1876.58C425.64 1876.58 427.94 1877.33 429.56 1878.83C431.18 1880.33 431.99 1882.46 431.99 1885.2V1885.22C431.99 1886.39 431.75 1887.61 431.26 1888.89C430.77 1890.17 430.1 1891.4 429.24 1892.56L419.66 1905.58H432.17V1909.99H413.51L413.52 1910.01Z'
        fill='black'
      />
      <path
        d='M441.491 1910.01L449.821 1893.58L449.801 1894.4C449.401 1895.11 448.821 1895.63 448.051 1895.95C447.281 1896.27 446.381 1896.43 445.371 1896.43C442.991 1896.43 441.071 1895.55 439.611 1893.78C438.151 1892.01 437.431 1889.67 437.431 1886.74V1886.72C437.431 1883.5 438.241 1881.01 439.851 1879.25C441.461 1877.49 443.741 1876.6 446.661 1876.6C449.581 1876.6 451.861 1877.49 453.461 1879.27C455.071 1881.05 455.871 1883.57 455.871 1886.84V1886.86C455.871 1888.21 455.671 1889.67 455.281 1891.24C454.891 1892.81 454.311 1894.35 453.551 1895.85L446.501 1910.03H441.491V1910.01ZM446.651 1892.19C448.111 1892.19 449.231 1891.7 450.031 1890.71C450.831 1889.72 451.221 1888.34 451.221 1886.55V1886.53C451.221 1884.79 450.821 1883.44 450.031 1882.48C449.231 1881.52 448.111 1881.05 446.651 1881.05C445.191 1881.05 444.041 1881.53 443.251 1882.48C442.451 1883.44 442.061 1884.79 442.061 1886.55V1886.57C442.061 1888.36 442.461 1889.74 443.251 1890.72C444.051 1891.7 445.181 1892.19 446.651 1892.19Z'
        fill='black'
      />
      {/* {Booth no 29} */}
      <path
        d='M479.01 1857.38H391.11V1956.15H479.01V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2154)} 
        className={eid === 2154 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
    <text x="410" y="1910" className='svgText'>29</text>
 
      <path
        d='M507.149 1910.35C505.279 1910.35 503.649 1910.01 502.249 1909.34C500.849 1908.67 499.719 1907.69 498.869 1906.42C498.019 1905.15 497.469 1903.6 497.229 1901.78H501.959C502.169 1903.21 502.729 1904.25 503.639 1904.91C504.549 1905.57 505.719 1905.9 507.139 1905.9C508.669 1905.9 509.859 1905.49 510.709 1904.67C511.559 1903.85 511.979 1902.7 511.979 1901.21V1900.21C511.979 1898.56 511.579 1897.28 510.769 1896.38C509.969 1895.48 508.829 1895.03 507.359 1895.03H505.019V1890.62H507.359C508.659 1890.62 509.669 1890.23 510.369 1889.46C511.069 1888.69 511.429 1887.59 511.429 1886.18V1885.16C511.429 1883.86 511.049 1882.85 510.299 1882.13C509.549 1881.42 508.479 1881.06 507.099 1881.06C505.929 1881.06 504.939 1881.39 504.129 1882.05C503.319 1882.71 502.759 1883.75 502.459 1885.18H497.749C498.219 1882.45 499.269 1880.34 500.899 1878.84C502.529 1877.35 504.599 1876.6 507.099 1876.6C509.949 1876.6 512.159 1877.35 513.719 1878.85C515.279 1880.35 516.059 1882.47 516.059 1885.2V1885.75C516.059 1887.4 515.609 1888.84 514.719 1890.06C513.819 1891.28 512.559 1892.18 510.919 1892.76C512.719 1893.14 514.119 1894.02 515.119 1895.41C516.109 1896.8 516.609 1898.56 516.609 1900.7V1901.25C516.609 1904.15 515.779 1906.39 514.129 1907.99C512.479 1909.59 510.139 1910.38 507.119 1910.38L507.149 1910.35Z'
        fill='black'
      />
      <path
        d='M532.679 1910.35C529.519 1910.35 527.159 1909.51 525.589 1907.81C524.019 1906.12 523.239 1903.83 523.239 1900.95V1885.91C523.239 1882.98 524.039 1880.68 525.629 1879.02C527.219 1877.35 529.569 1876.52 532.679 1876.52C535.789 1876.52 538.159 1877.35 539.749 1879.01C541.339 1880.67 542.129 1882.97 542.129 1885.92V1900.96C542.129 1903.89 541.339 1906.19 539.749 1907.85C538.169 1909.52 535.809 1910.35 532.679 1910.35ZM532.679 1905.94C534.379 1905.94 535.599 1905.5 536.349 1904.61C537.099 1903.72 537.479 1902.51 537.479 1900.96V1885.92C537.479 1884.36 537.099 1883.14 536.349 1882.26C535.599 1881.38 534.369 1880.94 532.679 1880.94C530.989 1880.94 529.759 1881.38 529.009 1882.26C528.259 1883.14 527.879 1884.36 527.879 1885.92V1900.96C527.879 1902.51 528.259 1903.72 529.009 1904.61C529.759 1905.5 530.989 1905.94 532.679 1905.94Z'
        fill='black'
      />
      {/* {Booth no 30} */}
      <path
        d='M568.33 1857.38H479.01V1956.15H568.33V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2155)} 
        className={eid === 2155 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
        <text x="504" y="1910" className='svgText'>30</text>
    
      {/* {Booth no 31} */}
      <path
        d='M649.76 1857.38H568.33V1956.15H649.76V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2156)} 
        className={eid === 2156 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M595.981 1910.35C594.111 1910.35 592.481 1910.01 591.081 1909.34C589.681 1908.67 588.551 1907.69 587.701 1906.42C586.851 1905.15 586.301 1903.6 586.061 1901.78H590.791C591.001 1903.21 591.561 1904.25 592.471 1904.91C593.381 1905.57 594.551 1905.9 595.971 1905.9C597.501 1905.9 598.691 1905.49 599.541 1904.67C600.391 1903.85 600.811 1902.7 600.811 1901.21V1900.21C600.811 1898.56 600.411 1897.28 599.601 1896.38C598.801 1895.48 597.661 1895.03 596.191 1895.03H593.851V1890.62H596.191C597.491 1890.62 598.501 1890.23 599.201 1889.46C599.901 1888.69 600.261 1887.59 600.261 1886.18V1885.16C600.261 1883.86 599.881 1882.85 599.131 1882.13C598.381 1881.42 597.311 1881.06 595.931 1881.06C594.761 1881.06 593.771 1881.39 592.961 1882.05C592.151 1882.71 591.591 1883.75 591.291 1885.18H586.581C587.051 1882.45 588.101 1880.34 589.731 1878.84C591.361 1877.35 593.431 1876.6 595.931 1876.6C598.781 1876.6 600.991 1877.35 602.551 1878.85C604.111 1880.35 604.891 1882.47 604.891 1885.2V1885.75C604.891 1887.4 604.441 1888.84 603.551 1890.06C602.651 1891.28 601.391 1892.18 599.751 1892.76C601.551 1893.14 602.951 1894.02 603.951 1895.41C604.941 1896.8 605.441 1898.56 605.441 1900.7V1901.25C605.441 1904.15 604.611 1906.39 602.961 1907.99C601.311 1909.59 598.971 1910.38 595.951 1910.38L595.981 1910.35Z'
        fill='black'
      />
      <path
        d='M620.061 1876.92V1910H615.421V1881.97L610.711 1884.86V1880.06L615.421 1876.92H620.061Z'
        fill='black'
      />
      <path
        d='M685.89 1910.35C684.02 1910.35 682.39 1910.01 680.99 1909.34C679.59 1908.67 678.46 1907.69 677.61 1906.42C676.76 1905.15 676.21 1903.6 675.97 1901.78H680.7C680.91 1903.21 681.47 1904.25 682.38 1904.91C683.29 1905.57 684.46 1905.9 685.88 1905.9C687.41 1905.9 688.6 1905.49 689.45 1904.67C690.3 1903.85 690.72 1902.7 690.72 1901.21V1900.21C690.72 1898.56 690.32 1897.28 689.51 1896.38C688.71 1895.48 687.57 1895.03 686.1 1895.03H683.76V1890.62H686.1C687.4 1890.62 688.41 1890.23 689.11 1889.46C689.81 1888.69 690.17 1887.59 690.17 1886.18V1885.16C690.17 1883.86 689.79 1882.85 689.04 1882.13C688.29 1881.42 687.22 1881.06 685.84 1881.06C684.67 1881.06 683.68 1881.39 682.87 1882.05C682.06 1882.71 681.5 1883.75 681.2 1885.18H676.49C676.96 1882.45 678.01 1880.34 679.64 1878.84C681.27 1877.35 683.34 1876.6 685.84 1876.6C688.69 1876.6 690.9 1877.35 692.46 1878.85C694.02 1880.35 694.8 1882.47 694.8 1885.2V1885.75C694.8 1887.4 694.35 1888.84 693.46 1890.06C692.56 1891.28 691.3 1892.18 689.66 1892.76C691.46 1893.14 692.86 1894.02 693.86 1895.41C694.85 1896.8 695.35 1898.56 695.35 1900.7V1901.25C695.35 1904.15 694.52 1906.39 692.87 1907.99C691.22 1909.59 688.88 1910.38 685.86 1910.38L685.89 1910.35Z'
        fill='black'
      />
      <path
        d='M701.469 1910.01V1905.98L713.009 1890.6C713.679 1889.72 714.199 1888.81 714.569 1887.87C714.939 1886.93 715.129 1886.04 715.129 1885.21V1885.16C715.129 1883.84 714.739 1882.82 713.969 1882.09C713.199 1881.36 712.099 1881 710.669 1881C709.339 1881 708.249 1881.4 707.409 1882.19C706.569 1882.99 706.059 1884.11 705.869 1885.57V1885.59H701.069V1885.57C701.479 1882.72 702.519 1880.51 704.189 1878.94C705.859 1877.37 707.999 1876.58 710.629 1876.58C713.599 1876.58 715.899 1877.33 717.519 1878.83C719.139 1880.33 719.949 1882.46 719.949 1885.2V1885.22C719.949 1886.39 719.709 1887.61 719.219 1888.89C718.729 1890.17 718.059 1891.4 717.199 1892.56L707.619 1905.58H720.129V1909.99H701.469V1910.01Z'
        fill='black'
      />

      {/* {Booth N0 32} */}
      <path
        d='M737.12 1857.38H649.75V1956.15H737.12V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2157)} 
        className={eid === 2157 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
          <text x="670" y="1910" className='svgText'>32</text>

      {/* {Booth N0 33} */}
      <path
        d='M819.93 1857.38H737.12V1956.15H819.93V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2158)} 
        className={eid === 2158 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M770.97 1910.35C769.1 1910.35 767.47 1910.01 766.07 1909.34C764.67 1908.67 763.54 1907.69 762.69 1906.42C761.84 1905.15 761.29 1903.6 761.05 1901.78H765.78C765.99 1903.21 766.55 1904.25 767.46 1904.91C768.37 1905.57 769.54 1905.9 770.96 1905.9C772.49 1905.9 773.68 1905.49 774.53 1904.67C775.38 1903.85 775.8 1902.7 775.8 1901.21V1900.21C775.8 1898.56 775.4 1897.28 774.59 1896.38C773.79 1895.48 772.65 1895.03 771.18 1895.03H768.84V1890.62H771.18C772.48 1890.62 773.49 1890.23 774.19 1889.46C774.89 1888.69 775.25 1887.59 775.25 1886.18V1885.16C775.25 1883.86 774.87 1882.85 774.12 1882.13C773.37 1881.42 772.3 1881.06 770.92 1881.06C769.75 1881.06 768.76 1881.39 767.95 1882.05C767.14 1882.71 766.58 1883.75 766.28 1885.18H761.57C762.04 1882.45 763.09 1880.34 764.72 1878.84C766.35 1877.35 768.42 1876.6 770.92 1876.6C773.77 1876.6 775.98 1877.35 777.54 1878.85C779.1 1880.35 779.88 1882.47 779.88 1885.2V1885.75C779.88 1887.4 779.43 1888.84 778.54 1890.06C777.64 1891.28 776.38 1892.18 774.74 1892.76C776.54 1893.14 777.94 1894.02 778.94 1895.41C779.93 1896.8 780.43 1898.56 780.43 1900.7V1901.25C780.43 1904.15 779.6 1906.39 777.95 1907.99C776.3 1909.59 773.96 1910.38 770.94 1910.38L770.97 1910.35Z'
        fill='black'
      />
      <path
        d='M795.619 1910.35C793.749 1910.35 792.119 1910.01 790.719 1909.34C789.319 1908.67 788.189 1907.69 787.339 1906.42C786.489 1905.15 785.939 1903.6 785.699 1901.78H790.429C790.639 1903.21 791.199 1904.25 792.109 1904.91C793.019 1905.57 794.189 1905.9 795.609 1905.9C797.139 1905.9 798.329 1905.49 799.179 1904.67C800.029 1903.85 800.449 1902.7 800.449 1901.21V1900.21C800.449 1898.56 800.049 1897.28 799.239 1896.38C798.439 1895.48 797.299 1895.03 795.829 1895.03H793.489V1890.62H795.829C797.129 1890.62 798.139 1890.23 798.839 1889.46C799.539 1888.69 799.899 1887.59 799.899 1886.18V1885.16C799.899 1883.86 799.519 1882.85 798.769 1882.13C798.019 1881.42 796.949 1881.06 795.569 1881.06C794.399 1881.06 793.409 1881.39 792.599 1882.05C791.789 1882.71 791.229 1883.75 790.929 1885.18H786.219C786.689 1882.45 787.739 1880.34 789.369 1878.84C790.999 1877.35 793.069 1876.6 795.569 1876.6C798.419 1876.6 800.629 1877.35 802.189 1878.85C803.749 1880.35 804.529 1882.47 804.529 1885.2V1885.75C804.529 1887.4 804.079 1888.84 803.189 1890.06C802.289 1891.28 801.029 1892.18 799.389 1892.76C801.189 1893.14 802.589 1894.02 803.589 1895.41C804.579 1896.8 805.079 1898.56 805.079 1900.7V1901.25C805.079 1904.15 804.249 1906.39 802.599 1907.99C800.949 1909.59 798.609 1910.38 795.589 1910.38L795.619 1910.35Z'
        fill='black'
      />
      <path
        d='M853.879 1910.35C852.009 1910.35 850.379 1910.01 848.979 1909.34C847.579 1908.67 846.449 1907.69 845.599 1906.42C844.749 1905.15 844.199 1903.6 843.959 1901.78H848.689C848.899 1903.21 849.459 1904.25 850.369 1904.91C851.279 1905.57 852.449 1905.9 853.869 1905.9C855.399 1905.9 856.589 1905.49 857.439 1904.67C858.289 1903.85 858.709 1902.7 858.709 1901.21V1900.21C858.709 1898.56 858.309 1897.28 857.499 1896.38C856.699 1895.48 855.559 1895.03 854.089 1895.03H851.749V1890.62H854.089C855.389 1890.62 856.399 1890.23 857.099 1889.46C857.799 1888.69 858.159 1887.59 858.159 1886.18V1885.16C858.159 1883.86 857.779 1882.85 857.029 1882.13C856.279 1881.42 855.209 1881.06 853.829 1881.06C852.659 1881.06 851.669 1881.39 850.859 1882.05C850.049 1882.71 849.489 1883.75 849.189 1885.18H844.479C844.949 1882.45 845.999 1880.34 847.629 1878.84C849.259 1877.35 851.329 1876.6 853.829 1876.6C856.679 1876.6 858.889 1877.35 860.449 1878.85C862.009 1880.35 862.789 1882.47 862.789 1885.2V1885.75C862.789 1887.4 862.339 1888.84 861.449 1890.06C860.549 1891.28 859.289 1892.18 857.649 1892.76C859.449 1893.14 860.849 1894.02 861.849 1895.41C862.839 1896.8 863.339 1898.56 863.339 1900.7V1901.25C863.339 1904.15 862.509 1906.39 860.859 1907.99C859.209 1909.59 856.869 1910.38 853.849 1910.38L853.879 1910.35Z'
        fill='black'
      />
      <path
        d='M869.51 1904.93V1900.9L880.61 1876.94H885.39L874.56 1900.63H890.78V1904.93H869.51ZM883.78 1910.03V1890.55H888.31V1910.03H883.78Z'
        fill='black'
      />
      {/* {Booth N0 34} */}
      <path
        d='M910.15 1857.38H819.93V1956.15H910.15V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2159)} 
        className={eid === 2159 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="840" y="1910" className='svgText'>34</text>
    
      {/* {Booth N0 35} */}
      <path
        d='M994.091 1857.38H910.141V1956.15H994.091V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2160)} 
        className={eid === 2160 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M940.961 1910.35C939.091 1910.35 937.461 1910.01 936.061 1909.34C934.661 1908.67 933.531 1907.69 932.681 1906.42C931.831 1905.15 931.281 1903.6 931.041 1901.78H935.771C935.981 1903.21 936.541 1904.25 937.451 1904.91C938.361 1905.57 939.531 1905.9 940.951 1905.9C942.481 1905.9 943.671 1905.49 944.521 1904.67C945.371 1903.85 945.791 1902.7 945.791 1901.21V1900.21C945.791 1898.56 945.391 1897.28 944.581 1896.38C943.781 1895.48 942.641 1895.03 941.171 1895.03H938.831V1890.62H941.171C942.471 1890.62 943.481 1890.23 944.181 1889.46C944.881 1888.69 945.241 1887.59 945.241 1886.18V1885.16C945.241 1883.86 944.861 1882.85 944.111 1882.13C943.361 1881.42 942.291 1881.06 940.911 1881.06C939.741 1881.06 938.751 1881.39 937.941 1882.05C937.131 1882.71 936.571 1883.75 936.271 1885.18H931.561C932.031 1882.45 933.081 1880.34 934.711 1878.84C936.341 1877.35 938.411 1876.6 940.911 1876.6C943.761 1876.6 945.971 1877.35 947.531 1878.85C949.091 1880.35 949.871 1882.47 949.871 1885.2V1885.75C949.871 1887.4 949.421 1888.84 948.531 1890.06C947.631 1891.28 946.371 1892.18 944.731 1892.76C946.531 1893.14 947.931 1894.02 948.931 1895.41C949.921 1896.8 950.421 1898.56 950.421 1900.7V1901.25C950.421 1904.15 949.591 1906.39 947.941 1907.99C946.291 1909.59 943.951 1910.38 940.931 1910.38L940.961 1910.35Z'
        fill='black'
      />
      <path
        d='M966.291 1910.35C963.771 1910.35 961.711 1909.63 960.091 1908.18C958.471 1906.73 957.461 1904.69 957.051 1902.05V1902.03H961.691V1902.05C961.861 1903.26 962.341 1904.21 963.151 1904.88C963.961 1905.55 965.001 1905.89 966.291 1905.89C967.781 1905.89 968.921 1905.39 969.741 1904.4C970.551 1903.41 970.961 1902.01 970.961 1900.2V1897.42C970.961 1895.62 970.551 1894.22 969.741 1893.23C968.931 1892.24 967.781 1891.75 966.291 1891.75C965.491 1891.75 964.711 1891.98 963.971 1892.43C963.231 1892.88 962.571 1893.52 962.011 1894.34H957.781V1876.91H974.641V1881.32H962.421V1888.76C963.001 1888.29 963.641 1887.93 964.351 1887.67C965.061 1887.41 965.791 1887.28 966.531 1887.28C969.411 1887.28 971.641 1888.16 973.221 1889.93C974.801 1891.7 975.591 1894.19 975.591 1897.4V1900.18C975.591 1903.41 974.781 1905.91 973.161 1907.68C971.541 1909.45 969.251 1910.33 966.291 1910.33V1910.35Z'
        fill='black'
      />
      {/* {Booth N0 36} */}
      <path
        d='M1086.58 1857.38H994.09V1956.15H1086.58V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2161)} 
        className={eid === 2161 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1029.18 1910.35C1027.31 1910.35 1025.68 1910.01 1024.28 1909.34C1022.88 1908.67 1021.75 1907.69 1020.9 1906.42C1020.05 1905.15 1019.5 1903.6 1019.26 1901.78H1023.99C1024.2 1903.21 1024.76 1904.25 1025.67 1904.91C1026.58 1905.57 1027.75 1905.9 1029.17 1905.9C1030.7 1905.9 1031.89 1905.49 1032.74 1904.67C1033.59 1903.85 1034.01 1902.7 1034.01 1901.21V1900.21C1034.01 1898.56 1033.61 1897.28 1032.8 1896.38C1032 1895.48 1030.86 1895.03 1029.39 1895.03H1027.05V1890.62H1029.39C1030.69 1890.62 1031.7 1890.23 1032.4 1889.46C1033.1 1888.69 1033.46 1887.59 1033.46 1886.18V1885.16C1033.46 1883.86 1033.08 1882.85 1032.33 1882.13C1031.58 1881.42 1030.51 1881.06 1029.13 1881.06C1027.96 1881.06 1026.97 1881.39 1026.16 1882.05C1025.35 1882.71 1024.79 1883.75 1024.49 1885.18H1019.78C1020.25 1882.45 1021.3 1880.34 1022.93 1878.84C1024.56 1877.35 1026.63 1876.6 1029.13 1876.6C1031.98 1876.6 1034.19 1877.35 1035.75 1878.85C1037.31 1880.35 1038.09 1882.47 1038.09 1885.2V1885.75C1038.09 1887.4 1037.64 1888.84 1036.75 1890.06C1035.85 1891.28 1034.59 1892.18 1032.95 1892.76C1034.75 1893.14 1036.15 1894.02 1037.15 1895.41C1038.14 1896.8 1038.64 1898.56 1038.64 1900.7V1901.25C1038.64 1904.15 1037.81 1906.39 1036.16 1907.99C1034.51 1909.59 1032.17 1910.38 1029.15 1910.38L1029.18 1910.35Z'
        fill='black'
      />
      <path
        d='M1053.8 1910.35C1050.87 1910.35 1048.6 1909.48 1047 1907.73C1045.39 1905.99 1044.59 1903.53 1044.59 1900.36V1900.34C1044.59 1898.92 1044.79 1897.4 1045.18 1895.8C1045.57 1894.2 1046.14 1892.66 1046.89 1891.17L1054.08 1876.93H1059.09L1050.65 1893.43L1050.67 1892.61C1051.46 1891.11 1052.86 1890.36 1054.88 1890.36C1057.47 1890.36 1059.48 1891.22 1060.91 1892.94C1062.34 1894.66 1063.05 1897.09 1063.05 1900.23V1900.25C1063.05 1903.45 1062.25 1905.94 1060.64 1907.7C1059.03 1909.47 1056.76 1910.35 1053.81 1910.35H1053.8ZM1053.8 1905.89C1055.29 1905.89 1056.42 1905.43 1057.21 1904.52C1058 1903.61 1058.39 1902.31 1058.39 1900.63V1900.61C1058.39 1898.76 1057.97 1897.33 1057.14 1896.32C1056.31 1895.31 1055.12 1894.81 1053.57 1894.81C1052.19 1894.81 1051.12 1895.32 1050.36 1896.33C1049.6 1897.34 1049.22 1898.78 1049.22 1900.63V1900.65C1049.22 1902.33 1049.62 1903.63 1050.41 1904.53C1051.21 1905.43 1052.33 1905.88 1053.79 1905.88L1053.8 1905.89Z'
        fill='black'
      />
      <path
        d='M1117.31 1910.35C1115.44 1910.35 1113.81 1910.01 1112.41 1909.34C1111.01 1908.67 1109.88 1907.69 1109.03 1906.42C1108.18 1905.15 1107.63 1903.6 1107.39 1901.78H1112.12C1112.33 1903.21 1112.89 1904.25 1113.8 1904.91C1114.71 1905.57 1115.88 1905.9 1117.3 1905.9C1118.83 1905.9 1120.02 1905.49 1120.87 1904.67C1121.72 1903.85 1122.14 1902.7 1122.14 1901.21V1900.21C1122.14 1898.56 1121.74 1897.28 1120.93 1896.38C1120.13 1895.48 1118.99 1895.03 1117.52 1895.03H1115.18V1890.62H1117.52C1118.82 1890.62 1119.83 1890.23 1120.53 1889.46C1121.23 1888.69 1121.59 1887.59 1121.59 1886.18V1885.16C1121.59 1883.86 1121.21 1882.85 1120.46 1882.13C1119.71 1881.42 1118.64 1881.06 1117.26 1881.06C1116.09 1881.06 1115.1 1881.39 1114.29 1882.05C1113.48 1882.71 1112.92 1883.75 1112.62 1885.18H1107.91C1108.38 1882.45 1109.43 1880.34 1111.06 1878.84C1112.69 1877.35 1114.76 1876.6 1117.26 1876.6C1120.11 1876.6 1122.32 1877.35 1123.88 1878.85C1125.44 1880.35 1126.22 1882.47 1126.22 1885.2V1885.75C1126.22 1887.4 1125.77 1888.84 1124.88 1890.06C1123.98 1891.28 1122.72 1892.18 1121.08 1892.76C1122.88 1893.14 1124.28 1894.02 1125.28 1895.41C1126.27 1896.8 1126.77 1898.56 1126.77 1900.7V1901.25C1126.77 1904.15 1125.94 1906.39 1124.29 1907.99C1122.64 1909.59 1120.3 1910.38 1117.28 1910.38L1117.31 1910.35Z'
        fill='black'
      />
      <path
        d='M1150.48 1876.92V1881.06L1141.92 1910H1136.98L1145.54 1881.33H1137.12V1886.09H1132.48V1876.92H1150.48Z'
        fill='black'
      />
      {/* {Booth N0 37} */}
      <path
        d='M1170.35 1857.38H1086.59V1956.15H1170.35V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2162)} 
        className={eid === 2162 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
           <text x="1104" y="1910" className='svgText'>37</text>
   
      <path
        d='M1200.44 1910.35C1198.57 1910.35 1196.94 1910.01 1195.54 1909.34C1194.14 1908.67 1193.01 1907.69 1192.16 1906.42C1191.31 1905.15 1190.76 1903.6 1190.52 1901.78H1195.25C1195.46 1903.21 1196.02 1904.25 1196.93 1904.91C1197.84 1905.57 1199.01 1905.9 1200.43 1905.9C1201.96 1905.9 1203.15 1905.49 1204 1904.67C1204.85 1903.85 1205.27 1902.7 1205.27 1901.21V1900.21C1205.27 1898.56 1204.87 1897.28 1204.06 1896.38C1203.26 1895.48 1202.12 1895.03 1200.65 1895.03H1198.31V1890.62H1200.65C1201.95 1890.62 1202.96 1890.23 1203.66 1889.46C1204.36 1888.69 1204.72 1887.59 1204.72 1886.18V1885.16C1204.72 1883.86 1204.34 1882.85 1203.59 1882.13C1202.84 1881.42 1201.77 1881.06 1200.39 1881.06C1199.22 1881.06 1198.23 1881.39 1197.42 1882.05C1196.61 1882.71 1196.05 1883.75 1195.75 1885.18H1191.04C1191.51 1882.45 1192.56 1880.34 1194.19 1878.84C1195.82 1877.35 1197.89 1876.6 1200.39 1876.6C1203.24 1876.6 1205.45 1877.35 1207.01 1878.85C1208.57 1880.35 1209.35 1882.47 1209.35 1885.2V1885.75C1209.35 1887.4 1208.9 1888.84 1208.01 1890.06C1207.11 1891.28 1205.85 1892.18 1204.21 1892.76C1206.01 1893.14 1207.41 1894.02 1208.41 1895.41C1209.4 1896.8 1209.9 1898.56 1209.9 1900.7V1901.25C1209.9 1904.15 1209.07 1906.39 1207.42 1907.99C1205.77 1909.59 1203.43 1910.38 1200.41 1910.38L1200.44 1910.35Z'
        fill='black'
      />
      <path
        d='M1226.22 1910.35C1224.2 1910.35 1222.44 1909.97 1220.94 1909.21C1219.44 1908.45 1218.27 1907.38 1217.44 1906C1216.61 1904.62 1216.19 1903 1216.19 1901.15V1900.6C1216.19 1898.95 1216.6 1897.39 1217.43 1895.94C1218.26 1894.48 1219.36 1893.38 1220.74 1892.64C1219.59 1892 1218.65 1891.08 1217.94 1889.88C1217.23 1888.68 1216.87 1887.4 1216.87 1886.07V1885.27C1216.87 1882.65 1217.72 1880.54 1219.43 1878.96C1221.14 1877.38 1223.4 1876.58 1226.22 1876.58C1229.04 1876.58 1231.3 1877.37 1233.01 1878.96C1234.72 1880.55 1235.57 1882.65 1235.57 1885.27V1886.07C1235.57 1887.44 1235.21 1888.72 1234.49 1889.92C1233.77 1891.12 1232.82 1892.03 1231.63 1892.65C1233.03 1893.39 1234.14 1894.49 1234.99 1895.95C1235.83 1897.41 1236.25 1898.96 1236.25 1900.61V1901.16C1236.25 1903.01 1235.83 1904.63 1235 1906.01C1234.17 1907.39 1233 1908.46 1231.5 1909.22C1230 1909.98 1228.24 1910.36 1226.22 1910.36V1910.35ZM1226.22 1905.89C1227.84 1905.89 1229.15 1905.42 1230.14 1904.49C1231.13 1903.56 1231.63 1902.33 1231.63 1900.82V1900.5C1231.63 1898.97 1231.13 1897.74 1230.14 1896.8C1229.15 1895.87 1227.84 1895.4 1226.22 1895.4C1224.6 1895.4 1223.29 1895.87 1222.29 1896.8C1221.3 1897.73 1220.8 1898.97 1220.8 1900.52V1900.86C1220.8 1902.38 1221.3 1903.59 1222.29 1904.51C1223.28 1905.43 1224.59 1905.89 1226.22 1905.89ZM1226.22 1890.94C1227.63 1890.94 1228.77 1890.49 1229.63 1889.6C1230.49 1888.71 1230.93 1887.52 1230.93 1886.05V1885.73C1230.93 1884.32 1230.5 1883.19 1229.63 1882.33C1228.76 1881.47 1227.63 1881.04 1226.22 1881.04C1224.81 1881.04 1223.67 1881.47 1222.81 1882.33C1221.94 1883.19 1221.51 1884.33 1221.51 1885.75V1886.09C1221.51 1887.55 1221.94 1888.72 1222.81 1889.61C1223.67 1890.5 1224.81 1890.94 1226.22 1890.94Z'
        fill='black'
      />
      {/* {Booth N0 38} */}
      <path
        d='M1252.85 1857.38H1170.34V1956.15H1252.85V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2163)} 
        className={eid === 2163 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
    <text x="1185" y="1910" className='svgText'>38</text>
   
      <path
        d='M1287.86 1910.35C1285.99 1910.35 1284.36 1910.01 1282.96 1909.34C1281.56 1908.67 1280.43 1907.69 1279.58 1906.42C1278.73 1905.15 1278.18 1903.6 1277.94 1901.78H1282.67C1282.88 1903.21 1283.44 1904.25 1284.35 1904.91C1285.26 1905.57 1286.43 1905.9 1287.85 1905.9C1289.38 1905.9 1290.57 1905.49 1291.42 1904.67C1292.27 1903.85 1292.69 1902.7 1292.69 1901.21V1900.21C1292.69 1898.56 1292.29 1897.28 1291.48 1896.38C1290.68 1895.48 1289.54 1895.03 1288.07 1895.03H1285.73V1890.62H1288.07C1289.37 1890.62 1290.38 1890.23 1291.08 1889.46C1291.78 1888.69 1292.14 1887.59 1292.14 1886.18V1885.16C1292.14 1883.86 1291.76 1882.85 1291.01 1882.13C1290.26 1881.42 1289.19 1881.06 1287.81 1881.06C1286.64 1881.06 1285.65 1881.39 1284.84 1882.05C1284.03 1882.71 1283.47 1883.75 1283.17 1885.18H1278.46C1278.93 1882.45 1279.98 1880.34 1281.61 1878.84C1283.24 1877.35 1285.31 1876.6 1287.81 1876.6C1290.66 1876.6 1292.87 1877.35 1294.43 1878.85C1295.99 1880.35 1296.77 1882.47 1296.77 1885.2V1885.75C1296.77 1887.4 1296.32 1888.84 1295.43 1890.06C1294.53 1891.28 1293.27 1892.18 1291.63 1892.76C1293.43 1893.14 1294.83 1894.02 1295.83 1895.41C1296.82 1896.8 1297.32 1898.56 1297.32 1900.7V1901.25C1297.32 1904.15 1296.49 1906.39 1294.84 1907.99C1293.19 1909.59 1290.85 1910.38 1287.83 1910.38L1287.86 1910.35Z'
        fill='black'
      />
      <path
        d='M1307.34 1910.01L1315.67 1893.58L1315.65 1894.4C1315.26 1895.11 1314.67 1895.63 1313.9 1895.95C1313.13 1896.27 1312.23 1896.43 1311.22 1896.43C1308.84 1896.43 1306.92 1895.55 1305.46 1893.78C1304 1892.01 1303.28 1889.67 1303.28 1886.74V1886.72C1303.28 1883.5 1304.09 1881.01 1305.7 1879.25C1307.32 1877.48 1309.59 1876.6 1312.52 1876.6C1315.45 1876.6 1317.72 1877.49 1319.32 1879.27C1320.93 1881.05 1321.73 1883.57 1321.73 1886.84V1886.86C1321.73 1888.21 1321.53 1889.67 1321.14 1891.24C1320.75 1892.81 1320.17 1894.35 1319.41 1895.85L1312.36 1910.03H1307.35L1307.34 1910.01ZM1312.51 1892.19C1313.97 1892.19 1315.09 1891.7 1315.89 1890.71C1316.69 1889.72 1317.08 1888.34 1317.08 1886.55V1886.53C1317.08 1884.79 1316.68 1883.44 1315.89 1882.48C1315.09 1881.52 1313.97 1881.05 1312.51 1881.05C1311.05 1881.05 1309.9 1881.53 1309.11 1882.48C1308.31 1883.44 1307.92 1884.79 1307.92 1886.55V1886.57C1307.92 1888.36 1308.32 1889.74 1309.11 1890.72C1309.91 1891.7 1311.04 1892.19 1312.51 1892.19Z'
        fill='black'
      />
      {/* {Booth N0 39} */}
      <path
        d='M1340.33 1857.38H1252.85V1956.15H1340.33V1857.38Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2163)} 
        className={eid === 2163 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
       <text x="1270" y="1910" className='svgText'>39</text>
   
      {/* {Booth N0 40} */}
      <path
        d='M1437.46 1804.92H1340.33V1956.15H1437.46V1804.92Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2164)} 
        className={eid === 2164 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1375.98 1904.93V1900.9L1387.08 1876.94H1391.86L1381.03 1900.63H1397.25V1904.93H1375.98ZM1390.25 1910.03V1890.55H1394.78V1910.03H1390.25Z'
        fill='black'
      />
      <path
        d='M1412.39 1910.35C1409.23 1910.35 1406.87 1909.51 1405.3 1907.81C1403.73 1906.11 1402.95 1903.83 1402.95 1900.95V1885.91C1402.95 1882.98 1403.75 1880.68 1405.34 1879.02C1406.93 1877.35 1409.28 1876.52 1412.39 1876.52C1415.5 1876.52 1417.87 1877.35 1419.46 1879.01C1421.04 1880.67 1421.84 1882.97 1421.84 1885.92V1900.96C1421.84 1903.89 1421.05 1906.19 1419.46 1907.85C1417.87 1909.52 1415.52 1910.35 1412.39 1910.35ZM1412.39 1905.94C1414.09 1905.94 1415.31 1905.5 1416.06 1904.61C1416.81 1903.72 1417.19 1902.51 1417.19 1900.96V1885.92C1417.19 1884.36 1416.81 1883.14 1416.06 1882.26C1415.31 1881.38 1414.08 1880.94 1412.39 1880.94C1410.7 1880.94 1409.47 1881.38 1408.72 1882.26C1407.97 1883.14 1407.59 1884.36 1407.59 1885.92V1900.96C1407.59 1902.51 1407.97 1903.72 1408.72 1904.61C1409.47 1905.5 1410.7 1905.94 1412.39 1905.94Z'
        fill='black'
      />
      {/* {Booth N0 41} */}
      <path
        d='M1437.46 1721.85H1340.33V1804.92H1437.46V1721.85Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2165)} 
        className={eid === 2165 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}     

      />
      <path
        d='M1375.98 1774.19V1770.16L1387.08 1746.2H1391.86L1381.03 1769.89H1397.25V1774.19H1375.98ZM1390.25 1779.29V1759.81H1394.78V1779.29H1390.25Z'
        fill='black'
      />
      <path
        d='M1409.79 1746.18V1779.26H1405.15V1751.23L1400.44 1754.12V1749.32L1405.15 1746.18H1409.79Z'
        fill='black'
      />
      <path
        d='M1195.2 1746.05V1742.02L1206.3 1718.06H1211.08L1200.25 1741.75H1216.47V1746.05H1195.19H1195.2ZM1209.47 1751.15V1731.67H1214V1751.15H1209.47Z'
        fill='black'
      />
      <path
        d='M1221.66 1751.13V1747.1L1233.2 1731.72C1233.87 1730.84 1234.39 1729.93 1234.76 1728.99C1235.13 1728.05 1235.32 1727.16 1235.32 1726.33V1726.28C1235.32 1724.96 1234.93 1723.94 1234.16 1723.21C1233.39 1722.48 1232.29 1722.12 1230.86 1722.12C1229.53 1722.12 1228.44 1722.52 1227.6 1723.31C1226.76 1724.11 1226.25 1725.23 1226.06 1726.69V1726.71H1221.26V1726.69C1221.67 1723.84 1222.71 1721.63 1224.38 1720.06C1226.05 1718.49 1228.19 1717.7 1230.82 1717.7C1233.79 1717.7 1236.09 1718.45 1237.71 1719.95C1239.33 1721.45 1240.15 1723.58 1240.15 1726.32V1726.34C1240.15 1727.51 1239.91 1728.73 1239.42 1730.01C1238.93 1731.29 1238.26 1732.52 1237.39 1733.68L1227.81 1746.7H1240.32V1751.11H1221.66V1751.13Z'
        fill='black'
      />
      {/* {Booth N0 42} */}
      <path
        d='M1266.22 1689.29H1164.69V1777.92H1266.22V1689.29Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2166)} 
        className={eid === 2166 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
        <text x="1190" y="1750" className='svgText'>42</text>
    
      {/* {Booth No 43} */}
      <path
        d='M1437.46 1635.83H1340.33V1721.85H1437.46V1635.83Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2167)} 
        className={eid === 2167 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1375.98 1688.37V1684.34L1387.08 1660.38H1391.86L1381.03 1684.07H1397.25V1688.37H1375.98ZM1390.25 1693.47V1673.99H1394.78V1693.47H1390.25Z'
        fill='black'
      />
      <path
        d='M1411.5 1693.79C1409.63 1693.79 1408 1693.45 1406.6 1692.78C1405.2 1692.11 1404.07 1691.13 1403.22 1689.86C1402.37 1688.59 1401.82 1687.04 1401.58 1685.22H1406.31C1406.52 1686.65 1407.08 1687.69 1407.99 1688.35C1408.9 1689.01 1410.07 1689.34 1411.49 1689.34C1413.02 1689.34 1414.21 1688.93 1415.06 1688.11C1415.91 1687.29 1416.33 1686.14 1416.33 1684.65V1683.65C1416.33 1682 1415.93 1680.72 1415.12 1679.82C1414.31 1678.92 1413.18 1678.47 1411.71 1678.47H1409.37V1674.06H1411.71C1413.01 1674.06 1414.02 1673.67 1414.73 1672.9C1415.44 1672.13 1415.79 1671.03 1415.79 1669.62V1668.6C1415.79 1667.3 1415.41 1666.29 1414.66 1665.57C1413.91 1664.86 1412.84 1664.5 1411.46 1664.5C1410.29 1664.5 1409.3 1664.83 1408.49 1665.49C1407.68 1666.15 1407.12 1667.19 1406.82 1668.62H1402.11C1402.58 1665.89 1403.63 1663.78 1405.26 1662.28C1406.89 1660.79 1408.96 1660.04 1411.46 1660.04C1414.31 1660.04 1416.52 1660.79 1418.08 1662.29C1419.64 1663.79 1420.42 1665.91 1420.42 1668.64V1669.19C1420.42 1670.84 1419.97 1672.28 1419.08 1673.5C1418.19 1674.72 1416.92 1675.62 1415.28 1676.2C1417.09 1676.58 1418.49 1677.46 1419.48 1678.85C1420.47 1680.24 1420.97 1682 1420.97 1684.14V1684.69C1420.97 1687.59 1420.14 1689.83 1418.49 1691.43C1416.84 1693.02 1414.5 1693.82 1411.48 1693.82L1411.5 1693.79Z'
        fill='black'
      />
      <path
        d='M1195.2 1657.42V1653.39L1206.3 1629.43H1211.08L1200.25 1653.12H1216.47V1657.42H1195.19H1195.2ZM1209.47 1662.52V1643.04H1214V1662.52H1209.47Z'
        fill='black'
      />
      <path
        d='M1221.71 1657.42V1653.39L1232.81 1629.43H1237.59L1226.76 1653.12H1242.98V1657.42H1221.7H1221.71ZM1235.97 1662.52V1643.04H1240.5V1662.52H1235.97Z'
        fill='black'
      />
      {/* {Booth N0 44} */}
      <path
        d='M1266.22 1604.41H1164.69V1689.29H1266.22V1604.41Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2168)} 
        className={eid === 2168 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="1189" y="1659" className='svgText'>44</text>
    
      <path
        d='M1375.98 1603.88V1599.85L1387.08 1575.89H1391.86L1381.03 1599.58H1397.25V1603.88H1375.98ZM1390.25 1608.97V1589.49H1394.78V1608.97H1390.25Z'
        fill='black'
      />
      <path
        d='M1412.18 1609.29C1409.66 1609.29 1407.6 1608.57 1405.98 1607.12C1404.36 1605.67 1403.35 1603.63 1402.94 1600.99V1600.97H1407.58V1600.99C1407.75 1602.2 1408.23 1603.15 1409.04 1603.82C1409.84 1604.49 1410.89 1604.83 1412.18 1604.83C1413.67 1604.83 1414.82 1604.33 1415.63 1603.34C1416.44 1602.35 1416.85 1600.95 1416.85 1599.14V1596.36C1416.85 1594.56 1416.44 1593.16 1415.63 1592.17C1414.82 1591.18 1413.67 1590.69 1412.18 1590.69C1411.38 1590.69 1410.6 1590.92 1409.86 1591.37C1409.12 1591.83 1408.46 1592.46 1407.9 1593.28H1403.67V1575.85H1420.53V1580.26H1408.31V1587.7C1408.89 1587.23 1409.53 1586.87 1410.24 1586.61C1410.95 1586.35 1411.68 1586.22 1412.42 1586.22C1415.3 1586.22 1417.53 1587.1 1419.11 1588.87C1420.69 1590.64 1421.48 1593.13 1421.48 1596.34V1599.12C1421.48 1602.35 1420.67 1604.85 1419.05 1606.62C1417.43 1608.39 1415.14 1609.27 1412.18 1609.27V1609.29Z'
        fill='black'
      />
      {/* {Booth N0 45} */}
      <path
        d='M1437.46 1552.87H1340.33V1635.83H1437.46V1552.87Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2167)} 
        className={eid === 2167 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="1370" y="1610" className='svgText'>45</text>
    
      {/* {Booth No 46} */}
      <path
        d='M1340.33 1517.05H1320.9V1588.75H1340.33V1517.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2169)} 
        className={eid === 2169 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1293.54 1517.05H1266.22V1633.75H1293.54V1517.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
       
      />
      {/* {Booth No 46} */}
      <path
        d='M1437.46 1467.58H1340.33V1552.87H1437.46V1467.58Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2169)} 
        className={eid === 2169 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1285.55 1563.48V1559.45L1296.65 1535.49H1301.43L1290.6 1559.18H1306.82V1563.48H1285.55ZM1299.82 1568.58V1549.1H1304.35V1568.58H1299.82Z'
        fill='black'
      />
      <path
        d='M1321.05 1568.89C1318.12 1568.89 1315.85 1568.02 1314.25 1566.27C1312.64 1564.53 1311.84 1562.07 1311.84 1558.9V1558.88C1311.84 1557.45 1312.04 1555.94 1312.43 1554.34C1312.82 1552.74 1313.39 1551.2 1314.14 1549.71L1321.33 1535.47H1326.34L1317.9 1551.97L1317.92 1551.15C1318.71 1549.65 1320.11 1548.9 1322.13 1548.9C1324.72 1548.9 1326.73 1549.76 1328.16 1551.48C1329.58 1553.2 1330.3 1555.63 1330.3 1558.77V1558.79C1330.3 1561.99 1329.5 1564.48 1327.89 1566.24C1326.28 1568.01 1324.01 1568.89 1321.06 1568.89H1321.05ZM1321.05 1564.43C1322.54 1564.43 1323.67 1563.97 1324.46 1563.06C1325.25 1562.15 1325.64 1560.85 1325.64 1559.17V1559.15C1325.64 1557.3 1325.22 1555.87 1324.39 1554.86C1323.56 1553.85 1322.36 1553.35 1320.82 1553.35C1319.44 1553.35 1318.37 1553.86 1317.61 1554.87C1316.85 1555.89 1316.47 1557.32 1316.47 1559.17V1559.19C1316.47 1560.87 1316.87 1562.17 1317.66 1563.07C1318.46 1563.97 1319.58 1564.42 1321.04 1564.42L1321.05 1564.43Z'
        fill='black'
      />
      <path
        d='M1375.98 1518.03V1514L1387.08 1490.04H1391.86L1381.03 1513.73H1397.25V1518.03H1375.98ZM1390.25 1523.13V1503.65H1394.78V1523.13H1390.25Z'
        fill='black'
      />
      <path
        d='M1411.48 1523.45C1408.55 1523.45 1406.28 1522.58 1404.68 1520.83C1403.07 1519.09 1402.27 1516.63 1402.27 1513.46V1513.44C1402.27 1512.01 1402.47 1510.5 1402.86 1508.9C1403.25 1507.3 1403.82 1505.76 1404.57 1504.27L1411.76 1490.03H1416.77L1408.33 1506.53L1408.35 1505.71C1409.14 1504.21 1410.54 1503.46 1412.56 1503.46C1415.15 1503.46 1417.16 1504.32 1418.59 1506.04C1420.01 1507.76 1420.73 1510.19 1420.73 1513.33V1513.35C1420.73 1516.55 1419.93 1519.04 1418.32 1520.8C1416.71 1522.57 1414.44 1523.45 1411.49 1523.45H1411.48ZM1411.48 1518.99C1412.97 1518.99 1414.1 1518.53 1414.89 1517.62C1415.68 1516.71 1416.07 1515.41 1416.07 1513.73V1513.71C1416.07 1511.86 1415.65 1510.43 1414.82 1509.42C1413.99 1508.41 1412.79 1507.91 1411.25 1507.91C1409.87 1507.91 1408.8 1508.42 1408.04 1509.43C1407.28 1510.45 1406.9 1511.88 1406.9 1513.73V1513.75C1406.9 1515.43 1407.3 1516.73 1408.09 1517.63C1408.89 1518.53 1410.01 1518.98 1411.47 1518.98L1411.48 1518.99Z'
        fill='black'
      />
      {/* {Booth N0 47} */}
      <path
        d='M1437.45 1368.65H1266.51V1467.57H1437.45V1368.65Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2170)} 
        className={eid === 2170 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1330.02 1427.99V1423.96L1341.12 1400H1345.9L1335.07 1423.69H1351.29V1427.99H1330.02ZM1344.29 1433.09V1413.61H1348.82V1433.09H1344.29Z'
        fill='black'
      />
      <path
        d='M1374.08 1399.98V1404.12L1365.52 1433.06H1360.58L1369.14 1404.39H1360.72V1409.15H1356.08V1399.98H1374.08Z'
        fill='black'
      />
      <path
        d='M1006.07 1507.41V1503.38L1017.17 1479.42H1021.95L1011.12 1503.11H1027.34V1507.41H1006.07ZM1020.34 1512.5V1493.02H1024.87V1512.5H1020.34Z'
        fill='black'
      />
      <path
        d='M1042.73 1512.82C1040.71 1512.82 1038.95 1512.44 1037.45 1511.68C1035.95 1510.92 1034.78 1509.85 1033.95 1508.47C1033.12 1507.09 1032.7 1505.47 1032.7 1503.62V1503.07C1032.7 1501.42 1033.11 1499.86 1033.94 1498.41C1034.77 1496.95 1035.87 1495.85 1037.25 1495.11C1036.1 1494.47 1035.16 1493.55 1034.45 1492.35C1033.74 1491.15 1033.38 1489.87 1033.38 1488.54V1487.74C1033.38 1485.12 1034.23 1483.01 1035.94 1481.43C1037.65 1479.85 1039.91 1479.05 1042.73 1479.05C1045.55 1479.05 1047.82 1479.84 1049.52 1481.43C1051.23 1483.02 1052.08 1485.12 1052.08 1487.74V1488.54C1052.08 1489.91 1051.72 1491.19 1051 1492.39C1050.28 1493.59 1049.33 1494.5 1048.14 1495.12C1049.54 1495.86 1050.65 1496.96 1051.5 1498.42C1052.34 1499.88 1052.76 1501.43 1052.76 1503.08V1503.63C1052.76 1505.48 1052.34 1507.1 1051.51 1508.48C1050.68 1509.86 1049.51 1510.93 1048.01 1511.69C1046.51 1512.45 1044.75 1512.83 1042.73 1512.83V1512.82ZM1042.73 1508.36C1044.35 1508.36 1045.66 1507.89 1046.66 1506.96C1047.65 1506.03 1048.15 1504.8 1048.15 1503.29V1502.97C1048.15 1501.44 1047.65 1500.21 1046.66 1499.27C1045.67 1498.34 1044.36 1497.87 1042.73 1497.87C1041.1 1497.87 1039.8 1498.34 1038.81 1499.27C1037.82 1500.2 1037.32 1501.44 1037.32 1502.99V1503.33C1037.32 1504.85 1037.82 1506.06 1038.81 1506.98C1039.8 1507.9 1041.11 1508.36 1042.73 1508.36ZM1042.73 1493.42C1044.14 1493.42 1045.28 1492.97 1046.14 1492.08C1047.01 1491.19 1047.44 1490 1047.44 1488.53V1488.21C1047.44 1486.8 1047.01 1485.67 1046.14 1484.81C1045.28 1483.95 1044.14 1483.52 1042.73 1483.52C1041.32 1483.52 1040.18 1483.95 1039.32 1484.81C1038.46 1485.67 1038.02 1486.81 1038.02 1488.23V1488.57C1038.02 1490.03 1038.45 1491.2 1039.32 1492.09C1040.19 1492.98 1041.32 1493.42 1042.73 1493.42Z'
        fill='black'
      />
      {/* {Booth N0 48} */}
      <path
        d='M1072.43 1517.05H982.93V1547.72H1072.43V1517.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2171)} 
        className={eid === 2171 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />

      <path
        d='M1201.53 1427.99V1423.96L1212.63 1400H1217.41L1206.58 1423.69H1222.8V1427.99H1201.53ZM1215.8 1433.09V1413.61H1220.33V1433.09H1215.8Z'
        fill='black'
      />
      <path
        d='M1238.19 1433.41C1236.17 1433.41 1234.41 1433.03 1232.91 1432.27C1231.41 1431.51 1230.24 1430.44 1229.41 1429.06C1228.58 1427.68 1228.16 1426.06 1228.16 1424.21V1423.66C1228.16 1422.01 1228.57 1420.45 1229.4 1419C1230.23 1417.54 1231.33 1416.44 1232.71 1415.7C1231.56 1415.06 1230.62 1414.14 1229.91 1412.94C1229.2 1411.74 1228.84 1410.46 1228.84 1409.13V1408.33C1228.84 1405.71 1229.69 1403.6 1231.4 1402.02C1233.11 1400.44 1235.37 1399.64 1238.19 1399.64C1241.01 1399.64 1243.28 1400.43 1244.98 1402.02C1246.69 1403.61 1247.54 1405.71 1247.54 1408.33V1409.13C1247.54 1410.5 1247.18 1411.78 1246.46 1412.98C1245.74 1414.18 1244.79 1415.09 1243.6 1415.71C1245 1416.45 1246.11 1417.55 1246.96 1419.01C1247.8 1420.47 1248.22 1422.02 1248.22 1423.67V1424.22C1248.22 1426.07 1247.8 1427.69 1246.97 1429.07C1246.14 1430.45 1244.97 1431.52 1243.47 1432.28C1241.97 1433.04 1240.21 1433.42 1238.19 1433.42V1433.41ZM1238.19 1428.95C1239.81 1428.95 1241.12 1428.48 1242.12 1427.55C1243.11 1426.62 1243.61 1425.39 1243.61 1423.88V1423.56C1243.61 1422.03 1243.11 1420.8 1242.12 1419.86C1241.13 1418.93 1239.82 1418.46 1238.19 1418.46C1236.56 1418.46 1235.26 1418.93 1234.27 1419.86C1233.28 1420.79 1232.78 1422.03 1232.78 1423.58V1423.92C1232.78 1425.44 1233.28 1426.65 1234.27 1427.57C1235.26 1428.49 1236.57 1428.95 1238.19 1428.95ZM1238.19 1414C1239.6 1414 1240.74 1413.55 1241.6 1412.66C1242.47 1411.77 1242.9 1410.58 1242.9 1409.11V1408.79C1242.9 1407.38 1242.47 1406.25 1241.6 1405.39C1240.74 1404.53 1239.6 1404.1 1238.19 1404.1C1236.78 1404.1 1235.64 1404.53 1234.78 1405.39C1233.92 1406.25 1233.48 1407.39 1233.48 1408.81V1409.15C1233.48 1410.61 1233.91 1411.78 1234.78 1412.67C1235.65 1413.56 1236.78 1414 1238.19 1414Z'
        fill='black'
      />

     {/* {Booth N0 48} */}
      <path
        d='M1266.51 1385.75H1179.76V1467.57H1266.51V1385.75Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2171)} 
        className={eid === 2171 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="1200" y="1430" className='svgText'>48</text>

      {/* {Booth N0 49} */}
      <path
        d='M1266.22 1517.05H1164.69V1604.41H1266.22V1517.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2172)} 
        className={eid === 2172 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1195.2 1572.69V1568.66L1206.3 1544.7H1211.08L1200.25 1568.39H1216.47V1572.69H1195.19H1195.2ZM1209.47 1577.79V1558.31H1214V1577.79H1209.47Z'
        fill='black'
      />
      <path
        d='M1225.55 1577.76L1233.88 1561.33L1233.86 1562.15C1233.47 1562.86 1232.88 1563.38 1232.11 1563.7C1231.34 1564.02 1230.44 1564.18 1229.42 1564.18C1227.04 1564.18 1225.12 1563.3 1223.66 1561.53C1222.2 1559.76 1221.48 1557.42 1221.48 1554.49V1554.47C1221.48 1551.25 1222.29 1548.76 1223.9 1547C1225.51 1545.24 1227.79 1544.35 1230.71 1544.35C1233.63 1544.35 1235.91 1545.24 1237.51 1547.02C1239.12 1548.8 1239.92 1551.32 1239.92 1554.59V1554.61C1239.92 1555.96 1239.72 1557.42 1239.33 1558.99C1238.94 1560.56 1238.36 1562.1 1237.6 1563.6L1230.55 1577.78H1225.54L1225.55 1577.76ZM1230.72 1559.95C1232.18 1559.95 1233.3 1559.46 1234.1 1558.47C1234.9 1557.48 1235.3 1556.1 1235.3 1554.31V1554.29C1235.3 1552.55 1234.9 1551.2 1234.1 1550.24C1233.3 1549.28 1232.18 1548.81 1230.72 1548.81C1229.26 1548.81 1228.11 1549.29 1227.32 1550.24C1226.52 1551.2 1226.13 1552.55 1226.13 1554.31V1554.33C1226.13 1556.12 1226.53 1557.5 1227.32 1558.48C1228.12 1559.46 1229.25 1559.95 1230.72 1559.95Z'
        fill='black'
      />
      <path
        d='M1108.66 1578.1C1106.14 1578.1 1104.08 1577.38 1102.46 1575.93C1100.84 1574.48 1099.83 1572.44 1099.42 1569.8V1569.78H1104.06V1569.8C1104.23 1571.01 1104.71 1571.96 1105.52 1572.63C1106.32 1573.3 1107.37 1573.64 1108.66 1573.64C1110.15 1573.64 1111.3 1573.14 1112.11 1572.15C1112.92 1571.16 1113.33 1569.76 1113.33 1567.95V1565.17C1113.33 1563.37 1112.92 1561.97 1112.11 1560.98C1111.3 1559.99 1110.15 1559.5 1108.66 1559.5C1107.86 1559.5 1107.08 1559.73 1106.34 1560.18C1105.6 1560.63 1104.94 1561.27 1104.38 1562.09H1100.15V1544.66H1117.01V1549.07H1104.79V1556.51C1105.37 1556.04 1106.01 1555.68 1106.72 1555.42C1107.43 1555.16 1108.16 1555.03 1108.9 1555.03C1111.78 1555.03 1114.01 1555.91 1115.59 1557.68C1117.17 1559.45 1117.96 1561.94 1117.96 1565.15V1567.93C1117.96 1571.16 1117.15 1573.66 1115.53 1575.43C1113.91 1577.2 1111.62 1578.08 1108.66 1578.08V1578.1Z'
        fill='black'
      />
      <path
        d='M1134.23 1578.1C1131.07 1578.1 1128.71 1577.26 1127.14 1575.56C1125.57 1573.86 1124.78 1571.58 1124.78 1568.7V1553.66C1124.78 1550.73 1125.58 1548.43 1127.17 1546.77C1128.76 1545.1 1131.11 1544.27 1134.22 1544.27C1137.33 1544.27 1139.7 1545.1 1141.29 1546.76C1142.87 1548.42 1143.67 1550.72 1143.67 1553.67V1568.71C1143.67 1571.64 1142.88 1573.94 1141.29 1575.6C1139.7 1577.27 1137.35 1578.1 1134.22 1578.1H1134.23ZM1134.23 1573.69C1135.93 1573.69 1137.15 1573.25 1137.9 1572.36C1138.65 1571.47 1139.03 1570.26 1139.03 1568.71V1553.67C1139.03 1552.11 1138.65 1550.89 1137.9 1550.01C1137.15 1549.13 1135.92 1548.69 1134.23 1548.69C1132.54 1548.69 1131.31 1549.13 1130.56 1550.01C1129.81 1550.89 1129.43 1552.11 1129.43 1553.67V1568.71C1129.43 1570.26 1129.81 1571.47 1130.56 1572.36C1131.31 1573.25 1132.54 1573.69 1134.23 1573.69Z'
        fill='black'
      />
      {/* {Booth N0 50} */}
      <path
        d='M1164.69 1517.05H1072.43V1604.41H1164.69V1517.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2173)} 
        className={eid === 2173 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="1103" y="1570" className='svgText'>50</text>

      <path
        d='M1124.47 1433.41C1121.95 1433.41 1119.89 1432.69 1118.27 1431.24C1116.65 1429.79 1115.64 1427.75 1115.23 1425.11V1425.09H1119.87V1425.11C1120.04 1426.32 1120.52 1427.27 1121.33 1427.94C1122.13 1428.61 1123.18 1428.95 1124.47 1428.95C1125.96 1428.95 1127.11 1428.45 1127.92 1427.46C1128.73 1426.47 1129.14 1425.07 1129.14 1423.26V1420.48C1129.14 1418.68 1128.73 1417.28 1127.92 1416.29C1127.11 1415.3 1125.96 1414.81 1124.47 1414.81C1123.67 1414.81 1122.89 1415.04 1122.15 1415.49C1121.41 1415.94 1120.75 1416.58 1120.19 1417.4H1115.96V1399.97H1132.82V1404.38H1120.6V1411.82C1121.18 1411.35 1121.82 1410.99 1122.53 1410.73C1123.24 1410.47 1123.97 1410.34 1124.71 1410.34C1127.59 1410.34 1129.82 1411.22 1131.4 1412.99C1132.98 1414.76 1133.77 1417.25 1133.77 1420.46V1423.24C1133.77 1426.47 1132.96 1428.97 1131.34 1430.74C1129.72 1432.51 1127.43 1433.39 1124.47 1433.39V1433.41Z'
        fill='black'
      />
      <path
        d='M1148.59 1399.98V1433.06H1143.95V1405.03L1139.24 1407.92V1403.12L1143.95 1399.98H1148.59Z'
        fill='black'
      />
      {/* {Booth No 51} */}
      <path
        d='M1179.76 1385.75H1093.01V1467.57H1179.76V1385.75Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2174)} 
        className={eid === 2174 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
          <text x="1110" y="1430" className='svgText'>51</text>
    
      <path
        d='M1037.72 1433.41C1035.2 1433.41 1033.14 1432.69 1031.52 1431.24C1029.9 1429.79 1028.89 1427.75 1028.48 1425.11V1425.09H1033.12V1425.11C1033.29 1426.32 1033.77 1427.27 1034.58 1427.94C1035.38 1428.61 1036.43 1428.95 1037.72 1428.95C1039.21 1428.95 1040.36 1428.45 1041.17 1427.46C1041.98 1426.47 1042.39 1425.07 1042.39 1423.26V1420.48C1042.39 1418.68 1041.98 1417.28 1041.17 1416.29C1040.36 1415.3 1039.21 1414.81 1037.72 1414.81C1036.92 1414.81 1036.14 1415.04 1035.4 1415.49C1034.66 1415.94 1034 1416.58 1033.44 1417.4H1029.21V1399.97H1046.07V1404.38H1033.85V1411.82C1034.43 1411.35 1035.07 1410.99 1035.78 1410.73C1036.49 1410.47 1037.22 1410.34 1037.96 1410.34C1040.84 1410.34 1043.07 1411.22 1044.65 1412.99C1046.23 1414.76 1047.02 1417.25 1047.02 1420.46V1423.24C1047.02 1426.47 1046.21 1428.97 1044.59 1430.74C1042.97 1432.51 1040.68 1433.39 1037.72 1433.39V1433.41Z'
        fill='black'
      />
      <path
        d='M1053.35 1433.06V1429.03L1064.89 1413.65C1065.56 1412.77 1066.08 1411.86 1066.45 1410.92C1066.82 1409.98 1067.01 1409.09 1067.01 1408.26V1408.21C1067.01 1406.89 1066.62 1405.87 1065.85 1405.14C1065.08 1404.41 1063.98 1404.05 1062.55 1404.05C1061.22 1404.05 1060.13 1404.45 1059.29 1405.25C1058.45 1406.05 1057.94 1407.17 1057.75 1408.63V1408.65H1052.95V1408.63C1053.36 1405.78 1054.4 1403.57 1056.07 1402C1057.74 1400.43 1059.88 1399.64 1062.51 1399.64C1065.48 1399.64 1067.78 1400.39 1069.4 1401.89C1071.02 1403.39 1071.84 1405.52 1071.84 1408.26V1408.28C1071.84 1409.45 1071.6 1410.67 1071.11 1411.95C1070.62 1413.23 1069.95 1414.46 1069.08 1415.62L1059.5 1428.64H1072.01V1433.05H1053.35V1433.06Z'
        fill='black'
      />
      {/* {Booth N0 52} */}
      <path
        d='M1093.01 1385.75H1008.85V1467.57H1093.01V1385.75Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2175)} 
        className={eid === 2175 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="1025" y="1430" className='svgText'>52</text>
    
      {/* {Booth N0 53} */}
      <path
        d='M1008.85 1385.75H923.109V1467.57H1008.85V1385.75Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2176)} 
        className={eid === 2176 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M953.56 1433.41C951.04 1433.41 948.98 1432.69 947.36 1431.24C945.74 1429.79 944.73 1427.75 944.32 1425.11V1425.09H948.96V1425.11C949.13 1426.32 949.61 1427.27 950.42 1427.94C951.22 1428.61 952.27 1428.95 953.56 1428.95C955.05 1428.95 956.2 1428.45 957.01 1427.46C957.82 1426.47 958.23 1425.07 958.23 1423.26V1420.48C958.23 1418.68 957.82 1417.28 957.01 1416.29C956.2 1415.3 955.05 1414.81 953.56 1414.81C952.76 1414.81 951.98 1415.04 951.24 1415.49C950.5 1415.94 949.84 1416.58 949.28 1417.4H945.05V1399.97H961.91V1404.38H949.69V1411.82C950.27 1411.35 950.91 1410.99 951.62 1410.73C952.33 1410.47 953.06 1410.34 953.8 1410.34C956.68 1410.34 958.91 1411.22 960.49 1412.99C962.07 1414.76 962.86 1417.25 962.86 1420.46V1423.24C962.86 1426.47 962.05 1428.97 960.43 1430.74C958.81 1432.51 956.52 1433.39 953.56 1433.39V1433.41Z'
        fill='black'
      />
      <path
        d='M978.24 1433.41C976.37 1433.41 974.74 1433.07 973.34 1432.4C971.94 1431.73 970.81 1430.75 969.96 1429.48C969.11 1428.21 968.56 1426.66 968.32 1424.84H973.05C973.26 1426.27 973.82 1427.31 974.73 1427.97C975.64 1428.63 976.81 1428.96 978.23 1428.96C979.76 1428.96 980.95 1428.55 981.8 1427.73C982.65 1426.91 983.07 1425.76 983.07 1424.27V1423.27C983.07 1421.62 982.67 1420.34 981.86 1419.44C981.05 1418.54 979.92 1418.09 978.45 1418.09H976.11V1413.68H978.45C979.75 1413.68 980.76 1413.29 981.47 1412.52C982.18 1411.75 982.53 1410.65 982.53 1409.24V1408.22C982.53 1406.92 982.15 1405.91 981.4 1405.19C980.65 1404.48 979.58 1404.12 978.2 1404.12C977.03 1404.12 976.04 1404.45 975.23 1405.11C974.42 1405.77 973.86 1406.81 973.56 1408.24H968.85C969.32 1405.51 970.37 1403.4 972 1401.9C973.63 1400.41 975.7 1399.66 978.2 1399.66C981.05 1399.66 983.26 1400.41 984.82 1401.91C986.38 1403.41 987.16 1405.53 987.16 1408.26V1408.81C987.16 1410.46 986.71 1411.9 985.82 1413.12C984.92 1414.34 983.66 1415.24 982.02 1415.82C983.83 1416.2 985.23 1417.08 986.22 1418.47C987.21 1419.86 987.71 1421.62 987.71 1423.76V1424.31C987.71 1427.21 986.88 1429.45 985.23 1431.05C983.58 1432.64 981.24 1433.44 978.22 1433.44L978.24 1433.41Z'
        fill='black'
      />
      {/* {Booth N0 55} */}
      <path
        d='M1164.69 1604.41H1072.43V1690.53H1164.69V1604.41Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2177)} 
        className={eid === 2177 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M1108.66 1665.46C1106.14 1665.46 1104.08 1664.74 1102.46 1663.29C1100.84 1661.84 1099.83 1659.8 1099.42 1657.16V1657.14H1104.06V1657.16C1104.23 1658.37 1104.71 1659.32 1105.52 1659.99C1106.32 1660.66 1107.37 1661 1108.66 1661C1110.15 1661 1111.3 1660.5 1112.11 1659.51C1112.92 1658.52 1113.33 1657.12 1113.33 1655.31V1652.53C1113.33 1650.73 1112.92 1649.33 1112.11 1648.34C1111.3 1647.35 1110.15 1646.86 1108.66 1646.86C1107.86 1646.86 1107.08 1647.09 1106.34 1647.54C1105.6 1647.99 1104.94 1648.63 1104.38 1649.45H1100.15V1632.02H1117.01V1636.43H1104.79V1643.87C1105.37 1643.4 1106.01 1643.04 1106.72 1642.78C1107.43 1642.52 1108.16 1642.39 1108.9 1642.39C1111.78 1642.39 1114.01 1643.27 1115.59 1645.04C1117.17 1646.81 1117.96 1649.3 1117.96 1652.51V1655.29C1117.96 1658.52 1117.15 1661.02 1115.53 1662.79C1113.91 1664.56 1111.62 1665.44 1108.66 1665.44V1665.46Z'
        fill='black'
      />
      <path
        d='M1134.03 1665.46C1131.51 1665.46 1129.45 1664.74 1127.83 1663.29C1126.21 1661.84 1125.2 1659.8 1124.79 1657.16V1657.14H1129.43V1657.16C1129.6 1658.37 1130.08 1659.32 1130.89 1659.99C1131.69 1660.66 1132.74 1661 1134.03 1661C1135.52 1661 1136.67 1660.5 1137.48 1659.51C1138.29 1658.52 1138.7 1657.12 1138.7 1655.31V1652.53C1138.7 1650.73 1138.29 1649.33 1137.48 1648.34C1136.67 1647.35 1135.52 1646.86 1134.03 1646.86C1133.23 1646.86 1132.45 1647.09 1131.71 1647.54C1130.97 1648 1130.31 1648.63 1129.75 1649.45H1125.52V1632.02H1142.38V1636.43H1130.16V1643.87C1130.74 1643.4 1131.38 1643.04 1132.09 1642.78C1132.8 1642.52 1133.53 1642.39 1134.27 1642.39C1137.15 1642.39 1139.38 1643.27 1140.96 1645.04C1142.54 1646.81 1143.33 1649.3 1143.33 1652.51V1655.29C1143.33 1658.52 1142.52 1661.02 1140.9 1662.79C1139.28 1664.56 1136.99 1665.44 1134.03 1665.44V1665.46Z'
        fill='black'
      />
      <path
        d='M1108.66 1750.34C1106.14 1750.34 1104.08 1749.62 1102.46 1748.17C1100.84 1746.72 1099.83 1744.68 1099.42 1742.04V1742.02H1104.06V1742.04C1104.23 1743.25 1104.71 1744.2 1105.52 1744.87C1106.32 1745.54 1107.37 1745.88 1108.66 1745.88C1110.15 1745.88 1111.3 1745.38 1112.11 1744.39C1112.92 1743.4 1113.33 1742 1113.33 1740.19V1737.41C1113.33 1735.61 1112.92 1734.21 1112.11 1733.22C1111.3 1732.23 1110.15 1731.74 1108.66 1731.74C1107.86 1731.74 1107.08 1731.97 1106.34 1732.42C1105.6 1732.87 1104.94 1733.51 1104.38 1734.33H1100.15V1716.9H1117.01V1721.31H1104.79V1728.75C1105.37 1728.28 1106.01 1727.92 1106.72 1727.66C1107.43 1727.4 1108.16 1727.27 1108.9 1727.27C1111.78 1727.27 1114.01 1728.15 1115.59 1729.92C1117.17 1731.69 1117.96 1734.18 1117.96 1737.39V1740.17C1117.96 1743.4 1117.15 1745.9 1115.53 1747.67C1113.91 1749.44 1111.62 1750.32 1108.66 1750.32V1750.34Z'
        fill='black'
      />
      <path
        d='M1133.32 1750.34C1130.39 1750.34 1128.12 1749.47 1126.52 1747.72C1124.91 1745.98 1124.11 1743.52 1124.11 1740.35V1740.33C1124.11 1738.91 1124.31 1737.39 1124.7 1735.79C1125.09 1734.19 1125.66 1732.65 1126.41 1731.16L1133.6 1716.92H1138.61L1130.17 1733.42L1130.19 1732.6C1130.98 1731.1 1132.38 1730.35 1134.4 1730.35C1136.99 1730.35 1139 1731.21 1140.43 1732.93C1141.85 1734.65 1142.57 1737.08 1142.57 1740.22V1740.24C1142.57 1743.44 1141.77 1745.93 1140.16 1747.69C1138.55 1749.46 1136.28 1750.34 1133.33 1750.34H1133.32ZM1133.32 1745.88C1134.81 1745.88 1135.94 1745.42 1136.73 1744.51C1137.52 1743.6 1137.91 1742.3 1137.91 1740.62V1740.6C1137.91 1738.75 1137.49 1737.32 1136.66 1736.31C1135.83 1735.3 1134.63 1734.8 1133.09 1734.8C1131.71 1734.8 1130.64 1735.31 1129.88 1736.32C1129.12 1737.34 1128.74 1738.77 1128.74 1740.62V1740.64C1128.74 1742.32 1129.14 1743.62 1129.93 1744.52C1130.73 1745.42 1131.85 1745.87 1133.31 1745.87L1133.32 1745.88Z'
        fill='black'
      />
      {/* {Booth N0 56} */}
      <path
        d='M1164.69 1689.29H1072.43V1777.92H1164.69V1689.29Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2177)} 
        className={eid === 2177 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="1103" y="1750" className='svgText'>56</text>
    
      <path
        d='M608.4 1732.48C605.88 1732.48 603.82 1731.76 602.2 1730.31C600.58 1728.86 599.57 1726.82 599.16 1724.18V1724.16H603.8V1724.18C603.97 1725.39 604.45 1726.34 605.26 1727.01C606.06 1727.68 607.11 1728.02 608.4 1728.02C609.89 1728.02 611.04 1727.52 611.85 1726.53C612.66 1725.54 613.07 1724.14 613.07 1722.33V1719.55C613.07 1717.75 612.66 1716.35 611.85 1715.36C611.04 1714.37 609.89 1713.88 608.4 1713.88C607.6 1713.88 606.82 1714.11 606.08 1714.56C605.34 1715.01 604.68 1715.65 604.12 1716.47H599.89V1699.04H616.75V1703.45H604.53V1710.89C605.11 1710.42 605.75 1710.06 606.46 1709.8C607.17 1709.54 607.9 1709.41 608.64 1709.41C611.52 1709.41 613.75 1710.29 615.33 1712.06C616.91 1713.83 617.7 1716.32 617.7 1719.53V1722.31C617.7 1725.54 616.89 1728.04 615.26 1729.81C613.64 1731.58 611.35 1732.46 608.39 1732.46L608.4 1732.48Z'
        fill='black'
      />
      <path
        d='M641.62 1699.06V1703.2L633.06 1732.14H628.12L636.68 1703.47H628.26V1708.23H623.62V1699.06H641.62Z'
        fill='black'
      />
      {/* {Booth N0 58,57} */}
      <path
        d='M666.97 1601.04H575.49V1757.94H666.97V1601.04Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2178)} 
        className={eid === 2178 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M608.4 1654.04C605.88 1654.04 603.82 1653.32 602.2 1651.87C600.58 1650.42 599.57 1648.38 599.16 1645.74V1645.72H603.8V1645.74C603.97 1646.95 604.45 1647.9 605.26 1648.57C606.06 1649.24 607.11 1649.58 608.4 1649.58C609.89 1649.58 611.04 1649.08 611.85 1648.09C612.66 1647.1 613.07 1645.7 613.07 1643.89V1641.11C613.07 1639.31 612.66 1637.91 611.85 1636.92C611.04 1635.93 609.89 1635.44 608.4 1635.44C607.6 1635.44 606.82 1635.67 606.08 1636.12C605.34 1636.57 604.68 1637.21 604.12 1638.03H599.89V1620.6H616.75V1625.01H604.53V1632.45C605.11 1631.98 605.75 1631.62 606.46 1631.36C607.17 1631.1 607.9 1630.97 608.64 1630.97C611.52 1630.97 613.75 1631.85 615.33 1633.62C616.91 1635.39 617.7 1637.88 617.7 1641.09V1643.87C617.7 1647.1 616.89 1649.6 615.26 1651.37C613.64 1653.14 611.35 1654.02 608.39 1654.02L608.4 1654.04Z'
        fill='black'
      />
      <path
        d='M634.23 1654.04C632.21 1654.04 630.45 1653.66 628.95 1652.9C627.45 1652.14 626.28 1651.07 625.45 1649.69C624.62 1648.31 624.2 1646.69 624.2 1644.84V1644.29C624.2 1642.64 624.61 1641.08 625.44 1639.63C626.27 1638.17 627.37 1637.07 628.75 1636.33C627.6 1635.69 626.66 1634.77 625.95 1633.57C625.24 1632.37 624.88 1631.09 624.88 1629.76V1628.96C624.88 1626.34 625.73 1624.23 627.44 1622.65C629.15 1621.07 631.41 1620.27 634.23 1620.27C637.05 1620.27 639.32 1621.06 641.02 1622.65C642.73 1624.24 643.58 1626.34 643.58 1628.96V1629.76C643.58 1631.13 643.22 1632.41 642.5 1633.61C641.78 1634.81 640.83 1635.72 639.64 1636.34C641.04 1637.08 642.15 1638.18 643 1639.64C643.84 1641.1 644.26 1642.65 644.26 1644.3V1644.85C644.26 1646.7 643.84 1648.32 643.01 1649.7C642.18 1651.08 641.01 1652.15 639.51 1652.91C638.01 1653.67 636.25 1654.05 634.23 1654.05V1654.04ZM634.23 1649.58C635.85 1649.58 637.16 1649.11 638.16 1648.18C639.15 1647.25 639.65 1646.02 639.65 1644.51V1644.19C639.65 1642.66 639.15 1641.43 638.16 1640.49C637.17 1639.56 635.86 1639.09 634.23 1639.09C632.6 1639.09 631.3 1639.56 630.31 1640.49C629.32 1641.42 628.82 1642.66 628.82 1644.21V1644.55C628.82 1646.07 629.32 1647.28 630.31 1648.2C631.3 1649.12 632.61 1649.58 634.23 1649.58ZM634.23 1634.63C635.64 1634.63 636.78 1634.18 637.64 1633.29C638.51 1632.4 638.94 1631.21 638.94 1629.74V1629.42C638.94 1628.01 638.51 1626.88 637.64 1626.02C636.78 1625.16 635.64 1624.73 634.23 1624.73C632.82 1624.73 631.68 1625.16 630.82 1626.02C629.96 1626.88 629.52 1628.02 629.52 1629.44V1629.78C629.52 1631.24 629.95 1632.41 630.82 1633.3C631.69 1634.19 632.82 1634.63 634.23 1634.63Z'
        fill='black'
      />
      <path
        d='M608.4 1575.61C605.88 1575.61 603.82 1574.89 602.2 1573.44C600.58 1571.99 599.57 1569.95 599.16 1567.31V1567.29H603.8V1567.31C603.97 1568.52 604.45 1569.47 605.26 1570.14C606.06 1570.81 607.11 1571.15 608.4 1571.15C609.89 1571.15 611.04 1570.65 611.85 1569.66C612.66 1568.67 613.07 1567.27 613.07 1565.46V1562.68C613.07 1560.88 612.66 1559.48 611.85 1558.49C611.04 1557.5 609.89 1557.01 608.4 1557.01C607.6 1557.01 606.82 1557.24 606.08 1557.69C605.34 1558.14 604.68 1558.78 604.12 1559.6H599.89V1542.17H616.75V1546.58H604.53V1554.02C605.11 1553.55 605.75 1553.19 606.46 1552.93C607.17 1552.67 607.9 1552.54 608.64 1552.54C611.52 1552.54 613.75 1553.42 615.33 1555.19C616.91 1556.96 617.7 1559.45 617.7 1562.66V1565.44C617.7 1568.67 616.89 1571.17 615.26 1572.94C613.64 1574.71 611.35 1575.59 608.39 1575.59L608.4 1575.61Z'
        fill='black'
      />
      <path
        d='M627.921 1575.27L636.251 1558.84L636.231 1559.66C635.841 1560.37 635.251 1560.89 634.481 1561.21C633.711 1561.53 632.811 1561.69 631.791 1561.69C629.411 1561.69 627.491 1560.81 626.031 1559.04C624.571 1557.27 623.851 1554.93 623.851 1552V1551.98C623.851 1548.76 624.661 1546.27 626.271 1544.51C627.881 1542.75 630.161 1541.86 633.081 1541.86C636.001 1541.86 638.281 1542.75 639.881 1544.53C641.491 1546.31 642.291 1548.83 642.291 1552.1V1552.12C642.291 1553.47 642.091 1554.93 641.701 1556.5C641.311 1558.07 640.731 1559.61 639.971 1561.11L632.921 1575.29H627.911L627.921 1575.27ZM633.091 1557.45C634.551 1557.45 635.671 1556.96 636.471 1555.97C637.271 1554.98 637.671 1553.6 637.671 1551.81V1551.79C637.671 1550.05 637.271 1548.7 636.471 1547.74C635.671 1546.78 634.551 1546.31 633.091 1546.31C631.631 1546.31 630.481 1546.79 629.691 1547.74C628.891 1548.7 628.501 1550.05 628.501 1551.81V1551.83C628.501 1553.62 628.901 1555 629.691 1555.98C630.491 1556.96 631.621 1557.45 633.091 1557.45Z'
        fill='black'
      />
      {/* {Booth N0 59} */}
      <path
        d='M666.97 1522.65H575.49V1601.04H666.97V1522.65Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
    
      />
      <path
        d='M607.7 1496.86C604.77 1496.86 602.5 1495.99 600.9 1494.24C599.29 1492.5 598.49 1490.04 598.49 1486.87V1486.85C598.49 1485.43 598.69 1483.91 599.08 1482.31C599.47 1480.71 600.04 1479.17 600.79 1477.68L607.98 1463.44H612.99L604.55 1479.94L604.57 1479.12C605.36 1477.62 606.76 1476.87 608.78 1476.87C611.37 1476.87 613.38 1477.73 614.81 1479.45C616.24 1481.17 616.95 1483.6 616.95 1486.74V1486.76C616.95 1489.96 616.15 1492.45 614.54 1494.21C612.93 1495.98 610.66 1496.86 607.71 1496.86H607.7ZM607.7 1492.4C609.19 1492.4 610.32 1491.94 611.11 1491.03C611.9 1490.12 612.29 1488.82 612.29 1487.14V1487.12C612.29 1485.27 611.87 1483.84 611.04 1482.83C610.21 1481.82 609.01 1481.32 607.47 1481.32C606.09 1481.32 605.02 1481.83 604.26 1482.84C603.5 1483.85 603.12 1485.29 603.12 1487.14V1487.16C603.12 1488.84 603.52 1490.14 604.31 1491.04C605.11 1491.94 606.23 1492.39 607.69 1492.39L607.7 1492.4Z'
        fill='black'
      />
      <path
        d='M632.52 1496.86C629.36 1496.86 627 1496.02 625.43 1494.32C623.86 1492.62 623.07 1490.34 623.07 1487.46V1472.42C623.07 1469.49 623.87 1467.19 625.46 1465.53C627.05 1463.86 629.4 1463.03 632.51 1463.03C635.62 1463.03 637.99 1463.86 639.57 1465.52C641.15 1467.18 641.95 1469.48 641.95 1472.43V1487.47C641.95 1490.4 641.16 1492.7 639.57 1494.36C637.99 1496.03 635.63 1496.86 632.51 1496.86H632.52ZM632.52 1492.45C634.22 1492.45 635.44 1492.01 636.19 1491.12C636.94 1490.23 637.32 1489.02 637.32 1487.47V1472.43C637.32 1470.87 636.94 1469.65 636.19 1468.77C635.44 1467.89 634.21 1467.45 632.52 1467.45C630.83 1467.45 629.6 1467.89 628.85 1468.77C628.1 1469.65 627.72 1470.87 627.72 1472.43V1487.47C627.72 1489.02 628.1 1490.23 628.85 1491.12C629.6 1492.01 630.83 1492.45 632.52 1492.45Z'
        fill='black'
      />
      {/* {Booth N0 60} */}
      <path
        d='M666.97 1443.86H575.49V1522.64H666.97V1443.86Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2180)} 
        className={eid === 2180 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="600" y="1490" className='svgText'>60</text>

      <path
        d='M607.7 1400.77C604.77 1400.77 602.5 1399.9 600.9 1398.15C599.29 1396.41 598.49 1393.95 598.49 1390.78V1390.76C598.49 1389.34 598.69 1387.82 599.08 1386.22C599.47 1384.62 600.04 1383.08 600.79 1381.59L607.98 1367.35H612.99L604.55 1383.85L604.57 1383.03C605.36 1381.53 606.76 1380.78 608.78 1380.78C611.37 1380.78 613.38 1381.64 614.81 1383.36C616.24 1385.08 616.95 1387.51 616.95 1390.65V1390.67C616.95 1393.87 616.15 1396.36 614.54 1398.12C612.93 1399.89 610.66 1400.77 607.71 1400.77H607.7ZM607.7 1396.31C609.19 1396.31 610.32 1395.85 611.11 1394.94C611.9 1394.03 612.29 1392.73 612.29 1391.05V1391.03C612.29 1389.18 611.87 1387.75 611.04 1386.74C610.21 1385.73 609.01 1385.23 607.47 1385.23C606.09 1385.23 605.02 1385.74 604.26 1386.75C603.5 1387.76 603.12 1389.2 603.12 1391.05V1391.07C603.12 1392.75 603.52 1394.05 604.31 1394.95C605.11 1395.85 606.23 1396.3 607.69 1396.3L607.7 1396.31Z'
        fill='black'
      />
      <path
        d='M631.071 1367.35V1400.43H626.431V1372.4L621.721 1375.29V1370.49L626.431 1367.35H631.071Z'
        fill='black'
      />
      {/* {Booth N0 61} */}
      <path
        d='M666.97 1365.55H575.49V1409.47H666.97V1365.55Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      {/* {Booth N0 62} */}
      <path
        d='M666.97 1216.29H484.11V1365.55H666.97V1216.29Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2181)} 
        className={eid === 2181 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M565.721 1305.25C562.791 1305.25 560.521 1304.38 558.921 1302.63C557.311 1300.89 556.511 1298.43 556.511 1295.26V1295.24C556.511 1293.81 556.711 1292.3 557.101 1290.7C557.491 1289.1 558.061 1287.56 558.811 1286.07L566.001 1271.83H571.011L562.571 1288.33L562.591 1287.51C563.381 1286.01 564.781 1285.26 566.801 1285.26C569.391 1285.26 571.401 1286.12 572.831 1287.84C574.261 1289.56 574.971 1291.99 574.971 1295.13V1295.15C574.971 1298.35 574.171 1300.84 572.561 1302.6C570.951 1304.37 568.681 1305.25 565.731 1305.25H565.721ZM565.721 1300.79C567.211 1300.79 568.341 1300.33 569.131 1299.42C569.921 1298.51 570.311 1297.21 570.311 1295.53V1295.51C570.311 1293.66 569.891 1292.23 569.061 1291.22C568.231 1290.21 567.031 1289.71 565.491 1289.71C564.111 1289.71 563.041 1290.22 562.281 1291.23C561.521 1292.24 561.141 1293.68 561.141 1295.53V1295.55C561.141 1297.23 561.541 1298.53 562.331 1299.43C563.131 1300.33 564.251 1300.78 565.711 1300.78L565.721 1300.79Z'
        fill='black'
      />
      <path
        d='M580.6 1304.9V1300.87L592.14 1285.49C592.81 1284.61 593.33 1283.7 593.7 1282.76C594.07 1281.82 594.26 1280.93 594.26 1280.1V1280.05C594.26 1278.73 593.87 1277.71 593.1 1276.98C592.33 1276.25 591.23 1275.89 589.8 1275.89C588.47 1275.89 587.38 1276.29 586.53 1277.09C585.69 1277.89 585.18 1279.01 584.99 1280.47V1280.49H580.19V1280.47C580.6 1277.62 581.64 1275.41 583.31 1273.84C584.98 1272.27 587.13 1271.48 589.75 1271.48C592.72 1271.48 595.02 1272.23 596.64 1273.73C598.26 1275.23 599.07 1277.36 599.07 1280.1V1280.12C599.07 1281.29 598.83 1282.51 598.34 1283.79C597.85 1285.07 597.18 1286.3 596.31 1287.46L586.73 1300.48H599.24V1304.89H580.58L580.6 1304.9Z'
        fill='black'
      />
      <path
        d='M725.44 1192.29C722.51 1192.29 720.24 1191.42 718.64 1189.67C717.03 1187.93 716.229 1185.47 716.229 1182.3V1182.28C716.229 1180.86 716.43 1179.34 716.82 1177.74C717.21 1176.14 717.78 1174.6 718.53 1173.11L725.719 1158.87H730.729L722.289 1175.37L722.31 1174.55C723.1 1173.05 724.5 1172.3 726.52 1172.3C729.11 1172.3 731.119 1173.16 732.549 1174.88C733.979 1176.6 734.69 1179.03 734.69 1182.17V1182.19C734.69 1185.39 733.89 1187.88 732.28 1189.64C730.67 1191.41 728.4 1192.29 725.45 1192.29H725.44ZM725.44 1187.84C726.93 1187.84 728.059 1187.38 728.849 1186.47C729.639 1185.56 730.03 1184.26 730.03 1182.58V1182.56C730.03 1180.71 729.61 1179.28 728.78 1178.27C727.95 1177.26 726.75 1176.76 725.21 1176.76C723.83 1176.76 722.76 1177.27 722 1178.28C721.24 1179.29 720.859 1180.73 720.859 1182.58V1182.6C720.859 1184.28 721.259 1185.58 722.049 1186.48C722.849 1187.38 723.97 1187.83 725.43 1187.83L725.44 1187.84Z'
        fill='black'
      />
      <path
        d='M749.38 1192.29C747.51 1192.29 745.88 1191.95 744.48 1191.28C743.08 1190.61 741.95 1189.63 741.1 1188.36C740.25 1187.09 739.7 1185.54 739.46 1183.72H744.19C744.4 1185.15 744.96 1186.19 745.87 1186.85C746.78 1187.51 747.95 1187.84 749.37 1187.84C750.9 1187.84 752.09 1187.43 752.94 1186.61C753.79 1185.79 754.21 1184.64 754.21 1183.15V1182.15C754.21 1180.5 753.81 1179.22 753 1178.32C752.19 1177.42 751.06 1176.97 749.59 1176.97H747.25V1172.56H749.59C750.89 1172.56 751.9 1172.17 752.6 1171.4C753.31 1170.63 753.66 1169.53 753.66 1168.12V1167.1C753.66 1165.8 753.28 1164.79 752.53 1164.07C751.78 1163.36 750.71 1163 749.33 1163C748.16 1163 747.17 1163.33 746.36 1163.99C745.55 1164.65 744.99 1165.69 744.69 1167.12H739.98C740.45 1164.39 741.5 1162.28 743.13 1160.78C744.76 1159.29 746.83 1158.54 749.33 1158.54C752.18 1158.54 754.39 1159.29 755.95 1160.79C757.51 1162.29 758.29 1164.41 758.29 1167.14V1167.69C758.29 1169.34 757.84 1170.78 756.95 1172C756.06 1173.22 754.79 1174.12 753.15 1174.7C754.96 1175.08 756.35 1175.96 757.35 1177.35C758.34 1178.74 758.84 1180.5 758.84 1182.64V1183.19C758.84 1186.09 758.01 1188.33 756.36 1189.93C754.71 1191.53 752.37 1192.32 749.35 1192.32L749.38 1192.29Z'
        fill='black'
      />
      {/* {Booth N0 63} */}
      <path
        d='M792.85 1115.63H666.97V1239.71H792.85V1115.63Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2182)} 
        className={eid === 2182 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="700" y="1170" className='svgText'>63</text>

      <path
        d='M575.49 1679.5H483.93V1757.93H575.49V1679.5Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2183)} 
        className={eid === 2183 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M516.18 1732.48C513.25 1732.48 510.98 1731.61 509.38 1729.86C507.77 1728.12 506.97 1725.66 506.97 1722.49V1722.47C506.97 1721.04 507.17 1719.53 507.56 1717.93C507.95 1716.33 508.52 1714.79 509.27 1713.3L516.46 1699.06H521.47L513.03 1715.56L513.05 1714.74C513.84 1713.24 515.24 1712.49 517.26 1712.49C519.85 1712.49 521.86 1713.35 523.29 1715.07C524.72 1716.79 525.43 1719.22 525.43 1722.36V1722.38C525.43 1725.58 524.63 1728.07 523.02 1729.83C521.41 1731.6 519.14 1732.48 516.19 1732.48H516.18ZM516.18 1728.02C517.67 1728.02 518.8 1727.56 519.59 1726.65C520.38 1725.74 520.77 1724.44 520.77 1722.76V1722.74C520.77 1720.89 520.35 1719.46 519.52 1718.45C518.69 1717.44 517.49 1716.94 515.95 1716.94C514.57 1716.94 513.5 1717.45 512.74 1718.46C511.98 1719.47 511.6 1720.91 511.6 1722.76V1722.78C511.6 1724.46 512 1725.76 512.79 1726.66C513.59 1727.56 514.71 1728.01 516.17 1728.01L516.18 1728.02Z'
        fill='black'
      />
      <path
        d='M531.1 1727.07V1723.04L542.2 1699.08H546.98L536.15 1722.77H552.37V1727.07H531.09H531.1ZM545.37 1732.17V1712.69H549.9V1732.17H545.37Z'
        fill='black'
      />
      <path
        d='M516.18 1654.04C513.25 1654.04 510.98 1653.17 509.38 1651.42C507.77 1649.68 506.97 1647.22 506.97 1644.05V1644.03C506.97 1642.6 507.17 1641.09 507.56 1639.49C507.95 1637.89 508.52 1636.35 509.27 1634.86L516.46 1620.62H521.47L513.03 1637.12L513.05 1636.3C513.84 1634.8 515.24 1634.05 517.26 1634.05C519.85 1634.05 521.86 1634.91 523.29 1636.63C524.72 1638.35 525.43 1640.78 525.43 1643.92V1643.94C525.43 1647.14 524.63 1649.63 523.02 1651.39C521.41 1653.16 519.14 1654.04 516.19 1654.04H516.18ZM516.18 1649.58C517.67 1649.58 518.8 1649.12 519.59 1648.21C520.38 1647.3 520.77 1646 520.77 1644.32V1644.3C520.77 1642.45 520.35 1641.02 519.52 1640.01C518.69 1639 517.49 1638.5 515.95 1638.5C514.57 1638.5 513.5 1639.01 512.74 1640.02C511.98 1641.03 511.6 1642.47 511.6 1644.32V1644.34C511.6 1646.02 512 1647.32 512.79 1648.22C513.59 1649.12 514.71 1649.57 516.17 1649.57L516.18 1649.58Z'
        fill='black'
      />
      <path
        d='M540.8 1654.04C538.28 1654.04 536.22 1653.32 534.6 1651.87C532.98 1650.42 531.97 1648.38 531.56 1645.74V1645.72H536.2V1645.74C536.37 1646.95 536.85 1647.9 537.66 1648.57C538.46 1649.24 539.51 1649.58 540.8 1649.58C542.29 1649.58 543.44 1649.08 544.25 1648.09C545.06 1647.1 545.47 1645.7 545.47 1643.89V1641.11C545.47 1639.31 545.06 1637.91 544.25 1636.92C543.44 1635.93 542.29 1635.44 540.8 1635.44C540 1635.44 539.22 1635.67 538.48 1636.12C537.74 1636.57 537.08 1637.21 536.52 1638.03H532.29V1620.6H549.15V1625.01H536.93V1632.45C537.51 1631.98 538.15 1631.62 538.86 1631.36C539.57 1631.1 540.3 1630.97 541.04 1630.97C543.92 1630.97 546.15 1631.85 547.73 1633.62C549.31 1635.39 550.1 1637.88 550.1 1641.09V1643.87C550.1 1647.1 549.29 1649.6 547.67 1651.37C546.05 1653.14 543.76 1654.02 540.8 1654.02V1654.04Z'
        fill='black'
      />
      {/* {Booth N0 65} */}
      <path
        d='M575.49 1601.04H483.93V1679.5H575.49V1601.04Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2183)} 
        className={eid === 2183 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="510" y="1650" className='svgText'>65</text>

      {/* {Booth N0 66} */}
      <path
        d='M575.49 1522.65H483.93V1601.04H575.49V1522.65Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2184)} 
        className={eid === 2184 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M516.18 1575.61C513.25 1575.61 510.98 1574.74 509.38 1572.99C507.77 1571.25 506.97 1568.79 506.97 1565.62V1565.6C506.97 1564.17 507.17 1562.66 507.56 1561.06C507.95 1559.46 508.52 1557.92 509.27 1556.43L516.46 1542.19H521.47L513.03 1558.69L513.05 1557.87C513.84 1556.37 515.24 1555.62 517.26 1555.62C519.85 1555.62 521.86 1556.48 523.29 1558.2C524.72 1559.92 525.43 1562.35 525.43 1565.49V1565.51C525.43 1568.71 524.63 1571.2 523.02 1572.96C521.41 1574.73 519.14 1575.61 516.19 1575.61H516.18ZM516.18 1571.15C517.67 1571.15 518.8 1570.69 519.59 1569.78C520.38 1568.87 520.77 1567.57 520.77 1565.89V1565.87C520.77 1564.02 520.35 1562.59 519.52 1561.58C518.69 1560.57 517.49 1560.07 515.95 1560.07C514.57 1560.07 513.5 1560.58 512.74 1561.59C511.98 1562.6 511.6 1564.04 511.6 1565.89V1565.91C511.6 1567.59 512 1568.89 512.79 1569.79C513.59 1570.69 514.71 1571.14 516.17 1571.14L516.18 1571.15Z'
        fill='black'
      />
      <path
        d='M540.09 1575.61C537.16 1575.61 534.89 1574.74 533.29 1572.99C531.68 1571.25 530.88 1568.79 530.88 1565.62V1565.6C530.88 1564.17 531.08 1562.66 531.47 1561.06C531.86 1559.46 532.43 1557.92 533.18 1556.43L540.37 1542.19H545.38L536.94 1558.69L536.96 1557.87C537.75 1556.37 539.15 1555.62 541.17 1555.62C543.76 1555.62 545.77 1556.48 547.2 1558.2C548.63 1559.92 549.34 1562.35 549.34 1565.49V1565.51C549.34 1568.71 548.54 1571.2 546.93 1572.96C545.32 1574.73 543.05 1575.61 540.1 1575.61H540.09ZM540.09 1571.15C541.58 1571.15 542.71 1570.69 543.5 1569.78C544.29 1568.87 544.68 1567.57 544.68 1565.89V1565.87C544.68 1564.02 544.26 1562.59 543.43 1561.58C542.6 1560.57 541.4 1560.07 539.86 1560.07C538.48 1560.07 537.41 1560.58 536.65 1561.59C535.89 1562.61 535.51 1564.04 535.51 1565.89V1565.91C535.51 1567.59 535.91 1568.89 536.71 1569.79C537.51 1570.69 538.63 1571.14 540.09 1571.14V1571.15Z'
        fill='black'
      />
      {/* {Booth N0 67} */}
      <path
        d='M575.49 1443.86H483.93V1522.64H575.49V1443.86Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2184)} 
        className={eid === 2184 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M516.18 1497.02C513.25 1497.02 510.98 1496.15 509.38 1494.4C507.77 1492.66 506.97 1490.2 506.97 1487.03V1487.01C506.97 1485.58 507.17 1484.07 507.56 1482.47C507.95 1480.87 508.52 1479.33 509.27 1477.84L516.46 1463.6H521.47L513.03 1480.1L513.05 1479.28C513.84 1477.78 515.24 1477.03 517.26 1477.03C519.85 1477.03 521.86 1477.89 523.29 1479.61C524.72 1481.33 525.43 1483.76 525.43 1486.9V1486.92C525.43 1490.12 524.63 1492.61 523.02 1494.37C521.41 1496.14 519.14 1497.02 516.19 1497.02H516.18ZM516.18 1492.56C517.67 1492.56 518.8 1492.1 519.59 1491.19C520.38 1490.28 520.77 1488.98 520.77 1487.3V1487.28C520.77 1485.43 520.35 1484 519.52 1482.99C518.69 1481.98 517.49 1481.48 515.95 1481.48C514.57 1481.48 513.5 1481.99 512.74 1483C511.98 1484.01 511.6 1485.45 511.6 1487.3V1487.32C511.6 1489 512 1490.3 512.79 1491.2C513.59 1492.1 514.71 1492.55 516.17 1492.55L516.18 1492.56Z'
        fill='black'
      />
      <path
        d='M548.65 1463.59V1467.73L540.09 1496.67H535.15L543.7 1468H535.28V1472.76H530.64V1463.59H548.64H548.65Z'
        fill='black'
      />
      <path
        d='M516.27 1400.77C513.34 1400.77 511.07 1399.9 509.47 1398.15C507.86 1396.41 507.06 1393.95 507.06 1390.78V1390.76C507.06 1389.34 507.26 1387.82 507.65 1386.22C508.04 1384.62 508.61 1383.08 509.36 1381.59L516.55 1367.35H521.56L513.12 1383.85L513.14 1383.03C513.93 1381.53 515.33 1380.78 517.35 1380.78C519.94 1380.78 521.95 1381.64 523.38 1383.36C524.81 1385.08 525.52 1387.51 525.52 1390.65V1390.67C525.52 1393.87 524.72 1396.36 523.11 1398.12C521.5 1399.89 519.23 1400.77 516.28 1400.77H516.27ZM516.27 1396.31C517.76 1396.31 518.89 1395.85 519.68 1394.94C520.47 1394.03 520.86 1392.73 520.86 1391.05V1391.03C520.86 1389.18 520.44 1387.75 519.61 1386.74C518.78 1385.73 517.58 1385.23 516.04 1385.23C514.66 1385.23 513.59 1385.74 512.83 1386.75C512.07 1387.76 511.69 1389.2 511.69 1391.05V1391.07C511.69 1392.75 512.09 1394.05 512.88 1394.95C513.68 1395.85 514.8 1396.3 516.26 1396.3L516.27 1396.31Z'
        fill='black'
      />
      <path
        d='M541.34 1400.77C539.32 1400.77 537.56 1400.39 536.06 1399.63C534.56 1398.87 533.39 1397.8 532.56 1396.42C531.73 1395.04 531.31 1393.42 531.31 1391.57V1391.02C531.31 1389.37 531.72 1387.81 532.55 1386.36C533.38 1384.9 534.48 1383.8 535.86 1383.06C534.71 1382.42 533.77 1381.5 533.06 1380.3C532.35 1379.1 531.99 1377.82 531.99 1376.49V1375.69C531.99 1373.07 532.84 1370.96 534.55 1369.38C536.26 1367.8 538.52 1367 541.34 1367C544.16 1367 546.43 1367.79 548.13 1369.38C549.84 1370.97 550.69 1373.07 550.69 1375.69V1376.49C550.69 1377.86 550.33 1379.14 549.61 1380.34C548.89 1381.54 547.94 1382.45 546.75 1383.07C548.15 1383.81 549.26 1384.91 550.11 1386.37C550.95 1387.83 551.37 1389.38 551.37 1391.03V1391.58C551.37 1393.43 550.95 1395.05 550.12 1396.43C549.29 1397.81 548.12 1398.88 546.62 1399.64C545.12 1400.4 543.36 1400.78 541.34 1400.78V1400.77ZM541.34 1396.31C542.96 1396.31 544.27 1395.84 545.26 1394.91C546.25 1393.98 546.75 1392.75 546.75 1391.24V1390.92C546.75 1389.39 546.25 1388.16 545.26 1387.22C544.27 1386.29 542.96 1385.82 541.34 1385.82C539.72 1385.82 538.41 1386.29 537.42 1387.22C536.43 1388.15 535.93 1389.39 535.93 1390.94V1391.28C535.93 1392.8 536.43 1394.01 537.42 1394.93C538.41 1395.85 539.72 1396.31 541.34 1396.31ZM541.34 1381.37C542.75 1381.37 543.89 1380.92 544.75 1380.03C545.61 1379.14 546.05 1377.95 546.05 1376.48V1376.16C546.05 1374.75 545.62 1373.62 544.75 1372.76C543.89 1371.9 542.75 1371.47 541.34 1371.47C539.93 1371.47 538.79 1371.9 537.93 1372.76C537.07 1373.62 536.63 1374.76 536.63 1376.18V1376.52C536.63 1377.98 537.06 1379.15 537.93 1380.04C538.79 1380.93 539.93 1381.37 541.34 1381.37Z'
        fill='black'
      />
      {/* {Booth N0 68} */}
      <path
        d='M575.54 1365.55H484.06V1409.47H575.54V1365.55Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M551.65 1174.55C548.72 1174.55 546.45 1173.68 544.85 1171.93C543.24 1170.19 542.44 1167.73 542.44 1164.56V1164.54C542.44 1163.12 542.64 1161.6 543.03 1160C543.42 1158.4 543.99 1156.86 544.74 1155.37L551.93 1141.13H556.94L548.5 1157.63L548.52 1156.81C549.31 1155.31 550.71 1154.56 552.73 1154.56C555.32 1154.56 557.33 1155.42 558.76 1157.14C560.19 1158.86 560.9 1161.29 560.9 1164.43V1164.45C560.9 1167.65 560.1 1170.14 558.49 1171.9C556.88 1173.67 554.61 1174.55 551.66 1174.55H551.65ZM551.65 1170.09C553.14 1170.09 554.27 1169.63 555.06 1168.72C555.85 1167.81 556.24 1166.51 556.24 1164.83V1164.81C556.24 1162.96 555.82 1161.53 554.99 1160.52C554.16 1159.51 552.96 1159.01 551.42 1159.01C550.04 1159.01 548.97 1159.52 548.21 1160.53C547.45 1161.54 547.07 1162.98 547.07 1164.83V1164.85C547.07 1166.53 547.47 1167.83 548.26 1168.73C549.06 1169.63 550.18 1170.08 551.64 1170.08L551.65 1170.09Z'
        fill='black'
      />
      <path
        d='M570.42 1174.21L578.75 1157.78L578.73 1158.6C578.34 1159.31 577.75 1159.83 576.98 1160.15C576.21 1160.47 575.31 1160.63 574.3 1160.63C571.92 1160.63 570 1159.75 568.54 1157.98C567.08 1156.21 566.36 1153.87 566.36 1150.94V1150.92C566.36 1147.7 567.17 1145.21 568.78 1143.45C570.4 1141.68 572.67 1140.8 575.59 1140.8C578.51 1140.8 580.79 1141.69 582.39 1143.47C584 1145.25 584.8 1147.77 584.8 1151.04V1151.06C584.8 1152.41 584.6 1153.87 584.21 1155.44C583.82 1157.01 583.24 1158.55 582.48 1160.05L575.43 1174.23H570.42V1174.21ZM575.58 1156.39C577.04 1156.39 578.16 1155.9 578.96 1154.91C579.76 1153.92 580.15 1152.54 580.15 1150.75V1150.73C580.15 1148.99 579.75 1147.64 578.96 1146.68C578.16 1145.72 577.04 1145.25 575.58 1145.25C574.12 1145.25 572.97 1145.73 572.18 1146.68C571.38 1147.64 570.98 1148.99 570.98 1150.75V1150.77C570.98 1152.56 571.38 1153.94 572.18 1154.92C572.98 1155.9 574.11 1156.39 575.58 1156.39Z'
        fill='black'
      />
      {/* {Booth No 70,71,72} */}
      <path
        d='M792.85 875.05H518.64V1115.63H792.85V875.05Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2186)} 
        className={eid === 2186 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M645.619 1047.85V1051.99L637.059 1080.93H632.119L640.679 1052.26H632.259V1057.02H627.619V1047.85H645.619Z'
        fill='black'
      />
      <path
        d='M661.43 1081.28C658.27 1081.28 655.91 1080.43 654.34 1078.74C652.77 1077.05 651.979 1074.76 651.979 1071.88V1056.84C651.979 1053.91 652.78 1051.61 654.37 1049.95C655.96 1048.28 658.309 1047.45 661.419 1047.45C664.529 1047.45 666.899 1048.28 668.479 1049.94C670.059 1051.6 670.859 1053.9 670.859 1056.85V1071.89C670.859 1074.82 670.069 1077.12 668.479 1078.78C666.899 1080.45 664.539 1081.28 661.419 1081.28H661.43ZM661.43 1076.86C663.13 1076.86 664.349 1076.42 665.099 1075.53C665.849 1074.64 666.229 1073.43 666.229 1071.88V1056.84C666.229 1055.28 665.849 1054.06 665.099 1053.18C664.349 1052.3 663.12 1051.86 661.43 1051.86C659.74 1051.86 658.51 1052.3 657.76 1053.18C657.01 1054.06 656.63 1055.28 656.63 1056.84V1071.88C656.63 1073.43 657.01 1074.64 657.76 1075.53C658.51 1076.42 659.74 1076.86 661.43 1076.86Z'
        fill='black'
      />
      <path
        d='M645.62 918.19V922.33L637.06 951.27H632.12L640.68 922.6H632.26V927.36H627.62V918.19H645.62Z'
        fill='black'
      />
      <path
        d='M659.97 918.19V951.27H655.33V923.24L650.62 926.13V921.33L655.33 918.19H659.97Z'
        fill='black'
      />
      <path
        d='M753.48 987.521V991.66L744.93 1020.6H739.99L748.55 991.93H740.13V996.69H735.49V987.521H753.49H753.48Z'
        fill='black'
      />
      <path
        d='M759.35 1020.61V1016.58L770.89 1001.2C771.56 1000.32 772.08 999.41 772.45 998.47C772.82 997.53 773.01 996.64 773.01 995.81V995.76C773.01 994.44 772.62 993.42 771.85 992.69C771.08 991.96 769.98 991.6 768.55 991.6C767.22 991.6 766.13 992 765.28 992.79C764.44 993.59 763.93 994.71 763.74 996.17V996.19H758.94V996.17C759.35 993.32 760.39 991.11 762.06 989.54C763.73 987.97 765.88 987.19 768.5 987.19C771.47 987.19 773.77 987.94 775.39 989.44C777.01 990.94 777.82 993.07 777.82 995.81V995.83C777.82 997 777.58 998.22 777.09 999.5C776.6 1000.78 775.93 1002.01 775.06 1003.17L765.48 1016.19H777.99V1020.6H759.33L759.35 1020.61Z'
        fill='black'
      />
      {/* {Booth No 75} */}
      <path
        d='M948.701 362.84H857.73V456.53H948.701V362.84Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M897.66 392.23V396.37L889.1 425.31H884.16L892.72 396.64H884.3V401.4H879.66V392.23H897.66Z'
        fill='black'
      />
      <path
        d='M913.269 425.65C910.749 425.65 908.689 424.93 907.069 423.48C905.449 422.03 904.439 419.99 904.029 417.35V417.33H908.669V417.35C908.839 418.56 909.319 419.51 910.129 420.18C910.929 420.85 911.979 421.19 913.269 421.19C914.759 421.19 915.909 420.69 916.719 419.7C917.529 418.71 917.939 417.31 917.939 415.5V412.72C917.939 410.92 917.529 409.52 916.719 408.53C915.909 407.54 914.759 407.05 913.269 407.05C912.469 407.05 911.689 407.28 910.949 407.73C910.209 408.18 909.549 408.82 908.989 409.64H904.759V392.21H921.619V396.62H909.399V404.06C909.979 403.59 910.619 403.23 911.329 402.97C912.039 402.71 912.769 402.58 913.509 402.58C916.389 402.58 918.619 403.46 920.199 405.23C921.779 407 922.569 409.49 922.569 412.7V415.48C922.569 418.71 921.759 421.21 920.139 422.98C918.519 424.75 916.229 425.63 913.269 425.63V425.65Z'
        fill='black'
      />
      {/* {Booth N0 78} */}
      <path
        d='M362.44 518.02H260.05V606.63H362.44V518.02Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M311.5 544.229V548.37L302.94 577.31H298L306.56 548.64H298.14V553.4H293.5V544.229H311.5Z'
        fill='black'
      />
      <path
        d='M327.56 577.66C325.54 577.66 323.78 577.28 322.28 576.52C320.78 575.76 319.61 574.69 318.78 573.31C317.95 571.93 317.53 570.31 317.53 568.46V567.91C317.53 566.26 317.94 564.7 318.77 563.25C319.6 561.79 320.7 560.69 322.08 559.95C320.93 559.31 319.99 558.39 319.28 557.19C318.57 555.99 318.21 554.71 318.21 553.38V552.58C318.21 549.96 319.06 547.85 320.77 546.27C322.48 544.69 324.74 543.89 327.56 543.89C330.38 543.89 332.65 544.68 334.35 546.27C336.06 547.86 336.91 549.96 336.91 552.58V553.38C336.91 554.75 336.55 556.03 335.83 557.23C335.11 558.43 334.16 559.34 332.97 559.96C334.37 560.7 335.48 561.8 336.33 563.26C337.17 564.72 337.59 566.27 337.59 567.92V568.47C337.59 570.32 337.17 571.94 336.34 573.32C335.51 574.7 334.34 575.77 332.84 576.53C331.34 577.29 329.58 577.67 327.56 577.67V577.66ZM327.56 573.2C329.18 573.2 330.49 572.73 331.48 571.8C332.47 570.87 332.97 569.64 332.97 568.13V567.81C332.97 566.28 332.47 565.05 331.48 564.11C330.49 563.18 329.18 562.71 327.56 562.71C325.94 562.71 324.63 563.18 323.64 564.11C322.65 565.04 322.15 566.28 322.15 567.83V568.17C322.15 569.69 322.65 570.9 323.64 571.82C324.63 572.74 325.94 573.2 327.56 573.2ZM327.56 558.25C328.97 558.25 330.11 557.8 330.97 556.91C331.83 556.02 332.27 554.83 332.27 553.36V553.04C332.27 551.63 331.84 550.5 330.97 549.64C330.11 548.78 328.97 548.35 327.56 548.35C326.15 548.35 325.01 548.78 324.15 549.64C323.29 550.5 322.85 551.64 322.85 553.06V553.4C322.85 554.86 323.28 556.03 324.15 556.92C325.02 557.81 326.15 558.25 327.56 558.25Z'
        fill='black'
      />
      <path
        d='M202.95 544.229V548.37L194.4 577.31H189.46L198.02 548.64H189.6V553.4H184.96V544.229H202.96H202.95Z'
        fill='black'
      />
      <path
        d='M212.71 577.319L221.04 560.889L221.02 561.709C220.63 562.419 220.04 562.939 219.27 563.259C218.5 563.579 217.6 563.739 216.59 563.739C214.21 563.739 212.29 562.859 210.83 561.089C209.37 559.319 208.65 556.979 208.65 554.049V554.029C208.65 550.809 209.46 548.319 211.07 546.559C212.69 544.789 214.96 543.909 217.88 543.909C220.8 543.909 223.08 544.799 224.68 546.579C226.29 548.359 227.09 550.879 227.09 554.149V554.169C227.09 555.519 226.89 556.979 226.5 558.549C226.11 560.119 225.53 561.659 224.77 563.159L217.72 577.339H212.71V577.319ZM217.87 559.499C219.33 559.499 220.45 559.009 221.25 558.019C222.05 557.029 222.44 555.649 222.44 553.859V553.839C222.44 552.099 222.04 550.749 221.25 549.789C220.45 548.829 219.33 548.359 217.87 548.359C216.41 548.359 215.26 548.839 214.47 549.789C213.67 550.749 213.27 552.099 213.27 553.859V553.879C213.27 555.669 213.67 557.049 214.47 558.029C215.27 559.009 216.4 559.499 217.87 559.499Z'
        fill='black'
      />
      {/* {Booth N0 79} */}
      <path
        d='M260.05 518.02H145.33V606.63H260.05V518.02Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2190)} 
        className={eid === 2190 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="185" y="573" className='svgText'>79</text>
 
      <path
        d='M195.551 666.269C193.531 666.269 191.771 665.889 190.271 665.129C188.771 664.369 187.601 663.299 186.771 661.919C185.941 660.539 185.521 658.919 185.521 657.069V656.519C185.521 654.869 185.93 653.309 186.76 651.859C187.59 650.399 188.691 649.299 190.071 648.559C188.921 647.919 187.991 646.999 187.271 645.799C186.561 644.589 186.201 643.319 186.201 641.989V641.189C186.201 638.569 187.05 636.459 188.76 634.879C190.47 633.299 192.731 632.499 195.551 632.499C198.371 632.499 200.63 633.289 202.34 634.879C204.05 636.469 204.9 638.569 204.9 641.189V641.989C204.9 643.359 204.541 644.639 203.821 645.839C203.101 647.039 202.151 647.949 200.961 648.569C202.361 649.309 203.471 650.409 204.321 651.869C205.161 653.329 205.581 654.879 205.581 656.529V657.079C205.581 658.929 205.161 660.549 204.331 661.929C203.501 663.309 202.331 664.379 200.831 665.139C199.331 665.899 197.571 666.279 195.551 666.279V666.269ZM195.551 661.809C197.171 661.809 198.481 661.339 199.471 660.409C200.461 659.479 200.961 658.249 200.961 656.739V656.419C200.961 654.889 200.461 653.659 199.471 652.719C198.481 651.789 197.171 651.319 195.551 651.319C193.931 651.319 192.621 651.789 191.631 652.719C190.641 653.649 190.141 654.889 190.141 656.439V656.779C190.141 658.299 190.641 659.509 191.631 660.429C192.621 661.349 193.931 661.809 195.551 661.809ZM195.551 646.859C196.961 646.859 198.101 646.409 198.961 645.519C199.821 644.619 200.26 643.439 200.26 641.969V641.649C200.26 640.239 199.831 639.109 198.961 638.249C198.091 637.389 196.961 636.959 195.551 636.959C194.141 636.959 193.001 637.389 192.141 638.249C191.271 639.109 190.84 640.249 190.84 641.669V642.009C190.84 643.469 191.271 644.639 192.141 645.529C193.001 646.419 194.141 646.859 195.551 646.859Z'
        fill='black'
      />
      <path
        d='M221.51 666.269C218.35 666.269 215.99 665.419 214.42 663.729C212.85 662.039 212.07 659.749 212.07 656.869V641.829C212.07 638.899 212.87 636.599 214.46 634.939C216.05 633.269 218.4 632.439 221.51 632.439C224.62 632.439 226.99 633.269 228.58 634.929C230.16 636.589 230.96 638.889 230.96 641.839V656.879C230.96 659.809 230.17 662.109 228.58 663.769C226.99 665.439 224.64 666.269 221.51 666.269ZM221.51 661.849C223.21 661.849 224.43 661.409 225.18 660.519C225.93 659.629 226.31 658.419 226.31 656.869V641.829C226.31 640.269 225.93 639.049 225.18 638.169C224.43 637.289 223.2 636.849 221.51 636.849C219.82 636.849 218.59 637.289 217.84 638.169C217.09 639.049 216.71 640.269 216.71 641.829V656.869C216.71 658.419 217.09 659.629 217.84 660.519C218.59 661.409 219.82 661.849 221.51 661.849Z'
        fill='black'
      />
      {/* {Booth N0 80} */}
      <path
        d='M260.05 606.63H145.33V688.25H260.05V606.63Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2192)} 
        className={eid === 2192 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="185" y="660" className='svgText'>80</text>
   
      <path
        d='M300.12 666.27C298.1 666.27 296.34 665.89 294.84 665.13C293.34 664.37 292.17 663.3 291.34 661.92C290.51 660.54 290.09 658.92 290.09 657.07V656.52C290.09 654.87 290.5 653.31 291.33 651.86C292.16 650.4 293.26 649.3 294.64 648.56C293.49 647.92 292.56 647 291.84 645.8C291.13 644.59 290.77 643.32 290.77 641.99V641.19C290.77 638.57 291.62 636.46 293.33 634.88C295.04 633.3 297.3 632.5 300.12 632.5C302.94 632.5 305.2 633.29 306.91 634.88C308.62 636.47 309.47 638.57 309.47 641.19V641.99C309.47 643.36 309.11 644.64 308.39 645.84C307.67 647.04 306.72 647.95 305.53 648.57C306.93 649.31 308.04 650.41 308.89 651.87C309.73 653.33 310.15 654.88 310.15 656.53V657.08C310.15 658.93 309.73 660.55 308.9 661.93C308.07 663.31 306.9 664.38 305.4 665.14C303.9 665.9 302.14 666.28 300.12 666.28V666.27ZM300.12 661.81C301.74 661.81 303.05 661.34 304.04 660.41C305.03 659.48 305.53 658.25 305.53 656.74V656.42C305.53 654.89 305.03 653.66 304.04 652.72C303.05 651.79 301.74 651.32 300.12 651.32C298.5 651.32 297.19 651.79 296.2 652.72C295.21 653.65 294.71 654.89 294.71 656.44V656.78C294.71 658.3 295.21 659.51 296.2 660.43C297.19 661.35 298.5 661.81 300.12 661.81ZM300.12 646.86C301.53 646.86 302.67 646.41 303.53 645.52C304.39 644.62 304.83 643.44 304.83 641.97V641.65C304.83 640.24 304.4 639.11 303.53 638.25C302.66 637.39 301.53 636.96 300.12 636.96C298.71 636.96 297.57 637.39 296.71 638.25C295.84 639.11 295.41 640.25 295.41 641.67V642.01C295.41 643.47 295.84 644.64 296.71 645.53C297.57 646.42 298.71 646.86 300.12 646.86Z'
        fill='black'
      />
      <path
        d='M324.63 632.84V665.92H319.99V637.89L315.28 640.78V635.98L319.99 632.84H324.63Z'
        fill='black'
      />
      {/* {Booth N0 81} */}
      <path
        d='M362.44 606.63H260.05V688.25H362.44V606.63Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2193)} 
        className={eid === 2193 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
         <text x="290" y="660" className='svgText'>81</text>
    
      <path
        d='M254.23 825.54C252.21 825.54 250.45 825.161 248.95 824.401C247.45 823.641 246.28 822.57 245.45 821.19C244.62 819.81 244.2 818.191 244.2 816.341V815.79C244.2 814.14 244.61 812.58 245.44 811.13C246.27 809.67 247.37 808.571 248.75 807.831C247.6 807.191 246.67 806.27 245.95 805.07C245.24 803.86 244.88 802.59 244.88 801.26V800.461C244.88 797.841 245.73 795.731 247.44 794.151C249.15 792.571 251.41 791.771 254.23 791.771C257.05 791.771 259.31 792.561 261.02 794.151C262.73 795.741 263.58 797.841 263.58 800.461V801.26C263.58 802.63 263.22 803.91 262.5 805.11C261.78 806.31 260.83 807.221 259.64 807.841C261.04 808.581 262.15 809.681 263 811.141C263.84 812.601 264.26 814.15 264.26 815.8V816.35C264.26 818.2 263.84 819.821 263.01 821.201C262.18 822.581 261.01 823.65 259.51 824.41C258.01 825.17 256.25 825.55 254.23 825.55V825.54ZM254.23 821.081C255.85 821.081 257.16 820.61 258.15 819.68C259.14 818.75 259.64 817.52 259.64 816.01V815.69C259.64 814.16 259.14 812.93 258.15 811.99C257.16 811.06 255.85 810.591 254.23 810.591C252.61 810.591 251.3 811.06 250.31 811.99C249.32 812.92 248.82 814.161 248.82 815.711V816.05C248.82 817.57 249.32 818.781 250.31 819.701C251.3 820.621 252.61 821.081 254.23 821.081ZM254.23 806.13C255.64 806.13 256.78 805.68 257.64 804.79C258.5 803.89 258.94 802.71 258.94 801.24V800.92C258.94 799.51 258.51 798.381 257.64 797.521C256.77 796.661 255.64 796.23 254.23 796.23C252.82 796.23 251.68 796.661 250.82 797.521C249.95 798.381 249.52 799.52 249.52 800.94V801.281C249.52 802.741 249.95 803.91 250.82 804.8C251.68 805.69 252.82 806.13 254.23 806.13Z'
        fill='black'
      />
      <path
        d='M270.251 825.2V821.17L281.791 805.79C282.461 804.91 282.981 804 283.351 803.06C283.721 802.12 283.911 801.23 283.911 800.4V800.35C283.911 799.03 283.521 798.01 282.751 797.28C281.981 796.55 280.881 796.19 279.451 796.19C278.121 796.19 277.031 796.59 276.191 797.38C275.351 798.18 274.841 799.3 274.651 800.76V800.78H269.851V800.76C270.261 797.91 271.301 795.7 272.971 794.13C274.641 792.56 276.781 791.78 279.411 791.78C282.381 791.78 284.681 792.53 286.301 794.03C287.921 795.53 288.741 797.66 288.741 800.4V800.42C288.741 801.59 288.501 802.81 288.011 804.09C287.521 805.37 286.851 806.6 285.981 807.76L276.401 820.78H288.911V825.19H270.251V825.2Z'
        fill='black'
      />
      {/* {Booth N0 82} */}
      <path
        d='M362.43 734.58H145.33V900.35H362.43V734.58Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2194)} 
        className={eid === 2194 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
       <text x="220" y="835" className='svgText'>82</text>
     
      <path
        d='M254.23 1167.62C252.21 1167.62 250.45 1167.24 248.95 1166.48C247.45 1165.72 246.28 1164.65 245.45 1163.27C244.62 1161.89 244.2 1160.27 244.2 1158.42V1157.87C244.2 1156.22 244.61 1154.66 245.44 1153.21C246.27 1151.75 247.37 1150.65 248.75 1149.91C247.6 1149.27 246.67 1148.35 245.95 1147.15C245.24 1145.94 244.88 1144.67 244.88 1143.34V1142.54C244.88 1139.92 245.73 1137.81 247.44 1136.23C249.15 1134.65 251.41 1133.85 254.23 1133.85C257.05 1133.85 259.31 1134.64 261.02 1136.23C262.73 1137.82 263.58 1139.92 263.58 1142.54V1143.34C263.58 1144.71 263.22 1145.99 262.5 1147.19C261.78 1148.39 260.83 1149.3 259.64 1149.92C261.04 1150.66 262.15 1151.76 263 1153.22C263.84 1154.68 264.26 1156.23 264.26 1157.88V1158.43C264.26 1160.28 263.84 1161.9 263.01 1163.28C262.18 1164.66 261.01 1165.73 259.51 1166.49C258.01 1167.25 256.25 1167.63 254.23 1167.63V1167.62ZM254.23 1163.16C255.85 1163.16 257.16 1162.69 258.15 1161.76C259.14 1160.83 259.64 1159.6 259.64 1158.09V1157.77C259.64 1156.24 259.14 1155.01 258.15 1154.07C257.16 1153.14 255.85 1152.67 254.23 1152.67C252.61 1152.67 251.3 1153.14 250.31 1154.07C249.32 1155 248.82 1156.24 248.82 1157.79V1158.13C248.82 1159.65 249.32 1160.86 250.31 1161.78C251.3 1162.7 252.61 1163.16 254.23 1163.16ZM254.23 1148.21C255.64 1148.21 256.78 1147.76 257.64 1146.87C258.5 1145.98 258.94 1144.79 258.94 1143.32V1143C258.94 1141.59 258.51 1140.46 257.64 1139.6C256.77 1138.74 255.64 1138.31 254.23 1138.31C252.82 1138.31 251.68 1138.74 250.82 1139.6C249.95 1140.46 249.52 1141.6 249.52 1143.02V1143.36C249.52 1144.82 249.95 1145.99 250.82 1146.88C251.68 1147.77 252.82 1148.21 254.23 1148.21Z'
        fill='black'
      />
      <path
        d='M279.311 1167.62C277.441 1167.62 275.811 1167.28 274.411 1166.61C273.011 1165.94 271.881 1164.96 271.031 1163.69C270.181 1162.42 269.631 1160.87 269.391 1159.05H274.121C274.331 1160.48 274.891 1161.52 275.801 1162.18C276.711 1162.84 277.881 1163.17 279.301 1163.17C280.831 1163.17 282.021 1162.76 282.871 1161.94C283.721 1161.12 284.141 1159.97 284.141 1158.48V1157.48C284.141 1155.83 283.741 1154.55 282.931 1153.65C282.131 1152.75 280.991 1152.3 279.521 1152.3H277.181V1147.89H279.521C280.821 1147.89 281.831 1147.5 282.541 1146.73C283.251 1145.96 283.601 1144.86 283.601 1143.45V1142.43C283.601 1141.13 283.221 1140.12 282.471 1139.4C281.721 1138.69 280.651 1138.33 279.271 1138.33C278.101 1138.33 277.111 1138.66 276.301 1139.32C275.491 1139.98 274.931 1141.02 274.631 1142.45H269.921C270.391 1139.72 271.441 1137.61 273.071 1136.11C274.701 1134.62 276.771 1133.87 279.271 1133.87C282.121 1133.87 284.331 1134.62 285.891 1136.12C287.451 1137.62 288.231 1139.74 288.231 1142.47V1143.02C288.231 1144.67 287.781 1146.11 286.891 1147.33C285.991 1148.55 284.731 1149.45 283.091 1150.03C284.901 1150.41 286.301 1151.29 287.291 1152.68C288.281 1154.07 288.781 1155.83 288.781 1157.97V1158.52C288.781 1161.42 287.951 1163.66 286.301 1165.26C284.651 1166.86 282.311 1167.65 279.291 1167.65L279.311 1167.62Z'
        fill='black'
      />
      {/* {Booth N0 83} */}
      <path
        d='M363.24 938.55H145.33V1383.02H363.24V938.55Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2195)} 
        className={eid === 2195 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="220" y="1150" className='svgText'>83</text>
    
      <path
        d='M172.33 1445.82C170.31 1445.82 168.55 1445.44 167.05 1444.68C165.55 1443.92 164.38 1442.85 163.55 1441.47C162.72 1440.09 162.3 1438.47 162.3 1436.62V1436.07C162.3 1434.42 162.71 1432.86 163.54 1431.41C164.37 1429.95 165.47 1428.85 166.85 1428.11C165.7 1427.47 164.77 1426.55 164.05 1425.35C163.34 1424.14 162.98 1422.87 162.98 1421.54V1420.74C162.98 1418.12 163.83 1416.01 165.54 1414.43C167.25 1412.85 169.51 1412.05 172.33 1412.05C175.15 1412.05 177.41 1412.84 179.12 1414.43C180.83 1416.02 181.68 1418.12 181.68 1420.74V1421.54C181.68 1422.91 181.32 1424.19 180.6 1425.39C179.88 1426.59 178.93 1427.5 177.74 1428.12C179.14 1428.86 180.25 1429.96 181.1 1431.42C181.94 1432.88 182.36 1434.43 182.36 1436.08V1436.63C182.36 1438.48 181.94 1440.1 181.11 1441.48C180.28 1442.86 179.11 1443.93 177.61 1444.69C176.11 1445.45 174.35 1445.83 172.33 1445.83V1445.82ZM172.33 1441.36C173.95 1441.36 175.26 1440.89 176.25 1439.96C177.24 1439.03 177.74 1437.8 177.74 1436.29V1435.97C177.74 1434.44 177.24 1433.21 176.25 1432.27C175.26 1431.34 173.95 1430.87 172.33 1430.87C170.71 1430.87 169.4 1431.34 168.41 1432.27C167.42 1433.2 166.92 1434.44 166.92 1435.99V1436.33C166.92 1437.85 167.42 1439.06 168.41 1439.98C169.4 1440.9 170.71 1441.36 172.33 1441.36ZM172.33 1426.41C173.74 1426.41 174.88 1425.96 175.74 1425.07C176.6 1424.18 177.04 1422.99 177.04 1421.52V1421.2C177.04 1419.79 176.61 1418.66 175.74 1417.8C174.87 1416.94 173.74 1416.51 172.33 1416.51C170.92 1416.51 169.78 1416.94 168.92 1417.8C168.05 1418.66 167.62 1419.8 167.62 1421.22V1421.56C167.62 1423.02 168.05 1424.19 168.92 1425.08C169.78 1425.97 170.92 1426.41 172.33 1426.41Z'
        fill='black'
      />
      <path
        d='M198.09 1445.82C195.57 1445.82 193.51 1445.1 191.89 1443.65C190.27 1442.2 189.26 1440.16 188.85 1437.52V1437.5H193.49V1437.52C193.66 1438.73 194.14 1439.68 194.95 1440.35C195.75 1441.02 196.8 1441.36 198.09 1441.36C199.58 1441.36 200.73 1440.86 201.54 1439.87C202.35 1438.88 202.76 1437.48 202.76 1435.67V1432.89C202.76 1431.09 202.35 1429.69 201.54 1428.7C200.73 1427.71 199.58 1427.22 198.09 1427.22C197.29 1427.22 196.51 1427.45 195.77 1427.9C195.03 1428.35 194.37 1428.99 193.81 1429.81H189.58V1412.38H206.44V1416.79H194.22V1424.23C194.8 1423.76 195.44 1423.4 196.15 1423.14C196.86 1422.88 197.59 1422.75 198.33 1422.75C201.21 1422.75 203.44 1423.63 205.02 1425.4C206.6 1427.17 207.39 1429.66 207.39 1432.87V1435.65C207.39 1438.88 206.58 1441.38 204.96 1443.15C203.34 1444.92 201.05 1445.8 198.09 1445.8V1445.82Z'
        fill='black'
      />
      {/* {Booth N0 85} */}
      <svg xmlns="http://www.w3.org/2000/svg" width="2000" height="2000">
      <path
        d='M220.76 1383.02H145.33V1474.54H220.76V1383.02Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2197)} 
        className={eid === 2197 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="160" y="1440" className='svgText'>85</text>
    </svg>

 
      <path
        d='M172.33 1551.75C170.31 1551.75 168.55 1551.37 167.05 1550.61C165.55 1549.85 164.38 1548.78 163.55 1547.4C162.72 1546.02 162.3 1544.4 162.3 1542.55V1542C162.3 1540.35 162.71 1538.79 163.54 1537.34C164.37 1535.88 165.47 1534.78 166.85 1534.04C165.7 1533.4 164.77 1532.48 164.05 1531.28C163.34 1530.07 162.98 1528.8 162.98 1527.47V1526.67C162.98 1524.05 163.83 1521.94 165.54 1520.36C167.25 1518.78 169.51 1517.98 172.33 1517.98C175.15 1517.98 177.41 1518.77 179.12 1520.36C180.83 1521.95 181.68 1524.05 181.68 1526.67V1527.47C181.68 1528.84 181.32 1530.12 180.6 1531.32C179.88 1532.52 178.93 1533.43 177.74 1534.05C179.14 1534.79 180.25 1535.89 181.1 1537.35C181.94 1538.81 182.36 1540.36 182.36 1542.01V1542.56C182.36 1544.41 181.94 1546.03 181.11 1547.41C180.28 1548.79 179.11 1549.86 177.61 1550.62C176.11 1551.38 174.35 1551.76 172.33 1551.76V1551.75ZM172.33 1547.29C173.95 1547.29 175.26 1546.82 176.25 1545.89C177.24 1544.96 177.74 1543.73 177.74 1542.22V1541.9C177.74 1540.37 177.24 1539.14 176.25 1538.2C175.26 1537.27 173.95 1536.8 172.33 1536.8C170.71 1536.8 169.4 1537.27 168.41 1538.2C167.42 1539.13 166.92 1540.37 166.92 1541.92V1542.26C166.92 1543.78 167.42 1544.99 168.41 1545.91C169.4 1546.83 170.71 1547.29 172.33 1547.29ZM172.33 1532.34C173.74 1532.34 174.88 1531.89 175.74 1531C176.6 1530.11 177.04 1528.92 177.04 1527.45V1527.13C177.04 1525.72 176.61 1524.59 175.74 1523.73C174.87 1522.87 173.74 1522.44 172.33 1522.44C170.92 1522.44 169.78 1522.87 168.92 1523.73C168.05 1524.59 167.62 1525.73 167.62 1527.15V1527.49C167.62 1528.95 168.05 1530.12 168.92 1531.01C169.78 1531.9 170.92 1532.34 172.33 1532.34Z'
        fill='black'
      />
      <path
        d='M197.39 1551.75C194.46 1551.75 192.19 1550.88 190.59 1549.13C188.98 1547.39 188.18 1544.93 188.18 1541.76V1541.74C188.18 1540.32 188.38 1538.8 188.77 1537.2C189.16 1535.6 189.73 1534.06 190.48 1532.57L197.67 1518.33H202.68L194.24 1534.83L194.26 1534.01C195.05 1532.51 196.45 1531.76 198.47 1531.76C201.06 1531.76 203.07 1532.62 204.5 1534.34C205.93 1536.06 206.64 1538.49 206.64 1541.63V1541.65C206.64 1544.85 205.84 1547.34 204.23 1549.1C202.62 1550.87 200.35 1551.75 197.4 1551.75H197.39ZM197.39 1547.29C198.88 1547.29 200.01 1546.83 200.8 1545.92C201.59 1545.01 201.98 1543.71 201.98 1542.03V1542.01C201.98 1540.16 201.56 1538.73 200.73 1537.72C199.9 1536.71 198.7 1536.21 197.16 1536.21C195.78 1536.21 194.71 1536.72 193.95 1537.73C193.19 1538.75 192.81 1540.18 192.81 1542.03V1542.05C192.81 1543.73 193.21 1545.03 194 1545.93C194.8 1546.83 195.92 1547.28 197.38 1547.28L197.39 1547.29Z'
        fill='black'
      />
      {/* {Booth No 86} */}
      <path
        d='M220.76 1474.54H145.33V1592.86H220.76V1474.54Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2197)} 
        className={eid === 2197 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <text x="160" y="1540" className='svgText'>86</text>
 
      <path
        d='M597.852 1180.25L579.656 1193.66L593.061 1211.85L611.256 1198.45L597.852 1180.25Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M657.354 1190.62L640.836 1206.04L645.279 1210.8L661.797 1195.38L657.354 1190.62Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      {/* {Booth N0 69} */}
      <path
        d='M653.16 1121.47H606.25V1145.97H653.16V1121.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2185)} 
        className={eid === 2185 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      {/* {Booth No 69} */}
      <path
        d='M646.374 1178.85L629.856 1194.28L645.281 1210.8L661.799 1195.37L646.374 1178.85Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        onClick={() => setEid(2185)} 
        className={eid === 2185 ? 'chashma-gala-2024-room-clicked' : 'chashma-gala-2024-room'}
      />
      <path
        d='M468.38 366.47H433V388.99H468.38V366.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M272.39 366.47H237.01V388.99H272.39V366.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M476.24 324.3H428.71V330.73H476.24V324.3Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M653.16 1121.47H606.25V1128.93H653.16V1121.47Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M584.534 1190.06L579.663 1193.65L593.068 1211.85L597.939 1208.26L584.534 1190.06Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M276.67 324.3H229.14V349.67H276.67V324.3Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M476.24 324.3H428.71V349.67H476.24V324.3Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M476.24 405.43H428.71V430.8H476.24V405.43Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M276.67 405.43H229.14V430.8H276.67V405.43Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M276.67 424.37H229.14V430.8H276.67V424.37Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M476.24 424.37H428.71V430.8H476.24V424.37Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M276.67 324.3H229.14V330.73H276.67V324.3Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default ChashamaGala2024Slide2;
